import propTypes from "prop-types"
import { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import Select from "react-select"

import { getAllPrices } from "services/prices"
import { Loading } from "components"

const SelectPrices = ({
  name,
  price,
  onChange,
  onBlur,
  values,
  setErrorPrices,
  setIsErrorPrices,
  invalid,
  editingPrice,
}) => {
  const [filtersPrices, setfiltersPrices] = useState("")
  const [inputSelectPrices, setInputSelectPrices] = useState("")
  const [loadFirst, setLoadFirst] = useState(false)

  const {
    data: prices,
    error: errorPrices,
    isError: isErrorPrices,
    isLoading: isLoadingPrices,
    isSuccess,
  } = useQuery(["prices", filtersPrices, filtersPrices.toString()], () => getAllPrices(filtersPrices))

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#ddd" : invalid ? "red" : "#ddd",
      "&:hover": {
        borderColor: state.isFocused ? "#ddd" : invalid ? "red" : "#ddd",
      },
    }),
  }

  useEffect(() => {
    setErrorPrices(errorPrices)
  }, [errorPrices])

  useEffect(() => {
    setIsErrorPrices(isErrorPrices)
  }, [isErrorPrices])

  useEffect(() => {
    if (isSuccess) {
      if (!loadFirst) setLoadFirst(true)
    }
  }, [isSuccess])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const params = new URLSearchParams("")
      params.set("search", inputSelectPrices)
      setfiltersPrices(params)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [inputSelectPrices])

  const optionsPrices = prices || []

  return prices || loadFirst ? (
    <Select
      className={invalid ? "is-invalid" : undefined}
      styles={customStyles}
      name={name}
      onChange={(val) => {
        onChange(val.value)
      }}
      defaultValue={{
        value: price,
        label:
          optionsPrices?.data?.results?.filter((e) => {
            return price === e?.id
          })[0]?.name ?? "",
      }}
      onBlur={onBlur}
      onInputChange={setInputSelectPrices}
      isLoading={isLoadingPrices}
      options={optionsPrices
        ?.map((price) => ({ value: price?.id, label: price?.name, active: price?.is_active }))
        ?.filter((element) => {
          let exist = true
          values?.forEach((priceOption) => {
            if (priceOption?.price === element?.value) {
              if (editingPrice !== element?.value) {
                exist = false
              }
            }
            if (element?.active === false) {
              exist = false
            }
          })
          return exist
        })}
      noOptionsMessage={() => "0 Listas de precios"}
      loadingMessage={() => "Cargando..."}
      placeholder="Lista de precios"
      aria-invalid={invalid}
    />
  ) : (
    <Loading isLoading={true} />
  )
}

SelectPrices.propTypes = {
  name: propTypes.string,
  price: propTypes.any,
  onChange: propTypes.func,
  onBlur: propTypes.func,
  values: propTypes.array.isRequired,
  setErrorPrices: propTypes.func,
  setIsErrorPrices: propTypes.func,
  invalid: propTypes.any,
  editingPrice: propTypes.string,
}

export default SelectPrices
