import axios from "axios"

import storage from "services/storage"

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000"

export const authUserKey = "authUser"

export const API = axios.create({
  baseURL: API_URL,
  responseType: "json",
  headers: { "Content-Type": "application/json" },
})

API.interceptors.request.use(function (config) {
  const USER = storage.get(authUserKey)
  const TOKEN = USER?.auth_token

  config.headers.Authorization = TOKEN ? `Bearer ${TOKEN}` : ""
  return config
}, 
function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
})

API.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const errorResponse = error.response
    if (errorResponse) {
      switch (errorResponse.status) {
        case 401:
          if (errorResponse?.data?.error_type === "NotAuthenticated") localStorage.removeItem(authUserKey)
          break
        case 500:
          throw Object({
            error_type: "InternalError",
            errors: [{ message: errorResponse?.request?.statusText }],
          })
        default:
          throw Object(errorResponse?.data)
      }
    }

    throw Object({
      error_type: "NetworkError",
      errors: [{ message: error?.message }],
    })
  }
)
