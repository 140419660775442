import React from "react"
import propTypes from "prop-types"
import { Button } from "reactstrap"

const TicketItemList = ({ item, openInvoiceModal, isOnlySeller, openTicketModal }) => {
  const date = new Date(item.date)
  const formattedDate = date.toLocaleString()
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }
  function currencyFormatter({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }

  return (
    <tr key={item.id}>
      <td style={styleCenterTable}>{item.folio}</td>
      <td style={styleCenterTable}>{formattedDate}</td>
      {!isOnlySeller && (
        <td style={styleCenterTable}>
          {item?.user?.first_name} {item?.user?.last_name}
        </td>
      )}
      <td style={styleCenterTable}>{item?.customer?.name}</td>
      <td style={styleCenterTable}>{currencyFormatter({ currency: "USD", value: item.total })}</td>
      <td style={styleCenterTable}>
        {item.uuid_invoice ? (
          <i className="fa fa-check" />
        ) : (
          <i
            style={{
              transform: "rotate(45deg)",
            }}
            className="fa fa-plus"
          />
        )}
      </td>
      <td style={styleCenterTable}>
        <Button
          style={{ marginRight: "5px" }}
          color="primary"
          onClick={() => openInvoiceModal(item.id)}
          title="Facturar"
        >
          <i className="fa fa-file-invoice-dollar" />
        </Button>
        <Button
          style={{ marginLeft: "5px" }}
          color="success"
          onClick={() => openTicketModal(item.id)}
          title="Ver detalles"
        >
          <i className="fa fa-list" />
        </Button>
      </td>
    </tr>
  )
}

TicketItemList.propTypes = {
  item: propTypes.object,
  openInvoiceModal: propTypes.func,
  isOnlySeller: propTypes.bool,
  openTicketModal: propTypes.func,
}

export default TicketItemList
