import React from "react"
import propTypes from "prop-types"
import { Table } from "reactstrap"

const InventoryItemList = ({ item, name, stores, checkboxCost }) => {
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }
  const styleBarCodeTable = { textAlign: "right", verticalAlign: "middle", width: "10rem" }
  const styleNameTable = { textAlign: "left", verticalAlign: "middle", width: "20rem" }
  const styleBrandeTable = { textAlign: "left", verticalAlign: "middle", width: "11rem" }
  const styleBorderedColumns = {
    borderLeft: "1px solid #b1b4b8",
    borderRight: "1px solid #b1b4b8",
  }

  const inventories = item || {}
  let valuesInventories
  if (inventories) {
    valuesInventories = Object.values(inventories)
  }

  function currencyFormatterMoney({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }

  function currencyFormatterQuantity({ value }) {
    const formatter = new Intl.NumberFormat("en-US", {})
    return formatter.format(value)
  }

  return (
    <tr key={item?.id} className="table-light">
      <th style={styleBarCodeTable} scope="row">
        {item.bar_code}
      </th>
      <td style={styleNameTable}>{name?.length <= 40 ? name : name?.substring(0, 40) + "..."}</td>
      <td style={styleBrandeTable}>{item?.brand}</td>
      {stores.map((store, index) => {
        return (
          <td key={store.id} style={styleBorderedColumns}>
            <Table className="mt-1 mb-0">
              <tbody>
                <td style={{ width: "5rem", textAlign: "center" }}>
                  {valuesInventories[index + 5]?.quantity
                    ? currencyFormatterQuantity({ value: valuesInventories[index + 5]?.quantity })
                    : "0"}
                </td>
                {checkboxCost && (
                  <td style={{ width: "5rem", textAlign: "center" }}>
                    {valuesInventories[index + 5]?.inventoryCost
                      ? currencyFormatterMoney({ currency: "USD", value: valuesInventories[index + 5]?.inventoryCost })
                      : "$0"}
                  </td>
                )}
              </tbody>
            </Table>
          </td>
        )
      })}
      <td style={styleCenterTable}>
        <Table className="mt-1 mb-0">
          <tbody>
            <td style={{ width: "5rem", textAlign: "center" }}>
              {currencyFormatterQuantity({ value: valuesInventories[valuesInventories.length - 1]?.quantity })}
            </td>
            {checkboxCost && (
              <td style={{ width: "5rem", textAlign: "center" }}>
                {currencyFormatterMoney({
                  currency: "USD",
                  value: valuesInventories[valuesInventories.length - 1]?.inventoryCost,
                })}
              </td>
            )}
          </tbody>
        </Table>
      </td>
    </tr>
  )
}

InventoryItemList.propTypes = {
  item: propTypes.object,
  name: propTypes.string,
  stores: propTypes.array,
  checkboxCost: propTypes.bool,
}

export default InventoryItemList
