import React, { useContext, useEffect, useState } from "react"
import { Alert, Button, Container, Col, Form, Row } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import { Formik } from "formik"

import storage from "services/storage"
import { authUserKey } from "config/API"
import { getCashCutCounterManual, createCashCut, getAllLimitForCashCut } from "services/periodic-tasks"
import Swal from "sweetalert2"
import { UserContext } from "App"
import "assets/scss/custom/components/periodic-task.scss"
import { EditModalForm } from "components/periodicTasks"

const PeriodicTask = () => {
  document.title = "Cortes de caja manuales | Punto de venta"
  const user = useContext(UserContext)
  const { data, error, isError, refetch } = useQuery(["cash-cuts"], () => getCashCutCounterManual())
  const limitCashCut = useQuery(["limit-cash-cuts"], () => getAllLimitForCashCut())
  const permissions = storage.get(authUserKey).user_permissions || []
  const canAdd = permissions.some((e) => e.codename === "add_cashcut")
  const counter = data?.results[0]

  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")
  const [modalCreate, setmodalCreate] = useState(false)
  const toggleModalCreate = () => {
    setmodalCreate(!modalCreate)
  }

  const initialValues = {
    type_cash_cut: "Corte de caja manual",
    user: user?.id,
  }

  const { mutateAsync } = useMutation(createCashCut)

  const submit = async (data) => {
    await mutateAsync(data)
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  useEffect(() => {
    if (showAlertSuccess) {
      Toast.fire("¡Actualizado!", msgSuccess, "success")
      setShowAlertSuccess(false)
    }
  }, [showAlertSuccess])

  const openCreateModal = (data) => {
    Swal.fire({
      title: "Crear corte de caja",
      text: "¿Está seguro que desea ejecutar un corte de caja manual?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        submit(data).then(() => {
          refetch()
          Toast.fire("Realizado!", "El corte de caja ha sido creado correctamente", "success")
        })
      }
    })
  }

  function currencyFormatter({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }

  return !permissions.some((e) => e.codename === "view_cashcut") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <>
      {canAdd && (
        <EditModalForm
          isOpen={modalCreate}
          toggle={toggleModalCreate}
          setShowAlertSuccess={setShowAlertSuccess}
          setMsgSuccess={setMsgSuccess}
          data={limitCashCut?.isLoading ? {} : limitCashCut?.data[0]}
          error={limitCashCut.error}
          isLoading={limitCashCut.isLoading}
          isError={limitCashCut.isError}
          refetch={limitCashCut.refetch}
          limitId={limitCashCut?.isLoading ? "" : limitCashCut?.data[0].id}
        />
      )}
      <Container fluid>
        <Alert color="danger" isOpen={isError}>
          {error?.errors?.map((error) => error?.message)}
        </Alert>

        <h2 style={{ textAlign: "center" }}>Estos son los valores actuales de la caja:</h2>
        <div className="bg-white p-3">
          <div className="image">
            <Row>
              <Col xs="auto" className="fa fa-clipboard-check display-grid col-icon">
                <i />
              </Col>
              <Col className="justify-content-center display-grid">
                <h4>
                  <b>Total general:</b> ${counter?.total_tickets}
                </h4>
                <h4>
                  <b>Total de facturas:</b> ${counter?.total_invoices}
                </h4>
                <h4>
                  <b>Total de tickets:</b> ${counter?.total_ticket_counter}
                </h4>
              </Col>
              <Col className="justify-content-center display-grid">
                <h4>
                  <b>Efectivo: </b> ${counter?.total_cash}
                </h4>
                <h4>
                  <b>Pago con tarjeta:</b> ${counter?.card_pay}
                </h4>
                <h4>&nbsp;</h4>
              </Col>
            </Row>
            <Row>
              {canAdd && (
                <Formik enableReinitialize initialValues={initialValues} onSubmit={submit}>
                  {({ values, handleChange, handleSubmit, handleBlur, touched, errors }) => (
                    <Form className="form-horizontal" onSubmit={handleSubmit}>
                      <Col className="justify-content-center" style={{ display: "flex", alignItems: "flex-end" }}>
                        <Button
                          style={{ alignContent: "flex-end", float: "right" }}
                          onClick={() => openCreateModal(values)}
                          color="success"
                        >
                          <i className="fa fa-check" /> Ejecutar
                        </Button>
                      </Col>
                    </Form>
                  )}
                </Formik>
              )}
            </Row>
            <br />
            <Row>
              {canAdd && (
                <Col className="justify-content-end" style={{ display: "flex", alignItems: "flex-end" }}>
                  <Col>
                    <Alert
                      color="info"
                      className="d-flex align-items-center me-2"
                      style={{ height: "2.3rem", marginBottom: "0" }}
                    >
                      El límite para realizar un corte de caja actualmente es de:<p>&nbsp;</p>
                      {limitCashCut?.data
                        ? currencyFormatter({ currency: "USD", value: limitCashCut?.data[0]?.limit })
                        : ""}
                    </Alert>
                  </Col>
                  <Button color="warning" className="d-flex align-items-center me-2" onClick={toggleModalCreate}>
                    <i className="me-2 fa fa-edit" />
                    <span className="text-truncate">Editar límite</span>
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </Container>
    </>
  )
}

export default PeriodicTask
