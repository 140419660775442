import * as Yup from "yup"

import { messages, FILE_SIZE, SUPPORTED_FORMATS } from "utils/messages"

const schemaImport = Yup.object({
  file: Yup.mixed()
    .test("required", messages.required, (value) => value !== undefined)
    .test("fileSize", messages.fileSize, (value) => value?.size <= FILE_SIZE)
    .test(
      "fileFormat",
      messages.unsupportedTypeFile,
      (value) => value === null || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
})

export default schemaImport
