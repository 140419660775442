import propTypes from "prop-types"
import { useEffect } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useMutation } from "@tanstack/react-query"

import { createInventory } from "services/inventories"
import InventoryForm from "./InventoryForm"
import { API } from "config/API"

const CreateModalInventory = ({ 
  isOpen, 
  toggle, 
  storeId, 
  setShowAlertSuccess, 
  setMsgSuccess, 
  inventories, 
  refetch,
  inventoriesRegistered
}) => {
  const {
   isLoading,
   isError, 
   error, 
   isSuccess, 
   mutateAsync 
} = useMutation(createInventory)

  const submit = async (data) => {
    const dataTemp = { ...data }
    dataTemp.product = dataTemp.product ? dataTemp.product.id : null
    await mutateAsync({ ...dataTemp, store: storeId })
  }

  const handleSearch = async () => {
    refetch(inventories.length = 0)
    const response = await API.get(
      `/stores-inventories?ordering=quantity&product__name=&store__id=${storeId}`
    )
    refetch(inventories.concat(response.results))
  }

  useEffect(() => {
    if (isSuccess) {
      setMsgSuccess("Inventario creado satisfactoriamente")
      handleSearch()
      setShowAlertSuccess(true)
      toggle()
    }
  }, [isSuccess])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Crear inventario
      </ModalHeader>
      <ModalBody>
        <InventoryForm
          submit={submit}
          toggle={toggle}
          isLoading={isLoading}
          isError={isError}
          error={error}
          isEdit={false}
          inventoriesRegistered={inventoriesRegistered}
        />
      </ModalBody>
    </Modal>
  )
}

CreateModalInventory.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  storeId: propTypes.number,
  setShowAlertSuccess: propTypes.func.isRequired,
  setMsgSuccess: propTypes.func.isRequired,
  inventories: propTypes.any,
  refetch: propTypes.func,
  inventoriesRegistered: propTypes.array,
}

export default CreateModalInventory
