import { API } from "config/API"

const createFormData = (payload) => {
  const FormData = require("form-data")
  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    if (key === "invoice_pdf" && (
      typeof(payload[key]) === "string" || 
      typeof(payload[key]) === "undefined" ||
      payload[key] === null
    )) {
      return
    }
    if (key === "invoice_xml" && (
      typeof(payload[key]) === "string" || 
      typeof(payload[key]) === "undefined" ||
      payload[key] === null
    )) {
      return
    }
    if (key === "files") {
      [...payload[key]].forEach((file) => {
        formData.append("files", {file}); // agregamos el archivo con su nombre original
      });
    } else {
      formData.append(key, payload[key]);
    }
  });  
  return formData;
}


export const getAllInvoices = async () => {
  const response = await API.get("/invoices/all")
  return response
}

export const getInvoices = async (filters) => {
  const response = await API.get("/invoices?ordering=-arrival_date", { params: filters })
  return response
}

export const getInvoice = async (id) => {
  const response = await API.get(`/invoices/${id}`)
  return response
}

export const createInvoice = async (payload) => {
  const response = await API.post("/invoices", createFormData(payload))
  return response
}

export const editInvoice = async (id, payload) => {
  const response = await API.patch(`/invoices/${id}`, createFormData(payload))
  return response
}

export const deleteInvoice = async (id) => {
  const response = await API.delete(`/invoices/${id}`)
  return response
}
