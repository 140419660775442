import { API } from "config/API"

export const getAllTaxRegimens = async () => {
  const response = await API.get("/tax-regimens/all")
  return response
}

export const getCFDIUses = async () => {
  const response = await API.get("/cfdi-uses")
  return response
}
