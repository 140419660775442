import React from "react"
import propTypes from "prop-types"

const ItemListRow = ({ item }) => {
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }

  return (
    <tr key={item.id} className="table-light">
      <th style={styleCenterTable} scope="row">{item.row}</th>
      <td style={styleCenterTable} className="text-center">
        <span className="text-truncate">{item.message}</span>
      </td>
    </tr>
  )
}

ItemListRow.propTypes = {
  item: propTypes.object,
}

export default ItemListRow
