import React from "react"
import propTypes from "prop-types"

import { Col, Input, Label, Row } from "reactstrap"
import { Loading, RequiredIcon } from "components"

const MovementRetrieve = ({ data, error, isLoading }) => {
  return (
    <>
      {!error && isLoading ? (
        <div className="my-5 text-center">
          <Loading isLoading={isLoading} size={2} />
        </div>
      ) : error && !isLoading ? (
        <div className="my-5 text-center">
          <h1>Error intenta mas tarde</h1>
        </div>
      ) : (
        <>
          <Row>
            <Col md={6}>
              <Label>
                Origen: <RequiredIcon />
              </Label>
              <Input name="source_store" type="text" value={data?.source_store?.name} disabled />
            </Col>
            <Col md={6}>
              <Label>
                Destino: <RequiredIcon />
              </Label>
              <Input name="destiny_store" type="text" value={data?.destiny_store?.name} disabled />
            </Col>
          </Row>
          <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Col md={6}>
              <Label>Fecha: </Label>
              <Input
                type="text"
                value={new Date(data?.date).toLocaleDateString("es-MX", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
                disabled
              />
            </Col>
            <Col md={6}>
              <Label>Usuario: </Label>
              <Input type="text" value={`${data?.user?.first_name} ${data?.user?.last_name}`} disabled />
            </Col>
          </Row>
          <Row style={{ marginTop: "20px", marginBottom: "10px" }}>
            <Label for="movement_details">Lista de productos</Label>
            <Col lg={5}>
              <Label className="form-label">
                Cantidad <RequiredIcon />
              </Label>
            </Col>
            <Col lg={7}>
              <Label className="form-label">
                Producto <RequiredIcon />
              </Label>
            </Col>
            {data?.details?.map((detail, index) => (
              <Row key={index}>
                <Col lg={5} className="mb-3">
                  <Input type="number" value={detail?.quantity} disabled />
                </Col>
                <Col lg={7}>
                  <Input type="text" value={detail?.product} disabled />
                </Col>
              </Row>
            ))}
          </Row>
          <Row>
            <Col>
              <Label>Motivo:</Label>
              <Input name="reason" type="text" value={data?.reason} disabled />
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col>
              <Label>Descripcion:</Label>
              <Input name="description" type="text" value={data?.description} disabled />
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

MovementRetrieve.propTypes = {
  data: propTypes.object,
  error: propTypes.any,
  isLoading: propTypes.bool,
}

export default MovementRetrieve
