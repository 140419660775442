import { API } from "config/API"

export const createTicket = async (payload) => {
  const response = await API.post("/tickets", payload)
  return response
}

export const getAllTickets = async () => {
  const response = await API.get("/tickets/all")
  return response
}

export const getTickets = async (filters) => {
  const response = await API.get("/tickets?ordering=-date", { params: filters })
  return response
}

export const getPaidTickets = async (filters) => {
  const response = await API.get("/tickets?is_paid=true&ordering=-date", { params: filters })
  return response
}

export const updateTicket = async (id, payload) => {
  const response = await API.put(`/tickets/${id}`, payload)
  return response
}

export const getInvoices = async (filters) => {
  const response = await API.get("/tickets/invoices?ordering=-date", { params: filters })
  return response
}

export const getPendingTickets = async (filters) => {
  const response = await API.get("/tickets/pending_tickets?ordering=-date", { params: filters })
  return response
}

export const getNoPaidTickets = async (filters) => {
  const response = await API.get("/tickets/no_paid_tickets?ordering=-date", { params: filters })
  return response
}

export const getTicket = async (id) => {
  const response = await API.get(`/tickets/${id}`)
  return response
}

export const deleteTicket = async (id) => {
  const response = await API.delete(`/tickets/${id}`)
  return response
}

export const createInvoice = async (id, payload) => {
  const response = await API.post(`/tickets/${id}/create_invoice`, payload)
  return response
}

export const cancelInvoice = async (id, payload) => {
  const response = await API.post(`/tickets/${id}/cancelation`, payload)
  return response
}

export const sendEmailInvoice = async (id, payload) => {
  const response = await API.post(`/tickets/${id}/send_mail`, payload)
  return response
}

export const acuseInvoice = async (id) => {
  const response = await API.get(`/tickets/${id}/acuse`)
  return response
}

export const pdfInvoice = async (id) => {
  const response = await API.get(`/tickets/${id}/recuperate_custom_pdf`, { responseType: "blob" })
  return response
}

export const usePdfInvoice = (id) => {
  const response = pdfInvoice(id)
  const nameFile = `factura_${id}.pdf`
  return response.then((data) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement("a")
    link.href = downloadUrl
    link.setAttribute("download", nameFile)
    document.body.appendChild(link)
    link.click()
    link.remove()
  })
}

export const xmlInvoice = async (id) => {
  const response = await API.get(`/tickets/${id}/recuperate_xml`, { responseType: "blob" })
  return response
}

export const useXmlInvoice = (id) => {
    const response = xmlInvoice(id)
    const nameFile = `factura_${id}.xml`
    return response.then((data) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement("a")
      link.href = downloadUrl
      link.setAttribute("download", nameFile)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
  }
