import React, { useState, useEffect, useCallback } from "react"
import propTypes from "prop-types"
import { useParams, useLocation } from "react-router-dom"
import { Container, Table, Alert, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { useMutation, useQuery } from "@tanstack/react-query"
import Swal from "sweetalert2"
import { debounce } from "lodash"

import { getProduct } from "services/products"
import { deleteProductPresentation } from "services/product-presentations"
import { Loading } from "components"
import { CreateModalForm, EditModalForm } from "components/products/ModalPresentation"
import storage from "services/storage"
import { authUserKey } from "config/API"

import ListHeader from "ui-componets/list-header"
import PresentationItemList from "components/products/productPresentationList"
import Shortcuts from "components/Shortcuts"

const ProductsPresentation = ({ idFromModal }) => {
  const { id: idParams } = useParams()
  const id = idParams || idFromModal
  const { data, error, isLoading, isError, refetch } = useQuery(["product", id], () => getProduct(id))
  const Location = useLocation()

  const [filters, setFilters] = useState("")
  const [searchTxt, setSearchTxt] = useState(new URLSearchParams(Location.search).get("search") ?? "")

  const [modalCreate, setmodalCreate] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)
  const [productId, setproductId] = useState(null)
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  const products = data?.presentations

  const productDeleted = useMutation((id) => deleteProductPresentation(id))

  const permissions = storage.get(authUserKey).user_permissions || []
  const canAdd = permissions.some((e) => e.codename === "add_product")
  const canEdit = permissions.some((e) => e.codename === "change_product")
  const canDelete = permissions.some((e) => e.codename === "delete_product")

  const toggleModalCreate = () => {
    setmodalCreate(!modalCreate)
  }

  const toggleModalEdit = () => {
    if (modalEdit) {
      setproductId("")
    }
    setmodalEdit(!modalEdit)
  }

  const openEditModal = (productId) => {
    setproductId(productId)
    toggleModalEdit()
  }

  const openDeleteModal = (row) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar al producto: <b>${row.description}</b>?`,
      text: "Este cambio no se podrá revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        productDeleted.mutateAsync(row?.id).then(() => {
          Toast.fire("¡Eliminado!", `El producto <b>${row.description}</b> ha sido eliminado correctamente`, "success")
          refetch()
        })
      }
    })
  }

  useEffect(() => {
    if (showAlertSuccess) {
      Toast.fire({
        icon: "success",
        title: msgSuccess,
      })
      refetch()
      setShowAlertSuccess(false)
    }
  }, [showAlertSuccess])

  const debouncedSearch = useCallback(
    debounce((text) => {
      const params = new URLSearchParams()
      params.set("search", text)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    }, 300),
    []
  )

  function handleChangeSearch(event) {
    setSearchTxt(event.target.value)
    debouncedSearch(event.target.value)
  }

  const handleSearch = (pageParams) => {
    const params = new URLSearchParams(filters)
    if (pageParams) {
      const newParams = new URLSearchParams(pageParams)
      window.history.replaceState({}, null, `${Location.pathname}?${newParams}`)
      setFilters(newParams)
    } else if (searchTxt) {
      params.set("search", searchTxt)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    } else {
      if (params.get("search")) {
        params.delete("offset")
        params.delete("per_page")
        params.delete("search")
        window.history.replaceState({}, null, `${Location.pathname}?${params}`)
        setFilters(params)
      }
    }
  }

  useEffect(() => {
    setFilters(Location.search)
  }, [])

  return !permissions.some((e) => e.codename === "view_product") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <React.Fragment>
      {canAdd && (
        <CreateModalForm
          isOpen={modalCreate}
          toggle={toggleModalCreate}
          refetch={refetch}
          productId={id}
          setShowAlertSuccess={setShowAlertSuccess}
          setMsgSuccess={setMsgSuccess}
        />
      )}
      {canEdit && productId && (
        <EditModalForm
          isOpen={modalEdit}
          toggle={toggleModalEdit}
          refetch={refetch}
          productId={productId}
          setShowAlertSuccess={setShowAlertSuccess}
          setMsgSuccess={setMsgSuccess}
        />
      )}
      <Container fluid>
        <Alert color="danger" isOpen={isError}>
          {error?.message}
        </Alert>
        <Alert color="success" isOpen={showAlertSuccess} toggle={() => setShowAlertSuccess(false)}>
          {msgSuccess}
        </Alert>

        <h2>Lista de Presentaciones de Producto</h2>
        <div className="bg-white p-3">
          <ListHeader
            handleOnChangeSearch={handleChangeSearch}
            handleSearch={handleSearch}
            searchTxt={searchTxt}
            canAdd={canAdd}
            toggleModalCreate={toggleModalCreate}
          />
          {!isError && isLoading ? (
            <div className="my-5 text-center">
              <Loading isLoading={isLoading} size={2} />
            </div>
          ) : isError && !isLoading ? (
            <div className="my-5 text-center">
              <h1>Error intenta mas tarde</h1>
            </div>
          ) : (
            <div className="table-responsive mt-3">
              <Table>
                <thead>
                  <tr>
                    <th>Código de barras</th>
                    <th>Nombre</th>
                    <th>Cantidad por presentación</th>
                    <th className="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {products?.length ? (
                    products.sort((a, b)=>{
                      if (a.key > b.key) {
                        return 1;
                      }
                      if (a.key < b.key) {
                        return -1;
                      }
                      return 0;
                    })?.map((item) => {
                      return (
                        <PresentationItemList
                          key={item.id}
                          item={item}
                          canDelete={canDelete}
                          canEdit={canEdit}
                          openDeleteModal={openDeleteModal}
                          openEditModal={openEditModal}
                        />
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No hay datos
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="text-center">
                {(products.data?.next || products.data?.previous) && (
                  <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                    {products.data?.previous && (
                      <PaginationItem>
                        <PaginationLink previous />
                      </PaginationItem>
                    )}
                    {products.data?.next && (
                      <PaginationItem>
                        <PaginationLink next />
                      </PaginationItem>
                    )}
                  </Pagination>
                )}
              </div>
            </div>
          )}
        </div>
      </Container>
      <Shortcuts toggleModalCreate={toggleModalCreate} />
    </React.Fragment>
  )
}

ProductsPresentation.propTypes = {
  idFromModal: propTypes.string
}

export default ProductsPresentation
