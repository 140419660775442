import React from "react"
import propTypes from "prop-types"
import {
  Button,
} from "reactstrap"


const AgentsItemList = ({item, canDelete, canEdit, openEditModal, openDeleteModal}) => {
  const renderDeleteButton = () => {
    if(!canDelete){
      return null;
    }

    return (
      <Button color="primary" className="m-1" onClick={() => openEditModal(item.id)}>
        <i className="fa fa-edit" />
      </Button>
    )
  };

  const renderEditButton = () => {
    if (!canEdit) {
      return null;
    }

    return (
      <Button color="danger" onClick={() => openDeleteModal(item)}>
        <i className="fa fa-trash" />
      </Button>
    );
  };

  return (
    <tr>
      <td>{item.name}</td>
      <td>{item.email}</td>
      <td>{item.phone}</td>
      <td>{item.provider?.name || "--"}</td>
      <td className="text-center">
        { renderDeleteButton() }
        { renderEditButton() }
      </td>
    </tr>
  )
}


AgentsItemList.propTypes = {
  item: propTypes.object,
  canDelete: propTypes.bool,
  canEdit: propTypes.bool,
  openEditModal: propTypes.func,
  openDeleteModal: propTypes.func,
}


export default AgentsItemList
