import { API } from "config/API"

export const getAllBranches = async (filters) => {
  const response = await API.get("/branch-offices/all", { params: filters })
  return response
}

export const getBranches = async (filters) => {
  const response = await API.get("/branch-offices", { params: filters })
  return response
}

export const getBranch = async (id) => {
  const response = await API.get(`/branch-offices/${id}`)
  return response
}

export const createBranch = async (payload) => {
  const response = await API.post("/branch-offices", payload)
  return response
}

export const editBranch = async (id, payload) => {
  const response = await API.patch(`/branch-offices/${id}`, payload)
  return response
}

export const deleteBranch = async (id) => {
  const response = await API.delete(`/branch-offices/${id}`)
    if (response.status !== 400) {
    throw new Error(response.data.errors[0].message);
  }

  return response
}
