import { useState, useEffect } from "react"
import propTypes from "prop-types"
import { Alert, Button, Col, Form, Input, Label, Row } from "reactstrap"
import { Link } from "react-router-dom"
import { Formik } from "formik"

import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"
import schema from "./schema"

const BranchesForm = (props) => {
  const {
    data,
    error,
    isError,
    isLoading,
    isRetrivePage,
    submit,
    toggle,
  } = props
  const [shownErrorAlert, setShownErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShownErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShownErrorAlert(false)
    }
  }, [isError])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: data?.name ?? "",
        location: data?.location ?? "",
      }}
      validationSchema={schema}
      onSubmit={submit}
    >
      {({ values, handleChange, handleSubmit, handleBlur, touched, errors }) => (
        <Form className="form-horizontal" onSubmit={handleSubmit}>
          <Alert color="danger" isOpen={shownErrorAlert} toggle={() => setShownErrorAlert(false)}>
            {isError && error.errors?.map((error) => error?.message)}
          </Alert>

          <Row className="mb-3">
            <Col>
              <Label className="form-label">
                Nombre de la sucursal <RequiredIcon />
              </Label>
              <Input
                autoComplete="do-not-autofill"
                className="form-control"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                invalid={
                  !!(touched.name && errors.name) ||
                  (isError && validationErrors.findIndex((e) => e.field === "name") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="name"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
            <Col>
              <Label className="form-label">Domicilio</Label>
              <Input
                autoComplete="do-not-autofill"
                className="form-control"
                name="location"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.location}
                invalid={
                  !!(touched.location && errors.location) ||
                  (isError && validationErrors.findIndex((e) => e.field === "location") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="location"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
          </Row>

          <hr />

          <Row>
            <Col className="d-flex justify-content-end">
              {isRetrivePage ? (
                <Link to={{ pathname: "/branch-offices" }}>
                  <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                    <i className="fa fa-times" /> Salir
                  </Button>
                </Link>
              ) : (
                <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                  <i className="fa fa-times" /> Cerrar
                </Button>
              )}
              <Button color="success" type="submit" disabled={isLoading}>
                <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

BranchesForm.defaultProps = {
  data: null,
  disabledForm: false,
  isRetrivePage: false,
}

BranchesForm.propTypes = {
  data: propTypes.any,
  disabledForm: propTypes.bool,
  error: propTypes.any,
  isCreate: propTypes.bool,
  isError: propTypes.bool,
  isLoading: propTypes.bool,
  isRetrivePage: propTypes.bool,
  submit: propTypes.func,
  toggle: propTypes.func,
}

export default BranchesForm
