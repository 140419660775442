import { useState, useEffect } from "react"
import propTypes from "prop-types"
import { useQuery, useMutation } from "@tanstack/react-query"
import { Alert, Button, Col, Form, FormFeedback, Input, Label, Row, Table } from "reactstrap"
import { FieldArray, Formik } from "formik"
import Select from "react-select"
import Swal from "sweetalert2"

import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"
import schema from "./schema"
import "assets/scss/custom/pages/providers.scss"
import { AgentCreateModalForm, AgentEditModalForm, AgentsItemList } from "./agents"
import { deleteAgent } from "services/agents"
import { getAllBrands } from "services/brands"

const ProviderForm = (props) => {
  const { data, disabledForm, error, isError, isLoading, isRetrivePage, refetchData, submit, toggle } = props
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")
  const [observationsCounter, setObservationCounter] = useState(255 - data?.observations?.length || 255)
  const [agentId, setAgentId] = useState(null)
  const [shownErrorAlert, setShownErrorAlert] = useState(false)
  const [shownSuccessAlert, setShownSuccessAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const agentDelete = useMutation((id) => deleteAgent(id))
  const [filters, setfilters] = useState("")
  const [inputSelect, setInputSelect] = useState("")
  const brands = useQuery(["brands"], () => getAllBrands(filters))
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const params = new URLSearchParams("")
      params.set("search", inputSelect)
      setfilters(params)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [inputSelect])

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShownErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShownErrorAlert(false)
    }
  }, [isError])

  useEffect(() => {
    if (shownSuccessAlert) {
      Toast.fire({
        icon: "success",
        title: msgSuccess,
        didOpen: () => {
          refetchData()
          setShownSuccessAlert(false)
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "green",
      })
    }
  }, [shownSuccessAlert])

  const openDeleteModal = (row) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar al agente: <b>${row?.name}</b>?`,
      text: "Este cambio no se podrá revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        agentDelete.mutateAsync(row?.id).then(() => {
          refetchData()
          Toast.fire("!Eliminado!", `El agente <b>${row?.name}</b> ha sido eliminado correctamente`, "success")
        })
      }
    })
  }

  const openEditModal = (item) => {
    setAgentId(item.id)
    toggleEditModal()
  }

  const handleOnKeyDown = (event) => {
    if (event.key === "Backspace" && event.target.value.length) {
      return setObservationCounter(observationsCounter + 1)
    }
    if (event.target.value.length) {
      return setObservationCounter(observationsCounter - 1)
    }
  }

  const CustomHandleOnChange = (event, handleChange) => {
    if (!event.target.value.length) {
      setObservationCounter(255)
    }
    handleChange(event)
  }

  const toggleCreateModal = () => {
    setIsCreateModalOpen(!isCreateModalOpen)
  }

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        agents: data?.agents ?? [],
        business_name: data?.business_name ?? "",
        credit_days: data?.credit_days ?? 0,
        email: data?.email ?? "",
        name: data?.name ?? "",
        observations: data?.observations ?? "",
        phone: data?.phone ?? "",
        rfc: data?.rfc ?? "",
        brands: data?.brands?.map((brand) => ({ value: brand.id, id: brand.id, label: brand.name })) ?? [],
      }}
      validationSchema={schema}
      onSubmit={submit}
    >
      {({ values, handleChange, handleSubmit, handleBlur, touched, errors, setFieldValue }) => (
        <Form className="form-horizontal" onSubmit={handleSubmit}>
          <Alert color="danger" isOpen={shownErrorAlert} toggle={() => setShownErrorAlert(false)}>
            {isError && error?.errors?.map((error) => error?.message)}
          </Alert>
          {data?.id && (
            <>
              {!disabledForm && (
                <AgentCreateModalForm
                  isOpen={isCreateModalOpen}
                  providerId={data?.id}
                  registeredPrices={data?.prices}
                  setMsgSuccess={setMsgSuccess}
                  setShowAlertSuccess={setShownSuccessAlert}
                  toggle={toggleCreateModal}
                />
              )}
              {!disabledForm && agentId && (
                <AgentEditModalForm
                  agentId={agentId}
                  isOpen={isEditModalOpen}
                  registeredPrices={data?.prices}
                  setMsgSuccess={setMsgSuccess}
                  setShowAlertSuccess={setShownSuccessAlert}
                  toggle={toggleEditModal}
                />
              )}
            </>
          )}
          <Row className="mb-3">
            <Col>
              <Label className="form-label">
                Nombre del negocio <RequiredIcon />
              </Label>
              <Input
                autoComplete="off"
                className="form-control"
                name="name"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                invalid={
                  !!(touched.name && errors.name) ||
                  (isError && validationErrors.findIndex((e) => e.field === "name") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="name"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
            <Col>
              <Label className="form-label">Razón Social</Label>
              <Input
                autoComplete="off"
                className="form-control"
                name="business_name"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.business_name?.toUpperCase()}
                invalid={
                  !!(touched.business_name && errors.business_name) ||
                  (isError && validationErrors.findIndex((e) => e.field === "business_name") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="business_name"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Label className="form-label">
                Teléfono <RequiredIcon />
              </Label>
              <Input
                autoComplete="off"
                className="form-control"
                maxLength={10}
                name="phone"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                invalid={
                  !!(touched.phone && errors.phone) ||
                  (isError && validationErrors.findIndex((e) => e.field === "phone") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="phone"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
            <Col>
              <Label className="form-label">Email</Label>
              <Input
                autoComplete="off"
                className="form-control"
                name="email"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                invalid={
                  !!(touched.email && errors.email) ||
                  (isError && validationErrors.findIndex((e) => e.field === "email") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="email"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Label className="form-label">Días de crédito</Label>
              <Input
                autoComplete="off"
                className="form-control"
                maxLength={13}
                name="credit_days"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.credit_days}
                invalid={
                  !!(touched.credit_days && errors.credit_days) ||
                  (isError && validationErrors.findIndex((e) => e.field === "credit_days") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="credit_days"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
            <Col>
              <Label className="form-label">RFC</Label>
              <Input
                autoComplete="off"
                className="form-control"
                maxLength={13}
                name="rfc"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.rfc?.toUpperCase()}
                invalid={
                  !!(touched.rfc && errors.rfc) ||
                  (isError && validationErrors.findIndex((e) => e.field === "rfc") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="rfc"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Label for="brands">Marcas</Label>
              <Select
                name="brands"
                id="brands"
                className={
                  (touched.brands && errors.brands) ||
                  (isError && validationErrors.findIndex((e) => e.field === "brands") >= 0)
                    ? "is-invalid"
                    : undefined
                }
                isMulti
                value={values.brands}
                isDisabled={disabledForm}
                onChange={(val) => {
                  setFieldValue("brands", val)
                }}
                onBlur={handleBlur}
                onInputChange={setInputSelect}
                isLoading={isLoading}
                options={brands?.data?.map((element) => ({
                  value: element.id,
                  id: element.id,
                  label: element.name,
                }))}
                noOptionsMessage={() => "0 marcas"}
                loadingMessage={() => "Cargando..."}
                placeholder="Marcas"
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="brands"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Label className="form-label">Observaciones</Label>
              <Input
                autoComplete="off"
                className="form-control"
                maxLength={255}
                name="observations"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={(event) => CustomHandleOnChange(event, handleChange)}
                onKeyDown={handleOnKeyDown}
                type="textarea"
                value={values.observations}
                invalid={
                  !!(touched.observations && errors.observations) ||
                  (isError && validationErrors.findIndex((e) => e.field === "observations") >= 0)
                }
              />
              <div className="observation-counter">
                <span>{observationsCounter}</span>
              </div>
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="observations"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mb-3">
            <Col className="justify-content" style={{ display: "flex", alignItems: "flex-end" }} >
              <h5>Agentes</h5>
            </Col>
            <Col className="text-end">
              {!disabledForm && data?.id && (
                <Button
                  color="info"
                  onClick={() => {
                    toggleCreateModal()
                  }}
                >
                  <i className="fa fa-plus" /> Agregar
                </Button>
              )}
            </Col>
          </Row>
          {data?.id ? (
            <Table striped>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Email</th>
                  <th>Teléfono</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {data?.agents?.map((agent) =>
                  AgentsItemList({
                    item: agent,
                    canDelete: !disabledForm,
                    canEdit: !disabledForm,
                    openEditModal,
                    openDeleteModal,
                  })
                )}
              </tbody>
            </Table>
          ) : (
            <FieldArray
              name="agents"
              value={values.agents}
              render={(arrayHelpers) => (
                <>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Button
                          color="info"
                          type="button"
                          onClick={() => arrayHelpers.push({ id: "", name: "", email: "", phone: "" })}
                        >
                          <i className="fa fa-plus" /> Agregar
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {values.agents?.map((agent, index) => (
                        <div className="mt-2" key={`agents.${index}.id`}>
                          <Row className="mb-3">
                            <Col>
                              <Label className="form-label">
                                Nombre <RequiredIcon />
                              </Label>
                              <Input
                                name={`agents.${index}.name`}
                                className="form-control"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={agent.name}
                                invalid={
                                  !!(
                                    touched?.agents &&
                                    touched?.agents[index]?.name &&
                                    errors.agents &&
                                    errors?.agents[index]?.name
                                  )
                                }
                              />
                              {touched?.agents &&
                                touched?.agents[index]?.name &&
                                errors?.agents &&
                                errors?.agents[index]?.name && (
                                  <FormFeedback type="invalid">{errors?.agents[index]?.name}</FormFeedback>
                                )}
                            </Col>
                            <Col>
                              <Label className="form-label">
                                Teléfono <RequiredIcon />
                              </Label>
                              <Input
                                name={`agents.${index}.phone`}
                                className="form-control"
                                type="tel"
                                maxLength={10}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={agent.phone}
                                invalid={
                                  !!(
                                    touched?.agents &&
                                    touched?.agents[index]?.phone &&
                                    errors.agents &&
                                    errors?.agents[index]?.phone
                                  )
                                }
                              />
                              {touched?.agents &&
                                touched?.agents[index]?.phone &&
                                errors?.agents &&
                                errors?.agents[index]?.phone && (
                                  <FormFeedback type="invalid">{errors?.agents[index]?.phone}</FormFeedback>
                                )}
                            </Col>
                            <Col>
                              <Label className="form-label">Email</Label>
                              <Input
                                name={`agents.${index}.email`}
                                className="form-control"
                                type="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={agent.email}
                                invalid={
                                  !!(
                                    touched?.agents &&
                                    touched?.agents[index]?.email &&
                                    errors.agents &&
                                    errors?.agents[index]?.email
                                  )
                                }
                              />
                              {touched?.agents &&
                                touched?.agents[index]?.email &&
                                errors?.agents &&
                                errors?.agents[index]?.email && (
                                  <FormFeedback type="invalid">{errors?.agents[index]?.email}</FormFeedback>
                                )}
                            </Col>
                            <Col md={2} className="mt-3 text-center d-lg-flex">
                              <div className="my-auto pt-2">
                                <Button color="danger" type="button" onClick={() => arrayHelpers.remove(index)}>
                                  <i className="fa fa-trash" />
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </>
              )}
            />
          )}

          <hr />

          <Row>
            <Col className="d-flex justify-content-end">
              {!isRetrivePage && (
                <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                  <i className="fa fa-times" /> Cerrar
                </Button>
              )}
              {!disabledForm && (
                <Button color="success" type="submit" disabled={isLoading}>
                  <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

ProviderForm.defaultProps = {
  data: null,
  disabledForm: false,
  isRetrivePage: false,
}

ProviderForm.propTypes = {
  data: propTypes.any,
  disabledForm: propTypes.bool,
  error: propTypes.any,
  isCreate: propTypes.bool,
  isError: propTypes.bool,
  isLoading: propTypes.bool,
  isRetrivePage: propTypes.bool,
  refetchData: propTypes.func,
  submit: propTypes.func,
  toggle: propTypes.func,
}

export default ProviderForm
