import propTypes from "prop-types"
import { useEffect } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useMutation } from "@tanstack/react-query"
import { createBranch } from "services/branch-offices"
import BranchesForm from "./BranchesForm"

const CreateModalForm = ({ isOpen, toggle, setShowAlertSuccess, setMsgSuccess }) => {
  const { isLoading, isError, error, isSuccess, mutateAsync } = useMutation(createBranch)

  useEffect(() => {
    if (isSuccess) {
      setMsgSuccess("Sucursal creada satisfactoriamente")
      setShowAlertSuccess(true)
      toggle()
    }
  }, [isSuccess])

  const submit = async (data) => {
    await mutateAsync(data)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Agregar Sucursal
      </ModalHeader>
      <ModalBody>
        <BranchesForm
          isCreate={true}
          submit={submit}
          toggle={toggle}
          isLoading={isLoading}
          isError={isError}
          error={error}
        />
      </ModalBody>
    </Modal>
  )
}

CreateModalForm.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  setShowAlertSuccess: propTypes.func,
  setMsgSuccess: propTypes.func,
}

export default CreateModalForm
