import * as Yup from "yup"

import { messages, NUNBER_REGEX, RFC_EXAMPLE, RFC_REGEX } from "utils/messages"

const schema = Yup.object({
  name: Yup.string().max(120, messages.max_chars(120)).required(messages.required),
  email: Yup.string().email(messages.email),
  phone: Yup.string()
    .matches(NUNBER_REGEX, messages.number)
    .min(10, messages.exactly(10))
    .max(10, messages.exactly(10))
    .required(messages.required),
  rfc: Yup.string()
    .matches(RFC_REGEX, messages.invalidFormat(RFC_EXAMPLE))
    .min(12, messages.exactly(12))
    .max(13, messages.exactly(13)),
  business_name: Yup.string().max(255, messages.max_chars(255)),
  postal_code: Yup.string()
    .matches(NUNBER_REGEX, messages.number)
    .min(5, messages.exactly(5))
    .max(5, messages.exactly(5)),
  credit_days: Yup.number(messages.positive).min(0).integer(),
  credit_limit: Yup.string(),
  tax_regimen: Yup.object().nullable(),
  price: Yup.object().required(messages.required),
  cfdi_use: Yup.object().nullable(),
})

export default schema
