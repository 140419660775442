import { API } from "config/API"

export const getAllMeasurements = async (filters) => {
  const response = await API.get("/unit-measurements/all", { params: filters })
  return response
}

export const getMeasurements = async (filters) => {
  const response = await API.get("/unit-measurements", { params: filters })
  return response
}

export const getMeasurement = async (id) => {
  const response = await API.get(`/unit-measurements/${id}`)
  return response
}

export const createMeasurements = async (payload) => {
  const response = await API.post("/unit-measurements", payload)
  return response
}

export const editMeasurements = async (id, payload) => {
  const response = await API.patch(`/unit-measurements/${id}`, payload)
  return response
}

export const deleteMeasurements = async (id) => {
  const response = await API.delete(`/unit-measurements/${id}`)
  return response
}