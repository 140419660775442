import { useEffect, useState } from "react"
import propTypes from "prop-types"
import { Formik } from "formik"
import { Button, Form, Label, Alert, Col, Row, Input } from "reactstrap"

import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"
import schema from "./schema"

const StoresForm = ({ data, submit, toggle, isLoading, isError, error }) => {
  const [errorProducts, setErrorProducts] = useState({})
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])

  const errorsAlert = () => {
    let errorTmp = {}
    if (errorProducts) {
      errorTmp = errorProducts
    } else if (isError) {
      errorTmp = error
    }

    return (
      <Alert color="danger" isOpen={showErrorAlert}>
        {errorTmp?.errors?.map((error) => error?.message)}
      </Alert>
    )
  }

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setErrorProducts()
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  return (
    <>
      {errorsAlert()}
      <Formik
        enableReinitialize
        initialValues={{
          name: data?.name ?? "",
          code: data?.code ?? "",
          manager: data?.manager ?? "",
        }}
        validationSchema={schema}
        onSubmit={submit}
      >
        {({ values, handleChange, handleSubmit, handleBlur, touched, errors, setFieldValue, setTouched }) => (
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <Label className="form-label">
                  Código <RequiredIcon />
                </Label>
                <Input
                  autoComplete="do-not-autofill"
                  className="form-control"
                  name="code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.code}
                  invalid={
                    !!(touched.name && errors.name) ||
                    (isError && validationErrors.findIndex((e) => e.field === "code") >= 0)
                  }
                />
                <ErrorsText
                  formName="code"
                  touched={touched}
                  errorsForm={errors}
                  isErrorBack={isError}
                  errorsBack={error}
                />
              </Col>
              <Col>
                <Label for="price">Nombre</Label> <RequiredIcon />
                <Input
                  autoComplete="do-not-autofill"
                  className="form-control"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  invalid={
                    !!(touched.name && errors.name) ||
                    (isError && validationErrors.findIndex((e) => e.field === "name") >= 0)
                  }
                />
                <ErrorsText
                  formName="name"
                  touched={touched}
                  errorsForm={errors}
                  isErrorBack={isError}
                  errorsBack={error}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Label className="form-label">
                  Administrador
                </Label>
                <Input
                  autoComplete="do-not-autofill"
                  className="form-control"
                  name="manager"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.manager}
                  invalid={
                    !!(touched.name && errors.name) ||
                    (isError && validationErrors.findIndex((e) => e.field === "manager") >= 0)
                  }
                />
                <ErrorsText
                  formName="manager"
                  touched={touched}
                  errorsForm={errors}
                  isErrorBack={isError}
                  errorsBack={error}
                />
              </Col>
            </Row>

            <hr />
            <Row className="mb-3">
              <Col className="text-end">
                <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                  <i className="fa fa-times" /> Cerrar
                </Button>

                <Button color="success" type="submit" disabled={isLoading}>
                  <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

StoresForm.defaultProps = {
  data: null,
}

StoresForm.propTypes = {
  data: propTypes.any,
  submit: propTypes.func.isRequired,
  toggle: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
  isError: propTypes.bool.isRequired,
  error: propTypes.object,
}

export default StoresForm
