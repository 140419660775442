import { API } from "config/API"

export const getAllBusinessNames = async () => {
  const response = await API.get("/business-names/all")
  return response
}

export const getBusinessNames = async (filters) => {
  const response = await API.get("/business-names", { params: filters })
  return response
}

export const getBusinessName = async (id) => {
  const response = await API.get(`/business-names/${id}`)
  return response
}

export const createBusinessName = async (payload) => {
  const response = await API.post("/business-names", payload)
  return response
}

export const editBusinessName = async (id, payload) => {
  const response = await API.patch(`/business-names/${id}`, payload)
  return response
}

export const deleteBusinessName = async (id) => {
  const response = await API.delete(`/business-names/${id}`)
  return response
}
