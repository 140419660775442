import { useEffect } from "react"
import propTypes from "prop-types"
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import { getFile, editFile } from "services/files"
import { Loading } from "components"
import FileForm from "./file-form"


const FileEditModalForm = ({ isOpen, toggle, fileId, setShowAlertSuccess, setMsgSuccess }) => {
    const {
        data,
        error,
        isLoading,
        isError,
        refetch,
    } = useQuery(["file", fileId], () => getFile(fileId))
    const {
        isLoading: isLoadingEdit,
        isError: isErrorEdit,
        error: errorEdit,
        isSuccess: isSuccessEdit,
        mutateAsync,
    } = useMutation(["file", fileId], (data) => editFile(fileId, data))

    const submit = async (data) => {
        const dataTemp = { ...data }
        dataTemp.type = dataTemp?.type?.value || null
        await mutateAsync({
            ...dataTemp,
        })
    }

    useEffect(() => {
        if (isSuccessEdit) {
            setMsgSuccess("Archivo modificado satisfactoriamente")
            setShowAlertSuccess(true)
            toggle()
        }
    }, [isSuccessEdit])

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
            <ModalHeader toggle={toggle} charcode="X">
                Editar Archivo
            </ModalHeader>
            <ModalBody>
                {isLoading ? (
                    <div className="text-center">
                        <Loading isLoading={isLoading} size={2} />
                    </div>
                ) : isError ? (
                    <div className="text-center">
                        <Alert color="danger" isOpen={isError}>
                            {error?.errors?.map((error) => error?.message)}
                        </Alert>
                    </div>
                ) : (
                    <FileForm
                        data={data}
                        error={errorEdit}
                        isCreateModal={false}
                        isError={isErrorEdit}
                        isLoading={isLoadingEdit}
                        refetchData={refetch}
                        submit={submit}
                        toggle={toggle}
                    />
                )}
            </ModalBody>
        </Modal>
    )
}


FileEditModalForm.defaultProps = {
    fileId: null,
}


FileEditModalForm.propTypes = {
    fileId: propTypes.string,
    isOpen: propTypes.any,
    setMsgSuccess: propTypes.func,
    setShowAlertSuccess: propTypes.func,
    toggle: propTypes.func,
}


export default FileEditModalForm
