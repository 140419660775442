import * as Yup from "yup"

import { messages } from "utils/messages"

const schema = Yup.object({
  date: Yup.date(),
  source_store: Yup.number().required().positive().integer(),
  destiny_store: Yup.number().required().positive().integer(),
  reason: Yup.string().max(255, messages.max_chars(255)).required(messages.required),
  description: Yup.string().max(255, messages.max_chars(255)).required(messages.required),
  movement_details: Yup.array().of(Yup.object()).nullable(),
})

export default schema
