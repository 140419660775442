import React, { useState } from "react"
import {
  Alert,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import storage from "services/storage"
import { authUserKey } from "config/API"
import { useLocation } from "react-router-dom"

import { useQuery } from "@tanstack/react-query"
import { getDailyCashCuts } from "services/cash-cuts"
import { InventoriesList, MovementsBetweenStoresList } from "components/inventories-sections"
import TotalTableInventories from "components/inventories-sections/total-table-inventories"

const InventoriesSections = () => {
  document.title = "Inventarios | Punto de venta"
  const permissions = storage.get(authUserKey).user_permissions || []
  const cashCuts = useQuery(["cash-cuts-daily"], () => getDailyCashCuts())
  const [tabState, settabState] = useState({ activeTab: "inventories" })
  const Location = useLocation()

  const toggleActiveTab = (tab) => {
    if (tabState.activeTab !== tab) {
      settabState({
        activeTab: tab,
      })
      window.history.replaceState({}, null, `${Location.pathname}/${tab}`)
    }
  }

  return !permissions.some((e) => e.codename === "view_cashcut") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="page-content">
      <Container fluid>
        <Alert color="danger" isOpen={cashCuts.isError}>
          {cashCuts.error?.errors?.map((error) => error?.message)}
        </Alert>

        <h2>Seccion de inventarios</h2>
        <div className="bg-white p-3">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={` ${tabState.activeTab === "inventories" && "active"}`}
                onClick={() => toggleActiveTab("inventories")}
              >
                Inventarios
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={` ${tabState.activeTab === "totals" && "active"}`}
                onClick={() => toggleActiveTab("totals")}
              >
                Totales de Inventarios
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={` ${tabState.activeTab === "adjustments" && "active"}`}
                onClick={() => toggleActiveTab("adjustments")}
              >
                Ajustes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={` ${tabState.activeTab === "movements" && "active"}`}
                onClick={() => toggleActiveTab("movements")}
              >
                Movimientos entre inventarios
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={` ${tabState.activeTab === "reports" && "active"}`}
                onClick={() => toggleActiveTab("reports")}
              >
                Reportes
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={tabState.activeTab}>
            <TabPane tabId="inventories">
              <InventoriesList/>
            </TabPane>
            <TabPane tabId="totals">
              <TotalTableInventories/>
            </TabPane>
            <TabPane tabId="adjustments">
              <div style={{ marginTop: "20px", marginBottom: "20px" }}>adjustments</div>
            </TabPane>
            <TabPane tabId="movements">
              <MovementsBetweenStoresList/>
            </TabPane>
            <TabPane tabId="reports">
              <div style={{ marginTop: "20px", marginBottom: "20px" }}>Próximamente</div>
            </TabPane>
          </TabContent>
        </div>
      </Container>
    </div>
  )
}

export default InventoriesSections
