import React, { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useTable, useGlobalFilter, useSortBy, useFilters, usePagination } from "react-table"
import { Table, Row, Col, Button, Input, Card, CardBody } from "reactstrap"
import { reorderInventories, editInventory } from "services/inventories"
import Swal from "sweetalert2"

// Componente TableContainer para mostrar la tabla de productos de reorden
const TableContainer = ({ refetchData }) => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [updateData, setUpdateData] = useState(false)

  const handleOrderStatusChange = (rowData) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¿Deseas marcar el producto "${rowData.product.description}" como ordenado?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        const updateInventory = {
          ordered: true,
        }

        editInventory(rowData.id, updateInventory)
          .then(() => {
            Swal.fire("Producto ordenado!", "", "success")
            setUpdateData(!updateData)
            refetchData()
          })
          .catch((error) => {
            Swal.fire("Error al ordenar el producto", error.message, "error")
          })
      }
    })
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await reorderInventories()
        setData(response.results)
        setIsLoading(false)
      } catch (error) {
        setError(error)
        setIsLoading(false)
      }
    }

    fetchData()
  }, [updateData])

  const columns = React.useMemo(
    () => [
      {
        Header: "Producto",
        accessor: "product.product.name",
      },
      {
        Header: "Descripción",
        accessor: "product.description",
      },
      {
        Header: "Cantidad",
        accessor: "quantity",
      },
      {
        Header: "Punto de Reorden",
        accessor: "reorder_point",
      },
      {
        Header: "Ordenado",
        accessor: "ordered",
        Cell: ({ row }) => (
          <Button color="primary" onClick={() => handleOrderStatusChange(row.original)}>
            Ordenar
          </Button>
        ),
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10, // Tamaño de página predeterminado
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = (event) => {
    const inputValue = event.target.value
    const page = inputValue ? Number(inputValue) - 1 : 0
    if (page >= 0 && page < pageCount) {
      gotoPage(page)
    }
  }

  return (
    <Fragment>
      {isLoading ? (
        <div>Cargando...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : (
        <div>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Productos por agotarse</h4>
              <Row className="mb-2">
                <Col md={4}>
                  <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                    {[10, 20, 30, 40, 50].map((size) => (
                      <option key={size} value={size}>
                        Mostrar {size}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              <div className="table-responsive react-table">
                <Table style={{ background: "white" }} {...getTableProps()} className="mb-0">
                  <thead className="table-light table-nowrap">
                    {headerGroups.map((headerGroup) => (
                      <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th key={column.id}>
                            <div className="mb-2" {...column.getSortByToggleProps()}>
                              {column.render("Header")}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {page.map((row, rowIndex) => {
                      prepareRow(row)

                      return (
                        <tr key={`row-${rowIndex}`} {...row.getRowProps()}>
                          {row.cells.map((cell, cellIndex) => {
                            const cellStyle = {
                              fontWeight: "normal",
                            }

                            if (row.original.quantity <= 0) {
                              cellStyle.backgroundColor = "#ff3b00"
                              cellStyle.color = "white"
                            } else if (row.original.quantity > 0) {
                              cellStyle.backgroundColor = "#f3f369"
                            }

                            return (
                              <td key={`cell-${rowIndex}-${cellIndex}`} {...cell.getCellProps()} style={cellStyle}>
                                {cell.render("Cell")}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>

              <Row className="justify-content-center justify-content-center align-items-center">
                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button color="primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                      {"<<"}
                    </Button>
                    <Button color="primary" onClick={previousPage} disabled={!canPreviousPage}>
                      {"<"}
                    </Button>
                  </div>
                </Col>
                <Col className="col-md-auto d-none d-md-block">
                  Página{" "}
                  <strong>
                    {pageIndex + 1} de {pageOptions.length}
                  </strong>
                </Col>
                <Col className="col-md-auto">
                  <Input
                    type="number"
                    min={1}
                    style={{ width: 70 }}
                    max={pageOptions.length}
                    defaultValue={pageIndex + 1}
                    onChange={onChangeInInput}
                  />
                </Col>
                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
                      {">"}
                    </Button>
                    <Button color="primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                      {">>"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      )}
    </Fragment>
  )
}

TableContainer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  row: PropTypes.object,
  refetchData: PropTypes.func,
}

export default TableContainer
