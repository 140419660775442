import React from "react"
import propTypes from "prop-types"
import { Button } from "reactstrap"
import Swal from "sweetalert2"
import { getTicket, deleteTicket } from "services/tickets"
import { useHistory } from "react-router"

const PendingPaidItemList = ({ item }) => {
  const date = new Date(item.date)
  const formattedDate = date.toLocaleString()
  const history = useHistory()
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  function currencyFormatter({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }

  const handleOnClickSale = () => {
    getTicket(item.id).then((ticket) => {
      history.push(`sale/${ticket.id}/payment`)
    })
  }

  const handleOnClickCancel = (id) => {
    deleteTicket(id).then(() => {
      history.replace("/sale")
      Toast.fire({
        icon: "error",
        title: "Venta eliminada con exito",
      })
    })
  }

  return (
    <tr key={item.id}>
      <td style={styleCenterTable}>{item.folio}</td>
      <td style={styleCenterTable}>{formattedDate}</td>
      <td style={styleCenterTable}>
        {item?.user?.first_name} {item?.user?.last_name}
      </td>
      <td style={styleCenterTable}>{item?.customer?.name}</td>
      <td style={styleCenterTable}>{currencyFormatter({ currency: "USD", value: item.total })}</td>
      <td style={styleCenterTable}>
        <Button color="primary" title="Continuar venta" onClick={handleOnClickSale}>
          <i className="fa fa-dollar-sign" />
        </Button>
        <Button
          style={{ marginLeft: "10px" }}
          color="danger"
          title="Eliminar venta"
          onClick={() => handleOnClickCancel(item.id)}
        >
          <i className="fa fa-trash" />
        </Button>
      </td>
    </tr>
  )
}

PendingPaidItemList.propTypes = {
  item: propTypes.object,
}

export default PendingPaidItemList
