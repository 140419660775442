import { API } from "config/API"

export const getCustomers = async (filters) => {
  const response = await API.get("/customers?ordering=name", { params: filters })
  return response
}

export const getCustomer = async (id) => {
  const response = await API.get(`/customers/${id}`)
  return response
}

export const createCustomer = async (payload) => {
  const response = await API.post("/customers", payload)
  return response
}

export const editCustomer = async (id, payload) => {
  const response = await API.patch(`/customers/${id}`, payload)
  return response
}

export const deleteCustomer = async (id) => {
  const response = await API.delete(`/customers/${id}`)
  return response
}

export const getPricesCustomer = async (id) => {
  const response = await API.get(`/customers/${id}/get_prices?per_page=5000`)
  return response
}

export const masiveImportCustomers = async (payload) => {
  const FormData = require("form-data")
  const formData = new FormData()
  formData.append("file", payload.file)
  const response = await API.post("/customers/masive_import", formData, {
    headers: {
      "Content-Type": undefined,
    },
  })
  return response
}
