import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import { getBenefitPerMonth } from "services/cash-cuts"

const Earning = () => {
  const data = useRef()
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
  const dailyCashArray = useRef()
  const totalCashMonth = useRef()
  const [isLoading, setIsLoading] = useState(true)
  const payload = { month: selectedMonth, year: "2023" }

  function currencyFormatter({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }

  useEffect(() => {
    setIsLoading(true) 
    getBenefitPerMonth(payload)
      .then((response) => {
        data.current = response

        dailyCashArray.current = data?.current?.map((item) => {
          const date = new Date(item.date)
          const day = date.getDate()
          const totalCash = item.total_cash

          return { day, totalCash }
        })

        dailyCashArray.current?.sort((a, b) => a.day - b.day)

        const totalCashAccumulated = dailyCashArray.current?.reduce((acc, item) => {
          acc += item.totalCash
          return acc
        }, 0)
        totalCashMonth.current = totalCashAccumulated

        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setIsLoading(false) 
      })
  }, [selectedMonth])

  const options = {
    chart: {
      toolbar: "false",
      dropShadow: {
        enabled: !0,
        color: "#000",
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },
    xaxis: {
      categories: dailyCashArray.current?.map((item) => item.day),
    },
    yaxis: {
      title: {
        text: "Ventas Totales",
      },
    },
    colors: ["#556ee6"],
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
  }

  const series = [
    {
      name: "Venta total por día",
      data: dailyCashArray.current?.map((item) => item.totalCash),
    },
  ]

  const onChangeMonth = (value) => {
    setSelectedMonth(Number(value))
  }

  return (
    <React.Fragment>
      <Col xl="8">
        <Card>
          <CardBody>
            {isLoading ? (
              <p>Cargando datos...</p>
            ) : (
              <>
                <div className="clearfix">
                  <div className="float-end">
                    <div className="input-group input-group-sm">
                      <select
                        className="form-select form-select-sm"
                        value={selectedMonth}
                        onChange={(e) => {
                          onChangeMonth(e.target.value)
                        }}
                      >
                        <option value="1">Jan</option>
                        <option value="2">Feb</option>
                        <option value="3">Mar</option>
                        <option value="4">Abr</option>
                        <option value="5">May</option>
                        <option value="6">Jun</option>
                        <option value="7">Jul</option>
                        <option value="8">Ago</option>
                        <option value="9">Sep</option>
                        <option value="10">Oct</option>
                        <option value="11">Nov</option>
                        <option value="12">Dic</option>
                      </select>
                      <div className="input-group-append">
                        <label className="input-group-text">Mes</label>
                      </div>
                    </div>
                  </div>
                  <h4 className="card-title mb-4">Ventas mensuales</h4>
                </div>

                <Row>
                  <Col lg="2">
                    <div className="text-muted">
                      <div className="mb-4">
                        <p>Mes actual</p>
                        <h5>{currencyFormatter({ currency: "USD", value: totalCashMonth.current })}</h5>
                      </div>
                    </div>
                  </Col>

                  <Col lg="10">
                    <div id="line-chart" dir="ltr">
                      <ReactApexChart
                        series={series}
                        options={options}
                        type="line"
                        height={320}
                        className="apex-charts"
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Earning
