import React, { useContext } from "react"
import { withRouter } from "react-router-dom"
import { Container, Col, Row } from "reactstrap"
import { BasicInfo, ChangePassword } from "../../components/profile/settings"
import { UserContext } from "App"

const Profile = () => {
  document.title = "Mi perfil | Punto de venta"
  const userData = useContext(UserContext);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h1>Mi perfil</h1>
          <div>
            <Row>
              <Col sm={6} className="p-1">
                <div className="bg-white p-2">
                  <BasicInfo data={userData} />
                </div>
              </Col>
              <Col sm={6} className="p-1">
                <div className="bg-white p-2">
                  <ChangePassword />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

Profile.propTypes = {}

export default withRouter(Profile)
