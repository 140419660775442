import React, { useState, useEffect } from "react"
import propTypes from "prop-types"
import { Formik } from "formik"
import { useQuery } from "@tanstack/react-query"
import { Button, Form, Input, Label, Alert, Row, Col, FormGroup, Tooltip } from "reactstrap"

import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"
import { getAllPermissions } from "services/Permissions"
import schema from "./schema"

const PriceForm = ({ isCreate, submit, data, toggle, isLoadingSubmit, isError, error }) => {
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  const permissions = useQuery(["permissions"], () => getAllPermissions())

  const isLoading = permissions.isLoading

  const isLoadingDiv = () => {
    return (
      <div className="text-center">
        <Loading isLoading={isLoading} size={2} />
      </div>
    )
  }

  const isErrorDiv = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={permissions.isError}>
          {permissions?.error?.errors?.map((error) => error?.message)}
        </Alert>
      </div>
    )
  }

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  return (
    <>
      {isLoading ? (
        isLoadingDiv()
      ) : permissions.isError ? (
        isErrorDiv()
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            name: data?.name ?? "",
            description: data?.description ?? "",
            is_active: data?.is_active ?? "",
            identifier: data?.identifier ?? "",
            discount_volume: data?.discount_volume ?? "",
          }}
          validationSchema={schema}
          onSubmit={submit}
        >
          {({ values, handleChange, handleSubmit, handleBlur, touched, errors }) => (
            <Form className="form-horizontal" onSubmit={handleSubmit}>
              <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
                {isError && error?.errors?.map((error) => error?.message)}
              </Alert>

              <Row className="mb-3">
                <Col>
                  <Label className="form-label">
                    Nombre <RequiredIcon />
                  </Label>
                  <Input
                    name="identifier"
                    className="form-control"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.identifier}
                    invalid={
                      !!(touched.identifier && errors.identifier) ||
                      (isError && validationErrors.findIndex((e) => e.field === "identifier") >= 0)
                    }
                  />
                  <ErrorsText
                    formName="identifier"
                    touched={touched}
                    errorsForm={errors}
                    isErrorBack={isError}
                    errorsBack={error}
                  />
                </Col>
                <Col>
                  <Label className="form-label">Descripción</Label>
                  <Input
                    name="description"
                    className="form-control"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    invalid={
                      !!(touched.description && errors.description) ||
                      (isError && validationErrors.findIndex((e) => e.field === "description") >= 0)
                    }
                  />
                  <ErrorsText
                    formName="description"
                    touched={touched}
                    errorsForm={errors}
                    isErrorBack={isError}
                    errorsBack={error}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className="form-label">
                    ID <RequiredIcon />
                  </Label>
                  <Input
                    name="name"
                    className="form-control"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    invalid={
                      !!(touched.name && errors.name) ||
                      (isError && validationErrors.findIndex((e) => e.field === "name") >= 0)
                    }
                    disabled={true}
                  />
                  <ErrorsText
                    formName="name"
                    touched={touched}
                    errorsForm={errors}
                    isErrorBack={isError}
                    errorsBack={error}
                  />
                </Col>

                <Col>
                  <Label className="form-label">¿El precio esta activo?</Label>
                  <FormGroup switch>
                    <Input
                      name="is_active"
                      className="form-control"
                      type="switch"
                      onChange={handleChange}
                      checked={values.is_active}
                      disabled={values.name === "P1"}
                      invalid={
                        !!(touched.is_active && errors.is_active) ||
                        (isError && validationErrors.findIndex((e) => e.field === "is_active") >= 0)
                      }
                    />
                    <ErrorsText
                      formName="is_active"
                      touched={touched}
                      errorsForm={errors}
                      isErrorBack={isError}
                      errorsBack={error}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <Label id={"discount_volume"} className="form-label">
                    Regla por descuento de volumen
                  </Label>
                  <FormGroup switch>
                    <Input
                      name="discount_volume"
                      className="form-control"
                      type="switch"
                      onChange={handleChange}
                      checked={values.discount_volume}
                      disabled={values.name === "P1"}
                      invalid={
                        !!(touched.discount_volume && errors.discount_volume) ||
                        (isError && validationErrors.findIndex((e) => e.field === "discount_volume") >= 0)
                      }
                    />
                    <ErrorsText
                      formName="discount_volume"
                      touched={touched}
                      errorsForm={errors}
                      isErrorBack={isError}
                      errorsBack={error}
                    />
                    <Tooltip
                      hideArrow={true}
                      innerClassName="mb-1"
                      style={{ backgroundColor: "#74788d" }}
                      placement={"top"}
                      isOpen={tooltipOpen}
                      target={"discount_volume"}
                      toggle={toggleTooltip}
                    >
                      <span>
                        Si la regla está inactiva, solo los clientes con el precio asignado accederán a este precio
                      </span>
                    </Tooltip>
                  </FormGroup>
                </Col>
              </Row>

              <hr />

              <Row>
                <Col className="d-flex justify-content-end">
                  <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoadingSubmit}>
                    <i className="fa fa-times" /> Cerrar
                  </Button>
                  <Button color="success" type="submit" disabled={isLoadingSubmit}>
                    <Loading isLoading={isLoadingSubmit} /> <i className="fa fa-save" /> Guardar
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

PriceForm.defaultProps = {
  data: null,
}

PriceForm.propTypes = {
  isCreate: propTypes.bool,
  submit: propTypes.func,
  toggle: propTypes.func,
  data: propTypes.any,
  isLoadingSubmit: propTypes.bool,
  isError: propTypes.bool,
  error: propTypes.any,
}

export default PriceForm
