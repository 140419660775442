import React from "react"
import { Alert, Container, Table } from "reactstrap"
import { useQuery } from "@tanstack/react-query"

import storage from "services/storage"
import { authUserKey } from "config/API"
import { getAllStores } from "services/stores"
import { Loading } from "components"
import TotalAverageInventories from "./total-average-inventories"

const TotalTableInventories = () => {
  document.title = "Total de inventarios | Punto de venta"

  const stores = useQuery(["stores"], () => getAllStores())
  const permissions = storage.get(authUserKey).user_permissions || []
  const isLoading = stores.isLoading

  const styleBorderedColumns = {
    borderLeft: "1px solid #b1b4b8",
    borderRight: "1px solid #b1b4b8",
  }
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle", ...styleBorderedColumns }
  const styleCenterTotalTable = { textAlign: "center", verticalAlign: "middle" }
  const styleLeftTable = { textAlign: "left", verticalAlign: "middle", width: "3rem", paddingRight: "0.6rem" }
  const styleQuantityTable = { textAlign: "left", verticalAlign: "middle", width: "2rem", paddingLeft: "3rem" }

  return !permissions.some((e) => e.codename === "view_store") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="mt-3">
      <Container fluid>
        <Alert color="danger" isOpen={stores.isError}>
          {stores?.error?.errors?.map((error) => error?.message)}
        </Alert>
        <h2 className="mb-2">Total de inventarios</h2>
        <div className="bg-white p-3 pt-0">
          {!stores.error && isLoading ? (
            <div className="my-5 text-center">
              <Loading isLoading={isLoading} size={2} />
            </div>
          ) : stores.error && !isLoading ? (
            <div className="my-5 text-center">
              <h1>Error intenta mas tarde</h1>
            </div>
          ) : (
            <div className="table-responsive">
              <Table hover responsive>
                <thead>
                  <tr className="table-secondary">
                    <th style={{ width: "30rem" }} />
                    {stores.data.map((store) => (
                      <th key={store.id} style={styleCenterTable}>
                        {store.name}
                        <Table className="mt-1 mb-0">
                          <thead>
                            <th style={styleQuantityTable}>Cantidad</th>
                            <th style={styleLeftTable}>Dinero</th>
                          </thead>
                        </Table>
                      </th>
                    ))}
                    <th style={styleCenterTotalTable}>
                      Total
                      <Table className="mt-1 mb-0">
                        <thead>
                          <th style={styleQuantityTable}>Cantidad</th>
                          <th style={styleLeftTable}>Dinero</th>
                        </thead>
                      </Table>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <TotalAverageInventories stores={stores?.data} />
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

export default TotalTableInventories
