import { useEffect } from "react"
import propTypes from "prop-types"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useMutation } from "@tanstack/react-query"
import { userRegister } from "services/auth"
import UserForm from "./UserForm"


const CreateModalForm = ({ isOpen, toggle, setShowAlertSuccess, setMsgSuccess }) => {
  const { isLoading, isError, error, isSuccess, mutateAsync } = useMutation(userRegister)

  const submit = async (data) => {
    const dataTemp = { ...data }
    dataTemp.user_type = dataTemp?.user_type?.value || null
    await mutateAsync(dataTemp)
  }

  useEffect(() => {
    if (isSuccess) {
      setMsgSuccess("Usuario creado satisfactoriamente")
      setShowAlertSuccess(true)
      toggle()
    }
  }, [isSuccess])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Agregar Usuario
      </ModalHeader>
      <ModalBody>
        <UserForm
          isCreate={true}
          submit={submit}
          toggle={toggle}
          isLoadingSubmit={isLoading}
          isError={isError}
          error={error}
        />
      </ModalBody>
    </Modal>
  )
}


CreateModalForm.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  setShowAlertSuccess: propTypes.func,
  setMsgSuccess: propTypes.func,
}


export default CreateModalForm
