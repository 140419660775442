import React, { useState, useEffect, useCallback } from "react"
import { useLocation } from "react-router-dom"
import {
  Alert,
  Button,
  Col,
  Container,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import { Formik, Form } from "formik"
import Select from "react-select"
import { useQuery } from "@tanstack/react-query"
import { debounce } from "lodash"
import Swal from "sweetalert2"

import storage from "services/storage"
import { API, authUserKey } from "config/API"
import { getPaidTickets, createInvoice } from "services/tickets"
import { Loading } from "components"
import { TicketItemList } from "components/sales"
import { getUsers } from "services/users"
import TicketRetrive from "./ticketRetrive"

const TicketByUser = () => {
  document.title = "Ventas realizadas | Punto de venta"

  const Location = useLocation()
  const [filters, setFilters] = useState("")
  const [searchTxt, setSearchTxt] = useState("")
  const [modalInvoiceOpen, setModalInvoiceOpen] = useState(false)
  const [modalTicketOpen, setModalTicketOpen] = useState(false)
  const [ticketId, setTicketId] = useState(null)
  const initialValues = {
    payForm: null,
    voucherType: null,
    paymentMethod: null,
  }

  const tickets = useQuery(["tickets", filters, filters.toString()], () => getPaidTickets(filters))
  const users = useQuery(["users", filters, filters.toString()], () => getUsers(filters))
  const usersFiltered = users?.data?.results.filter((user) => user.user_type === "A" || user.user_type === "S")
  const [filteredTickets, setFilteredTickets] = useState()
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }

  const isLoading = tickets.isLoading
  const permissions = storage.get(authUserKey).user_permissions || []
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  useEffect(() => {
    setFilters(Location.search)
  }, [])

  useEffect(() => {
    setFilteredTickets(tickets?.data?.results)
  }, [tickets?.data])

  const handleSearch = async () => {
    const response = await API.get(
      // eslint-disable-next-line
      `/tickets?is_paid=true&ordering=-date&user__email=${searchTxt.email}&user__first_name=${searchTxt.first_name}&user__last_name=${searchTxt.last_name}`
    )
    setFilteredTickets(response.results)
  }

  const debouncedSearch = useCallback(
    debounce(async (text) => {
      const response = await API.get(
        // eslint-disable-next-line
        `/tickets?is_paid=true&ordering=-date&user__email=${text.email}&user__first_name=${text.first_name}&user__last_name=${text.last_name}`
      )
      setFilteredTickets(response.results)
    }, 300),
    []
  )

  function handleChangeSearch(event) {
    if (event) {
      setSearchTxt(event)
      debouncedSearch(event)
    } else {
      setFilteredTickets(tickets?.data?.results)
    }
  }

  const openInvoiceModal = (id) => {
    setTicketId(id)
    setModalInvoiceOpen(true)
  }

  const openTicketModal = (id) => {
    setTicketId(id)
    setModalTicketOpen(true)
  }

  const closeInvoiceModal = () => {
    setModalInvoiceOpen(false)
  }

  const closeTicketModal = () => {
    setModalTicketOpen(false)
  }

  const handleSubmit = async (values) => {
    const payload = {
      pay_form: values.payForm.value,
      voucher_type: values.voucherType.value,
      pay_method: values.paymentMethod.value,
    }
    try {
      await createInvoice(ticketId, payload)
      Toast.fire({
        icon: "success",
        title: "Venta realizada correctamente",
      })
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error,
      })
    }
  }

  return !permissions.some((e) => e.codename === "view_ticket") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <React.Fragment>
      <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
      <div className="table-responsive">
        <Container fluid>
          <Alert color="danger" isOpen={tickets.isError}>
            {tickets.error?.errors?.map((error) => error?.message)}
          </Alert>
          <div>
            <Col md="6">
              <Row>
                <Col xs="9">
                  <Select
                    id="customer"
                    name="customer"
                    isClearable
                    values={""}
                    onChange={handleChangeSearch}
                    options={usersFiltered?.map((row) => ({
                      ...row,
                      label: `${row.first_name} ${row.last_name} - ${row.email}`,
                      value: row.id,
                    }))}
                    noOptionsMessage={() => "0 opciones"}
                    loadingMessage={() => "Cargando..."}
                    placeholder="Vendedor"
                  />
                </Col>
                <Col xs="3" className="">
                  <Button color="info" className="d-flex align-items-center" onClick={() => handleSearch()}>
                    <i className="me-2 fa fa-search" />
                    <span className="text-truncate">Buscar</span>
                  </Button>
                </Col>
              </Row>
            </Col>
            {!tickets.error && isLoading ? (
              <div className="my-5 text-center">
                <Loading isLoading={isLoading} size={2} />
              </div>
            ) : tickets.error && !tickets.isLoading ? (
              <div className="my-5 text-center">
                <h1>Error intenta mas tarde</h1>
              </div>
            ) : (
              <div className="table-responsive" style={{ marginTop: "30px" }}>
                <Table>
                  <thead>
                    <tr>
                      <th style={styleCenterTable}>Folio</th>
                      <th style={styleCenterTable}>Fecha de creación</th>
                      <th style={styleCenterTable}>Cliente</th>
                      <th style={styleCenterTable}>Total</th>
                      <th style={styleCenterTable}>Facturado</th>
                      <th style={styleCenterTable}>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTickets?.length > 0 ? (
                      filteredTickets?.map((item) => {
                        return (
                          <TicketItemList
                            key={item.id}
                            item={item}
                            openInvoiceModal={openInvoiceModal}
                            isOnlySeller={true}
                            openTicketModal={openTicketModal}
                          />
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No hay datos
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {modalInvoiceOpen && (
                  <Modal
                    centered={true}
                    size="lg"
                    backdrop="static"
                    isOpen={modalInvoiceOpen}
                    toggle={closeInvoiceModal}
                  >
                    <ModalHeader toggle={closeInvoiceModal}>Facturar Ticket</ModalHeader>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                      {({ values, setFieldValue }) => (
                        <Form>
                          <ModalBody>
                            <Label>Forma de pago</Label>
                            <Select
                              id="payForm"
                              name="payForm"
                              isClearable
                              value={values.payForm}
                              onChange={(val) => {
                                setFieldValue("payForm", val)
                              }}
                              options={[
                                {
                                  value: "01",
                                  label: "Efectivo",
                                },
                                {
                                  value: "02",
                                  label: "Cheque nominativo",
                                },
                                {
                                  value: "03",
                                  label: "Transferencia electrónica de fondos",
                                },
                                {
                                  value: "04",
                                  label: "Tarjeta de crédito",
                                },
                                {
                                  value: "99",
                                  label: "Por definir",
                                },
                              ]}
                              placeholder="Forma de pago"
                            />
                            <Label className="mt-2">Tipo de comprobante</Label>
                            <Select
                              id="voucherType"
                              name="voucherType"
                              isClearable
                              value={values.voucherType}
                              onChange={(val) => {
                                setFieldValue("voucherType", val)
                              }}
                              options={[
                                { value: "I", label: "Ingreso" },
                                { value: "E", label: "Egreso" },
                                { value: "T", label: "Traslado" },
                                { value: "N", label: "Nómina" },
                                { value: "P", label: "Pago" },
                              ]}
                              placeholder="Tipo de comprobante"
                            />
                            <Label className="mt-2">Método de pago</Label>
                            <Select
                              id="paymentMethod"
                              name="paymentMethod"
                              isClearable
                              value={values.paymentMethod}
                              onChange={(val) => {
                                setFieldValue("paymentMethod", val)
                              }}
                              options={[
                                {
                                  value: "PUE",
                                  label: "Pago en una sola exhibición",
                                },
                                {
                                  value: "PPD",
                                  label: "Pago en parcialidades o diferido",
                                },
                              ]}
                              placeholder="Método de pago"
                            />
                            <div className="d-flex justify-content-end">
                              <Button type="submit" color="success" style={{ marginTop: "10px" }}>
                                Submit
                              </Button>
                            </div>
                          </ModalBody>
                        </Form>
                      )}
                    </Formik>
                  </Modal>
                )}
                {modalTicketOpen && (
                  <Modal centered={true} size="lg" backdrop={true} isOpen={modalTicketOpen} toggle={closeTicketModal}>
                    <ModalBody>
                      <TicketRetrive id={ticketId} />
                    </ModalBody>
                  </Modal>
                )}
                <div className="text-center">
                  {(filteredTickets?.next || filteredTickets?.previous) && (
                    <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                      {filteredTickets?.previous && (
                        <PaginationItem>
                          <PaginationLink
                            previous
                            onClick={() => handleSearch(filteredTickets?.previous.split("?")[1])}
                          />
                        </PaginationItem>
                      )}
                      {filteredTickets?.next && (
                        <PaginationItem>
                          <PaginationLink next onClick={() => handleSearch(filteredTickets?.next.split("?")[1])} />
                        </PaginationItem>
                      )}
                    </Pagination>
                  )}
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TicketByUser
