import React, { useEffect, useState } from "react"
import propTypes from "prop-types"
import { Table } from "reactstrap"
import Select from "react-select"
import Swal from "sweetalert2"
import { getAllProductsPresentations } from "services/product-presentations"
import { createProductName } from "services/products-names"

const ProductTable = ({ products, refetch }) => {
  const [productsPresentations, setProductsPresentations] = useState([])

  useEffect(() => {
    getAllProductsPresentations()
      .then((res) => {
        setProductsPresentations(res)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  const handleSelectChange = (selectedOption, productDescription) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¿Deseas seleccionar el nombre  "${productDescription}" 
      para el siguiente producto "${selectedOption.label}"?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          name: productDescription,
          product_presentation: selectedOption.value,
        }
        createProductName(payload)
          .then(() => {
            refetch()
            Swal.fire("Producto actualizado!", "", "success")
          })
          .catch((error) => {
            Swal.fire("Error al ordenar el producto", error.message, "error")
          })
      }
    })
  }

  const selectOptions = productsPresentations.map((item) => ({
    value: item.id,
    label: item.description,
  }))

  const renderProducts = () => {
    if (typeof products !== "object") {
      return null
    }
    return products.map((product) => (
      <tr key={product.claveProdServ}>
        <td
          style={
            product.valorUnitario > product.costo && product.costo !== 0
              ? { backgroundColor: "#ff3b00", color: "white" }
              : {}
          }
        >
          {product.claveProdServ}
        </td>
        <td
          style={
            product.valorUnitario > product.costo && product.costo !== 0
              ? { backgroundColor: "#ff3b00", color: "white" }
              : {}
          }
        >
          {product.descripción}
        </td>
        <td
          style={
            product.valorUnitario > product.costo && product.costo !== 0
              ? { backgroundColor: "#ff3b00", color: "white" }
              : {}
          }
        >
          {product.cantidad}
        </td>
        <td
          style={
            product.valorUnitario > product.costo && product.costo !== 0
              ? { backgroundColor: "#ff3b00", color: "white" }
              : {}
          }
        >
          {product.productoPresentación ? (
            product.productoPresentación
          ) : (
            <Select
              onChange={(selectedOption) => handleSelectChange(selectedOption, product.descripción)}
              options={selectOptions}
            />
          )}
        </td>
        <td
          style={
            product.valorUnitario > product.costo && product.costo !== 0
              ? { backgroundColor: "#ff3b00", color: "white" }
              : {}
          }
        >
          {product.unidad}
        </td>
        <td
          style={
            product.valorUnitario > product.costo && product.costo !== 0
              ? { backgroundColor: "#ff3b00", color: "white" }
              : {}
          }
        >
          {product.valorUnitario}
        </td>
        <td
          style={
            product.valorUnitario > product.costo && product.costo !== 0
              ? { backgroundColor: "#ff3b00", color: "white" }
              : {}
          }
        >
          {product.importe}
        </td>
      </tr>
    ))
  }

  return (
    <Table style={{ border: "1px solid #eff2f7" }}>
      <thead>
        <tr>
          <th>Clave</th>
          <th>Descripción</th>
          <th>Cantidad</th>
          <th>Nombre de producto</th>
          {/* <th>Clave de Unidad</th> */}
          <th>Unidad</th>
          <th>Coste Unitario</th>
          {/* <th>Objeto de Impuesto</th> */}
          <th>Importe</th>
        </tr>
      </thead>
      <tbody>{renderProducts()}</tbody>
    </Table>
  )
}

ProductTable.propTypes = {
  products: propTypes.array,
  refetch: propTypes.func,
}

export default ProductTable
