import propTypes from "prop-types"

const Loading = ({ isLoading, size }) =>
  isLoading && <i className={"fa fa-spinner fa-spin fa-fw " + (size ? `fa-${size}x` : "")} />

Loading.propTypes = {
  isLoading: propTypes.bool.isRequired,
  size: propTypes.number,
}

export default Loading
