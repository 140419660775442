import { useMutation, useQueryClient } from "@tanstack/react-query"

import { authUserKey } from "config/API"
import { login, logout, forgotPassword, resetPassword, me, updateMe } from "services/auth"
import storage from "services/storage"

export const useLogin = () =>
  useMutation(login, {
    onSuccess: (data) => {
      storage.save(authUserKey, data)
    },
  })

export const useLogout = () =>
  useMutation(logout, {
    onSuccess: () => {
      storage.remove(authUserKey)
      window.location = "/login"
    },
  })

export const useForgotPassword = () =>
  useMutation(forgotPassword, {
    onSuccess: () => {},
  })

export const useResetPassword = () =>
  useMutation(resetPassword, {
    onSuccess: () => {
      setTimeout(()=>{
        window.location = "/login"
      }, 3000)
    },
  })

export const usePermissions = () =>
  useMutation(me, {
    onSuccess: (data) => {
      const authdata = storage.get(authUserKey)
      storage.save(authUserKey, { ...authdata, ...data })
      window.location = "/profile"
    },
  })

export const userUpdateMe = () => {
  const qc = useQueryClient()
  return useMutation(updateMe, {
    onSuccess: (data) => {
      const authdata = storage.get(authUserKey)
      storage.save(authUserKey, { ...authdata, ...data })
      qc.invalidateQueries("me")
    },
  })
}
