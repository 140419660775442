import React, { useState, useContext, useEffect, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import {
  Alert,
  Button,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import { useQuery } from "@tanstack/react-query"
import { useHistory, useParams } from "react-router"
import { Formik } from "formik"
import Select from "react-select"
import Swal from "sweetalert2"
import { v4 as uuidv4 } from "uuid"
import { Loading } from "components"
import { getCustomers, getPricesCustomer, getCustomer } from "services/customers"
import { getBusinessInfo } from "services/business-info"
import { searchPrices } from "services/productPrices"
import { UserContext, OfflineContext } from "App"
import storage from "services/storage"
import { useReactToPrint } from "react-to-print"
import { authUserKey } from "config/API"
import { ModalChange, PendingPaidSales, PaymentMethodModal, RenderTicketContent } from "components/sales"
import { createTicket, getTicket, updateTicket } from "services/tickets"
import PendingSales from "./pendingSales"
import { createTicketOffline, readFile, writeFile } from "SyncDB"
import RenderTicketContentOffline from "components/sales/ticketContentOffline"
import { syncTicketsOffline } from "services/offlineFiles"
import SearchProductsModal from "components/sales/modalSearchProducts"
import DiscountModal from "components/sales/modalDiscount"
import { getProductPresentation } from "services/product-presentations"
import { ModalPeriodicTask } from "pages/PeriodicTasks"

function TicketForm({ isCreate, submit, data, toggle, isLoadingSubmit, isError, error }) {
  const { user_permissions: permissions } = storage.get(authUserKey)
  // eslint-disable-next-line
  const [filters, setFilters] = useState("")
  // TODO check this line
  const [barcode, setBarcode] = useState("")
  const [exist, setExist] = useState(true)
  const user = useContext(UserContext)
  const customers = useRef([])
  const customersOffline = useRef([])
  const nameCustomers = useRef([])
  const [selectedCustomer, setSelectedCustomer] = useState({ value: "739a64f1-50a9-40b1-8530-ac293c047a0a" })
  const products = useRef()
  const [allProducts, setAllProducts] = useState([])
  const isLoading = permissions.isLoading
  const [subtotal, setSubtotal] = useState(0)
  const [customerGeneral, setCustomerGeneral] = useState(true)
  const [modalLeftMoney, setModalLeftMoney] = useState(false)
  const [isSearchProductsModalOpen, setIsSearchProductsModalOpen] = useState(false)
  const [isPaymentMethodModalOpen, setIsPaymentMethodModalOpen] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const [isSellPending, setIsSellPending] = useState(false)
  const [isPayment, setIsPayment] = useState(false)
  const [tabState, settabState] = useState({ activeTab: "sales" })
  const [payMethod, setPayMethod] = useState()
  const [maxHeight, setMaxHeight] = useState(0)
  const [quantity, setQuantity] = useState(0)
  const [isPrint, setIsPrint] = useState(false)
  const [discount, setDiscount] = useState(0.0)
  const [activeRowIndex, setActiveRowIndex] = useState(0)
  const [isNavigate, setIsNavigate] = useState(false)
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false)
  const [availableCredit, setAvailableCredit] = useState()
  const { isOffline, setIsOffline } = useContext(OfflineContext)
  const businessInfo = useQuery(["businessInfo"], () => getBusinessInfo())
  const lastProductInputRef = useRef(null)
  const barcodeInputRef = useRef()
  const productsRef = useRef([])
  const productsDefault = useRef()
  const history = useHistory()
  const dataForm = useRef()
  const { id, payment } = useParams()
  const dataTicket = useRef()
  const [ticket, setTicket] = useState()
  const componentRef = useRef()
  const tableRef = useRef(null)
  let userOffline
  let businessInfoOffline
  let ipcRenderer = null
  if (window.electron && window.electron.ipcRenderer) {
    ipcRenderer = window.electron.ipcRenderer
  }

  /*   const handleCustomPrint = async (HTMLIFrameElement) => {
    // Convertir el componente en un enlace de datos (data URL)
    const data = HTMLIFrameElement?.contentWindow?.document?.documentElement?.outerHTML
    // Crear un blob con el contenido del enlace de datos
    const blob = new Blob([data], { type: "text/html" })
    const url = URL.createObjectURL(blob)

    // Llamar a la función printComponent definida en preload.js
    try {
      if (ipcRenderer) {
        const response = await ipcRenderer.invoke("printComponent", url)
        console.log(response)
      }
    } catch (error) {
      console.error("Main error:", error)
    }
  } */

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // print: handleCustomPrint,
    onAfterPrint: () => {
      setIsPrint(false)
    },
  })

  function currencyFormatter({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }

  const StyleTableHeaders = { position: "sticky", background: "white", top: "0px", zIndex: "0" }

  if (id) {
    dataTicket.current = useQuery(["invoice", id], () => getTicket(id))
  }

  customers.current = useQuery(["customers", filters, filters.toString()], () => getCustomers(filters))
  nameCustomers.current = customers.current?.data?.results.map((customer) => ({
    value: customer.id,
    label: customer.name,
    price: customer.price,
  }))

  useEffect(() => {
    if (!isSearchProductsModalOpen) {
      const handleKeyPress = (event) => {
        if (event.key === "Escape") {
          setIsNavigate(false)
          barcodeInputRef.current.focus()
        }
      }

      window.addEventListener("keydown", handleKeyPress)

      return () => {
        window.removeEventListener("keydown", handleKeyPress)
      }
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isOffline) {
        const customers = await readFile("./customers.json")
        customersOffline.current = JSON.parse(customers)
        nameCustomers.current = customersOffline.current.map((customer) => ({
          value: customer.id,
          label: customer.name,
          price: customer.price_id__name,
        }))
      } else {
        if (navigator.onLine) {
          let tickets = await readFile("./ticketsOffline.json")
          if (tickets !== null && tickets !== "" && tickets !== undefined) {
            tickets = JSON.parse(tickets)
            const response = await syncTicketsOffline({ tickets })
            if (response === "Ok") {
              await writeFile("./ticketsOffline.json", "")
            } else {
              await writeFile("./ticketsOffline.json", response)
            }
          }
        } else {
          setIsOffline(true)
        }
      }
    }
    if (ipcRenderer) {
      fetchData()
    }
  }, [isOffline])

  useEffect(() => {
    const calculateMaxHeight = () => {
      const windowHeight = window.innerHeight
      const calculatedMaxHeight = windowHeight / 2.8
      setMaxHeight(calculatedMaxHeight)
    }

    calculateMaxHeight()

    // Agregar el listener del evento resize
    window.addEventListener("resize", calculateMaxHeight)

    // Eliminar el listener del evento resize al desmontar el componente
    return () => {
      window.removeEventListener("resize", calculateMaxHeight)
    }
  }, [])

  useEffect(() => {
    const quantityProducts = productsRef.current.reduce((total, producto) => {
      return total + producto.quantity
    }, 0)
    setQuantity(quantityProducts)
    const calculateDiscountTotal = async () => {
      let totalDiscount = 0
      let results
      for (const item of productsRef.current) {
        if (isOffline) {
          results = (products.current?.find((product) => product.product === item.id)).prices
        } else {
          results = (await searchPrices(item.id)).results
        }
        const precioUno = isOffline
          ? results.filter((price) => price.name === "P1")
          : results.filter((price) => price.price.name === "P1")
        const inicialPrice = parseFloat(precioUno[0]?.amount)
        const price = parseFloat(item.price)
        const difference = inicialPrice - price
        const discount = difference * item.quantity

        totalDiscount += discount
      }
      tableRef.current?.scrollIntoView({ behavior: "smooth", block: "end" })
      setDiscount(totalDiscount)
    }
    calculateDiscountTotal()
  }, [productsRef.current, allProducts])

  useEffect(() => {
    if (isPrint) {
      setTimeout(() => {
        handlePrint()
      }, 2000)
    }
  }, [isPrint])

  useEffect(() => {
    if (dataTicket.current?.data) {
      getCustomer(dataTicket.current.data?.customer?.id)
        .then((customer) => {
          setAvailableCredit(customer.available_credit)
        })
        .catch((error) => {
          console.error(error)
        })
      setIsSellPending(true)
      if (payment) {
        setIsPayment(true)
      }
      productsRef.current = dataTicket.current.data?.products
      const Products = dataTicket.current.data?.products.map((product) => ({
        description: product.product.description,
        id: product.product.product.id,
        name: product.product.product.name,
        price: parseFloat(product.price),
        productId: product.product.id,
        quantity: product.quantity,
        total: parseFloat(product.total),
        unitMeasurement: product.unit_measurement.id,
      }))
      productsRef.current = Products
      setAllProducts(productsRef.current)
      setSubtotal(parseFloat(dataTicket.current.data?.subtotal))
      setSelectedCustomer({ value: dataTicket.current.data?.customer?.id })
      const { user, customer, products, subtotal, total } = dataTicket.current.data
      dataForm.current = {
        user: user?.id,
        customer: {
          value: customer?.id,
          label: customer?.name,
        },
        descriptions: products?.map((product, index) => ({
          description: product.product.description,
          id: product.id,
          name: product.name,
          price: product.price,
          product: product.product.product.id,
          productId: product.product.id,
          unitMeasurement: product.unit_measurement?.id,
          quantity: product.quantity,
          total: product.total,
        })),
        subtotal: parseFloat(subtotal),
        total,
      }
    } else {
      dataForm.current = data
        ? { ...data }
        : {
            user: user?.id,
            customer: {
              value: "739a64f1-50a9-40b1-8530-ac293c047a0a",
              label: "Publico en General",
            },
            descriptions: [],
            subtotal: "",
            total: "",
          }
    }
  }, [dataTicket.current?.data, user])

  useEffect(() => {
    const fetchData = async () => {
      if (isOffline) {
        const productsOffline = await readFile("./products.json")
        products.current = JSON.parse(productsOffline)
        productsDefault.current = products.current.map((item) => {
          const productPrice = item.prices.find((price) => price.name === "P1")
          return {
            id: item.id,
            key: item.key,
            description: item.description,
            product: {
              id: item.product,
              name: item.name,
              bar_code: item.bar_code,
              cost: item.cost,
              unit_measurement: item.unit_measurement,
              price: {
                id: productPrice.id,
                price: productPrice.price_id,
                amount: productPrice.amount,
                quantity: productPrice.quantity,
                utility: productPrice.utility,
              },
            },
          }
        })
      } else {
        productsDefault.current = await getPricesCustomer("739a64f1-50a9-40b1-8530-ac293c047a0a")
        if (selectedCustomer.value !== undefined) {
          products.current = await getPricesCustomer(selectedCustomer?.value)
        }
        products.current = await getPricesCustomer("739a64f1-50a9-40b1-8530-ac293c047a0a")
      }
    }
    fetchData()
  }, [selectedCustomer, isOffline, isSearchProductsModalOpen])

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  const handleCheckProduct = () => {
    Swal.fire({
      title: "Producto no encontrado",
      text: "El producto no existe. Por favor, introduce un código de barras válido.",
      icon: "warning",
      showConfirmButton: false,
      timer: 3000,
    })
  }

  const handlePaymentMethod = () => {
    setIsPaymentMethodModalOpen(false)
    setModalLeftMoney(true)
  }

  const toggleModalSale = (handleSubmit) => {
    if (subtotal === 0) {
      Toast.fire({
        icon: "error",
        title: "Seleccione al menos un producto",
      })
      return
    }
    if (isPayment) {
      setIsDiscountModalOpen(true)
    } else {
      handleSubmit()
    }
  }

  const handleSelectCustomer = useCallback((customer) => {
    setSelectedCustomer(customer)
  }, [])

  const handleBarcodeKeyUp = (event) => {
    const { key } = event
    if (key === "ArrowDown") {
      setIsSearchProductsModalOpen(true)
      event.preventDefault()
    }
    if (key === "ArrowUp") {
      setIsNavigate(true)
      setActiveRowIndex(productsRef.current.length - 1)
      event.preventDefault()
    }
  }

  const handleBarcodeChange = (event) => {
    setBarcode(event.target.value)
  }

  const addProductByBarcode = (setFieldValue) => {
    let productToAdd = ""
    let priceProduct = ""
    let amount = ""
    if (isOffline) {
      const selectedCustomerId = selectedCustomer.value || "739a64f1-50a9-40b1-8530-ac293c047a0a"
      const priceCustomer = customersOffline.current.find(
        (customer) => customer.id === selectedCustomerId
      )?.price_id__name
      productToAdd = products.current?.find((product) => product.key === barcode)
      priceProduct = productToAdd?.prices?.find((price) => price.name === priceCustomer).amount
    } else {
      productToAdd = products.current?.results?.find((product) => product.key === barcode)
      amount = productToAdd?.product?.price.amount
    }
    const presentationUnit = productToAdd?.presentation_unit || 1

    if (!productToAdd) {
      setExist(false)
      handleCheckProduct()
      return
    }

    const {
      id: productId,
      description,
      product: { id, name, unit_measurement: unitMeasurement },
    } = productToAdd

    const newProduct = {
      id: isOffline ? productToAdd.product : id,
      productId,
      description,
      name: isOffline ? productToAdd.name : name,
      price: isOffline ? priceProduct : amount,
      quantity: 1,
      total: isOffline ? (priceProduct * presentationUnit) : (amount * presentationUnit),
      unitMeasurement: isOffline ? productToAdd.unit_measurement : unitMeasurement,
      identifier: uuidv4(),
      priceName: selectedCustomer.price?.name,
    }

    const initialValue = 0
    if (!isSellPending) {
      setAllProducts((prevProducts) => {
        return [...prevProducts, newProduct]
      })
    }

    productsRef.current.push(newProduct)
    if (productsRef.current.length) {
      const products = productsRef.current
      const newSubtotal = products.reduce((acc, product) => acc + +product.total, +initialValue)
      setFieldValue("descriptions", productsRef.current)
      setFieldValue("subtotal", newSubtotal)
      setFieldValue("total", newSubtotal.toFixed(2))
      setSubtotal(newSubtotal)
      setBarcode("")
    }

    if (selectedCustomer.value === "739a64f1-50a9-40b1-8530-ac293c047a0a") {
      setCustomerGeneral(true)
    } else {
      setCustomerGeneral(false)
    }
  }

  // Agrega una función que actualice los precios de los productos si el cliente cambia
  const updatePricesForNewCustomer = async (customer, setFieldValue) => {
    try {
      const updatedProducts = await getPricesCustomer(customer.value)
      const priceCustomer = parseInt((customer.price?.name).replace("P", ""))
      const updatedProductsList = allProducts.map((product) => {
        let priceProduct = parseInt(product?.priceName?.replace("P", ""))
        if (isNaN(priceProduct)) {
          priceProduct = 1
        }
        if (
          priceProduct !== priceCustomer &&
          updatedProducts?.results.find((producto) => producto.id === product.productId)
        ) {
          const updatedProduct = updatedProducts?.results.find((producto) => producto.id === product.productId)
          return {
            ...product,
            price: updatedProduct.product.price.amount,
            total: updatedProduct.product.price.amount * product.quantity,
            priceName: customer.price?.name,
          }
        }
        return product
      })
      productsRef.current = updatedProductsList
      setAllProducts(updatedProductsList) // Actualiza el estado para que el componente se vuelva a renderizar
      const products = productsRef.current
      const newSubtotal = products.reduce((acc, product) => acc + +product.total, +0)
      setSubtotal(newSubtotal)
      setFieldValue("descriptions", productsRef.current)
      setFieldValue("subtotal", newSubtotal)
      setFieldValue("total", newSubtotal.toFixed(2))
    } catch (error) {
      console.error(error)
    }
  }

  // Agrega una función que actualiza la cantidad del producto en el estado "allProducts"
  const updateQuantity = async (productId, newQuantity, index, setFieldValue, presentationId) => {
    try {
      let price
      let priceName
      let results
      let presentationUnit
      if (isOffline) {
        results = (products.current?.find((product) => product.product === productId)).prices
      } else {
        results = (await searchPrices(productId)).results
        presentationUnit = await getProductPresentation(presentationId)
      }
      const newQuantityPresentationUnit = parseInt(newQuantity) * parseInt(presentationUnit.presentation_unit)
      const precioUno = isOffline
        ? results.filter((price) => price.name === "P1")
        : results.filter((price) => price.price.name === "P1")
      const inicialPrice = precioUno[0]?.amount
      if (customerGeneral) {
        const matchingPrice = results
          .sort((a, b) => a.quantity - b.quantity)
          .reverse()
          .find((product) => product.quantity <= newQuantityPresentationUnit)
        if (matchingPrice) {
          price = matchingPrice.amount
          priceName = matchingPrice.price.name
        }
      } else {
        const product = allProducts.find((product) => product.id === productId)
        const prices = results
          .sort((a, b) => a.quantity - b.quantity)
          .filter((element) => element.amount < product.price)
          .reverse()
          .find((product) => product.quantity <= newQuantityPresentationUnit)
        if (prices) {
          price = prices.amount
          priceName = prices.price.name
        } else {
          price = product.price
        }
      }
      const newTotal = price * newQuantityPresentationUnit
      setAllProducts((prevProducts) =>
        prevProducts.map((product, mapIndex) => {
          if (mapIndex === index) {
            return {
              ...product,
              product: productId,
              price,
              quantity: newQuantity,
              total: newTotal.toFixed(4),
              inicialPrice,
              priceName,
            }
          }
          return product
        })
      )
      productsRef.current = productsRef.current.map((product, mapIndex) => {
        if (mapIndex === index) {
          return {
            ...product,
            product: productId,
            price,
            quantity: newQuantity,
            total: newTotal.toFixed(4),
            inicialPrice,
            priceName,
          }
        }
        return product
      })
      setFieldValue("descriptions", productsRef.current)
    } catch (error) {
      console.error(error)
    }
  }

  // Agrega un controlador de eventos para el cambio de cantidad en el último campo de entrada
  const handleQuantityChange = (event, productId, index, setFieldValue, presentationId) => {
    const newQuantity = parseInt(event.target.value)
    updateQuantity(productId, newQuantity, index, setFieldValue, presentationId)
  }

  // Agrega un controlador de eventos "onKeyDown" para la entrada de cantidad
  // que verifica si el usuario ha presionado la tecla "Enter"
  const handleQuantityKeyDown = (
    event,
    productId,
    productQuantity,
    index,
    setFieldValue,
    values,
    identifier,
    presentationId
  ) => {
    if (event.key === "Delete") {
      event.preventDefault()
      handleDeleteProduct(identifier, values, setFieldValue)
      barcodeInputRef.current.focus()
      return
    }
    if (event.key === "+") {
      event.preventDefault()
      updateQuantity(productId, productQuantity + 1, index, setFieldValue, presentationId)
    }
    if (event.key === "-") {
      event.preventDefault()
      updateQuantity(productId, Math.max(productQuantity - 1, 0), index, setFieldValue, presentationId)
    }
    if (event.key === "ArrowUp") {
      const prevActiveRowIndex = activeRowIndex
      if (prevActiveRowIndex !== 0) setActiveRowIndex(prevActiveRowIndex - 1)
      event.preventDefault()
    }
    if (event.key === "ArrowDown") {
      const prevActiveRowIndex = activeRowIndex
      if (prevActiveRowIndex !== allProducts.length - 1) setActiveRowIndex(prevActiveRowIndex + 1)
      event.preventDefault()
    }
    if (event.key === "Enter") {
      setIsNavigate(false)
      barcodeInputRef.current.focus()
      const newSubtotal = allProducts.reduce((acc, product) => {
        return acc + +product.total
      }, 0)
      setFieldValue("subtotal", newSubtotal)
      setFieldValue("total", newSubtotal)
      setSubtotal(newSubtotal)
    }
  }

  const handleKeyDown = (event, setFieldValue) => {
    if (event.keyCode === 13) {
      // Agregar el producto en base al código de barras
      addProductByBarcode(setFieldValue)
    }
  }

  useEffect(() => {
    // Si el producto no existe, muestra la alerta
    if (!exist) {
      handleCheckProduct()
    }
  }, [exist])

  const renderLoading = () => {
    return (
      <div className="text-center">
        <Loading isLoading={isLoading} size={2} />
      </div>
    )
  }

  const renderError = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={permissions.isError}>
          {permissions?.error?.errors?.map((error) => error?.message)}
        </Alert>
      </div>
    )
  }

  const handleSubmit = async (values, { resetForm }) => {
    const payload = {
      user: values.user,
      customer: values.customer.value,
      descriptions: values.descriptions.map((desc, index) => ({
        product: desc.productId, // Cambio de nombre de la propiedad "id" a "product"
        price: desc.price,
        unit_measurement: desc.unitMeasurement,
        quantity: desc.quantity,
        total: desc.total,
        folio: index + 1,
      })),
      subtotal: (values.subtotal + discount).toFixed(2),
      discount: discount.toFixed(2),
      total: values.total,
    }
    try {
      if (isSellPending) {
        const updatedPayload = { ...payload }
        updatedPayload.descriptions = values.descriptions.map((desc, index) => ({
          product: desc.productId, // Cambio de nombre de la propiedad "id" a "product"
          price: desc.price,
          unit_measurement: desc.unitMeasurement,
          quantity: desc.quantity,
          total: desc.total,
          folio: index + 1,
        }))
        if (isPayment) {
          updatedPayload.is_paid = true
          updatedPayload.pay_method = payMethod
        }
        updatedPayload.is_pending = false
        await updateTicket(id, updatedPayload)
        history.push("/sale")
        Toast.fire({
          icon: "success",
          title: "Venta realizada correctamente",
        })
      } else {
        if (isPending) {
          const updatedPayload = { ...payload }
          updatedPayload.is_pending = true
          await createTicket(updatedPayload)
          Toast.fire({
            icon: "info",
            title: "La venta fue pospuesta",
          })
        } else {
          if (isOffline) {
            const dataOffline = { ...values }
            userOffline = await readFile("./user.json")
            userOffline = JSON.parse(userOffline)
            businessInfoOffline = await readFile("./business-info.json")
            businessInfoOffline = JSON.parse(businessInfoOffline)
            dataOffline.business_info = businessInfoOffline
            dataOffline.discount = discount
            dataOffline.user = userOffline
            const hoy = new Date()
            const horaMexico = new Date(hoy.getTime())
            const date = horaMexico.toISOString()
            const payloadOffline = { ...payload }
            payloadOffline.date = date
            setTicket(dataOffline)
            createTicketOffline(payloadOffline)
            setIsPrint(true)
            Toast.fire({
              icon: "success",
              title: "Venta realizada correctamente",
            })
          } else {
            setTicket(await createTicket(payload))
            setIsPrint(true)
            Toast.fire({
              icon: "success",
              title: "Venta realizada correctamente",
            })
          }
        }
      }
      setIsPending(false)
      productsRef.current = []
      setSubtotal(0)
      setSelectedCustomer("739a64f1-50a9-40b1-8530-ac293c047a0a")
      setAllProducts([])
      resetForm()
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.errors[0].message,
      })
    }
  }

  const handleCancel = () => {
    productsRef.current = []
    setSubtotal(0)
    setSelectedCustomer("739a64f1-50a9-40b1-8530-ac293c047a0a")
    setAllProducts([])
    history.push("/sale")
    Toast.fire({
      icon: "error",
      title: "Venta cancelada",
    })
  }

  const toggleActiveTab = (tab) => {
    if (tabState.activeTab !== tab) {
      settabState({
        activeTab: tab,
      })
    }
  }

  const handleDeleteProduct = (productId, values, setFieldValue) => {
    // Obtén la lista de descripciones actual del formulario
    const descriptions = [...values.descriptions]

    // Filtra las descripciones para excluir el producto con el ID proporcionado
    const updatedDescriptions = descriptions.filter((desc) => desc.productId !== productId)

    // Actualiza la lista de productos eliminando el producto con el ID proporcionado
    const updatedAllProducts = updatedDescriptions.filter((product) => product.identifier !== productId)
    productsRef.current = updatedAllProducts
    setAllProducts(productsRef.current)

    // Actualiza los valores del formulario con las descripciones actualizadas
    setFieldValue("descriptions", productsRef.current)

    // Calcula el nuevo subtotal y total
    const newSubtotal = productsRef.current.reduce((subtotal, desc) => subtotal + desc.total, 0)
    const newTotal = parseFloat(newSubtotal).toFixed(2) // Asegúrate de tener una función para calcular el total

    // Actualiza los valores de subtotal y total en el formulario
    setSubtotal(parseFloat(newSubtotal))
    setFieldValue("subtotal", newSubtotal)
    setFieldValue("total", newTotal)
  }

  const handleDeleteMouseDown = (event, productId, values, setFieldValue) => {
    // Evita que el evento del botón se propague y cause problemas con el primer clic
    event.preventDefault()

    // Llama a la función para eliminar el producto
    handleDeleteProduct(productId, values, setFieldValue)
  }

  const [modalCashCut, setmodalCashCut] = useState(false)
  const toggleModalCashCut = () => {
    setmodalCashCut(!modalCashCut)
  }
  const canAdd = permissions.some((e) => e.codename === "add_cashcut")

  document.title = "Generar Ticket | Punto de venta"
  return (
    <React.Fragment>
      {canAdd &&
        <ModalPeriodicTask isOpen={modalCashCut} toggle={toggleModalCashCut} />
      }
      {isLoading && renderLoading()}
      {!isLoading && permissions.isError && renderError()}
      {!isLoading && !permissions.isError && (
        <Formik enableReinitialize initialValues={dataForm.current || {}} onSubmit={handleSubmit}>
          {({ values, setFieldValue, handleChange, handleSubmit, handleBlur, touched, errors, setTouched }) => (
            <div className="page-content">
              <Container fluid>
                <Row>
                  <Col>
                    <h2>Generar Ticket</h2>
                  </Col>
                  <Col className="d-flex justify-content-end pb-2">
                    <Button color="success" className="d-flex align-items-center me-2" onClick={toggleModalCashCut}>
                      <i className="me-2 fa fa-clipboard-check" />
                      <span className="text-truncate">Corte de caja manual</span>
                    </Button>
                  </Col>
                </Row>
                <div className="bg-white p-3">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={` ${tabState.activeTab === "sales" && "active"}`}
                        onClick={() => toggleActiveTab("sales")}
                      >
                        Venta
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={` ${tabState.activeTab === "pending" && "active"}`}
                        onClick={() => toggleActiveTab("pending")}
                      >
                        Ventas Pendientes
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={` ${tabState.activeTab === "paidPending" && "active"}`}
                        onClick={() => toggleActiveTab("paidPending")}
                      >
                        Ventas por Cobrar
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={tabState.activeTab}>
                    <TabPane tabId="sales">
                      <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
                      <Row>
                        <Col>
                          <Label>Vendedor:</Label>
                          <div
                            style={{
                              border: "1px",
                              padding: "8px",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {user?.first_name}
                          </div>
                        </Col>
                        <Col>
                          <Label>Cliente:</Label>
                          <Select
                            style={{ zIndex: "3" }}
                            id="cliente"
                            name="cliente"
                            className=""
                            isClearable
                            value={values.customer}
                            onChange={(customer) => {
                              setFieldValue("customer", customer)
                              handleSelectCustomer(customer)
                              updatePricesForNewCustomer(customer, setFieldValue)
                            }}
                            options={nameCustomers.current}
                            noOptionsMessage={() => "0 opciones"}
                            loadingMessage={() => "Cargando..."}
                            placeholder="Cliente"
                            isDisabled={isPayment}
                          />
                        </Col>
                      </Row>
                      <br />
                      <hr />
                      <div style={{ maxHeight: maxHeight + "px", overflowY: "auto" }}>
                        <Table tabIndex={0}>
                          <thead>
                            <tr>
                              <th style={StyleTableHeaders}>Cantidad</th>
                              <th style={StyleTableHeaders}>Nombre</th>
                              <th style={StyleTableHeaders}>Descripción</th>
                              <th style={StyleTableHeaders}>Precio</th>
                              <th style={StyleTableHeaders}>Importe</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productsRef.current?.map((product, index) => {
                              const id = uuidv4()
                              const isLast = index === productsRef.current.length - 1
                              const style = isLast
                                ? {
                                    backgroundColor: "#5DADE2",
                                    color: "white",
                                  }
                                : {}
                              const isActive =
                                index === activeRowIndex && isNavigate ? { backgroundColor: "#5DADE2" } : {}
                              return (
                                <tr key={id + product.id} style={style}>
                                  <td style={isActive}>
                                    <Input
                                      value={product.quantity}
                                      type="number"
                                      disabled={index !== activeRowIndex}
                                      style={{
                                        width: "20%",
                                        border: "0px solid",
                                      }}
                                      ref={index === activeRowIndex && isNavigate ? lastProductInputRef : null}
                                      autoFocus={index === activeRowIndex && isNavigate}
                                      onChange={(event) => {
                                        handleQuantityChange(event, product.id, index, setFieldValue, product.productId)
                                      }}
                                      onKeyDown={(event) => {
                                        handleQuantityKeyDown(
                                          event,
                                          product.id,
                                          product.quantity,
                                          index,
                                          setFieldValue,
                                          values,
                                          product.identifier,
                                          product.productId
                                        )
                                      }}
                                      onFocus={(event) => {
                                        setTimeout(() => {
                                          event.target.select()
                                        }, 250) // Demorar la selección del contenido
                                      }}
                                    />
                                  </td>
                                  <td style={isActive}>{product?.name}</td>
                                  <td style={isActive}>{product?.description}</td>
                                  <td style={isActive}>
                                    {currencyFormatter({ currency: "USD", value: product.price })}
                                  </td>
                                  <td style={isActive}>
                                    {currencyFormatter({ currency: "USD", value: product.total })}
                                  </td>
                                  <td style={isActive}>
                                    <Button
                                      color="danger"
                                      onMouseDown={(event) =>
                                        handleDeleteMouseDown(event, product.identifier, values, setFieldValue)
                                      }
                                    >
                                      <i className="fa fa-trash" />
                                    </Button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                        <div ref={tableRef} style={{ height: "1px" }} />
                      </div>
                      <h2 className="text-center">Total de Artículos: {quantity}</h2>
                      <div className="text-end" style={{ marginRight: "20px" }}>
                        {/* <h2>
                          <Label>Subtotal:</Label>
                          {currencyFormatter({ currency: "USD", value: parseFloat(subtotal + discount).toFixed(2) })}
                        </h2> */}
                        <h2>
                          <Label>Ahorro:</Label>
                          {currencyFormatter({ currency: "USD", value: discount.toFixed(2) })}
                        </h2>
                        <h2>
                          <Label>Total:</Label>
                          {isCreate
                            ? currencyFormatter({ currency: "USD", value: subtotal.toFixed(2) })
                            : currencyFormatter({ currency: "USD", value: subtotal.toFixed(2) - discount.toFixed(2) })}
                        </h2>
                      </div>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="barcode"
                        value={barcode}
                        onChange={(event) => {
                          handleBarcodeChange(event, setFieldValue)
                        }}
                        onKeyDown={(event) => {
                          handleKeyDown(event, setFieldValue)
                        }}
                        disabled={isPayment}
                        innerRef={barcodeInputRef}
                        onKeyUp={handleBarcodeKeyUp}
                        onBlur={handleBlur}
                        placeholder="CÓDIGO DE BARRAS"
                      />
                      <DiscountModal
                        isOpen={isDiscountModalOpen}
                        toggleModal={() => setIsDiscountModalOpen(!isDiscountModalOpen)}
                        subtotal={subtotal}
                        setIsPaymentMethodModalOpen={setIsPaymentMethodModalOpen}
                        discount={discount}
                        setDiscount={setDiscount}
                        setFieldValue={setFieldValue}
                      />
                      <PaymentMethodModal
                        isOpen={isPaymentMethodModalOpen}
                        toggleModal={() => setIsPaymentMethodModalOpen(!isPaymentMethodModalOpen)}
                        handlePaymentMethod={handlePaymentMethod}
                        handleSubmit={handleSubmit}
                        setPayMethod={setPayMethod}
                        availableCredit={availableCredit}
                      />
                      <ModalChange
                        total={subtotal.toFixed(2)}
                        isOpen={modalLeftMoney}
                        toggleModal={() => setModalLeftMoney(!modalLeftMoney)}
                        handleSubmit={handleSubmit}
                      />
                      <SearchProductsModal
                        isOpen={isSearchProductsModalOpen}
                        toggleModal={() => setIsSearchProductsModalOpen(!isSearchProductsModalOpen)}
                        products={isOffline ? productsDefault.current : productsDefault.current?.results}
                        barcode={barcode}
                        setBarcode={setBarcode}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "20px",
                        }}
                      >
                        <Button color="danger" onClick={handleCancel} className="mr-2">
                          Cancelar
                        </Button>
                        <div className="mx-2"></div>
                        {!isPayment && (
                          <Button
                            color="warning"
                            onClick={() => {
                              setIsPending(true)
                              handleSubmit()
                            }}
                            className="ml-auto"
                            disabled={isSellPending}
                          >
                            Posponer venta
                          </Button>
                        )}
                        <div className="mx-2"></div>
                        <Button
                          color="info"
                          onClick={() => {
                            toggleModalSale(handleSubmit)
                          }}
                          className="ml-auto"
                        >
                          Finalizar
                        </Button>
                      </div>
                      {isPrint ? (
                        isOffline ? (
                          <div style={{ display: "none" }}>
                            <RenderTicketContentOffline ref={componentRef} data={ticket} businessInfo={businessInfo} />
                          </div>
                        ) : (
                          <div style={{ display: "none" }}>
                            <RenderTicketContent ref={componentRef} data={ticket} businessInfo={businessInfo} />
                          </div>
                        )
                      ) : null}
                    </TabPane>
                    <TabPane tabId="pending">
                      <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
                      <PendingSales activeTab={tabState} />
                    </TabPane>
                    <TabPane tabId="paidPending">
                      <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
                      <PendingPaidSales activeTab={tabState} />
                    </TabPane>
                  </TabContent>
                </div>
              </Container>
            </div>
          )}
        </Formik>
      )}
    </React.Fragment>
  )
}

TicketForm.propTypes = {
  isCreate: PropTypes.bool,
  submit: PropTypes.func,
  data: PropTypes.object,
  toggle: PropTypes.func,
  isLoadingSubmit: PropTypes.bool,
  isError: PropTypes.bool,
  error: PropTypes.object,
}

export default TicketForm
