import propTypes from "prop-types"
import { Formik } from "formik"
import { useQuery, useMutation } from "@tanstack/react-query"
import { useState, useEffect, useCallback, useRef } from "react"
import { Alert, Button, Col, Form, Input, Label, Row, Table } from "reactstrap"
import Swal from "sweetalert2"

import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"
import { CreateModalInventory, EditModalForm } from "components/stores/ModalInventories"
import { deleteInventory, getAllInventories } from "services/inventories"
import { getStores } from "services/stores"
import { debounce } from "lodash"

import schema from "./schema"
import ListHeader from "ui-componets/list-header"
import { API, authUserKey } from "config/API"
import storage from "services/storage"
import ModalTransferStore from "./ModalInventories/ModalTransferStore"

const StoreForm = (props) => {
  const { data, disabledForm, error, isError, isLoading, isRetrivePage, refetchData, submit, toggle } = props
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const [filters, setFilters] = useState("")
  const [inputSelect, setInputSelect] = useState("")
  const [modalStoresCreate, setmodalStoresCreate] = useState(false)
  const [modalStoresEdit, setmodalStoresEdit] = useState(false)
  const [modalTransferStores, setModalTransferStores] = useState(false)
  const [storeId, setstoreId] = useState(false)
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")
  const inventories = useQuery(["inventories"], () => getAllInventories(filters))
  const [filteredInventories, setFilteredInventories] = useState(inventories?.data || [])
  const stores = useRef()
  
  useEffect(()=>{
    const syncInventories = async () => {
      setFilteredInventories((filteredInventories.length = 0))
      const response = await API.get(
        `/stores-inventories?ordering=quantity&store__id=${storeToSearch}`
      )
      setFilteredInventories(filteredInventories.concat(response.results))
    }
    syncInventories()
  }, [inventories?.data])

  useEffect(() => {
    getStores()
      .then((res) => {
        stores.current = res
      })
      .catch((error) => console.log(error))
  }, [])

  useEffect(() => {
    setFilteredInventories(inventories?.data)
  }, [inventories?.data])

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  const inventoryDeleted = useMutation((id) => deleteInventory(id))
  const [searchTxt, setSearchTxt] = useState("")
  const storeToSearch = data.id

  const permissions = storage.get(authUserKey).user_permissions || []
  const canEdit = permissions.some((e) => e.codename === "change_storeinventory")
  const canDelete = permissions.some((e) => e.codename === "delete_storeinventory")
  const canAdd = permissions.some((e) => e.codename === "add_storeinventory")

  const debouncedSearch = useCallback(
    debounce(async (text) => {
      setFilteredInventories((filteredInventories.length = 0))
      const response = await API.get(
        `/stores-inventories?product__description=${text}&store__id=${storeToSearch}`
      )
      setFilteredInventories(filteredInventories.concat(response.results))
    }, 300),
    []
  )

  function handleChangeSearch(event) {
    setSearchTxt(event.target.value)
    debouncedSearch(event.target.value)
  }

  const handleSearch = async () => {
    setFilteredInventories((filteredInventories.length = 0))
    const response = await API.get(
      `/stores-inventories?product__description=${searchTxt}&store__id=${storeToSearch}`
    )
    setFilteredInventories(filteredInventories.concat(response.results))
  }

  const openDeleteInventory = (inventory) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar el inventario de: <b>${inventory.product.description}</b>?`,
      text: "Este cambio no se podrá revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        inventoryDeleted.mutateAsync(inventory?.id).then(() => {
          refetchData()
          inventories.refetch()
          handleSearch()
          Toast.fire(
            "!Eliminado!",
            `El inventario <b>${inventory.product.description}</b> ha sido eliminado correctamente`,
            "success"
          )
        })
      }
    })
  }

  const toggleModalStoresCreate = () => {
    setmodalStoresCreate(!modalStoresCreate)
  }

  const toggleModalTransferStores = () => {
    setModalTransferStores(!modalTransferStores)
  }

  const toggleModalStoresEdit = () => {
    setmodalStoresEdit(!modalStoresEdit)
  }

  useEffect(() => {
    if (showAlertSuccess) {
      Toast.fire({
        icon: "success",
        title: `${msgSuccess}`,
        didOpen: () => {
          refetchData()
          inventories.refetch()
          setShowAlertSuccess(false)
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "green",
      })
    }
  }, [showAlertSuccess])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const params = new URLSearchParams("")
      params.set("search", inputSelect)
      setFilters(filters)
      setFilters(params)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [inputSelect])

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setInputSelect()
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: data?.name ?? "",
        code: data?.code ?? "",
        manager: data?.manager ?? "",
      }}
      validationSchema={schema}
      onSubmit={submit}
    >
      {({ values, handleChange, handleSubmit, handleBlur, touched, errors }) => (
        <Form className="form-horizontal" onSubmit={handleSubmit}>
          <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
            {isError && error.errors?.map((error) => error?.message)}
          </Alert>
          {data?.id && (
            <>
              {!disabledForm && (
                <CreateModalInventory
                  isOpen={modalStoresCreate}
                  toggle={toggleModalStoresCreate}
                  storeId={data?.id}
                  setShowAlertSuccess={setShowAlertSuccess}
                  setMsgSuccess={setMsgSuccess}
                  inventories={filteredInventories}
                  refetch={setFilteredInventories}
                  inventoriesRegistered={data.inventories}
                />
              )}
              {!disabledForm && storeId && (
                <EditModalForm
                  isOpen={modalStoresEdit}
                  toggle={toggleModalStoresEdit}
                  inventoryId={storeId}
                  setShowAlertSuccess={setShowAlertSuccess}
                  setMsgSuccess={setMsgSuccess}
                  inventories={filteredInventories}
                  refetchInventory={setFilteredInventories}
                  storeId={data?.id}
                  inventoriesRegistered={data.inventories}
                />
              )}
              {!disabledForm && stores.current?.results.length > 0 && (
                <ModalTransferStore
                  isOpen={modalTransferStores}
                  toggle={toggleModalTransferStores}
                  stores={stores.current?.results}
                  refetch={setFilteredInventories}
                  productsToTransfer={data.inventories}
                />
              )}
            </>
          )}
          <Row className="mb-3">
            <Col>
              <Label className="form-label">
                Código <RequiredIcon />
              </Label>
              <Input
                autoComplete="do-not-autofill"
                className="form-control"
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                invalid={
                  !!(touched.name && errors.name) ||
                  (isError && validationErrors.findIndex((e) => e.field === "code") >= 0)
                }
              />
              <ErrorsText
                formName="code"
                touched={touched}
                errorsForm={errors}
                isErrorBack={isError}
                errorsBack={error}
              />
            </Col>
            <Col>
              <Label for="price">Nombre</Label> <RequiredIcon />
              <Input
                autoComplete="do-not-autofill"
                className="form-control"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                invalid={
                  !!(touched.name && errors.name) ||
                  (isError && validationErrors.findIndex((e) => e.field === "name") >= 0)
                }
              />
              <ErrorsText
                formName="name"
                touched={touched}
                errorsForm={errors}
                isErrorBack={isError}
                errorsBack={error}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Label className="form-label">Administrador</Label>
              <Input
                autoComplete="do-not-autofill"
                className="form-control"
                name="manager"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.manager}
                invalid={
                  !!(touched.name && errors.name) ||
                  (isError && validationErrors.findIndex((e) => e.field === "manager") >= 0)
                }
              />
              <ErrorsText
                formName="manager"
                touched={touched}
                errorsForm={errors}
                isErrorBack={isError}
                errorsBack={error}
              />
            </Col>
          </Row>

          <hr />

          <Row className="mb-3">
            <Col>
              <Row>
                <Col>
                  <h5>Inventario del almacén</h5>
                </Col>
                <Col className="text-end">
                  {canAdd && !disabledForm && data?.id && (
                    <>
                      <Button
                        color="info"
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                          toggleModalTransferStores()
                        }}
                      >
                        <i className="fa fa-plus" /> Movimiento entre almacenes
                      </Button>
                      {/* <Button
                        color="info"
                        onClick={() => {
                          toggleModalStoresCreate()
                        }}
                      >
                        <i className="fa fa-plus" /> Agregar
                      </Button> */}
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <ListHeader
                    handleOnChangeSearch={handleChangeSearch}
                    handleSearch={handleSearch}
                    searchTxt={searchTxt}
                  />
                </Col>
              </Row>

              <Table>
                <thead>
                  <tr>
                    <th>Producto</th>
                    <th>Cantidad en almacén</th>
                    <th>Punto de reorden</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredInventories !== 0
                    ? filteredInventories?.map((inventory) => (
                        <tr key={inventory?.id}>
                          <td
                            style={
                              inventory?.quantity <= 0
                                ? { backgroundColor: "#ff3b00", color: "white" }
                                : inventory?.quantity < inventory?.reorder_point
                                ? { backgroundColor: "#f3f369" }
                                : {}
                            }
                          >
                            {inventory?.product?.description}
                          </td>
                          <td
                            style={
                              inventory?.quantity <= 0
                                ? { backgroundColor: "#ff3b00", color: "white" }
                                : inventory?.quantity < inventory?.reorder_point
                                ? { backgroundColor: "#f3f369" }
                                : {}
                            }
                          >
                            {inventory?.quantity}
                          </td>
                          <td
                            style={
                              inventory?.quantity <= 0
                                ? { backgroundColor: "#ff3b00", color: "white" }
                                : inventory?.quantity < inventory?.reorder_point
                                ? { backgroundColor: "#f3f369" }
                                : {}
                            }
                          >
                            {inventory?.reorder_point}
                          </td>
                          <td
                            style={
                              inventory?.quantity <= 0
                                ? { backgroundColor: "#ff3b00", color: "white" }
                                : inventory?.quantity < inventory?.reorder_point
                                ? { backgroundColor: "#f3f369" }
                                : {}
                            }
                          >
                            {canEdit && !disabledForm && (
                              <Button
                                className="mx-1"
                                color="info"
                                size="sm"
                                onClick={() => {
                                  setstoreId(inventory?.id)
                                  toggleModalStoresEdit()
                                }}
                              >
                                <i className="fa fa-edit" />
                              </Button>
                            )}
                            {canDelete && !disabledForm && (
                              <Button
                                className="mx-1"
                                color="danger"
                                size="sm"
                                onClick={() => {
                                  openDeleteInventory(inventory)
                                }}
                              >
                                <i className="fa fa-trash" />
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Col>
          </Row>

          <hr />

          <div className="mt-3 text-end">
            {!isRetrivePage && (
              <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                <i className="fa fa-times" /> Cerrar
              </Button>
            )}
            {!disabledForm && (
              <Button color="success" type="submit" disabled={isLoading}>
                <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

StoreForm.defaultProps = {
  data: null,
  isRetrivePage: false,
  toggle: () => {},
  disabledForm: false,
}

StoreForm.propTypes = {
  data: propTypes.any,
  disabledForm: propTypes.bool,
  error: propTypes.any,
  isError: propTypes.bool,
  isLoading: propTypes.bool,
  isRetrivePage: propTypes.bool,
  refetchData: propTypes.func,
  submit: propTypes.func,
  toggle: propTypes.func,
}

export default StoreForm
