import * as Yup from "yup"

import { messages } from "utils/messages"

const schema = Yup.object({
  name: Yup.string().max(120, messages.max_chars(120)).required(messages.required),
  providers: Yup.array(),
  bar_code: Yup.string().max(20, messages.max_chars(20)).required(messages.required),
  sat_code: Yup.string().max(255, messages.max_chars(255)).required(messages.required),
  cost: Yup.string().required(messages.required),
  prices: Yup.array().of(Yup.object()).nullable(),
  brand: Yup.object().nullable(),
  unit_measurement: Yup.object().nullable(),
  taxes: Yup.array(),
})

export default schema
