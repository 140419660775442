import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "reactstrap"
import Earning from "./earning"
import TableContainer from "./reorderProducts"
import OrderedProductsTable from "./orderedProducts"
import MoroseClientsTable from "./moroseClients"
import { getOrderedInventories } from "services/inventories"
import { Loading } from "components"

const Dashboard = () => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const fetchOrderedInventories = () => {
    setIsLoading(true)
    setError(null)

    getOrderedInventories()
      .then((response) => {
        setData(response.results)
        setIsLoading(false)
      })
      .catch((error) => {
        setError(error)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchOrderedInventories()
  }, [])

  document.title = "Admin | Punto de venta"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Earning dataColors='["--bs-primary"]' />
            <Col lg="4">
              {isLoading ? (
                // Si isLoading es true, muestra "Cargando..."
                <div className="text-center">
                  <Loading isLoading={isLoading} size={2} />
                </div>
              ) : error ? (
                // Si hay un error, muestra un mensaje de error
                <div>Error: {error.message}</div>
              ) : data && data.length > 0 ? (
                // Si hay datos disponibles, muestra el componente OrderedProductsTable
                <OrderedProductsTable data={data} isLoading={isLoading} error={error} />
              ) : (
                // Si no hay datos disponibles, muestra un mensaje
                <div>No hay datos disponibles.</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <TableContainer refetchData={fetchOrderedInventories} />
            </Col>
          </Row>
          <Row>
            <MoroseClientsTable />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
