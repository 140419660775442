import { API } from "config/API"

export const getUsers = async (filters) => {
  const response = await API.get("/users", { params: filters })
  return response
}

export const getUser = async (id) => {
  const response = await API.get(`/users/${id}`)
  return response
}

export const createUser = async (payload) => {
  const response = await API.post("/users", payload)
  return response
}

export const editUser = async (id, payload) => {
  const response = await API.patch(`/users/${id}`, payload)
  return response
}

export const deleteUser = async (id) => {
  const response = await API.delete(`/users/${id}`)
  return response
}
