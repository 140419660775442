import React, { useEffect, useRef } from "react"
import { Row, Col } from "reactstrap"
import PropTypes from "prop-types"
import { useQuery } from "@tanstack/react-query"
import { getTicket } from "services/tickets"
import TableProduct from "components/sales/ticket"
import bwipjs from "bwip-js"
import { API } from "config/API"

const RenderTicketContent = React.forwardRef(({ data, businessInfo }, ref) => {
  const canvasRef = useRef(null)
  const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000"
  const qrData = `{
        "id": "${data?.id}", 
        "url": "${API_URL}/sales/${data?.id}",
        "user": {"id": "${data?.user?.id}", "name": "${data?.user?.first_name} ${data?.user?.last_name}"},
        "customer": {"id": "${data?.customer?.id}", "name": "${data?.customer?.name}"}, 
        "date": "${data?.date}",
        "subtotal": ${data?.subtotal},
        "total": ${data?.total}
    }`

  useEffect(() => {
    if (canvasRef.current) {
      bwipjs.toCanvas(canvasRef.current, {
        bcid: "qrcode",
        scale: 1,
        text: qrData,
      })
    }
  }, [data.id])

  let dataTicket = {}
  if (data?.descriptions) {
    dataTicket = useQuery(["invoice", data.id], () => getTicket(data.id))
    if (dataTicket.data) {
      data = dataTicket.data
    }
  }
  const totalQuantity = data?.products?.reduce((total, product) => total + product?.quantity, 0)
  const style = {
    p: { color: "black", lineHeight: "0.3em", fontSize: "1.2em" },
  }
  let customer = data?.customer.name
  let cashier = data?.user.first_name + " " + data.user.last_name
  if (data.customer.name) {
    if ((data?.customer?.name).length > 9) {
      customer = (data?.customer.name).substring(0, 9) + "..."
    }
    if ((data?.user?.first_name + " " + data.user.last_name).length > 9) {
      cashier = (data?.user.first_name + " " + data.user.last_name).substring(0, 9) + "..."
    }
  }

  function currencyFormatter({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }

  const apiUrl = ["https://api.pos.emkode.org", "https://api-dulceria.emkode.com", "https://api-plasticos.emkode.com"]
  const getImage = (item) => {
    if (apiUrl.includes(API.defaults.baseURL)) {
      const imageArray = item.image.medium_thumbnail.split("media")
      if (imageArray && imageArray[0].slice(-1) !== ".") {
        const imageString = imageArray[0] + ".media" + imageArray[1]
        return imageString
      }
    } else {
      return item.image.medium_thumbnail
    }
  }

  const date = new Date(data?.date)
  const formattedDate = date.toLocaleString()
  return (
    <div ref={ref} style={{ padding: "0em" }}>
      {businessInfo.data?.results?.map((business) => (
        <div key={business.id} style={{ textAlign: "center" }}>
          <Col style={{ marginTop: "60px" }}>
            <img src={getImage(business)} alt="Logo" />
          </Col>
          <Row>
            <Col>
              <h2 style={{ color: "black" }} className="text-center">
                {business.name}
              </h2>
              <p style={{ color: "black" }} className="text-center">
                RFC: {business.rfc}
                <br />
                {business.street}
                <br />
                {business.neighborhood}
                <br />
                C.P: {business.fiscal_zipcode}
                <br />
                Tel: {business.phone_number}
              </p>
            </Col>
          </Row>
        </div>
      ))}
      <p style={{borderBottom: "1px solid black"}}/>
      
      <Row>
        <Col>
          <p style={style.p}>Cliente: {customer}</p>
        </Col>
        <Col>
          <p style={style.p}>Cajero: {cashier}</p>
        </Col>
        <p style={style.p}>Fecha: {formattedDate}</p>
        <p style={{borderBottom: "1px solid black", width: "97.5%", marginLeft: "10px"}}/>
      </Row>

      <TableProduct products={data?.products} />
      <Row>
        <Col>
          {/* <h5 className="text-end">
            Subtotal: {currencyFormatter({ currency: "USD", value: parseFloat(data?.subtotal) })}
          </h5> */}
          <h2 style={{ color: "black" }} className="text-end">
            Total: {currencyFormatter({ currency: "USD", value: parseFloat(data?.total) })}
          </h2>
          <h5 style={{ color: "black" }} className="text-end">
            IVA Incluido
          </h5>
          <h3 style={{ color: "black" }} className="text-center">
            <strong>¡Usted Ahorró! {currencyFormatter({ currency: "USD", value: parseFloat(data?.discount) })}</strong>
          </h3>
        </Col>
      </Row>
      <h3 style={{ color: "black" }} className="text-center">
        Total de Artículos: {totalQuantity}
      </h3>
      {/* <Col className="text-center">
        <canvas ref={canvasRef}></canvas>
      </Col> */}
      <p style={{ color: "black" }} className="text-center">
        Gracias por su preferencia este ticket no es un comprobante fiscal si requiere factura solicítela el mismo día
      </p>
    </div>
  )
})

RenderTicketContent.propTypes = {
  data: PropTypes.object.isRequired,
  businessInfo: PropTypes.object.isRequired,
}

RenderTicketContent.displayName = "RenderTicketContent"

export default RenderTicketContent
