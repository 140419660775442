import React, { useState } from "react"
import propTypes from "prop-types"

import storage from "services/storage"
import { authUserKey } from "config/API"
import { RetrieveModalMovement } from "./ModalMovements"

const MovementItemList = ({ item }) => {
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }

  const [modalRetrieve, setModalRetrieve] = useState(false)
  const [movementId, setMovementId] = useState(null)
  const permissions = storage.get(authUserKey).user_permissions || []
  const canView = permissions.some((e) => e.codename === "view_store")

  const toggleModalRetrieve = () => {
    if (modalRetrieve) {
      setMovementId("")
    }
    setModalRetrieve(!modalRetrieve)
  }

  const openModalRetrieve = (movementId) => {
    setMovementId(movementId)
    toggleModalRetrieve()
  }

  return (
    <>
      {canView && movementId && (
        <RetrieveModalMovement isOpen={modalRetrieve} toggle={toggleModalRetrieve} movementId={movementId} />
      )}
      <tr key={item?.id}>
        <td style={styleCenterTable}>
          <a
            onClick={() => openModalRetrieve(item.id)}
            style={{ color: "rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1))" }}
          >
            <span>{item.id}</span>
          </a>
        </td>
        <td style={styleCenterTable}>{item.reason}</td>
        <td style={styleCenterTable}>{item.description}</td>
        <td style={styleCenterTable}>{item.source_store.name}</td>
        <td style={styleCenterTable}>{item.destiny_store.name}</td>
        <td style={styleCenterTable}>
          {new Date(item.date).toLocaleDateString("es-MX", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </td>
        <td style={styleCenterTable}>
          {item?.user?.first_name} {item?.user?.last_name}
        </td>
      </tr>
    </>
  )
}

MovementItemList.propTypes = {
  item: propTypes.object,
}

export default MovementItemList
