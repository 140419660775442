import React, { useContext, useState, useEffect } from "react"
import { useQueryClient, QueryObserver } from "@tanstack/react-query"

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

import { Link } from "react-router-dom"

import storage from "services/storage"
import { authUserKey } from "config/API"
import { UserContext } from "App"

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false)
  const [submenu, setSubmenu] = useState(false)
  const [username, setUsername] = useState("Admin")
  const qc = useQueryClient()
  const userData = useContext(UserContext)

  useEffect(() => {
    const observer = new QueryObserver(qc, {
      queryKey: ["me"],
      enabled: false,
    })

    const unsubscribe = observer.subscribe((queryResult) => {
      if (queryResult.isSuccess) {
        const user = queryResult.data
        if (user?.first_name !== "" || user?.last_name !== "") {
          setUsername(`${user?.first_name} ${user?.last_name}`)
        } else {
          setUsername("Admin")
        }
      }
    })
    // Clean up the subscription when the component unmounts
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    const user = storage.get(authUserKey)
    if (user?.first_name !== "" || user?.last_name !== "") {
      setUsername(`${user?.first_name} ${user?.last_name}`)
    } else {
      setUsername("Admin")
    }
  }, [])

  const permissions = storage.get(authUserKey).user_permissions || []
  const isPrice = permissions.some((e) => e.codename === "view_price")
  const isBranchOffice = permissions.some((e) => e.codename === "view_branchoffice")
  const isUser = permissions.some((e) => e.codename === "view_user")
  const isBusinessInfo = permissions.some((e) => e.codename === "view_businessinfo")
  const isAdmin = userData?.user_type

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item" id="page-header-user-dropdown" tag="button">
          <span className="rounded-circle header-profile-user"></span>
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            <span>Perfil</span>
          </Link>
          <DropdownItem divider />
          {/* Asignar permisos para poder ver este menu */}
          <Dropdown direction="left" isOpen={submenu} toggle={() => setSubmenu(!submenu)}>
            <DropdownToggle tag="div" className="dropdown-item" caret>
              <i className="bx bx-cog font-size-16 align-middle me-1" />
              <span>Configuración</span>
            </DropdownToggle>
            <DropdownMenu>
              {isBusinessInfo && (
                <Link to="/business-info" className="dropdown-item">
                  <i className="bx bx-edit font-size-16 align-middle me-1" />
                  <span>Personaliza tu ticket</span>
                </Link>
              )}
              {isPrice && (
                <Link to="/prices" className="dropdown-item">
                  <i className="bx bx-list-check  font-size-16 align-middle me-1" />
                  <span>Lista de precios</span>
                </Link>
              )}
              <Link to="/cash-control" className="dropdown-item">
                <i className="bx bx-money  font-size-16 align-middle me-1" />
                <span>Control de efectivo</span>
              </Link>
              {isBranchOffice && (
                <Link to="/branch-offices/1" className="dropdown-item">
                  <i className="bx bx-store font-size-16 align-middle me-1" />
                  <span>Sucursales</span>
                </Link>
              )}
              {isUser && (
                <Link to="/users" className="dropdown-item">
                  <i className="bx bx-user font-size-16 align-middle me-1" />
                  <span>Usuarios</span>
                </Link>
              )}
            </DropdownMenu>
          </Dropdown>
          {isAdmin && (
            <>
              <DropdownItem divider />
              <Link to="/logs-importation" className="dropdown-item">
                <i className="bx bx-import font-size-16 align-middle me-1" />
                <span>Logs de importación</span>
              </Link>
            </>
          )}
          <DropdownItem divider />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Salir</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {}

export default ProfileMenu
