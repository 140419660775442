import React, { useEffect, useState } from "react"
import propTypes from "prop-types"
import {
  Alert,
  Button,
  Label,
  Input,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupText,
} from "reactstrap"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Formik } from "formik"

import { createProductPresentation } from "services/product-presentations"
import { getProduct } from "services/products"
import schema from "./schema"
import RequiredIcon from "components/RequiredIcon"
import ErrorsText from "components/ErrorsText"

const CreateModalForm = ({ isOpen, toggle, refetch, productId, setShowAlertSuccess, setMsgSuccess }) => {
  const { isError, error, isSuccess, mutateAsync } = useMutation(createProductPresentation)
  const productBase = useQuery(["product", productId], () => getProduct(productId))
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const initialValues = {
    product: productId,
    clave: "",
    description: "",
    presentation_unit: "",
  }

  const handleSubmit = async (values) => {
    const { product, clave, description, presentation_unit: presentationUnit } = values

    const dataTemp = {
      product,
      key: clave,
      description: `${productBase?.data?.name} - ${description}`,
      presentation_unit: presentationUnit,
    }
    try {
      await mutateAsync(dataTemp)
    } catch (error) {}
  }

  useEffect(() => {
    if (isSuccess) {
      setMsgSuccess("Presentación creada satisfactoriamente")
      setShowAlertSuccess(true)
      refetch()
      toggle()
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Agregar presentación
      </ModalHeader>
      <ModalBody>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
          {({ values, handleSubmit, handleChange, handleBlur, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
              <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
                {isError && error?.errors?.map((error) => error?.message)}
              </Alert>
              <Label>
                Clave <RequiredIcon />
              </Label>
              <Input
                type="text"
                className="form-control"
                name="clave"
                value={values.clave}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={
                  !!(touched.clave && errors.clave) ||
                  (isError && validationErrors.findIndex((e) => e.field === "clave") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="clave"
                isErrorBack={isError}
                touched={touched}
              />

              <Label className="mt-2">
                Descripción <RequiredIcon />
              </Label>
              <InputGroup>
                <InputGroupText>{productBase?.data?.name} - </InputGroupText>
                <Input
                  type="text"
                  className="form-control"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={
                    !!(touched.description && errors.description) ||
                    (isError && validationErrors.findIndex((e) => e.field === "description") >= 0)
                  }
                />
                <ErrorsText
                  errorsBack={error}
                  errorsForm={errors}
                  formName="description"
                  isErrorBack={isError}
                  touched={touched}
                />
              </InputGroup>

              <Label className="mt-2">
                Cantidad por presentación <RequiredIcon />
              </Label>
              <Input
                type="number"
                className="form-control"
                name="presentation_unit"
                value={values.presentation_unit}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={
                  !!(touched.presentation_unit && errors.presentation_unit) ||
                  (isError && validationErrors.findIndex((e) => e.field === "presentation_unit") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="presentation_unit"
                isErrorBack={isError}
                touched={touched}
              />

              <div className="d-flex justify-content-end">
                <Button type="submit" color="success" style={{ marginTop: "10px" }}>
                  Crear
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

CreateModalForm.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  refetch: propTypes.func,
  productId: propTypes.any,
  setShowAlertSuccess: propTypes.func,
  setMsgSuccess: propTypes.func,
}

export default CreateModalForm
