import * as Yup from "yup"

import { messages, NUNBER_REGEX, } from "utils/messages"

const schema = Yup.object({
  name: Yup.string().max(120, messages.max_chars(120)).required(messages.required),
  email: Yup.string().email(messages.email),
  phone: Yup.string()
    .matches(NUNBER_REGEX, messages.number)
    .min(10, messages.exactly(10))
    .max(10, messages.exactly(10))
    .required(messages.required),
  provider: Yup.object().required(messages.required),
})

export default schema
