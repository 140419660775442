import { API } from "config/API"

export const getAllInventories = async () => {
  const response = await API.get("/stores-inventories/all?ordering=quantity")
  return response
}

export const reorderInventories = async () => {
  const response = await API.get(
    "/stores-inventories/reorder_products?quantity=&reorder_point=&product__description=&store__id=&ordered=false"
  )
  return response
}

export const getOrderedInventories = async () => {
  const response = await API.get(
    "/stores-inventories/reorder_products?quantity=&reorder_point=&product__description=&store__id=&ordered=true"
  )
  return response
}

export const getInventories = async (filters) => {
  const response = await API.get("/stores-inventories", { params: filters })
  return response
}

export const getInvetoryStore = async (id) => {
  const response = await API.get(`/stores-inventories?store__id=${id}`)
  return response
}

export const getInventory = async (id) => {
  const response = await API.get(`/stores-inventories/${id}`)
  return response
}

export const createInventory = async (payload) => {
  const response = await API.post("/stores-inventories", payload)
  return response
}

export const editInventory = async (id, payload) => {
  const response = await API.patch(`/stores-inventories/${id}`, payload)
  return response
}

export const deleteInventory = async (id) => {
  const response = await API.delete(`/stores-inventories/${id}`)
  return response
}
