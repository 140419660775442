import React, { useState, useEffect, useCallback } from "react"
import { useLocation } from "react-router-dom"
import {
  Container,
  Table,
  Alert,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap"
import { useMutation, useQuery } from "@tanstack/react-query"
import Swal from "sweetalert2"
import { debounce } from "lodash";

import storage from "services/storage"
import { authUserKey } from "config/API"
import { getAgents, deleteAgent } from "services/agents"
import { Loading } from "components"
import {
  AgentsItemList,
  AgentEditModalForm,
  AgentCreateModalForm,
} from "components/agents"
import ListHeader from "ui-componets/list-header"


const AgentsList = () => {
  document.title = "Lista de agentes | Punto de venta"

  const Location = useLocation()

  const [filters, setFilters] = useState("")
  const [searchTxt, setSearchTxt] = useState(new URLSearchParams(Location.search).get("search") ?? "")
  const [modalCreate, setModalCreate] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [agentId, setAgentId] = useState(null)
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    }
  })

  const agents = useQuery(["agents", filters, filters.toString()], () => getAgents(filters))
  const agentDeleted = useMutation((id) => deleteAgent(id))

  const permissions = storage.get(authUserKey).user_permissions || []
  const canAdd = permissions.some((e) => e.codename === "add_customer")
  const canDelete = permissions.some((e) => e.codename === "delete_customer")
  const canEdit = permissions.some((e) => e.codename === "change_customer")
  const canView = permissions.some((e) => e.codename === "view_customer")

  const toggleModalCreate = () => {
    setModalCreate(!modalCreate)
  }

  const toggleModalEdit = () => {
    if (modalEdit) {
      setAgentId("")
    }
    setModalEdit(!modalEdit)
  }

  const openEditModal = (agentId) => {
    setAgentId(agentId)
    toggleModalEdit()
  }

  const openDeleteModal = (agent) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar al agente: <b>${agent.name}</b>?`,
      text: "Este cambio no se podrá revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        agentDeleted.mutateAsync(agent?.id).then(() => {
          agents.refetch()
          Toast.fire("!Eliminado!", `El agente <b>${agent.name}</b> ha sido eliminado correctamente`, "success")
        })
      }
    })
  }

  const handleSearch = (pageParams) => {
    const params = new URLSearchParams(filters)
    if (pageParams) {
      const newParams = new URLSearchParams(pageParams)
      window.history.replaceState({}, null, `${Location.pathname}?${newParams}`)
      setFilters(newParams)
    } else if (searchTxt) {
      params.set("search", searchTxt)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    } else {
      if (params.get("search")) {
        params.delete("offset")
        params.delete("per_page")
        params.delete("search")
        window.history.replaceState({}, null, `${Location.pathname}?${params}`)
        setFilters(params)
      }
    }
  }

  const debouncedSearch = useCallback((debounce((text) => {
    const params = new URLSearchParams()
    params.set("search", text)
    params.delete("offset")
    params.delete("per_page")
    window.history.replaceState({}, null, `${Location.pathname}?${params}`)
    setFilters(params)
  }, 300)), [])

  function handleChangeSearch(event) {
    setSearchTxt(event.target.value);
    debouncedSearch(event.target.value);
  }

  const isLoading = agents.isLoading || agentDeleted.isLoading

  useEffect(() => {
    setFilters(Location.search)
  }, [Location.search])

  useEffect(() => {
    if (showAlertSuccess) {
      Toast.fire({
        icon: "success",
        title: msgSuccess,
        didOpen: () => {
          agents.refetch()
          setShowAlertSuccess(false)
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "green",
      })
    }
  }, [showAlertSuccess])

  return !canView ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <React.Fragment>
      <div className="page-content">
        {canAdd && (
          <AgentCreateModalForm
            isOpen={modalCreate}
            toggle={toggleModalCreate}
            setShowAlertSuccess={setShowAlertSuccess}
            setMsgSuccess={setMsgSuccess}
          />
        )}
        {canEdit && agentId && (
          <AgentEditModalForm
            isOpen={modalEdit}
            toggle={toggleModalEdit}
            agentId={agentId}
            setShowAlertSuccess={setShowAlertSuccess}
            setMsgSuccess={setMsgSuccess}
          />
        )}
        <Container fluid>
          <Alert color="danger" isOpen={agents.isError}>
            {agents?.error?.errors?.map((error) => error?.message)}
          </Alert>

          <h2>Lista de Agentes</h2>
          <div className="bg-white p-3">
            <ListHeader
              canAdd={canAdd}
              handleOnChangeSearch={handleChangeSearch}
              handleSearch={handleSearch}
              searchTxt={searchTxt}
              toggleModalCreate={toggleModalCreate}
            />
            {!agents.error && isLoading ? (
              <div className="my-5 text-center">
                <Loading isLoading={isLoading} size={2} />
              </div>
            ) : agents.error && !isLoading ? (
              <div className="my-5 text-center">
                <h1>Error intenta mas tarde</h1>
              </div>
            ) : (
              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Email</th>
                      <th>Teléfono</th>
                      <th>Proveedor</th>
                      <th className="text-center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agents?.data?.results?.map((agent) => {
                      return (
                        <AgentsItemList key={agent.id}
                          item={agent}
                          canEdit={canEdit}
                          canDelete={canDelete}
                          openEditModal={openEditModal}
                          openDeleteModal={openDeleteModal}
                        />
                      )
                    })}
                  </tbody>
                </Table>

                <div className="text-center">
                  {(agents.data?.next || agents.data?.previous) && (
                    <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                      {agents.data?.previous && (
                        <PaginationItem>
                          <PaginationLink
                            previous
                            onClick={() => handleSearch(agents.data?.previous.split("?")[1])}
                          />
                        </PaginationItem>
                      )}
                      {agents.data?.next && (
                        <PaginationItem>
                          <PaginationLink next onClick={() => handleSearch(agents.data?.next.split("?")[1])} />
                        </PaginationItem>
                      )}
                    </Pagination>
                  )}
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AgentsList
