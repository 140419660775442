import propTypes from "prop-types"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import MovementForm from "./MovementForm"

const CreateModalMovement = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="xl" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Crear movimiento de inventario
      </ModalHeader>
      <ModalBody>
        <MovementForm toggle={toggle} />
      </ModalBody>
    </Modal>
  )
}

CreateModalMovement.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
}

export default CreateModalMovement
