import { API } from "config/API"

const createFormData = (payload) => {
  const FormData = require("form-data")
  const formData = new FormData();
  Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
  return formData;
}


export const getFiles = async (filters) => {
  const response = await API.get("/invoices-files", { params: filters })
  return response
}

export const getFile = async (id) => {
  const response = await API.get(`/invoices-files/${id}`)
  return response
}

export const createFile = async (payload) => {
  const response = await API.post("/invoices-files", createFormData(payload))
  return response
}

export const editFile = async (id, payload) => {
  const response = await API.patch(`/invoices-files/${id}`, createFormData(payload))
  return response
}

export const deleteFile = async (id) => {
  const response = await API.delete(`/invoices-files/${id}`)
  return response
}
