import React, { useState, useEffect, useCallback } from "react"
import { Alert, Container, Pagination, PaginationItem, PaginationLink, Table } from "reactstrap"
import { useQuery } from "@tanstack/react-query"
import { debounce } from "lodash"

import storage from "services/storage"
import PendingPaidItemList from "./pending-paid-item-list"
import { authUserKey } from "config/API"
import { Loading } from "components"
import ListHeader from "ui-componets/list-header"
import { getNoPaidTickets } from "services/tickets"
import { PropTypes } from "prop-types"
import { getCashCutCounterManual, getLimitForCashCut } from "services/periodic-tasks"
import Swal from "sweetalert2"

const PendingPaidSales = (props) => {
  const { activeTab } = props
  const [filters, setFilters] = useState("")
  const [searchTxt, setSearchTxt] = useState(new URLSearchParams(Location.search).get("search") ?? "")
  const permissions = storage.get(authUserKey).user_permissions || []
  const ticketsNoPaid = useQuery(["ticketsNoPaid", filters, filters.toString()], () => getNoPaidTickets(filters), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retryOnMount: false,
    refetchInterval: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchIntervalInBackground: false,
    refetchIntervalSquared: false,
    retryDelay: (attemptIndex) => 0,
  })
  const { data: dataLimit } = useQuery(["limit-cash-cut"], () => getLimitForCashCut())
  const cashCutManual = useQuery(["cash-cut-manual"], () => getCashCutCounterManual())
  const limit = dataLimit?.results[0]

  const openAlertModal = () => {
    Swal.fire({
      title: "Alerta de corte de caja",
      text: `Has llegado al límite de $${limit.limit} en la caja. 
        Contacta a un administrador o un supervisor para realizar un corte de caja.`,
      icon: "warning",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
  }

  useEffect(() => {
    if (
      parseFloat(cashCutManual?.data?.results[0]?.total_tickets) >= parseFloat(limit?.limit) &&
      activeTab.activeTab === "paidPending"
    ) {
      openAlertModal()
    }
  }, [cashCutManual.data, limit, activeTab])

  const isLoadingNoPaid = ticketsNoPaid.isLoading
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }

  useEffect(() => {
    if (ticketsNoPaid.isSuccess) {
      ticketsNoPaid.refetch()
    }
  }, [activeTab, ticketsNoPaid])

  const handleSearch = (pageParams) => {
    const params = new URLSearchParams(filters)
    if (pageParams) {
      const newParams = new URLSearchParams(pageParams)
      window.history.replaceState({}, null, `${Location.pathname}?${newParams}`)
      setFilters(newParams)
    } else if (searchTxt) {
      params.set("search", searchTxt)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    } else {
      if (params.get("search")) {
        params.delete("offset")
        params.delete("per_page")
        params.delete("search")
        window.history.replaceState({}, null, `${Location.pathname}?${params}`)
        setFilters(params)
      }
    }
  }

  const debouncedSearch = useCallback(
    debounce((text) => {
      const params = new URLSearchParams()
      params.set("search", text)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    }, 300),
    []
  )

  function handleChangeSearch(event) {
    setSearchTxt(event.target.value)
    debouncedSearch(event.target.value)
  }
  return !permissions.some((e) => e.codename === "view_ticket") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <Container fluid>
      <Alert color="danger" isOpen={ticketsNoPaid.isError}>
        {ticketsNoPaid.error?.errors?.map((error) => error?.message)}
      </Alert>

      <h2>Lista de Ventas por Cobrar</h2>
      <div className="bg-white p-3">
        <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
        <ListHeader handleOnChangeSearch={handleChangeSearch} handleSearch={handleSearch} searchTxt={searchTxt} />
        {!ticketsNoPaid.error && isLoadingNoPaid ? (
          <div className="my-5 text-center">
            <Loading isLoading={isLoadingNoPaid} size={2} />
          </div>
        ) : ticketsNoPaid.error && !ticketsNoPaid.isLoading ? (
          <div className="my-5 text-center">
            <h1>Error intenta mas tarde</h1>
          </div>
        ) : (
          <div className="table-responsive mt-3">
            <Table>
              <thead>
                <tr>
                  <th style={styleCenterTable}>Folio</th>
                  <th style={styleCenterTable}>Fecha de creación</th>
                  <th style={styleCenterTable}>Cajero</th>
                  <th style={styleCenterTable}>Cliente</th>
                  <th style={styleCenterTable}>Total</th>
                  <th style={styleCenterTable}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {ticketsNoPaid.data?.results.length ? (
                  ticketsNoPaid.data?.results.map((item) => {
                    return <PendingPaidItemList key={item.id} item={item} />
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No hay datos
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div className="text-center">
              {(ticketsNoPaid.data?.next || ticketsNoPaid.data?.previous) && (
                <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                  {ticketsNoPaid.data?.previous && (
                    <PaginationItem>
                      <PaginationLink
                        previous
                        onClick={() => handleSearch(ticketsNoPaid.data?.previous.split("?")[1])}
                      />
                    </PaginationItem>
                  )}
                  {ticketsNoPaid.data?.next && (
                    <PaginationItem>
                      <PaginationLink next onClick={() => handleSearch(ticketsNoPaid.data?.next.split("?")[1])} />
                    </PaginationItem>
                  )}
                </Pagination>
              )}
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

PendingPaidSales.propTypes = {
  activeTab: PropTypes.object,
}

export default PendingPaidSales
