import { useEffect, useState } from "react"
import { useParams, useHistory, Link } from "react-router-dom"
import { Alert, Button, Col, Container, Row, Tooltip } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import Swal from "sweetalert2"

import { getBranch, editBranch, deleteBranch } from "services/branch-offices"
import { Loading } from "components"
import { BranchRetrieveForm } from "components/branches/ModalStores"
import storage from "services/storage"
import { authUserKey } from "config/API"

const Branch = () => {
  document.title = "Sucursal | Punto de venta"

  const [successAlert, setSuccessAlert] = useState(false)

  const { id } = useParams()
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })
  const history = useHistory()

  const { data, error, isLoading, isError, refetch } = useQuery(["branch_offices", id], () => getBranch(id))
  const deletedBranch = useMutation((id) => deleteBranch(id))

  const permissions = storage.get(authUserKey).user_permissions || []
  const canDelete = permissions.some((e) => e.codename === "delete_branchoffice")

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)

  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["branch_offices", id], (data) => editBranch(id, data))

  useEffect(() => {
    if (isSuccessEdit) {
      refetch()
      setSuccessAlert(true)
    }
  }, [isSuccessEdit])

  useEffect(() => {
    if (successAlert) {
      Toast.fire({
        icon: "success",
        title: "Sucursal modificada satisfactoriamente",
        didOpen: () => {
          refetch()
          setSuccessAlert(false)
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "green",
      })
    }
  }, [successAlert])

  // eslint-disable-next-line
  const openDeleteModal = (branch) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar la sucursal: <b>${branch.name}</b>?`,
      text: "Este cambio no se podrá revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        deletedBranch.mutateAsync(branch?.id).then(() => {
          Toast.fire(
            "!Eliminado!",
            `La sucursal <b>${branch.name}</b> ha sido eliminada correctamente`,
            "success"
          ).then(() => {
            history.push("/branch-offices")
          })
        })
      }
    })
  }

  const submit = async (data) => {
    await mutateAsync(data)
  }

  const renderError = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={isError}>
          {error?.errors?.map((error) => error?.message)}
        </Alert>
      </div>
    )
  }

  return !permissions.some((e) => e.codename === "view_branchoffice") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="page-content">
      <Container fluid>
        <Row style={{ marginBottom: "20px" }}>
          <Col className="text-start">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link to="/branch-offices" className="btn btn-primary" style={{ marginRight: "10px" }}>
                <i className="fa fa-arrow-left" /> Volver
              </Link>
              <h1 style={{ marginBottom: "0" }}>Sucursal: {data?.name}</h1>
            </div>
          </Col>
        </Row>

        <div className="bg-white p-3">
          {isError ? (
            renderError()
          ) : isLoading ? (
            <div className="text-center">
              <Loading isLoading={isLoading} size={2} />
            </div>
          ) : (
            <>
              <Row>
                <Col className="text-end">
                  {canDelete && (
                    <>
                      <Button color="danger" disabled={isLoading} id={"tooltip"}>
                        <i className="fa fa-trash" /> Eliminar
                      </Button>
                      <Tooltip
                        hideArrow={true}
                        innerClassName="mb-1"
                        style={{ backgroundColor: "#74788d" }}
                        placement={"top"}
                        isOpen={tooltipOpen}
                        target={"tooltip"}
                        toggle={toggle}
                      >
                        <span>No se puede eliminar la última sucursal</span>
                      </Tooltip>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <BranchRetrieveForm
                    data={data}
                    error={errorEdit}
                    isError={isErrorEdit}
                    isLoading={isLoadingEdit}
                    isRetrivePage={true}
                    refetchData={refetch}
                    submit={submit}
                    disabledForm={permissions.some((e) => e.codename === "edit_branchoffice")}
                  />
                </Col>
              </Row>
            </>
          )}
        </div>
      </Container>
    </div>
  )
}

export default Branch
