import React, { useState, useEffect, useCallback } from "react"
import { useLocation } from "react-router-dom"
import { Container, Table, Alert, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { useMutation, useQuery } from "@tanstack/react-query"
import Swal from "sweetalert2"
import { debounce } from "lodash"

import storage from "services/storage"
import { authUserKey } from "config/API"
import { getBrands, deleteBrand } from "services/brands"
import { Loading } from "components"
import { CreateModalForm, EditModalForm } from "components/brands"
import ListHeader from "ui-componets/list-header"
import BrandItemList from "components/brands/brand-item-list"
import Shortcuts from "components/Shortcuts"

const BrandsList = () => {
  document.title = "Lista de marcas | Punto de venta"

  const Location = useLocation()

  const [filters, setFilters] = useState("")
  const [searchTxt, setSearchTxt] = useState(new URLSearchParams(Location.search).get("search") ?? "")
  const [modalCreate, setmodalCreate] = useState(false)
  const [modalImport, setmodalImport] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)
  const [brandId, setBrandId] = useState(null)
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  const brands = useQuery(["brands", filters, filters.toString()], () => getBrands(filters))
  const brandDeleted = useMutation((id) => deleteBrand(id))

  const permissions = storage.get(authUserKey).user_permissions || []
  const canAdd = permissions.some((e) => e.codename === "add_brand")
  const canEdit = permissions.some((e) => e.codename === "change_brand")
  const canDelete = permissions.some((e) => e.codename === "delete_brand")
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }

  const toggleModalCreate = () => {
    setmodalCreate(!modalCreate)
  }

  const toggleModalImport = () => {
    setmodalImport(!modalImport)
  }

  const toggleModalEdit = () => {
    if (modalEdit) {
      setBrandId("")
    }
    setmodalEdit(!modalEdit)
  }

  const openEditModal = (brandId) => {
    setBrandId(brandId)
    toggleModalEdit()
  }

  const openDeleteModal = (row) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar la marca: <b>${row.name}</b>?`,
      text: "Este cambio no se podrá revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        brandDeleted.mutateAsync(row?.id).then(() => {
          brands.refetch()
          Toast.fire("!Eliminado!", `La marca <b>${row.name}</b> ha sido eliminado correctamente`, "success")
        })
      }
    })
  }

  const handleSearch = (pageParams) => {
    const params = new URLSearchParams(filters)
    if (pageParams) {
      const newParams = new URLSearchParams(pageParams)
      window.history.replaceState({}, null, `${Location.pathname}?${newParams}`)
      setFilters(newParams)
    } else if (searchTxt) {
      params.set("search", searchTxt)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    } else {
      if (params.get("search")) {
        params.delete("offset")
        params.delete("per_page")
        params.delete("search")
        window.history.replaceState({}, null, `${Location.pathname}?${params}`)
        setFilters(params)
      }
    }
  }

  const debouncedSearch = useCallback(
    debounce((text) => {
      const params = new URLSearchParams()
      params.set("search", text)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    }, 300),
    []
  )

  function handleChangeSearch(event) {
    setSearchTxt(event.target.value)
    debouncedSearch(event.target.value)
  }

  const isLoading = brands.isLoading || brandDeleted.isLoading

  useEffect(() => {
    setFilters(Location.search)
  }, [])

  useEffect(() => {
    if (showAlertSuccess) {
      Toast.fire({
        icon: "success",
        title: msgSuccess,
        didOpen: () => {
          brands.refetch()
          setShowAlertSuccess(false)
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "green",
      })
    }
  }, [showAlertSuccess])

  return !permissions.some((e) => e.codename === "view_customer") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <React.Fragment>
      <div className="page-content">
        {canAdd && (
          <CreateModalForm
            isOpen={modalCreate}
            toggle={toggleModalCreate}
            setShowAlertSuccess={setShowAlertSuccess}
            setMsgSuccess={setMsgSuccess}
          />
        )}
        {canEdit && brandId && (
          <EditModalForm
            isOpen={modalEdit}
            toggle={toggleModalEdit}
            brandId={brandId}
            setShowAlertSuccess={setShowAlertSuccess}
            setMsgSuccess={setMsgSuccess}
          />
        )}
        <Container fluid>
          <Alert color="danger" isOpen={brands.isError}>
            {brands?.error?.errors?.map((error) => error?.message)}
          </Alert>

          <h2>Lista de Marcas</h2>
          <div className="bg-white p-3">
            <ListHeader
              canAdd={canAdd}
              handleOnChangeSearch={handleChangeSearch}
              handleSearch={handleSearch}
              searchTxt={searchTxt}
              toggleModalCreate={toggleModalCreate}
            />
            {!brands.error && isLoading ? (
              <div className="my-5 text-center">
                <Loading isLoading={isLoading} size={2} />
              </div>
            ) : brands.error && !isLoading ? (
              <div className="my-5 text-center">
                <h1>Error intenta mas tarde</h1>
              </div>
            ) : (
              <div style={{ marginTop: "30px" }} className="table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th style={styleCenterTable}>Nombre de la marca</th>
                      <th style={styleCenterTable}>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {brands?.data?.results.length > 0 ? (
                      brands?.data?.results?.map((item) => {
                        return (
                          <BrandItemList
                            key={item.id}
                            item={item}
                            canDelete={canDelete}
                            canEdit={canEdit}
                            openDeleteModal={openDeleteModal}
                            openEditModal={openEditModal}
                          />
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No hay datos
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                <div className="text-center">
                  {(brands.data?.next || brands.data?.previous) && (
                    <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                      {brands.data?.previous && (
                        <PaginationItem>
                          <PaginationLink
                            previous
                            onClick={() => handleSearch(brands.data?.previous.split("?")[1])}
                          />
                        </PaginationItem>
                      )}
                      {brands.data?.next && (
                        <PaginationItem>
                          <PaginationLink next onClick={() => handleSearch(brands.data?.next.split("?")[1])} />
                        </PaginationItem>
                      )}
                    </Pagination>
                  )}
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
      <Shortcuts
        toggleModalCreate={toggleModalCreate}
        toggleModalImport={toggleModalImport}
      />
    </React.Fragment>
  )
}

export default BrandsList
