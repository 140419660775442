import React, { useState } from "react"
import propTypes from "prop-types"
import { Link } from "react-router-dom"
import { Button, Tooltip } from "reactstrap"

const BranchesItemList = ({ item, canDelete, canEdit }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)

  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }
  const { id, name, location } = item
  const renderEditButton = () => {
    if (!canEdit) {
      return null
    }

    return (
      <Link to={`/branch-offices/${id}`}>
        <Button color="primary" className="m-1">
          <i className="fa fa-edit" />
        </Button>
      </Link>
    )
  }

  const renderDeleteButton = () => {
    if (!canDelete) {
      return null
    }

    return (
      <>
        <Button color="danger" id={"tooltipDelete"}>
          <i className="fa fa-trash" />
        </Button>
        <Tooltip
          hideArrow={true}
          innerClassName="mb-1"
          style={{ backgroundColor: "#74788d" }}
          placement={"top"}
          isOpen={tooltipOpen}
          target={"tooltipDelete"}
          toggle={toggle}
        >
          <span>No se puede eliminar la última sucursal</span>
        </Tooltip>
      </>
    )
  }

  return (
    <tr key={id}>
      <td style={styleCenterTable}>{name}</td>
      <td style={styleCenterTable}>{location}</td>
      <td style={styleCenterTable} className="text-center">
        {renderEditButton()}
        {renderDeleteButton()}
      </td>
    </tr>
  )
}

BranchesItemList.propTypes = {
  item: propTypes.object,
  canDelete: propTypes.bool,
  canEdit: propTypes.bool,
}

export default BranchesItemList
