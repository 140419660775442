import { API } from "config/API"


const createFormData = (payload) => {
  const FormData = require("form-data")
  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    if (key === "image" && (
      typeof(payload[key]) === "undefined" ||
      payload[key] === null
    )) {
      ;
    } else {
      formData.append(key, payload[key]);
    }
  });  
  return formData;
}


export const getAllBusinessInfo = async (filters) => {
  const response = await API.get("/business-info/all", { params: filters })
  return response
}

export const getBusinessInfo = async (filters) => {
  const response = await API.get("/business-info", { params: filters })
  return response
}

export const createBusinessInfo = async (payload) => {
  const response = await API.post("/business-info", createFormData(payload))
  return response
}

export const editBusinessInfo = async (id, payload) => {
  const response = await API.patch(`/business-info/${id}`, createFormData(payload))
  return response
}

export const deleteBusinessInfo = async (id) => {
  const response = await API.delete(`/business-info/${id}`)
  return response
}
