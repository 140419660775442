import propTypes from "prop-types"
import { useEffect } from "react"
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import { getAgent, editAgent } from "services/agents"
import { Loading } from "components"
import AgentForm from "./agent-form"


const AgentEditModalForm = ({ isOpen, toggle, agentId, setShowAlertSuccess, setMsgSuccess }) => {
  const { data, error, isLoading, isError, refetch } = useQuery(["agent", agentId], () => getAgent(agentId))

  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["agent", agentId], (data) => editAgent(agentId, data))

  const submit = async (data) => {
    await mutateAsync({
      ...data,
      provider: data.provider?.value,
    })
  }

  useEffect(() => {
    if (isSuccessEdit) {
      setMsgSuccess("Cliente modificado satisfactoriamente")
      setShowAlertSuccess(true)
      refetch()
      toggle()
    }
  }, [isSuccessEdit])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Editar Agente
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className="text-center">
            <Loading isLoading={isLoading} size={2} />
          </div>
        ) : isError ? (
          <div className="text-center">
            <Alert color="danger" isOpen={isError}>
              {error?.errors?.map((error) => error?.message)}
            </Alert>
          </div>
        ) : (
          <AgentForm
            isCreateModal={false}
            submit={submit}
            toggle={toggle}
            isLoading={isLoadingEdit}
            isError={isErrorEdit}
            error={errorEdit}
            data={data}
          />
        )}
      </ModalBody>
    </Modal>
  )
}


AgentEditModalForm.defaultProps = {
  agentId: null,
}


AgentEditModalForm.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  agentId: propTypes.string,
  setShowAlertSuccess: propTypes.func,
  setMsgSuccess: propTypes.func,
}

export default AgentEditModalForm
