import * as Yup from "yup"

import { messages, RFC_EXAMPLE, RFC_REGEX } from "utils/messages"


const schema = Yup.object({
  name: Yup.string().max(120, messages.max_chars(120)).required(messages.required),
  street: Yup.string().max(255, messages.max_chars(255)),
  neighborhood: Yup.string().max(255, messages.max_chars(255)),
  zip_code: Yup.number()
    .positive(messages.positive)
    .min(0, messages.positive)
    .integer()
    .typeError(messages.number)
    .required(messages.required),
  phone_number: Yup.string()
    .min(10, messages.exactly(10))
    .max(15, messages.exactly(15))
    .required(messages.required),
  fiscal_address: Yup.string().max(255, messages.max_chars(255)),
  fiscal_zipcode: Yup.number()
    .positive(messages.positive)
    .min(0, messages.positive)
    .integer()
    .typeError(messages.number)
    .required(messages.required),
  business_name: Yup.string().max(255, messages.max_chars(255)).required(messages.required),
  rfc: Yup.string()
    .matches(RFC_REGEX, messages.invalidFormat(RFC_EXAMPLE))
    .min(12, messages.exactly(12))
    .max(13, messages.exactly(13))
    .required(messages.required),
  image: Yup.mixed().nullable(),
})


export default schema
