import React, { useContext, useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Container, Table, Alert, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { useQuery } from "@tanstack/react-query"
import { debounce } from "lodash"

import { Loading } from "components"
import ListHeader from "ui-componets/list-header"
import { getLogs } from "services/logs-importation"
import { LogItemList } from "components/logs-importation"
import { UserContext } from "App"

const LogsImportationList = () => {
  document.title = "Lista de logs | Punto de venta"

  const Location = useLocation()
  const userData = useContext(UserContext)
  const isAdmin = userData?.user_type

  const [filters, setFilters] = useState("")
  const [searchTxt, setSearchTxt] = useState(new URLSearchParams(Location.search).get("search") ?? "")

  const logsImportation = useQuery(["logs-importation", filters, filters.toString()], () => getLogs(filters))

  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }

  const handleSearch = (pageParams) => {
    const params = new URLSearchParams(filters)
    if (pageParams) {
      const newParams = new URLSearchParams(pageParams)
      window.history.replaceState({}, null, `${Location.pathname}?${newParams}`)
      setFilters(newParams)
    } else if (searchTxt) {
      params.set("search", searchTxt)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    } else {
      if (params.get("search")) {
        params.delete("offset")
        params.delete("per_page")
        params.delete("search")
        window.history.replaceState({}, null, `${Location.pathname}?${params}`)
        setFilters(params)
      }
    }
  }

  const debouncedSearch = useCallback(
    debounce((text) => {
      const params = new URLSearchParams()
      params.set("search", text)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    }, 300),
    []
  )

  function handleChangeSearch(event) {
    setSearchTxt(event.target.value)
    debouncedSearch(event.target.value)
  }

  const isLoading = logsImportation.isLoading

  useEffect(() => {
    setFilters(Location.search)
  }, [])

  return !isAdmin ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Alert color="danger" isOpen={logsImportation.isError}>
            {logsImportation?.error?.errors?.map((error) => error?.message)}
          </Alert>

          <h2>Lista de Logs de Importación</h2>
          <div className="bg-white p-3">
            <ListHeader handleOnChangeSearch={handleChangeSearch} handleSearch={handleSearch} searchTxt={searchTxt} />
            {!logsImportation.error && isLoading ? (
              <div className="my-5 text-center">
                <Loading isLoading={isLoading} size={2} />
              </div>
            ) : logsImportation.error && !isLoading ? (
              <div className="my-5 text-center">
                <h1>Error intenta mas tarde</h1>
              </div>
            ) : (
              <div style={{ marginTop: "30px" }} className="table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th style={styleCenterTable}>ID</th>
                      <th style={styleCenterTable}>Fecha</th>
                      <th style={styleCenterTable}>Hora</th>
                      <th style={styleCenterTable}>Tipo de importación</th>
                      <th style={styleCenterTable}>Usuario</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logsImportation?.data?.results.length > 0 ? (
                      logsImportation?.data?.results?.map((item) => {
                        return <LogItemList key={item.id} item={item} />
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No hay datos
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                <div className="text-center">
                  {(logsImportation.data?.next || logsImportation.data?.previous) && (
                    <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                      {logsImportation.data?.previous && (
                        <PaginationItem>
                          <PaginationLink
                            previous
                            onClick={() => handleSearch(logsImportation.data?.previous.split("?")[1])}
                          />
                        </PaginationItem>
                      )}
                      {logsImportation.data?.next && (
                        <PaginationItem>
                          <PaginationLink next onClick={() => handleSearch(logsImportation.data?.next.split("?")[1])} />
                        </PaginationItem>
                      )}
                    </Pagination>
                  )}
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LogsImportationList
