import React, { useContext } from "react"
import propTypes from "prop-types"
import { FormGroup, Label, Input } from "reactstrap"

import { Link } from "react-router-dom"
import { Shortcut } from "@shopify/react-shortcuts"
import { Icon } from "@iconify/react"

import { ProfileMenu } from "components"
import { OfflineContext } from "App"

const switchOffline = (isOffline, handleSwitchChange) => {
  if (window.electron && window.electron.ipcRenderer) {
    return (
      <div className="dropdown d-none d-lg-inline-block ms-1">
        <FormGroup switch>
          <Input
            type="switch"
            id="offlineSwitch"
            name="offlineSwitch"
            label="Modo Offline"
            checked={isOffline}
            onChange={handleSwitchChange}
          />
          <Label check>Modo Offline</Label>
        </FormGroup>
      </div>
    )
  }
}

const Header = () => {
  const { isOffline, setIsOffline } = useContext(OfflineContext)

  const handleSwitchChange = () => {
    setIsOffline((prevOffline) => !prevOffline)
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    const body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <Icon icon="mdi:point-of-sale" color="white" hFlip={true} vFlip={true} />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <Icon icon="mdi:point-of-sale" color="#white" hFlip={true} vFlip={true} />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={tToggle}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex align-items-center">
            {switchOffline(isOffline, handleSwitchChange)}
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={toggleFullscreen}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
      <Shortcut ordered={["f", "f"]} onMatch={() => toggleFullscreen()} />
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: propTypes.func,
  leftMenu: propTypes.any,
  leftSideBarType: propTypes.any,
  showRightSidebar: propTypes.any,
  showRightSidebarAction: propTypes.func,
  toggleLeftmenu: propTypes.func,
}

export default Header
