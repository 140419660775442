import { useEffect } from "react"
import propTypes from "prop-types"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useMutation } from "@tanstack/react-query"
import { createFile } from "services/files"
import FileForm from "./file-form"


const FileCreateModalForm = ({ isOpen, toggle, setShowAlertSuccess, setMsgSuccess, invoiceId }) => {
  const { isLoading, isError, error, isSuccess, mutateAsync } = useMutation(createFile)

  useEffect(() => {
    if (isSuccess) {
      setMsgSuccess("Archivo cargado satisfactoriamente")
      setShowAlertSuccess(true)
      toggle()
    }
  }, [isSuccess])

  const submit = async (data) => {
    const dataTemp = {...data}
    dataTemp.type = dataTemp?.type?.value || null
    await mutateAsync({
      ...dataTemp,
      invoice: invoiceId,
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Agregar Archivo
      </ModalHeader>
      <ModalBody>
        <FileForm
          error={error}
          isCreate={true}
          isError={isError}
          isLoading={isLoading}
          submit={submit}
          toggle={toggle}
        />
      </ModalBody>
    </Modal>
  )
}


FileCreateModalForm.propTypes = {
  isOpen: propTypes.any,
  setMsgSuccess: propTypes.func,
  setShowAlertSuccess: propTypes.func,
  toggle: propTypes.func,
  invoiceId: propTypes.string,
}


export default FileCreateModalForm
