import React from "react"
import propTypes from "prop-types"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"

const ProductsItemList = ({ item, canDelete, canEdit, openEditModal, openDeleteModal }) => {
  function currencyFormatter({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }

  const renderEditButton = () => {
    if (!canEdit) {
      return null
    }

    return (
      <Button color="primary" className="m-1" onClick={() => openEditModal(item.id)}>
        <i className="fa fa-edit" />
      </Button>
    )
  }

  const renderDeleteButton = () => {
    if (!canDelete) {
      return null
    }

    return (
      <Button color="danger" onClick={() => openDeleteModal(item)}>
        <i className="fa fa-trash" />
      </Button>
    )
  }

  return (
    <tr key={item.id}>
      <td style={styleCenterTable}>
        <Link to={{ pathname: `/products/${item.id}` }}>{item.bar_code}</Link>
      </td>
      <td style={styleCenterTable}>{item.name}</td>
      <td style={styleCenterTable}>{item.sat_code || "--"}</td>
      <td style={styleCenterTable}>{currencyFormatter({ currency: "USD", value: item.cost }) || "0"}</td>
      <td style={styleCenterTable} className="text-center">
        {renderEditButton()}
        {renderDeleteButton()}
      </td>
    </tr>
  )
}

ProductsItemList.propTypes = {
  item: propTypes.object,
  canDelete: propTypes.bool,
  canEdit: propTypes.bool,
  openEditModal: propTypes.func,
  openDeleteModal: propTypes.func,
}

export default ProductsItemList
