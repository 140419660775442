import React, { useRef, useEffect } from "react"
import { Row, Col } from "reactstrap"
import PropTypes from "prop-types"
import TableProduct from "components/sales/ticket"
import bwipjs from "bwip-js"

const RenderTicketContentOffline = React.forwardRef(({ data }, ref) => {
  const canvasRef = useRef(null)
  const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000"
  const qrData = `{
        "id": "${data?.id}", 
        "url": "${API_URL}/sales/${data?.id}",
        "user": {"id": "${data?.user?.id}", "name": "${data?.user?.first_name} ${data?.user?.last_name}"},
        "customer": {"id": "${data?.customer?.id}", "name": "${data?.customer?.name}"}, 
        "date": "${data?.date}",
        "subtotal": ${data?.subtotal},
        "total": ${data?.total}
    }`

  useEffect(() => {
    if (canvasRef.current) {
      bwipjs.toCanvas(canvasRef.current, {
        bcid: "qrcode",
        scale: 1,
        text: qrData,
      })
    }
  }, [data.id])

  const totalQuantity = data?.descriptions?.reduce((total, product) => total + product?.quantity, 0)
  const style = {
    p: { lineHeight: "0.3em", fontSize: "1.2em" },
  }
  let customer = data?.customer.label
  let cashier = data?.user.first_name + " " + data.user.last_name
  if (data.customer.label) {
    if ((data?.customer?.label).length > 9) {
      customer = (data?.customer.label).substring(0, 9) + "..."
    }
    if ((data.user?.first_name + " " + data.user.last_name).length > 9) {
      cashier = (data.user.first_name + " " + data.user.last_name).substring(0, 9) + "..."
    }
  }

  function currencyFormatter({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }
  const hoy = new Date()

  // Ajusta la hora a la zona horaria de México (UTC-6 en horario de verano, o UTC-7 en horario estándar)
  const horaMexico = new Date(hoy.getTime())

  // Obtiene los componentes de fecha y hora
  const dia = horaMexico.getDate()
  const mes = horaMexico.getMonth() + 1
  const año = horaMexico.getFullYear()
  const hora = horaMexico.getHours()
  const minutos = horaMexico.getMinutes()

  // Función para agregar un cero delante de números menores a 10
  function agregarCero(num) {
    return num < 10 ? "0" + num : num
  }

  // Formatea la fecha y hora en español
  const formattedDate = `${dia}/${mes}/${año} -- ${agregarCero(hora)}:${agregarCero(minutos)}`

  return (
    <div ref={ref} style={{ padding: "0em" }}>
      {data.business_info?.results?.map((business) => (
        <div key={business.id} style={{ textAlign: "center" }}>
          <Col>
            <img src={require("../../assets/images/logo.png")} alt="Logo" />
          </Col>
          <Row>
            <Col>
              <h2 className="text-center">{business.name}</h2>
              <p className="text-center">
                RFC: {business.rfc}
                <br />
                {business.street}
                <br />
                {business.neighborhood}
                <br />
                C.P: {business.fiscal_zipcode}
                <br />
                Tel: {business.phone_number}
              </p>
            </Col>
          </Row>
        </div>
      ))}
      <p style={{borderBottom: "1px solid black"}}/>

      <Row>
        <Col>
          <p style={style.p}>Cliente: {customer}</p>
        </Col>
        <Col>
          <p style={style.p}>Cajero: {cashier}</p>
        </Col>
        <p style={style.p}>Fecha: {formattedDate}</p>
        <p style={{borderBottom: "1px solid black", width: "97.5%", marginLeft: "10px"}}/>
      </Row>

      <TableProduct products={data?.descriptions} />
      <Row>
        <Col>
          {/* <h5 className="text-end">
            Subtotal: {currencyFormatter({ currency: "USD", value: parseFloat(data?.subtotal) })}
          </h5> */}
          <h2 className="text-end">Total: {currencyFormatter({ currency: "USD", value: parseFloat(data?.total) })}</h2>
          <h5 className="text-end">
            IVA Incluido
          </h5>
          <h3 className="text-center">
            <strong>¡Usted Ahorró! {currencyFormatter({ currency: "USD", value: parseFloat(data?.discount) })}</strong>
          </h3>
        </Col>
      </Row>
      <h3 className="text-center">Total de Artículos: {totalQuantity}</h3>
      {/* <Col className="text-center">
        <canvas ref={canvasRef}></canvas>
      </Col> */}
      <p className="text-center">
        Gracias por su preferencia este ticket no es un comprobante fiscal si requiere factura solicítela el mismo día
      </p>
    </div>
  )
})

RenderTicketContentOffline.propTypes = {
  data: PropTypes.object.isRequired,
}

RenderTicketContentOffline.displayName = "RenderTicketContentOffline"

export default RenderTicketContentOffline
