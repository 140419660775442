import { API } from "config/API"

export const syncCustomersOffline = async (payload) => {
  const response = await API.post("/offline/customers/syncronized_files", payload)
  return response
}

export const syncPricesOffline = async (payload) => {
  const response = await API.post("/offline/prices/syncronized_files", payload)
  return response
}

export const syncProductsOffline = async (payload) => {
  const response = await API.post("/offline/products/syncronized_files", payload)
  return response
}

export const syncTicketsOffline = async (payload) => {
  const response = await API.post("/offline/tickets/syncronized_tickets", payload)
  return response
}

