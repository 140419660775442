import { API } from "config/API"

export const getCreditAccounts = async () => {
  const response = await API.get("/credit-accounts?amount=&days=&deposit=&is_paid=false")
  return response
}

export const editCreditAccount = async (id, payload) => {
    const response = await API.patch(`/credit-accounts/${id}`, payload)
    return response
  }
