import { useEffect } from "react"
import { withRouter } from "react-router-dom"
import { useLogout } from "hooks/auth"

const Logout = () => {
  const logout = useLogout()

  useEffect(() => {
    logout.mutate()
  }, [])

  return <></>
}

export default withRouter(Logout)
