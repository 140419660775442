import { useState, useEffect } from "react"
import { useParams, useHistory, Link } from "react-router-dom"
import { Container, Alert, Row, Col, Button } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import Swal from "sweetalert2"

import { getProvider, editProvider, deleteProvider } from "services/providers"
import { Loading } from "components"
import { ProviderForm } from "components/providers"
import storage from "services/storage"
import { authUserKey } from "config/API"

const Provider = () => {
  document.title = "Proveedor | Punto de venta"

  const [successAlert, setSuccessAlert] = useState(false)
  const { id } = useParams()
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })
  const history = useHistory()
  const { data, error, isLoading, isError, refetch } = useQuery(["product", id], () => getProvider(id))
  const deleteProviderMutation = useMutation((id) => deleteProvider(id))
  const permissions = storage.get(authUserKey).user_permissions || []
  const canDelete = permissions.some((e) => e.codename === "delete_provider")

  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["product", id], (data) => editProvider(id, data))

  useEffect(() => {
    if (isSuccessEdit) {
      refetch()
      setSuccessAlert(true)
    }
  }, [isSuccessEdit])

  useEffect(() => {
    if (successAlert) {
      Toast.fire({
        icon: "success",
        title: "Proveedor modificado satisfactoriamente",
        didOpen: () => {
          refetch()
          setSuccessAlert(false)
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "green",
      })
    }
  }, [successAlert])

  const openDeleteModal = (provider) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar al proveedor: <b>${provider.name}</b>?`,
      text: "Este cambio no se podrá revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProviderMutation.mutateAsync(provider?.id).then(() => {
          Toast.fire(
            "!Eliminado!",
            `El proveedor <b>${provider.name}</b> ha sido eliminado correctamente`,
            "success"
          ).then(() => {
            history.push("/providers")
          })
        })
      }
    })
  }

  const submit = async (data) => {
    const dataTemp = { ...data }
    dataTemp.providers = dataTemp.providers ? dataTemp.providers?.map((e) => e.id) : undefined
    dataTemp.brands = dataTemp.brands ? dataTemp.brands?.map((brand) => brand.id) : null
    await mutateAsync(dataTemp)
  }

  const isErrorDiv = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={isError}>
          {error?.errors?.map((error) => error?.message)}
        </Alert>
      </div>
    )
  }

  return !permissions.some((e) => e.codename === "view_product") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="page-content">
      <Container fluid>
        <Row style={{ marginBottom: "20px" }}>
          <Col className="text-start">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link to="/providers" className="btn btn-primary" style={{ marginRight: "10px" }}>
                <i className="fa fa-arrow-left" /> Volver
              </Link>
              <h1 style={{ marginBottom: "0" }}>Proveedor {data?.name}</h1>
            </div>
          </Col>
        </Row>
        <div className="bg-white p-3">
          {isError ? (
            isErrorDiv()
          ) : isLoading ? (
            <div className="text-center">
              <Loading isLoading={isLoading} size={2} />
            </div>
          ) : (
            <>
              <Row>
                <Col className="text-end">
                  {canDelete && (
                    <Button color="danger" onClick={() => openDeleteModal(data)} disabled={isLoading}>
                      <i className="fa fa-trash" /> Eliminar
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <ProviderForm
                    data={data}
                    error={errorEdit}
                    isError={isErrorEdit}
                    isLoading={isLoadingEdit}
                    isRetrivePage={true}
                    refetchData={refetch}
                    submit={submit}
                    disabledForm={!permissions.some((e) => e.codename === "change_provider")}
                  />
                </Col>
              </Row>
            </>
          )}
        </div>
      </Container>
    </div>
  )
}

export default Provider
