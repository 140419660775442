import { useHistory } from "react-router-dom"
import { Shortcut } from "@shopify/react-shortcuts"


const GlobalShortcuts = () => {
  const history = useHistory()

  return (
    <>
      <Shortcut ordered={["c", "c"]} onMatch={() => history.push("/customers")} />
      <Shortcut ordered={["p", "v"]} onMatch={() => history.push("/providers")} />
      <Shortcut ordered={["p", "c"]} onMatch={() => history.push("/prices")} />
      <Shortcut ordered={["p", "d"]} onMatch={() => history.push("/products")} />
      <Shortcut ordered={["f", "c"]} onMatch={() => history.push("/invoices")} />
      <Shortcut ordered={["s", "c"]} onMatch={() => history.push("/branch-offices")} />
      <Shortcut ordered={["v", "v"]} onMatch={() => history.push("/sales")} />
      <Shortcut ordered={["u"]} onMatch={() => history.push("/users")} />
    </>
  )
}


export default GlobalShortcuts
