import React from "react"
import propTypes from "prop-types"
import { Button } from "reactstrap"
import { acuseInvoice, usePdfInvoice, useXmlInvoice } from "services/tickets"

const InvoiceItemList = ({ item, openCancelInvoiceModal, openEmailInvoiceModal }) => {
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }
  function currencyFormatter({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }

  const getAcuse = async (id) => {
    try {
      await acuseInvoice(id)
    } catch (err) {
      console.log(err)
    }
  }

  const getPdf = async (id) => {
    try {
      usePdfInvoice(id)
    } catch (err) {
      console.log(err)
    }
  }

  const getXml = async (id) => {
    try {
      useXmlInvoice(id)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <tr key={item.id}>
      <td style={styleCenterTable}>{item.uuid_invoice}</td>
      <td style={styleCenterTable}>{item?.customer?.name}</td>
      <td style={styleCenterTable}>{currencyFormatter({ currency: "USD", value: item.total })}</td>
      <td style={styleCenterTable}>
        <Button color="primary" style={{ marginRight: "10px" }} title="Recuperar PDF" onClick={() => getPdf(item.id)}>
          <i className="fa fa-file-pdf" />
        </Button>
        <Button color="info" style={{ marginRight: "10px" }} title="Recuperar XML" onClick={() => getXml(item.id)}>
          <i className="fa fa-file-excel" />
        </Button>
        <Button
          color="success"
          onClick={() => openEmailInvoiceModal(item.id)}
          title="Enviar por correo"
          style={{ marginRight: "10px" }}
        >
          <i className="fa fa-envelope" />
        </Button>
        <Button color="secondary" title="Recuperar Acuse" onClick={() => getAcuse(item.id)}>
          <i className="fa fa-file-invoice-dollar" />
        </Button>
        <Button
          color="danger"
          onClick={() => openCancelInvoiceModal(item.id)}
          style={{ marginLeft: "10px", marginRight: "10px" }}
          title="Cancelar Factura"
        >
          <i className="fa fa-ban" />
        </Button>
      </td>
    </tr>
  )
}

InvoiceItemList.propTypes = {
  item: propTypes.object,
  openInvoiceModal: propTypes.func,
  openCancelInvoiceModal: propTypes.func,
  openEmailInvoiceModal: propTypes.func,
}

export default InvoiceItemList
