import { useState, useEffect } from "react"
import propTypes from "prop-types"
import { useMutation, useQuery } from "@tanstack/react-query"
import { FieldArray, Formik } from "formik"
import {
  Button,
  Form,
  FormText,
  Input,
  Label,
  Alert,
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupText,
} from "reactstrap"
import Select from "react-select"
import Swal from "sweetalert2"
import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"
import { getAllBusinessNames } from "services/business-name"
import schema from "./schema"
import { getAllProviders } from "services/providers"
import FileCreateModalForm from "./files/file-create-modal-form"
import FileEditModalForm from "./files/file-edit-modal-form"
import { deleteFile } from "services/files"

const InvoiceForm = (props) => {
  const { isCreate, submit, data, toggle, isLoading, isError, error, disabledForm, refetchData } = props

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")
  const [fileId, setFileId] = useState(null)
  const [shownErrorAlert, setShownErrorAlert] = useState(false)
  const [shownSuccessAlert, setShownSuccessAlert] = useState(false)
  const fileDelete = useMutation((id) => deleteFile(id))
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const providers = useQuery(["providers"], () => getAllProviders())
  const businessNames = useQuery(["business-names"], () => getAllBusinessNames())

  function currencyFormatter({ value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return formatter.format(value)
  }
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShownErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShownErrorAlert(false)
    }
  }, [isError])

  useEffect(() => {
    if (shownSuccessAlert) {
      Toast.fire({
        icon: "success",
        title: `${msgSuccess}`,
      })
      refetchData()
      setShownSuccessAlert(false)
    }
  }, [shownSuccessAlert])

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  const openDeleteModal = ({ file, identifier }) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar el archivo <b>${getStringInvoice(file)}</b>?`,
      text: "Este cambio no se podrá revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        fileDelete.mutateAsync(identifier).then(() => {
          refetchData()
          Toast.fire({
            icon: "success",
            title: `El archivo <b>${getStringInvoice(file)}</b> ha sido eliminado correctamente`,
          })
        })
      }
      if (showErrorAlert) {
        ;<Alert color="danger" isOpen={shownErrorAlert} toggle={() => setShownErrorAlert(false)}>
          {isError && error?.errors?.map((error) => error?.message)}
        </Alert>
      }
    })
  }

  const openEditModal = (id) => {
    setFileId(id)
    toggleEditModal()
  }

  const toggleCreateModal = () => {
    setIsCreateModalOpen(!isCreateModalOpen)
  }

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen)
  }

  const typeOptions = [
    { value: "Factura", label: "Factura" },
    { value: "Remisión", label: "Remisión" },
  ]

  const statusOptions = [
    { value: "Pendiente", label: "Pendiente" },
    { value: "Pagada", label: "Pagada" },
  ]

  const conditionOptions = [
    { value: "Contado", label: "Contado" },
    { value: "Credito", label: "Credito" },
  ]

  const methodsOptions = [
    { value: "01 - Efectivo.", label: "01 - Efectivo." },
    { value: "02 - Cheque nominativo.", label: "02 - Cheque." },
    { value: "03 - Transferencia electrónica de fondos.", label: "03 - Transferencia." },
    { value: "99 - Por definir.", label: "99 - Por definir." },
  ]

  const getStringInvoice = (filename) => {
    if (isCreate) {
      if (typeof filename === "string") {
        return filename.substring(filename.indexOf("invoice/") + 8)
      }
    } else {
      if (typeof filename === "string") {
        return filename.substring(filename.indexOf("invoicefiles/") + 13)
      }
    }
  }

  const renderLoading = () => {
    return (
      <div className="text-center">
        <Loading isLoading={isLoading} size={2} />
      </div>
    )
  }

  const renderError = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={isError}>
          {error?.errors?.map((error) => error?.message)}
        </Alert>
      </div>
    )
  }

  return (
    <>
      {isLoading ? (
        renderLoading()
      ) : isError ? (
        renderError()
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            type: data?.type ? { value: data?.type, label: data?.type } : undefined,
            arrival_date: data?.arrival_date ?? "",
            number_invoice: data?.number_invoice ?? "",
            status: data?.status ? { value: data?.status, label: data?.status } : undefined,
            payment_condition: data?.payment_condition
              ? { value: data?.payment_condition, label: data?.payment_condition }
              : undefined,
            payment_method: data?.payment_method
              ? { value: data?.payment_method, label: data?.payment_method }
              : undefined,
            provider: data?.provider ? { value: data?.provider?.id, label: data?.provider?.name } : undefined,
            business_name: data?.business_name
              ? { value: data?.business_name?.id, label: data?.business_name?.name }
              : undefined,
            discount: data?.discount ?? 0,
            total: data?.total ?? 0,
            invoice_pdf: data?.invoice_pdf ?? undefined,
            invoice_xml: data?.invoice_xml ?? undefined,
            files: data?.files.map((file) => ({
              file: file.file,
              type: {
                value: file.type,
                label: file.type,
              },
              identifier: file.id,
            })),
          }}
          validationSchema={schema}
          onSubmit={submit}
        >
          {({ values, setFieldValue, handleChange, handleSubmit, handleBlur, touched, errors, setTouched }) => (
            <Form className="form-horizontal" onSubmit={handleSubmit} encType="multipart/form-data">
              <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
                {isError && error?.errors?.map((error) => error?.message)}
              </Alert>
              <>
                {!disabledForm && (
                  <FileCreateModalForm
                    isOpen={isCreateModalOpen}
                    invoiceId={data?.id}
                    registeredPrices={data?.prices}
                    setMsgSuccess={setMsgSuccess}
                    setShowAlertSuccess={setShownSuccessAlert}
                    toggle={toggleCreateModal}
                  />
                )}
                {!disabledForm && fileId && (
                  <FileEditModalForm
                    fileId={fileId}
                    isOpen={isEditModalOpen}
                    registeredPrices={data?.prices}
                    setMsgSuccess={setMsgSuccess}
                    setShowAlertSuccess={setShownSuccessAlert}
                    toggle={toggleEditModal}
                  />
                )}
              </>
              <Row className="mb-3">
                <Col>
                  <Label className="form-label">
                    Numero de factura <RequiredIcon />
                  </Label>
                  <Input
                    autoComplete="off"
                    className="form-control"
                    name="number_invoice"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.number_invoice}
                    invalid={
                      !!(touched.number_invoice && errors.number_invoice) ||
                      (isError && validationErrors.findIndex((e) => e.field === "number_invoice") >= 0)
                    }
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="number_invoice"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
                <Col>
                  <Label className="form-label">
                    Tipo de factura <RequiredIcon />
                  </Label>
                  <Select
                    id="type"
                    name="type"
                    className={
                      (touched.type && errors.type) ||
                      (isError && validationErrors.findIndex((e) => e.field === "type") >= 0)
                        ? "is-invalid"
                        : undefined
                    }
                    isClearable
                    value={values.type}
                    onChange={(val) => {
                      setFieldValue("type", val)
                    }}
                    onBlur={() => {
                      touched.type = true
                      setTouched(touched)
                    }}
                    isLoading={typeOptions.isLoading}
                    options={typeOptions}
                    noOptionsMessage={() => "0 opciones"}
                    loadingMessage={() => "Cargando..."}
                    placeholder="Tipo de factura"
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="type"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Label className="form-label">
                    Fecha de llegada <RequiredIcon />
                  </Label>
                  <Input
                    autoComplete="off"
                    className="form-control"
                    name="arrival_date"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="date"
                    value={values.arrival_date}
                    invalid={
                      !!(touched.arrival_date && errors.arrival_date) ||
                      (isError && validationErrors.findIndex((e) => e.field === "arrival_date") >= 0)
                    }
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="arrival_date"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
                <Col>
                  <Label className="form-label">
                    Estado de la factura <RequiredIcon />
                  </Label>
                  <Select
                    id="type"
                    name="status"
                    className={
                      (touched.status && errors.status) ||
                      (isError && validationErrors.findIndex((e) => e.field === "status") >= 0)
                        ? "is-invalid"
                        : undefined
                    }
                    isClearable
                    value={values.status}
                    onChange={(val) => {
                      setFieldValue("status", val)
                    }}
                    onBlur={() => {
                      touched.status = true
                      setTouched(touched)
                    }}
                    isLoading={statusOptions.isLoading}
                    options={statusOptions}
                    noOptionsMessage={() => "0 opciones"}
                    loadingMessage={() => "Cargando..."}
                    placeholder="Estado de la factura"
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="status"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>

              <hr />

              <Row className="mb-3">
                <Col>
                  <Label className="form-label">
                    Proveedor <RequiredIcon />
                  </Label>
                  <Select
                    id="provider"
                    name="provider"
                    className={
                      (touched.provider && errors.provider) ||
                      (isError && validationErrors.findIndex((e) => e.field === "provider") >= 0)
                        ? "is-invalid"
                        : undefined
                    }
                    isClearable
                    value={values.provider}
                    onChange={(val) => {
                      setFieldValue("provider", val)
                    }}
                    onBlur={() => {
                      touched.provider = true
                      setTouched(touched)
                    }}
                    isLoading={providers.isLoading}
                    options={providers?.data?.map((provider) => ({
                      ...provider,
                      label: provider.name,
                      value: provider.id,
                    }))}
                    noOptionsMessage={() => "0 opciones"}
                    loadingMessage={() => "Cargando..."}
                    placeholder="Proveedor"
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="provider"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
                <Col>
                  <Label className="form-label">
                    Razón social <RequiredIcon />
                  </Label>
                  <Select
                    id="business_name"
                    name="business_name"
                    className={
                      (touched.business_name && errors.business_name) ||
                      (isError && validationErrors.findIndex((e) => e.field === "business_name") >= 0)
                        ? "is-invalid"
                        : undefined
                    }
                    isClearable
                    value={values.business_name}
                    onChange={(val) => {
                      setFieldValue("business_name", val)
                    }}
                    onBlur={() => {
                      touched.business_name = true
                      setTouched(touched)
                    }}
                    isLoading={businessNames.isLoading}
                    options={businessNames?.data?.map((businessName) => ({
                      ...businessName,
                      label: businessName.name,
                      value: businessName.id,
                    }))}
                    noOptionsMessage={() => "0 opciones"}
                    loadingMessage={() => "Cargando..."}
                    placeholder="Razón social"
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="business_name"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Label className="form-label">
                    Condición de pago <RequiredIcon />
                  </Label>
                  <Select
                    id="payment_condition"
                    name="payment_condition"
                    className={
                      (touched.payment_condition && errors.payment_condition) ||
                      (isError && validationErrors.findIndex((e) => e.field === "payment_condition") >= 0)
                        ? "is-invalid"
                        : undefined
                    }
                    isClearable
                    value={values.payment_condition}
                    onChange={(val) => {
                      setFieldValue("payment_condition", val)
                    }}
                    onBlur={() => {
                      touched.payment_condition = true
                      setTouched(touched)
                    }}
                    isLoading={conditionOptions.isLoading}
                    options={conditionOptions}
                    noOptionsMessage={() => "0 opciones"}
                    loadingMessage={() => "Cargando..."}
                    placeholder="Condición de pago"
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="payment_condition"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
                <Col>
                  <Label className="form-label">
                    Forma de pago <RequiredIcon />
                  </Label>
                  <Select
                    id="payment_method"
                    name="payment_method"
                    className={
                      (touched.payment_method && errors.payment_method) ||
                      (isError && validationErrors.findIndex((e) => e.field === "payment_method") >= 0)
                        ? "is-invalid"
                        : undefined
                    }
                    isClearable
                    value={values.payment_method}
                    onChange={(val) => {
                      setFieldValue("payment_method", val)
                    }}
                    onBlur={() => {
                      touched.payment_method = true
                      setTouched(touched)
                    }}
                    isLoading={methodsOptions.isLoading}
                    options={methodsOptions}
                    noOptionsMessage={() => "0 opciones"}
                    loadingMessage={() => "Cargando..."}
                    placeholder="Forma de pago"
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="payment_method"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mb-3">
                <Col>
                  <Label className="form-label">Descuento</Label>
                  <InputGroup>
                    <InputGroupText>$</InputGroupText>
                    <Input
                      autoComplete="off"
                      className="form-control"
                      name="discount"
                      onBlur={(e) => {
                        // Dar formato de moneda al valor ingresado
                        const formattedValue = currencyFormatter({ value: e.target.value })

                        // Actualizar el valor formateado en el estado del formulario
                        setFieldValue("discount", formattedValue) // Utiliza setFieldValue de Formik
                        handleBlur(e)
                      }}
                      onChange={handleChange}
                      value={values.discount}
                    />
                  </InputGroup>
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="discount"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
                <Col>
                  <Label className="form-label">Total</Label>
                  <InputGroup>
                    <InputGroupText>$</InputGroupText>
                    <Input
                      autoComplete="off"
                      className="form-control"
                      name="total"
                      onBlur={(e) => {
                        // Dar formato de moneda al valor ingresado
                        const formattedValue = currencyFormatter({ value: e.target.value })

                        // Actualizar el valor formateado en el estado del formulario
                        setFieldValue("total", formattedValue) // Utiliza setFieldValue de Formik
                        handleBlur(e)
                      }}
                      onChange={handleChange}
                      value={values.total}
                    />
                  </InputGroup>
                  <ErrorsText
                    formName="total"
                    touched={touched}
                    errorsForm={errors}
                    isErrorBack={isError}
                    errorsBack={error}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <FormGroup>
                  <Col>
                    <Label className="form-label">PDF</Label>
                    <br />
                    {typeof values?.invoice_pdf === "string" ? (
                      <Alert
                        style={{
                          fontSize: "1em",
                        }}
                        color="warning"
                      >
                        Archivo actual: {getStringInvoice(values.invoice_pdf)}
                      </Alert>
                    ) : null}
                    <Input
                      className="form-control"
                      id="invoice_pdf"
                      name="invoice_pdf"
                      type="file"
                      accept="application/pdf"
                      label="Archivo a importar"
                      onChange={(e) => {
                        setFieldValue("invoice_pdf", e.target.files[0])
                      }}
                      placeholder={values.invoice_pdf}
                      onBlur={handleBlur}
                      invalid={
                        !!(touched.invoice_pdf && errors.invoice_pdf) ||
                        (isError && validationErrors.findIndex((e) => e.field === "invoice_pdf") >= 0)
                      }
                    />
                    <FormText color="muted">Subir archivo con el formato .pdf</FormText>
                    <ErrorsText
                      errorsBack={error}
                      errorsForm={errors}
                      formName="invoice_pdf"
                      isErrorBack={isError}
                      touched={touched}
                    />
                  </Col>
                </FormGroup>
                <Col>
                  <Label className="form-label">XML</Label>
                  <br />
                  {typeof values?.invoice_xml === "string" ? (
                    <Alert
                      style={{
                        fontSize: "1em",
                      }}
                      color="warning"
                    >
                      Archivo actual: {getStringInvoice(values.invoice_xml)}
                    </Alert>
                  ) : null}
                  <Input
                    className="form-control"
                    id="invoice_xml"
                    name="invoice_xml"
                    type="file"
                    accept="text/xml"
                    label="Archivo a importar"
                    onChange={(e) => {
                      setFieldValue("invoice_xml", e.target.files[0])
                    }}
                    onBlur={handleBlur}
                    invalid={
                      !!(touched.invoice_xml && errors.invoice_xml) ||
                      (isError && validationErrors.findIndex((e) => e.field === "invoice_xml") >= 0)
                    }
                  />
                  <FormText color="muted">Subir archivo con el formato .xml</FormText>
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="invoice_xml"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>
              <hr />
              <FieldArray
                name="files"
                value={values.files}
                render={({ push, remove }) => (
                  <div className="form-control">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <Label>Archivos</Label>
                      {!disabledForm && data?.id && (
                        <Button
                          className="text-end"
                          color="info"
                          onClick={() => {
                            toggleCreateModal()
                          }}
                        >
                          <i className="fa fa-plus" /> Agregar
                        </Button>
                      )}
                    </div>
                    <hr />
                    {values.files &&
                      values.files.map((file, index) => {
                        return (
                          <div key={index}>
                            {file.file !== "" && (
                              <Alert
                                style={{
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  fontSize: "1em",
                                }}
                                color="warning"
                              >
                                <div className="row">
                                  <div className="col-md-7">Archivo actual: {getStringInvoice(file.file)}</div>
                                  <div className="col-md-2" style={{ fontWeight: "bold" }}>
                                    {file.type.label}
                                  </div>
                                  <div
                                    className="col-md-3"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-end",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      style={{
                                        marginRight: "10px",
                                      }}
                                      color="primary"
                                      onClick={() => openEditModal(file.identifier)}
                                    >
                                      <i className="fa fa-edit" />
                                    </Button>
                                    <Button color="danger" type="button" onClick={() => openDeleteModal(file)}>
                                      <i className="fa fa-trash" />
                                    </Button>
                                  </div>
                                </div>
                              </Alert>
                            )}
                          </div>
                        )
                      })}
                  </div>
                )}
              />
              <br />
              <Row>
                <Col className="d-flex justify-content-end">
                  <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                    <i className="fa fa-times" /> Cerrar
                  </Button>
                  <Button color="success" type="submit" disabled={isLoading}>
                    <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

InvoiceForm.defaultProps = {
  data: null,
}

InvoiceForm.propTypes = {
  isCreate: propTypes.bool,
  submit: propTypes.func,
  toggle: propTypes.func,
  data: propTypes.any,
  isLoading: propTypes.bool,
  isError: propTypes.bool,
  error: propTypes.any,
  disabledForm: propTypes.bool,
  refetchData: propTypes.func,
}

export default InvoiceForm
