import { API } from "config/API"

export const getAllProviders = async (filters) => {
  const response = await API.get("/providers/all?ordering=name", { params: filters })
  return response
}
export const getProviders = async (filters) => {
  const response = await API.get("/providers?ordering=name", { params: filters })
  return response
}

export const getProvider = async (id) => {
  const response = await API.get(`/providers/${id}`)
  return response
}

export const createProvider = async (payload) => {
  const response = await API.post("/providers", payload)
  return response
}

export const editProvider = async (id, payload) => {
  const response = await API.patch(`/providers/${id}`, payload)
  return response
}

export const deleteProvider = async (id) => {
  const response = await API.delete(`/providers/${id}`)
  return response
}

export const masiveImportProviders = async (payload) => {
  const FormData = require("form-data")
  const formData = new FormData()
  formData.append("file", payload.file)
  const response = await API.post("/providers/masive_import", formData, {
    headers: {
      "Content-Type": undefined,
    },
  })
  return response
}
