import { useState, useEffect } from "react"
import propTypes from "prop-types"
import { Formik } from "formik"
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import Swal from "sweetalert2"

import { userUpdateMe } from "hooks/auth"
import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"

import schema from "./schema"

const BasicInfo = ({ data }) => {
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")


  const updateMe = userUpdateMe()

  const { isLoading, isError, error, isSuccess, data: dataUpdate, mutateAsync } = updateMe

  const submit = async (dataSubmit) => {
    await mutateAsync(dataSubmit)
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    }
  })


  useEffect(() => {
    if (isSuccess) {
      setMsgSuccess("Usuario modificado satisfactoriamente")
      setShowAlertSuccess(true)
      data = dataUpdate
    }
  }, [isSuccess])

  const isErrorDiv = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={isError}>
          {error?.errors?.map((error) => error?.message)}
        </Alert>
      </div>
    )
  }

  useEffect(() => {
    if (showAlertSuccess) {
      Toast.fire({
        icon: "success",
        title: `${msgSuccess}`
      })
      setShowAlertSuccess(false)
    }
  }, [showAlertSuccess])

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error?.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  return (
    <>
      {showErrorAlert && isErrorDiv()}
      <Formik
        enableReinitialize
        initialValues={{
          first_name: data?.first_name ?? "",
          last_name: data?.last_name ?? "",
          email: data?.email ?? "",
        }}
        validationSchema={schema}
        onSubmit={submit}
      >
        {({ values, handleChange, handleSubmit, handleBlur, touched, errors, setFieldValue, setTouched }) => (
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
              {isError && error.errors?.map((error) => error?.message)}
            </Alert>
            <h3>Información básica</h3>
            <Row className="mb-3">
              <Col sm={6}>
                <Label className="form-label">
                  Nombre <RequiredIcon />
                </Label>
                <Input
                  name="first_name"
                  className="form-control"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                  invalid={
                    !!(touched.first_name && errors.first_name) ||
                    (isError && validationErrors.findIndex((e) => e.field === "first_name") >= 0)
                  }
                />
                <ErrorsText
                  formName="first_name"
                  touched={touched}
                  errorsForm={errors}
                  isErrorBack={isError}
                  errorsBack={error}
                />
              </Col>
              <Col sm={6}>
                <Label className="form-label">
                  Apellido <RequiredIcon />
                </Label>
                <Input
                  name="last_name"
                  className="form-control"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                  invalid={
                    !!(touched.last_name && errors.last_name) ||
                    (isError && validationErrors.findIndex((e) => e.field === "last_name") >= 0)
                  }
                />
                <ErrorsText
                  formName="last_name"
                  touched={touched}
                  errorsForm={errors}
                  isErrorBack={isError}
                  errorsBack={error}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Label className="form-label">
                  Email <RequiredIcon />
                </Label>
                <Input
                  name="email"
                  className="form-control"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  invalid={
                    !!(touched.email && errors.email) ||
                    (isError && validationErrors.findIndex((e) => e.field === "email") >= 0)
                  }
                />
                <ErrorsText
                  formName="email"
                  touched={touched}
                  errorsForm={errors}
                  isErrorBack={isError}
                  errorsBack={error}
                />
              </Col>
            </Row>

            <hr />

            <div className="mt-3 text-end">
              <Button color="success" type="submit" disabled={isLoading}>
                <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

BasicInfo.propTypes = {
  data: propTypes.any.isRequired,
}

export default BasicInfo
