import { useState, useEffect } from "react"
import propTypes from "prop-types"
import { FormFeedback } from "reactstrap"

const ErrorsText = ({ formName, touched, errorsForm, isErrorBack, errorsBack }) => {
  const [validationErrors, setValidationErrors] = useState([])

  useEffect(() => {
    if (isErrorBack && errorsBack?.error_type === "ValidationError") {
      setValidationErrors(errorsBack?.errors)
    } else if (!isErrorBack) {
      setValidationErrors([])
    }
  }, [isErrorBack])

  return (
    <>
      {touched[formName] && errorsForm[formName] && <FormFeedback type="invalid">{errorsForm[formName]}</FormFeedback>}
      {isErrorBack &&
        validationErrors?.map(
          (error, key) =>
            error.field === formName && (
              <FormFeedback key={key} type="invalid">
                {error.message}
              </FormFeedback>
            )
        )}
    </>
  )
}

ErrorsText.propTypes = {
  formName: propTypes.string.isRequired,
  touched: propTypes.object.isRequired,
  errorsForm: propTypes.object.isRequired,
  isErrorBack: propTypes.bool.isRequired,
  errorsBack: propTypes.object,
}

export default ErrorsText
