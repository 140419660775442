import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { Icon } from "@iconify/react"
import { UserContext } from "App"
import SidebarContent from "./SidebarContent"

const Sidebar = () => {
  const userData = useContext(UserContext);
  const backgroundStyle = {
    background: userData?.user_type === "C" && "#e83e8c",
    // eslint-disable-next-line
    background: userData?.user_type === "S" && "#6f42c1",
    // eslint-disable-next-line
    background: userData?.user_type === "A" && "#293041",
    // eslint-disable-next-line
    background: userData?.user_type === "E" && "#564ab1",
  }

  return (
    <React.Fragment>
      <div className="vertical-menu" style={backgroundStyle}>
        <div className="navbar-brand-box" style={backgroundStyle}>
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              {/* <img src={logo} alt="" height="22" /> */}
            </span>
            <span className="logo-lg">
              {/* <img src={logoDark} alt="" height="17" /> */}
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              {/* <img src={logoLightSvg} alt="" height="22" /> */}
              <Icon icon="mdi:point-of-sale" color="white" hFlip={true} vFlip={true}
                style={{
                  fontSize: 30
                }}
              />
            </span>
            <div className="logo-lg">
              <Icon
                icon="mdi:point-of-sale"
                color="white"
                hFlip={true}
                vFlip={true}
                style={{
                  fontSize: 40
                }}
              />
              <span
                style={{
                  color: "white",
                  fontSize: 18,
                  marginLeft: 10,
                }}
              >
                Punto de venta
              </span>
            </div>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {}

export default Sidebar
