import * as Yup from "yup"

import { messages } from "utils/messages"

const schema = Yup.object().shape({
  type: Yup.object().required(messages.required),
  arrival_date: Yup.string().required(messages.required),
  number_invoice: Yup.string().required(messages.required),
  status: Yup.object().required(messages.required),
  payment_condition: Yup.object().required(messages.required),
  payment_method: Yup.object().required(messages.required),
  provider: Yup.object().required(messages.required),
  business_name: Yup.object().required(messages.required),
  discount: Yup.string().required(messages.required),
  total: Yup.string().required(messages.required),
  invoice_pdf: Yup.mixed().nullable(),
  invoice_xml: Yup.mixed().nullable(),
  files: Yup.array().of(Yup.object()).nullable(),
})

export default schema
