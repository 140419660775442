import React, { useContext } from "react"
import propTypes from "prop-types"
import { Table } from "reactstrap"
import { OfflineContext } from "App"

const TableProduct = ({ products }) => {
  // eslint-disable-next-line
  const { isOffline, setIsOffline } = useContext(OfflineContext)
  const renderProducts = () => {
    if (typeof products !== "object") {
      return null
    }
    return products.map((product) => (
      <tr key={isOffline ? product.identifier : product.product.key}>
        <td
          style={{ color: "black", paddingLeft: "0px", paddingRight: "0px", paddingTop: "4px", paddingBottom: "4px" }}
        >
          {product.quantity}
        </td>
        <td style={{ color: "black", fontSize: "14px", paddingTop: "4px", paddingBottom: "4px" }}>
          {isOffline ? product.name.substring(0, 16) : product.product.product.name.substring(0, 16)}
        </td>
        <td style={{ color: "black", paddingTop: "4px", paddingBottom: "4px" }}>
          ${parseFloat(product.price).toFixed(2)}
        </td>
        <td style={{ color: "black", paddingLeft: "0px", textAlign: "right", paddingTop: "4px", paddingBottom: "4px" }}>
          ${parseFloat(product.total).toFixed(2)}
        </td>
      </tr>
    ))
  }
  ;<hr style={{ height: "3px", backgroundColor: "black" }} />
  return (
    <Table style={{ border: "1px solid #eff2f7" }}>
      <thead style={{ borderBottom: "1px solid black" }}>
        <tr>
          <th style={{ color: "black", paddingLeft: "0px", paddingRight: "0px" }}>C/u</th>
          <th style={{ color: "black", paddingRight: "3rem", width: "38rem" }}>Descripción</th>
          <th style={{ color: "black", paddingRight: "0px" }}>Costo</th>
          <th style={{ color: "black", paddingLeft: "0px", paddingRight: "0px", textAlign: "center" }}>Importe</th>
        </tr>
      </thead>
      <tbody>{renderProducts()}</tbody>
    </Table>
  )
}

TableProduct.propTypes = {
  products: propTypes.array,
}

export default TableProduct
