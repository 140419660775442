import { API } from "config/API"

export const getManualCashCuts = async (filters) => {
  const response = await API.get(
    // eslint-disable-next-line max-len
    "/cash-cuts?type_cash_cut__icontains=Corte+de+caja+manual&ordering=-date",
    { params: filters }
  )
  return response
}

export const getBenefitPerMonth = async (payload) => {
  const response = await API.post("/cash-cuts/get_benefit_per_month", payload)
  return response
}

export const getDailyCashCuts = async (filters) => {
  const response = await API.get(
    // eslint-disable-next-line max-len
    "/cash-cuts?type_cash_cut__icontains=Corte+de+caja+diario&ordering=-date",
    { params: filters }
  )
  return response
}

export const getMonthlyCashCuts = async (filters) => {
  const response = await API.get(
    // eslint-disable-next-line max-len
    "/cash-cuts?type_cash_cut__icontains=Corte+de+caja+mensual&ordering=-date"
  )
  return response
}
