import React, { useState, useCallback } from "react"
import { Formik, Form } from "formik"
import {
  Alert,
  Container,
  Label,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap"
import { useQuery } from "@tanstack/react-query"
import Select from "react-select"
import { debounce } from "lodash"
import Swal from "sweetalert2"

import storage from "services/storage"
import InvoiceItemList from "components/sales/invoice-item-list"
import { authUserKey } from "config/API"
import { Loading } from "components"
import ListHeader from "ui-componets/list-header"
import { getInvoices, sendEmailInvoice, cancelInvoice } from "services/tickets"

const InvoiceList = () => {
  document.title = "Lista de Facturas | Punto de venta"
  const [filters, setFilters] = useState("")
  const [searchTxt, setSearchTxt] = useState(new URLSearchParams(Location.search).get("search") ?? "")
  const permissions = storage.get(authUserKey).user_permissions || []
  const [modalOpen, setModalOpen] = useState(false)
  const tickets = useQuery(["tickets", filters, filters.toString()], () => getInvoices(filters))
  const isLoading = tickets.isLoading
  const [ticketId, setTicketId] = useState(null)
  const [isCancel, setIsCancel] = useState(false)
  const [isSendEmail, setIsSendEmail] = useState(false)
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }
  const initialValuesCancel = {
    reason: null,
  }
  const initialValuesEmail = {
    email: "",
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  const handleSubmitEmail = async (values) => {
    const payload = {
      email: values.email,
    }
    try {
      await sendEmailInvoice(ticketId, payload)
      Toast.fire({
        icon: "success",
        title: "Factura enviada correctamente",
      })
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error,
      })
    }
  }

  const handleSubmit = async (values) => {
    const payload = {
      reason: values.reason.value,
    }
    try {
      await cancelInvoice(ticketId, payload)
      Toast.fire({
        icon: "success",
        title: "Factura cancelada correctamente",
      })
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error,
      })
    }
  }

  const openCancelInvoiceModal = (id) => {
    setIsCancel(true)
    setTicketId(id)
    setModalOpen(true)
  }

  const openEmailInvoiceModal = (id) => {
    setIsSendEmail(true)
    setTicketId(id)
    setModalOpen(true)
  }

  const closeCancelModal = () => {
    setModalOpen(false)
    setIsCancel(false)
  }

  const closeEmailModal = () => {
    setModalOpen(false)
    setIsSendEmail(false)
  }

  const handleSearch = (pageParams) => {
    const params = new URLSearchParams(filters)
    if (pageParams) {
      const newParams = new URLSearchParams(pageParams)
      window.history.replaceState({}, null, `${Location.pathname}?${newParams}`)
      setFilters(newParams)
    } else if (searchTxt) {
      params.set("search", searchTxt)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    } else {
      if (params.get("search")) {
        params.delete("offset")
        params.delete("per_page")
        params.delete("search")
        window.history.replaceState({}, null, `${Location.pathname}?${params}`)
        setFilters(params)
      }
    }
  }

  const debouncedSearch = useCallback(
    debounce((text) => {
      const params = new URLSearchParams()
      params.set("search", text)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    }, 300),
    []
  )

  function handleChangeSearch(event) {
    setSearchTxt(event.target.value)
    debouncedSearch(event.target.value)
  }

  return !permissions.some((e) => e.codename === "view_ticket") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="page-content">
      <Container fluid>
        <Alert color="danger" isOpen={tickets.isError}>
          {tickets.error?.errors?.map((error) => error?.message)}
        </Alert>

        <h2>Lista de Facturas</h2>
        <div className="bg-white p-3">
          <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
          <ListHeader handleOnChangeSearch={handleChangeSearch} handleSearch={handleSearch} searchTxt={searchTxt} />
          {!tickets.error && isLoading ? (
            <div className="my-5 text-center">
              <Loading isLoading={isLoading} size={2} />
            </div>
          ) : tickets.error && !tickets.isLoading ? (
            <div className="my-5 text-center">
              <h1>Error intenta mas tarde</h1>
            </div>
          ) : (
            <div className="table-responsive" style={{ marginTop: "30px" }}>
              <Table>
                <thead>
                  <tr>
                    <th style={styleCenterTable}>Folio</th>
                    <th style={styleCenterTable}>Cliente</th>
                    <th style={styleCenterTable}>Total</th>
                    <th style={styleCenterTable}>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {tickets.data?.results.filter((item) => item.uuid_invoice !== null).length > 0 ? (
                    tickets.data?.results
                      .filter((item) => item.uuid_invoice !== null)
                      .map((item) => {
                        return (
                          <InvoiceItemList
                            key={item.id}
                            item={item}
                            openCancelInvoiceModal={openCancelInvoiceModal}
                            openEmailInvoiceModal={openEmailInvoiceModal}
                          />
                        )
                      })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No hay datos
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {modalOpen && isCancel && (
                <Modal centered={true} size="lg" backdrop="static" isOpen={modalOpen} toggle={closeCancelModal}>
                  <ModalHeader toggle={closeCancelModal}>Cancelar Factura</ModalHeader>
                  <Formik initialValues={initialValuesCancel} onSubmit={handleSubmit}>
                    {({ values, setFieldValue }) => (
                      <Form>
                        <ModalBody>
                          <Label>Razon</Label>
                          <Select
                            id="reason"
                            name="reason"
                            isClearable
                            value={values.reason}
                            onChange={(val) => {
                              setFieldValue("reason", val)
                            }}
                            options={[
                              {
                                value: "01",
                                label: "Comprobante emitido con errores con relación",
                              },
                              {
                                value: "02",
                                label: "Comprobante emitido con errores sin relación",
                              },
                              {
                                value: "03",
                                label: "No se llevó a cabo la operación",
                              },
                              {
                                value: "04",
                                label: `Operación nominativa relacionada
                                                                     en la factura global`,
                              },
                            ]}
                            placeholder="Razon de la cancelación"
                          />
                          <div className="d-flex justify-content-end">
                            <Button type="submit" color="success" style={{ marginTop: "10px" }}>
                              Cancelar
                            </Button>
                          </div>
                        </ModalBody>
                      </Form>
                    )}
                  </Formik>
                </Modal>
              )}
              {modalOpen && isSendEmail && (
                <Modal centered={true} size="lg" backdrop="static" isOpen={modalOpen} toggle={closeEmailModal}>
                  <ModalHeader toggle={closeEmailModal}>Enviar Factura por Correo</ModalHeader>
                  <Formik initialValues={initialValuesEmail} onSubmit={handleSubmitEmail}>
                    {({ values, setFieldValue }) => (
                      <Form>
                        <ModalBody>
                          <Label>Email</Label>
                          <Input
                            type="email"
                            name="email"
                            id="email"
                            value={values.email}
                            onChange={(e) => setFieldValue("email", e.target.value)}
                          ></Input>
                          <div className="d-flex justify-content-end">
                            <Button type="submit" color="success" style={{ marginTop: "10px" }}>
                              Enviar
                            </Button>
                          </div>
                        </ModalBody>
                      </Form>
                    )}
                  </Formik>
                </Modal>
              )}
              <div className="text-center">
                {(tickets.data?.next || tickets.data?.previous) && (
                  <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                    {tickets.data?.previous && (
                      <PaginationItem>
                        <PaginationLink previous onClick={() => handleSearch(tickets.data?.previous.split("?")[1])} />
                      </PaginationItem>
                    )}
                    {tickets.data?.next && (
                      <PaginationItem>
                        <PaginationLink next onClick={() => handleSearch(tickets.data?.next.split("?")[1])} />
                      </PaginationItem>
                    )}
                  </Pagination>
                )}
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

export default InvoiceList
