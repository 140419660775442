import propTypes from "prop-types"
import { useEffect } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useMutation } from "@tanstack/react-query"

import { createPrices } from "services/productPrices"
import PricesForm from "./PricesForm"


const ModalPrices = ({ isOpen, toggle, registeredPrices, product, productId, setShowAlertSuccess, setMsgSuccess }) => {
  const { isLoading, isError, error, isSuccess, mutateAsync } = useMutation(createPrices)

  const submit = async (data) => {
    await mutateAsync({ ...data, product: productId })
  }

  useEffect(() => {
    if (isSuccess) {
      setMsgSuccess("Precio creado satisfactoriamente")
      setShowAlertSuccess(true)
      toggle()
    }
  }, [isSuccess])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Crear precio
      </ModalHeader>
      <ModalBody>
        <PricesForm
          submit={submit}
          toggle={toggle}
          isLoading={isLoading}
          isError={isError}
          error={error}
          product={product}
          registeredPrices={registeredPrices?.map((e) => ({ id: e.id, amount: e.amount, price: e.price.id }))}
        />
      </ModalBody>
    </Modal>
  )
}

ModalPrices.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  registeredPrices: propTypes.array,
  product: propTypes.object,
  productId: propTypes.string.isRequired,
  setShowAlertSuccess: propTypes.func.isRequired,
  setMsgSuccess: propTypes.func.isRequired,
}

export default ModalPrices
