import React, { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useTable, useGlobalFilter, useSortBy, useFilters, usePagination } from "react-table"
import { Table, Row, Col, Button, Input, Card, CardBody } from "reactstrap"
import { getCreditAccounts, editCreditAccount } from "services/credit-accounts"
import Swal from "sweetalert2"

// Componente TableContainer para mostrar la tabla de clientes morosos
const MoroseClientsTable = () => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [updateData, setUpdateData] = useState(false);

  const handleAbonarDeuda = (rowData) => {
    let payload
    Swal.fire({
      title: "Abonar a la deuda",
      html: `
        <p>Cliente: ${rowData?.ticket?.customer.name}</p>
        <p>Deuda actual: ${rowData.amount - rowData.deposit}</p>
        <input type="number" id="abonoInput" placeholder="Cantidad a abonar" class="swal2-input">
      `,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Abonar",
      cancelButtonText: "Cancelar",
      preConfirm: () => {
        const abono = Swal.getPopup().querySelector("#abonoInput").value
        return abono
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const abono = parseFloat(result.value)
        if (isNaN(abono) || abono <= 0) {
          Swal.fire("Error", "La cantidad de abono debe ser un número positivo.", "error")
        } else {
          if (abono >= rowData.amount - rowData.deposit) {
            // El abono es mayor que la deuda actual, marcar como pagada
            payload = { deposit: abono, is_paid: true }
          } else {
            // El abono es menor o igual a la deuda actual
            payload = { deposit: abono }
          }
          editCreditAccount(rowData.id, payload)
            .then(() => {
              Swal.fire("Deuda abonada con éxito!", "", "success")
              setUpdateData(!updateData); 
            })
            .catch((error) => {
              Swal.fire("Error al abonar a la deuda del cliente", error.message, "error")
            })
        }
      }
    })
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getCreditAccounts()
        setData(response.results)
        setIsLoading(false)
      } catch (error) {
        setError(error)
        setIsLoading(false)
      }
    }

    fetchData()
  }, [updateData])

  const columns = React.useMemo(
    () => [
      {
        Header: "Cliente",
        accessor: "ticket.customer.name",
      },
      {
        Header: "Días restantes",
        accessor: "final_date",
        Cell: ({ row }) => {
          const fechaLimite = new Date(row.original.final_date)
          const hoy = new Date()
          const diferenciaDias = Math.ceil((fechaLimite - hoy) / (1000 * 60 * 60 * 24))
          return <span>{diferenciaDias} días</span>
        },
      },
      {
        Header: "Deuda",
        accessor: (row) => {
          const formattedAmount = (row.amount - row.deposit).toLocaleString("es-MX", {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 2,
          })
          return formattedAmount.replace("MXN", "")
        },
      },

      {
        Header: "Acciones",
        Cell: ({ row }) => (
          <Button color="primary" onClick={() => handleAbonarDeuda(row.original)}>
            Abonar
          </Button>
        ),
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10, // Tamaño de página predeterminado
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = (event) => {
    const inputValue = event.target.value
    const page = inputValue ? Number(inputValue) - 1 : 0
    if (page >= 0 && page < pageCount) {
      gotoPage(page)
    }
  }

  return (
    <Fragment>
      {isLoading ? (
        <div>Cargando...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : (
        <div>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Clientes Morosos</h4>
              <Row className="mb-2">
                <Col md={4}>
                  <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                    {[10, 20, 30, 40, 50].map((size) => (
                      <option key={size} value={size}>
                        Mostrar {size}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              <div className="table-responsive react-table">
                <Table style={{ background: "white" }} {...getTableProps()} className="mb-0">
                  <thead className="table-light table-nowrap">
                    {headerGroups.map((headerGroup) => (
                      <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th key={column.id}>
                            <div className="mb-2" {...column.getSortByToggleProps()}>
                              {column.render("Header")}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {page.map((row, rowIndex) => {
                      prepareRow(row)

                      const fechaLimite = new Date(row.original.final_date)
                      const hoy = new Date()
                      const diferenciaDias = Math.ceil((fechaLimite - hoy) / (1000 * 60 * 60 * 24))

                      let backgroundColor = "white"
                      let color = "black"

                      if (diferenciaDias < 0) {
                        backgroundColor = "#ff3b00"
                        color = "white"
                      } else if (diferenciaDias <= 7) {
                        backgroundColor = "#f3f369"
                      }

                      const cellStyle = {
                        fontWeight: "normal",
                        backgroundColor,
                        color,
                      }

                      return (
                        <tr key={`row-${rowIndex}`} {...row.getRowProps()}>
                          {row.cells.map((cell, cellIndex) => {
                            return (
                              <td key={`cell-${rowIndex}-${cellIndex}`} {...cell.getCellProps()} style={cellStyle}>
                                {cell.render("Cell")}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>

              <Row className="justify-content-center justify-content-center align-items-center">
                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button color="primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                      {"<<"}
                    </Button>
                    <Button color="primary" onClick={previousPage} disabled={!canPreviousPage}>
                      {"<"}
                    </Button>
                  </div>
                </Col>
                <Col className="col-md-auto d-none d-md-block">
                  Página{" "}
                  <strong>
                    {pageIndex + 1} de {pageOptions.length}
                  </strong>
                </Col>
                <Col className="col-md-auto">
                  <Input
                    type="number"
                    min={1}
                    style={{ width: 70 }}
                    max={pageOptions.length}
                    defaultValue={pageIndex + 1}
                    onChange={onChangeInInput}
                  />
                </Col>
                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
                      {">"}
                    </Button>
                    <Button color="primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                      {">>"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      )}
    </Fragment>
  )
}

MoroseClientsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  row: PropTypes.object,
}

export default MoroseClientsTable
