import * as Yup from "yup"

import { messages } from "utils/messages"

const schema = Yup.object({
  name: Yup.string().max(120, messages.max_chars(120)).required(messages.required),
  location: Yup.string().max(255, messages.max_chars(255)),
})

export default schema
