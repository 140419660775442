import React from "react"
import propTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { authUserKey } from "config/API"
import storage from "services/storage"

const Authmiddleware = ({ component: Component, layout: Layout, isAuthProtected, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthProtected && !storage.get(authUserKey)) {
        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      }

      if (!isAuthProtected && storage.get(authUserKey)) {
        return <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: propTypes.bool,
  component: propTypes.any,
  location: propTypes.object,
  layout: propTypes.any,
}

export default Authmiddleware
