import propTypes from "prop-types"
import { useEffect, useState } from "react"
import { Formik } from "formik"
import { Button, Form, Label, Alert, Col, Row } from "reactstrap"
import CurrencyInput from "react-currency-input-field"

import { Loading, RequiredIcon } from "components"
import SelectPrices from "components/products/SelectPrices"
import ErrorsText from "components/ErrorsText"
import schema from "./schema"


const PricesForm = ({ data, submit, toggle, isLoading, isError, error, product, registeredPrices }) => {
  const [errorPrices, setErrorPrices] = useState({})
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])

  const errorsAlert = () => {
    let errorTmp = {}
    if (errorPrices) {
      errorTmp = errorPrices
    } else if (isError) {
      errorTmp = error
    }

    return (
      <Alert color="danger" isOpen={showErrorAlert}>
        {errorTmp?.errors?.map((error) => error?.message)}
      </Alert>
    )
  }

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  return (
    <>
      {errorsAlert()}
      <Formik
        enableReinitialize
        initialValues={{
          price: data?.price ? data?.price?.id : "",
          amount: data?.amount ? data?.amount : "",
          utility: data?.utility ? data?.utility : "",
          quantity: data?.quantity ? data?.quantity : "",
        }}
        validationSchema={schema}
        onSubmit={submit}
      >
        {({ values, handleSubmit, handleBlur, touched, errors, setFieldValue, setTouched }) => (
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <Label for="price">Nombre</Label>
                <SelectPrices
                  name={"price"}
                  onChange={(val) => {
                    setFieldValue("price", val)
                  }}
                  price={values?.price}
                  editingPrice={data?.price ? data?.price?.id : ""}
                  onBlur={(e) => {
                    setTouched({ ...touched, price: true })
                  }}
                  values={registeredPrices}
                  setErrorPrices={setErrorPrices}
                  setIsErrorPrices={setShowErrorAlert}
                  invalid={
                    !!(touched.price && errors.price) ||
                    (isError && validationErrors.findIndex((e) => e.field === "price") >= 0)
                  }
                />
                <ErrorsText
                  formName="price"
                  touched={touched}
                  errorsForm={errors}
                  isErrorBack={isError}
                  errorsBack={error}
                />
              </Col>
              <Col>
                <Label className="form-label">
                  Precio <RequiredIcon />
                </Label>
                <CurrencyInput
                  name="amount"
                  className={
                    !!(touched.amount && errors.amount) ||
                    (isError && validationErrors.findIndex((e) => e.field === "amount") >= 0)
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  prefix="$"
                  onValueChange={(value) => {
                    let utility = ((value / product.cost) * 100) - 100
                    utility = utility.toFixed(2)
                    if (!isNaN(value)){
                      setFieldValue("utility", utility)
                      setFieldValue("amount", value)
                    }else{
                      setFieldValue("amount", product.cost)
                      setFieldValue("utility", 0)
                    }
                  }}
                  onBlur={handleBlur}
                  decimalsLimit={2}
                  allowNegativeValue={false}
                  value={values.amount}
                />
                <ErrorsText
                  formName="amount"
                  touched={touched}
                  errorsForm={errors}
                  isErrorBack={isError}
                  errorsBack={error}
                />
              </Col>
            </Row>

            <Row className="mb-3">
            <Col>
                <Label className="form-label">
                  Utilidad <RequiredIcon />
                </Label>
                <CurrencyInput
                  name="utility"
                  className={
                    !!(touched.utility && errors.utility) ||
                    (isError && validationErrors.findIndex((e) => e.field === "utility") >= 0)
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  prefix="%"
                  onValueChange={(value) => {
                    let amount = product.cost * (parseFloat(value)+parseFloat(100)) / 100
                    amount = amount.toFixed(2)
                    if (!isNaN(value)){
                      setFieldValue("amount", amount)
                      setFieldValue("utility", value)
                    }else{
                      setFieldValue("amount", product.cost)
                      setFieldValue("utility", 0)
                    }
                  }}
                  onBlur={handleBlur}
                  decimalsLimit={2}
                  allowNegativeValue={false}
                  value={values.utility}
                />
                <ErrorsText
                  formName="utility"
                  touched={touched}
                  errorsForm={errors}
                  isErrorBack={isError}
                  errorsBack={error}
                />
              </Col>
              <Col>
                <Label className="form-label">
                  Cantidad <RequiredIcon />
                </Label>
                <CurrencyInput
                  name="quantity"
                  className={
                    !!(touched.quantity && errors.quantity) ||
                    (isError && validationErrors.findIndex((e) => e.field === "quantity") >= 0)
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  onValueChange={(value) => {
                    setFieldValue("quantity", value)
                  }}
                  onBlur={handleBlur}
                  decimalsLimit={2}
                  allowNegativeValue={false}
                  value={values.quantity}
                />
                <ErrorsText
                  formName="quantity"
                  touched={touched}
                  errorsForm={errors}
                  isErrorBack={isError}
                  errorsBack={error}
                />
              </Col>
            </Row>

            <hr />
            <Row className="mb-3">
              <Col className="text-end">
                <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                  <i className="fa fa-times" /> Cerrar
                </Button>

                <Button color="success" type="submit" disabled={isLoading}>
                  <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

PricesForm.defaultProps = {
  data: null,
}

PricesForm.propTypes = {
  data: propTypes.any,
  submit: propTypes.func.isRequired,
  toggle: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
  isError: propTypes.bool.isRequired,
  error: propTypes.object,
  product: propTypes.object,
  registeredPrices: propTypes.array,
}

export default PricesForm
