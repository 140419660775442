import { API } from "config/API"

export const getPeriodicTask = async () => {
  const response = await API.get("/cash-cuts/get_periodic_task")
  return response
}

export const updateScheduleTask = async (payload) => {
    const response = await API.post("/cash-cuts/update_schedule_of_task", payload)
    return response
  }

export const getCashCutCounterManual = async () => {
  const response = await API.get("/cash-cuts-counters?name__icontains=Corte+de+caja+manual")
  return response
}

export const createCashCut = async (payload) => {
  const response = await API.post("/cash-cuts", payload)
  return response
}

export const getLimitForCashCut = async () => {
  const response = await API.get("/limit-cash-cut")
  return response
}

export const getAllLimitForCashCut = async () => {
  const response = await API.get("/limit-cash-cut/all")
  return response
}

export const updateLimitForCashCut = async (id, payload) => {
  const response = await API.put(`/limit-cash-cut/${id}`, payload)
  return response
}
