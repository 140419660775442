import React, { useState, useEffect, useCallback } from "react"
import { useLocation } from "react-router-dom"
import { Container, Table, Button, Alert, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { useMutation, useQuery } from "@tanstack/react-query"
import Swal from "sweetalert2"
import { debounce } from "lodash"

import storage from "services/storage"
import { authUserKey } from "config/API"
import { getUsers, deleteUser } from "services/users"
import { Loading } from "components"
import { CreateModalForm, EditModalForm } from "components/users"
import ListHeader from "ui-componets/list-header"
import Shortcuts from "components/Shortcuts"

const UsersList = () => {
  document.title = "Lista de Usuarios | Punto de venta"

  const Location = useLocation()

  const [filters, setFilters] = useState("")
  const [searchTxt, setSearchTxt] = useState(new URLSearchParams(Location.search).get("search") ?? "")
  const [modalCreate, setmodalCreate] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)
  const [userId, setCustomerId] = useState(null)
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }

  const users = useQuery(["users", filters, filters.toString()], () => getUsers(filters))
  const userDeleted = useMutation((id) => deleteUser(id))

  const permissions = storage.get(authUserKey).user_permissions || []
  const canAdd = permissions.some((e) => e.codename === "add_user")
  const canEdit = permissions.some((e) => e.codename === "change_user")
  const canDelete = permissions.some((e) => e.codename === "delete_user")

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  const toggleModalCreate = () => {
    setmodalCreate(!modalCreate)
  }

  const toggleModalEdit = () => {
    if (modalEdit) {
      setCustomerId("")
    }
    setmodalEdit(!modalEdit)
  }

  const openEditModal = (userId) => {
    setCustomerId(userId)
    toggleModalEdit()
  }

  const openDeleteModal = (row) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar al usuario: <b>${row.first_name} ${row.last_name} </b>?`,
      text: "Este cambio no se podrá revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        userDeleted.mutateAsync(row?.id).then(() => {
          users.refetch()
          Toast.fire(
            "!Eliminado!",
            `El usuario <b>${row.first_name} ${row.last_name} </b> ha sido eliminado correctamente`,
            "success"
          )
        })
      }
    })
  }

  const handleType = (userType) => {
    switch (userType) {
      case "A":
        return "Administrador"
      case "E":
        return "Supervisor"
      case "C":
        return "Cajero"
      case "S":
        return "Vendedor"
      default:
        return "Tipo de usuario desconocido"
    }
  }

  const handleSearch = (pageParams) => {
    const params = new URLSearchParams(filters)
    if (pageParams) {
      const newParams = new URLSearchParams(pageParams)
      window.history.replaceState({}, null, `${Location.pathname}?${newParams}`)
      setFilters(newParams)
    } else if (searchTxt) {
      params.set("search", searchTxt)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    } else {
      if (params.get("search")) {
        params.delete("offset")
        params.delete("per_page")
        params.delete("search")
        window.history.replaceState({}, null, `${Location.pathname}?${params}`)
        setFilters(params)
      }
    }
  }

  const debouncedSearch = useCallback(
    debounce((text) => {
      const params = new URLSearchParams()
      params.set("search", text)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    }, 300),
    []
  )

  function handleChangeSearch(event) {
    setSearchTxt(event.target.value)
    debouncedSearch(event.target.value)
  }

  const isLoading = users.isLoading || userDeleted.isLoading

  useEffect(() => {
    setFilters(Location.search)
  }, [])

  useEffect(() => {
    if (showAlertSuccess) {
      Toast.fire({
        icon: "success",
        title: `${msgSuccess}`,
      })
      users.refetch()
      setShowAlertSuccess(false)
    }
  }, [showAlertSuccess])

  return !permissions.some((e) => e.codename === "view_user") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <React.Fragment>
      <div className="page-content">
        {canAdd && (
          <CreateModalForm
            isOpen={modalCreate}
            toggle={toggleModalCreate}
            setShowAlertSuccess={setShowAlertSuccess}
            setMsgSuccess={setMsgSuccess}
          />
        )}
        {canEdit && userId && (
          <EditModalForm
            isOpen={modalEdit}
            toggle={toggleModalEdit}
            userId={userId}
            setShowAlertSuccess={setShowAlertSuccess}
            setMsgSuccess={setMsgSuccess}
          />
        )}
        <Container fluid>
          <Alert color="danger" isOpen={users.isError}>
            {users?.error?.errors?.map((error) => error?.message)}
          </Alert>

          <h2>Lista de Usuarios</h2>
          <div className="bg-white p-3">
            <ListHeader
              canAdd={canAdd}
              handleOnChangeSearch={handleChangeSearch}
              handleSearch={handleSearch}
              searchTxt={searchTxt}
              toggleModalCreate={toggleModalCreate}
            />
            {!users.error && isLoading ? (
              <div className="my-5 text-center">
                <Loading isLoading={isLoading} size={2} />
              </div>
            ) : users.error && !isLoading ? (
              <div className="my-5 text-center">
                <h1>Error intenta mas tarde</h1>
              </div>
            ) : (
              <div className="table-responsive" style={{ marginTop: "30px" }}>
                <Table>
                  <thead>
                    <tr>
                      <th style={styleCenterTable}>Nombre</th>
                      <th style={styleCenterTable}>Apellido</th>
                      <th style={styleCenterTable}>Email</th>
                      <th style={styleCenterTable}>Tipo de usuario</th>
                      <th style={styleCenterTable}>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.data?.results?.map((row) => {
                      return (
                        <tr key={row.id}>
                          <td style={styleCenterTable}>{row.first_name}</td>
                          <td style={styleCenterTable}>{row.last_name}</td>
                          <td style={styleCenterTable}>{row.email}</td>
                          <td style={styleCenterTable}>{handleType(row.user_type)}</td>
                          <td style={styleCenterTable} className="text-center">
                            {canEdit && (
                              <Button color="primary" className="m-1" onClick={() => openEditModal(row.id)}>
                                <i className="fa fa-edit" />
                              </Button>
                            )}
                            {canDelete && (
                              <Button color="danger" onClick={() => openDeleteModal(row)}>
                                <i className="fa fa-trash" />
                              </Button>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>

                <div className="text-center">
                  {(users.data?.results?.next || users.data?.results?.previous) && (
                    <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                      {users.data?.results?.previous && (
                        <PaginationItem>
                          <PaginationLink
                            previous
                            onClick={() => handleSearch(users.data?.results?.previous.split("?")[1])}
                          />
                        </PaginationItem>
                      )}
                      {users.data?.results?.next && (
                        <PaginationItem>
                          <PaginationLink next onClick={() => handleSearch(users.data?.results?.next.split("?")[1])} />
                        </PaginationItem>
                      )}
                    </Pagination>
                  )}
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
      <Shortcuts toggleModalCreate={toggleModalCreate} />
    </React.Fragment>
  )
}

export default UsersList
