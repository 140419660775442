import { API } from "config/API"

export const getAgents = async (filters) => {
  const response = await API.get("/agents", { params: filters })
  return response
}

export const getAgent = async (id) => {
  const response = await API.get(`/agents/${id}`)
  return response
}

export const createAgent = async (payload) => {
  const response = await API.post("/agents", payload)
  return response
}

export const editAgent = async (id, payload) => {
  const response = await API.patch(`/agents/${id}`, payload)
  return response
}

export const deleteAgent = async (id) => {
  const response = await API.delete(`/agents/${id}`)
  return response
}
