import { Formik } from "formik"
import { useState, useEffect } from "react"
import { Button, Form, Input, Label, Alert, Col, Row } from "reactstrap"
import Swal from "sweetalert2"
import { useMutation } from "@tanstack/react-query"

import { changePassword } from "services/auth"
import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"

import schema from "./schema"

const ChangePassword = () => {
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    }
  })

  const { isLoading, isError, error, isSuccess, mutateAsync } = useMutation(["changePassword"], (data) =>
    changePassword(data)
  )

  const submit = async (dataSubmit, { resetForm }) => {
    await mutateAsync(dataSubmit)
    resetForm()
  }

  useEffect(() => {
    if (isSuccess) {
      setMsgSuccess("Contraseña actualizada satisfactoriamente")
      setShowAlertSuccess(true)
    }
  }, [isSuccess])

  const isErrorDiv = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={isError}>
          {error?.errors?.map((error) => error?.message)}
        </Alert>
      </div>
    )
  }

  useEffect(() => {
    if (showAlertSuccess) {
      Toast.fire({
        icon: "success",
        title: msgSuccess,
        didOpen: () => {
          setShowAlertSuccess(false)
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "green",
      })
    }
  }, [showAlertSuccess])

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error?.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  return (
    <>
      {showErrorAlert && isErrorDiv()}
      <Formik
        enableReinitialize
        initialValues={{
          current_password: "",
          new_password: "",
          password_confirm: "",
        }}
        validationSchema={schema}
        onSubmit={submit}
      >
        {({ values, handleChange, handleSubmit, handleBlur, touched, errors, setFieldValue, setTouched }) => (
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
              {isError && error?.errors?.map((error) => error?.message)}
            </Alert>
            <h3>Cambiar contraseña</h3>
            <Row className="mb-3">
              <Col>
                <Label className="form-label">
                  Contraseña Actual <RequiredIcon />
                </Label>
                <Input
                  name="current_password"
                  className="form-control"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.current_password}
                  invalid={
                    !!(touched.current_password && errors.current_password) ||
                    (isError && validationErrors.findIndex((e) => e.field === "current_password") >= 0)
                  }
                />
                <ErrorsText
                  formName="current_password"
                  touched={touched}
                  errorsForm={errors}
                  isErrorBack={isError}
                  errorsBack={error}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm={6}>
                <Label className="form-label">
                  Nueva contraseña <RequiredIcon />
                </Label>
                <Input
                  name="new_password"
                  className="form-control"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.new_password}
                  invalid={
                    !!(touched.new_password && errors.new_password) ||
                    (isError && validationErrors.findIndex((e) => e.field === "new_password") >= 0)
                  }
                />
                <ErrorsText
                  formName="new_password"
                  touched={touched}
                  errorsForm={errors}
                  isErrorBack={isError}
                  errorsBack={error}
                />
              </Col>
              <Col sm={6}>
                <Label className="form-label">
                  Confirma la contraseña <RequiredIcon />
                </Label>
                <Input
                  name="password_confirm"
                  className="form-control"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password_confirm}
                  invalid={
                    !!(touched.password_confirm && errors.password_confirm) ||
                    (isError && validationErrors.findIndex((e) => e.field === "password_confirm") >= 0)
                  }
                />
                <ErrorsText
                  formName="password_confirm"
                  touched={touched}
                  errorsForm={errors}
                  isErrorBack={isError}
                  errorsBack={error}
                />
              </Col>
            </Row>

            <hr />

            <div className="mt-3 text-end">
              <Button color="success" type="submit" disabled={isLoading}>
                <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

ChangePassword.propTypes = {}

export default ChangePassword
