import * as Yup from "yup"

import { messages } from "utils/messages"

const schema = Yup.object({
  clave: Yup.string().max(255, messages.max_chars(255)).required(messages.required),
  description: Yup.string().max(255, messages.max_chars(255)).required(messages.required),
  presentation_unit: Yup.number().integer(messages.integer).positive(messages.positive).required(messages.required),
})

export default schema
