import { useEffect } from "react"
import propTypes from "prop-types"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useMutation } from "@tanstack/react-query"

import { createCustomer } from "services/customers"
import CustomerForm from "./CustomerForm"

const CreateModalForm = ({ isOpen, toggle, setShowAlertSuccess, setMsgSuccess }) => {
  const { isLoading, isError, error, isSuccess, mutateAsync } = useMutation(createCustomer)

  const submit = async (data) => {
    await mutateAsync({
      ...data,
      price: data.price?.value,
      credit_days: data?.credit_days || null,
      credit_limit: parseFloat(data?.credit_limit.replace(/,/g, "")) || null,
      tax_regimen: data?.tax_regimen?.value || null,
      cfdi_use: data?.cfdi_use?.value || null,
    })
  }

  useEffect(() => {
    if (isSuccess) {
      setMsgSuccess("Cliente creado satisfactoriamente")
      setShowAlertSuccess(true)
      toggle()
    }
  }, [isSuccess])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Agregar Cliente
      </ModalHeader>
      <ModalBody>
        <CustomerForm
          isCreate={true}
          submit={submit}
          toggle={toggle}
          isLoading={isLoading}
          isError={isError}
          error={error}
        />
      </ModalBody>
    </Modal>
  )
}

CreateModalForm.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  setShowAlertSuccess: propTypes.func,
  setMsgSuccess: propTypes.func,
}

export default CreateModalForm
