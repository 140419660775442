import propTypes from "prop-types"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap"
import Select from "react-select"
import { useMutation } from "@tanstack/react-query"
import Swal from "sweetalert2"

import { transferProducts, getMovements } from "services/movementsStores"
import { getInvetoryStore } from "services/inventories"

const ModalTransferStore = ({ isOpen, toggle, stores, refetch, productsToTransfer }) => {
  const [selectedOriginStore, setSelectedOriginStore] = useState(1)
  const [selectedDestinationStore, setSelectedDestinationStore] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [products, setProducts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [movements, setMovements] = useState()
  const [searchTerm, setSearchTerm] = useState("")
  const [filteredMovements, setFilteredMovements] = useState()
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, "0")
  const day = String(today.getDate()).padStart(2, "0")
  const formattedDate = `${year}-${month}-${day}`
  const { id } = useParams()

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  function currencyFormatter({ value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return formatter.format(value)
  }

  const handleSearch = (isNext) => {
    const newPage = isNext ? currentPage + 1 : currentPage - 1
    if (newPage >= 0) {
      setCurrentPage(newPage)
    }
  }

  const { isLoading, isSuccess, mutateAsync } = useMutation(transferProducts)

  const submit = async () => {
    const quantity = document.querySelector("input[type='number']").value
    const reason = document.querySelector("input[maxlength='50']").value
    const description = document.querySelector("input[maxlength='100']").value
    const product = selectedProduct ? selectedProduct.value : null
    const source = selectedOriginStore ? selectedOriginStore.value : null
    const destiny = selectedDestinationStore ? selectedDestinationStore.value : null

    const data = {
      date: formattedDate,
      source_store: source,
      destiny_store: destiny,
      quantity,
      product,
      reason,
      description,
    }

    try {
      const result = await mutateAsync(data)
      if (result) {
        Toast.fire("!Exitoso!", "El movimiento se ha sido realizado correctamente", "success")
        setSelectedOriginStore(1)
        setSelectedDestinationStore(null)
        setSelectedProduct(null)
        const quantityInput = document.querySelector("input[type='number']")
        quantityInput.value = ""
        const reasonInput = document.querySelector("input[maxlength='50']")
        reasonInput.value = ""
        const descriptionInput = document.querySelector("input[maxlength='100']")
        descriptionInput.value = ""

        // refetch()
      }
    } catch (error) {
      Toast.fire("!Error!", error.errors[0].message, "error")
    }
  }

  useEffect(() => {
    if (isSuccess) {
      getInvetoryStore(id).then((response) => {
        refetch(response.results)
      })
    }
  }, [isSuccess])

  useEffect(() => {
    getMovements(currentPage).then((response) => {
      setMovements(response)
    })
  }, [isSuccess, currentPage])

  useEffect(() => {
    if (selectedOriginStore.value) {
      getInvetoryStore(selectedOriginStore.value).then((response) => {
        setProducts(response)
      })
    }
  }, [selectedOriginStore])

  useEffect(() => {
    const filtered = movements?.results?.filter((movement) => {
      return (
        movement.reason.toLowerCase().includes(searchTerm.toLowerCase()) ||
        movement.product.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        movement.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        movement.source_store.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        movement.destiny_store.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    })
    setFilteredMovements(filtered)
  }, [searchTerm, movements])

  const clearFields = () => {
    const numberInput = document.querySelector("input[type='number']")
    if (numberInput) {
      numberInput.value = 0
    }
    setSelectedProduct(null)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="xl" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Realizar Movimiento
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <Label>Origen:</Label>
            <Select
              value={selectedOriginStore}
              onChange={(selectedOption) => setSelectedOriginStore(selectedOption)}
              placeholder="Seleccione un almacén de origen"
              options={stores.map((store) => ({ value: store.id, label: store.name }))}
            />
          </Col>
          <Col md={6}>
            <Label>Destino:</Label>
            <Select
              value={selectedDestinationStore}
              onChange={(selectedOption) => setSelectedDestinationStore(selectedOption)}
              placeholder="Seleccione un almacén de destino"
              options={stores.map((store) => ({ value: store.id, label: store.name }))}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Col md={4}>
            <Label>Fecha:</Label>
            <Input disabled type="date" value={formattedDate} />
          </Col>
        </Row>
        <Table>
          <thead>
            <tr>
              <th>Cantidad</th>
              <th>Producto</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Input type="number" />
              </td>
              <td>
                <Select
                  id="selectProduct"
                  type="text"
                  placeholder="Seleccione un producto"
                  value={selectedProduct}
                  options={[
                    ...(products?.results
                      ? products?.results?.map((product) => ({
                          value: product.product.id,
                          label: product.product.description,
                        }))
                      : productsToTransfer?.map((product) => ({
                          value: product.product.id,
                          label: product.product.description,
                        }))),
                  ]}
                  onChange={(option) => setSelectedProduct(option)}
                />
              </td>
              <td>
                <Button color="danger" onClick={clearFields}>
                  Limpiar
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
        <Row>
          <Col>
            <Label>Motivo:</Label>
            <Input maxLength="50" required />
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col>
            <Label>Descripcion:</Label>
            <Input maxLength="100" />
          </Col>
        </Row>
        <Row>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{ marginTop: "10px", marginBottom: "10px" }}
              color={"success"}
              onClick={submit}
              disabled={isLoading}
            >
              Transferir
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <h3>Movimientos Realizados</h3>
          </Col>
          <Col md={4}></Col>
          <Col md={4}>
            <div className="text-right">
              <Input
                type="text"
                placeholder="Buscar..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Table>
          <thead>
            <tr>
              <th>Numero</th>
              <th>Motivo</th>
              <th>Cantidad</th>
              <th>Producto</th>
              <th>Descripción</th>
              <th>Almacén Origen</th>
              <th>Almacén Destino</th>
              <th>Fecha</th>
            </tr>
          </thead>
          <tbody>
            {filteredMovements?.map((movement) => (
              <tr key={movement.id}>
                <td
                  style={
                    id === movement.destiny_store.id.toString()
                      ? { backgroundColor: "#5bff5b" }
                      : id === movement.source_store.id.toString()
                      ? { backgroundColor: "#ff5b5b", color: "white" }
                      : {}
                  }
                >
                  {movement.id}
                </td>
                <td
                  style={
                    id === movement.destiny_store.id.toString()
                      ? { backgroundColor: "#5bff5b" }
                      : id === movement.source_store.id.toString()
                      ? { backgroundColor: "#ff5b5b", color: "white" }
                      : {}
                  }
                >
                  {movement.reason}
                </td>
                <td
                  style={
                    id === movement.destiny_store.id.toString()
                      ? { backgroundColor: "#5bff5b" }
                      : id === movement.source_store.id.toString()
                      ? { backgroundColor: "#ff5b5b", color: "white" }
                      : {}
                  }
                >
                  {currencyFormatter({ value: Number(movement.quantity).toFixed(1) })}
                </td>
                <td
                  style={
                    id === movement.destiny_store.id.toString()
                      ? { backgroundColor: "#5bff5b" }
                      : id === movement.source_store.id.toString()
                      ? { backgroundColor: "#ff5b5b", color: "white" }
                      : {}
                  }
                >
                  {movement.product.description}
                </td>
                <td
                  style={
                    id === movement.destiny_store.id.toString()
                      ? { backgroundColor: "#5bff5b" }
                      : id === movement.source_store.id.toString()
                      ? { backgroundColor: "#ff5b5b", color: "white" }
                      : {}
                  }
                >
                  {movement.description}
                </td>
                <td
                  style={
                    id === movement.destiny_store.id.toString()
                      ? { backgroundColor: "#5bff5b" }
                      : id === movement.source_store.id.toString()
                      ? { backgroundColor: "#ff5b5b", color: "white" }
                      : {}
                  }
                >
                  {movement.source_store.name}
                </td>
                <td
                  style={
                    id === movement.destiny_store.id.toString()
                      ? { backgroundColor: "#5bff5b" }
                      : id === movement.source_store.id.toString()
                      ? { backgroundColor: "#ff5b5b", color: "white" }
                      : {}
                  }
                >
                  {movement.destiny_store.name}
                </td>
                <td
                  style={
                    id === movement.destiny_store.id.toString()
                      ? { backgroundColor: "#5bff5b" }
                      : id === movement.source_store.id.toString()
                      ? { backgroundColor: "#ff5b5b", color: "white" }
                      : {}
                  }
                >
                  {new Date(movement.date).toLocaleDateString("es-MX", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="text-center">
          {(movements?.next || movements?.previous) && (
            <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
              {movements?.previous && (
                <PaginationItem>
                  <PaginationLink previous onClick={() => handleSearch(false)} />
                </PaginationItem>
              )}
              {movements?.next && (
                <PaginationItem>
                  <PaginationLink next onClick={() => handleSearch(true)} />
                </PaginationItem>
              )}
            </Pagination>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

ModalTransferStore.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  stores: propTypes.array,
  refetch: propTypes.func,
  productsToTransfer: propTypes.array,
}

export default ModalTransferStore
