import React from "react"
import propTypes from "prop-types"
import { withRouter } from "react-router-dom"

const NonAuthLayout = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>
}

NonAuthLayout.propTypes = {
  children: propTypes.any,
  location: propTypes.object,
}

export default withRouter(NonAuthLayout)
