import { API } from "config/API"

const downloadTemplate = async (urlTemplate) => {
   const response = await API.get(urlTemplate, { responseType: "blob" })
   return response
 }

export const useDownloadTemplate = (urlTemplate) => {
   const response = downloadTemplate(urlTemplate)
   const nameFile = `plantilla_${urlTemplate.split("/")[1]}.xlsx`
   return response.then((data) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", nameFile);
      document.body.appendChild(link);
      link.click();
      link.remove();
   });
}
