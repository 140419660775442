import propTypes from "prop-types"
import { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { Formik } from "formik"
import Select from "react-select"
import { Button, Form, Label, Alert, Col, Row, Input } from "reactstrap"
import CurrencyInput from "react-currency-input-field"

import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"
import schema from "./schema"
import { getAllProductsPresentations } from "services/product-presentations"


const InventoryForm = ({ 
    data, 
    submit, 
    toggle, 
    isLoading, 
    isError, 
    error, 
    isEdit, 
    inventoriesRegistered 
  }) => {
  const [errorProducts, setErrorProducts] = useState({})
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  // TODO: check this line
  // eslint-disable-next-line 
  const [inputSelect, setInputSelect] = useState("")
  
  const productsPresentations = useQuery(["productsPresentations"], () => getAllProductsPresentations())

  const errorsAlert = () => {
    let errorTmp = {}
    if (errorProducts) {
      errorTmp = errorProducts
    } else if (isError) {
      errorTmp = error
    }

    return (
      <Alert color="danger" isOpen={showErrorAlert}>
        {errorTmp?.errors?.map((error) => error?.message)}
      </Alert>
    )
  }

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setErrorProducts()
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])


  const filteredProducts = () => {
    let data = productsPresentations?.data

    for (let i = 0; i < productsPresentations?.data?.length; i++) {
      for (let j = 0; j < inventoriesRegistered?.length; j++) {
        if (productsPresentations?.data[i]?.description === inventoriesRegistered[j]?.product?.description) {
          data = data.filter(product => product !== productsPresentations?.data[i])
          break
        }
      }
    }
    
    data = data?.map((product) => ({ ...product, label: product.description, value: product.id }))
    return data
  }


  return (
    <>
      {errorsAlert()}
      <Formik
        enableReinitialize
        initialValues={{
          product: data?.product ? { value: data?.product?.id, label: data?.product?.description } : undefined,
          quantity: data?.quantity ?? "",
          reorder_point: data?.reorder_point ?? "",
        }}
        validationSchema={schema}
        onSubmit={submit}
      >
        {({ values, handleChange, handleSubmit, handleBlur, touched, errors, setFieldValue}) => (
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <Label for="product">
                  Producto <RequiredIcon/>
                </Label>
                <Select
                  name="product"
                  id="product"
                  className={
                    (touched.product && errors.product) ||
                    (isError && validationErrors.findIndex((e) => e.field === "product") >= 0)
                      ? "is-invalid"
                      : undefined
                  }
                  isClearable
                  value={values.product}
                  onChange={(val) => {
                    setFieldValue("product", val)
                  }}
                  onBlur={handleBlur}
                  onInputChange={setInputSelect}
                  isLoading={isLoading}
                  options={filteredProducts()}
                  noOptionsMessage={() => "0 productos"}
                  loadingMessage={() => "Cargando..."}
                  placeholder="Producto"
                  isDisabled={isEdit}
                />
                <ErrorsText
                  errorsBack={error}
                  errorsForm={errors}
                  formName="product"
                  isErrorBack={isError}
                  touched={touched}
                />
              </Col>
              <Col>
                <Label className="form-label">
                  Cantidad <RequiredIcon />
                </Label>
                <Input
                  autoComplete="off"
                  className="form-control"
                  name="quantity"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.quantity}
                  invalid={
                    !!(touched.quantity && errors.quantity) ||
                    (isError && validationErrors.findIndex((e) => e.field === "quantity") >= 0)
                  }
                />
                <ErrorsText
                  errorsBack={error}
                  errorsForm={errors}
                  formName="quantity"
                  isErrorBack={isError}
                  touched={touched}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Label className="form-label">
                  Punto de reorden
                </Label>
                <CurrencyInput
                  autoComplete="off"
                  className="form-control"
                  name="reorder_point"
                  onBlur={handleBlur}
                  onValueChange={(val) => {
                    setFieldValue("reorder_point", val)
                  }}
                  value={values.reorder_point}
                  invalid={
                    !!(touched.name && errors.name) ||
                    (isError && validationErrors.findIndex((e) => e.field === "reorder_point") >= 0)
                  }
                  allowNegativeValue={false}
                />
                <ErrorsText
                  formName="reorder_point"
                  touched={touched}
                  errorsForm={errors}
                  isErrorBack={isError}
                  errorsBack={error}
                />
              </Col>
            </Row>

            <hr />
            <Row className="mb-3">
              <Col className="text-end">
                <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                  <i className="fa fa-times" /> Cerrar
                </Button>

                <Button color="success" type="submit" disabled={isLoading}>
                  <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

InventoryForm.defaultProps = {
  data: null,
}

InventoryForm.propTypes = {
  data: propTypes.any,
  submit: propTypes.func.isRequired,
  toggle: propTypes.func.isRequired,
  isLoading: propTypes.bool.isRequired,
  isError: propTypes.bool.isRequired,
  error: propTypes.object,
  isEdit: propTypes.bool,
  inventoriesRegistered: propTypes.array,
}

export default InventoryForm
