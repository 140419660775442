import { API } from "config/API"

export const getAllProducts = async (filters) => {
  const response = await API.get("/products/all?ordering=name", { params: filters })
  return response
}

export const getProducts = async (filters) => {
  const response = await API.get("/products?ordering=name", { params: filters })
  return response
}

export const getProduct = async (id) => {
  const response = await API.get(`/products/${id}`)
  return response
}

export const createProduct = async (payload) => {
  const response = await API.post("/products", payload)
  return response
}

export const editProduct = async (id, payload) => {
  const response = await API.patch(`/products/${id}`, payload)
  return response
}

export const deleteProduct = async (id) => {
  const response = await API.delete(`/products/${id}`)
  return response
}

export const masiveImportProducts = async (payload) => {
  const FormData = require("form-data")
  const formData = new FormData()
  formData.append("file", payload.file)
  const response = await API.post("/products/masive_import", formData, {
    headers: {
      "Content-Type": undefined,
    },
  })
  return response
}
