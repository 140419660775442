import React, { useCallback, useEffect, useRef, useState } from "react"
import { Alert, Container, Input, Label, Pagination, PaginationLink, PaginationItem, Table } from "reactstrap"
import { useQuery } from "@tanstack/react-query"
import { debounce } from "lodash"

import storage from "services/storage"
import { authUserKey } from "config/API"
import { getAllStores } from "services/stores"
import { Loading } from "components"
import { getAllProductsPresentationsInventories } from "services/product-presentations"
import InventoryItemList from "./inventory-item-list"
import InventoryItemTotal from "./inventory-item-total"
import ListHeader from "ui-componets/list-header"
import { useLocation } from "react-router-dom"

const InventoriesList = () => {
  document.title = "Lista de inventarios | Punto de venta"

  const Location = useLocation()
  const [checkboxCost, setCheckboxCost] = useState(true)
  const [checkboxInventoryZero, setCheckboxInventoryZero] = useState(false)
  const [filters, setFilters] = useState("")
  const [searchTxt, setSearchTxt] = useState(new URLSearchParams(Location.search).get("search") ?? "")
  const stores = useQuery(["stores"], () => getAllStores())
  const inventories = useQuery(["inventories", filters, filters.toString()], () =>
    getAllProductsPresentationsInventories(filters)
  )
  const filteredInventories = useRef([])
  filteredInventories.current =
    inventories?.data?.results?.filter((item) => item.total && item.total.quantity !== 0) || []

  const permissions = storage.get(authUserKey).user_permissions || []
  const styleBorderedColumns = {
    borderLeft: "1px solid #b1b4b8",
    borderRight: "1px solid #b1b4b8",
  }
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle", ...styleBorderedColumns  }
  const styleCenterTotalTable = { textAlign: "center", verticalAlign: "middle" }
  const styleBarCodeTable = { textAlign: "right", verticalAlign: "middle", width: "10rem" }
  const styleNameTable = { textAlign: "left", verticalAlign: "middle", width: "20rem" }
  const styleBrandTable = { textAlign: "left", verticalAlign: "middle", width: "11rem" }
  const styleLeftTable = { textAlign: "left", verticalAlign: "middle", width: "3rem", paddingLeft: "1rem" }
  const styleQuantityTable = { textAlign: "left", verticalAlign: "middle", width: "2.5rem", paddingLeft: "1.5rem" }
  const styleTotalTable = { textAlign: "center", verticalAlign: "middle", width: "30rem" }

  const handleCheckboxCost = () => {
    setCheckboxCost(!checkboxCost)
  }

  const handleCheckboxInventoryZero = () => {
    setCheckboxInventoryZero(!checkboxInventoryZero)
  }

  const handleSearch = (pageParams) => {
    const params = new URLSearchParams(filters)
    if (pageParams) {
      const newParams = new URLSearchParams(pageParams)
      window.history.replaceState({}, null, `${Location.pathname}/inventories?${newParams}`)
      setFilters(newParams)
    } else if (searchTxt) {
      params.set("search", searchTxt)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}/inventories?${params}`)
      setFilters(params)
    } else {
      if (params.get("search")) {
        params.delete("offset")
        params.delete("per_page")
        params.delete("search")
        window.history.replaceState({}, null, `${Location.pathname}/inventories?${params}`)
        setFilters(params)
      }
    }
  }

  const debouncedSearch = useCallback(
    debounce((text) => {
      const params = new URLSearchParams()
      params.set("search", text)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}/inventories?${params}`)
      setFilters(params)
    }, 300),
    []
  )

  function handleChangeSearch(event) {
    setSearchTxt(event.target.value)
    debouncedSearch(event.target.value)
  }

  useEffect(() => {
    const newInventories = []
    if (!checkboxInventoryZero) {
      inventories?.data?.results?.forEach(function (item) {
        if (item.total && item.total.quantity !== 0) {
          newInventories.push(item)
        }
      })
    }
    filteredInventories.current = newInventories
  }, [checkboxInventoryZero, inventories?.data?.results])

  const isLoading = stores.isLoading

  return !permissions.some((e) => e.codename === "view_store") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="mt-3">
      <Container fluid>
        <Alert color="danger" isOpen={stores.isError}>
          {stores?.error?.errors?.map((error) => error?.message)}
        </Alert>
        <h2 className="mb-2">Inventarios</h2>
        <div className="bg-white p-3 pt-0">
          <ListHeader handleOnChangeSearch={handleChangeSearch} handleSearch={handleSearch} searchTxt={searchTxt} />
          {!stores.error && isLoading ? (
            <div className="my-5 text-center">
              <Loading isLoading={isLoading} size={2} />
            </div>
          ) : stores.error && !isLoading ? (
            <div className="my-5 text-center">
              <h1>Error intenta mas tarde</h1>
            </div>
          ) : (
            <div className="table-responsive">
              <div className="justify-content-end" style={{ display: "flex", alignItems: "flex-end" }}>
                <Input className="mb-1" type="checkbox" checked={checkboxCost} onChange={handleCheckboxCost} />{" "}
                <Label style={{ paddingLeft: "0.5rem" }} check>
                  Mostrar Costo
                </Label>
              </div>
              <div className="justify-content-end" style={{ display: "flex", alignItems: "flex-end" }}>
                <Input
                  className="mb-1"
                  type="checkbox"
                  checked={checkboxInventoryZero}
                  onChange={handleCheckboxInventoryZero}
                />{" "}
                <Label style={{ paddingLeft: "0.5rem" }} check>
                  Mostrar producto con inventorio en cero
                </Label>
              </div>

              <Table hover responsive>
                <thead>
                  <tr className="table-secondary">
                    <th style={styleBarCodeTable}>Codigo de barras</th>
                    <th style={styleNameTable}>Nombre del producto</th>
                    <th style={styleBrandTable}>Marca</th>
                    {stores.data.map((store) => (
                      <th key={store.id} style={styleCenterTable}>
                        {store.name}
                        <Table className="mt-1 mb-0">
                          <thead>
                            {checkboxCost ? (
                              <>
                                <th style={styleQuantityTable}>Cantidad</th>
                                <th style={styleLeftTable}>Dinero</th>
                              </>
                            ) : (
                              <th style={styleCenterTotalTable}>Cantidad</th>
                            )}
                          </thead>
                        </Table>
                      </th>
                    ))}
                    <th style={styleCenterTotalTable}>
                      Total
                      <Table className="mt-1 mb-0">
                        <thead>
                          {checkboxCost ? (
                            <>
                              <th style={styleQuantityTable}>Cantidad</th>
                              <th style={styleLeftTable}>Dinero</th>
                            </>
                          ) : (
                            <th style={styleCenterTotalTable}>Cantidad</th>
                          )}
                        </thead>
                      </Table>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {checkboxInventoryZero ? (
                    inventories?.data?.results?.length > 0 ? (
                      inventories?.data?.results?.map((item, index) => {
                        return (
                          <InventoryItemList
                            key={item.id}
                            item={item}
                            name={item.name}
                            stores={stores.data}
                            checkboxCost={checkboxCost}
                            checkboxInventoryZero={checkboxInventoryZero}
                          />
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No hay datos
                        </td>
                      </tr>
                    )
                  ) : filteredInventories?.current?.length > 0 ? (
                    filteredInventories?.current?.map((item, index) => {
                      return (
                        <InventoryItemList
                          key={item.id}
                          item={item}
                          name={item.name}
                          stores={stores.data}
                          checkboxCost={checkboxCost}
                          checkboxInventoryZero={checkboxInventoryZero}
                        />
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No hay datos
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <div className="text-center">
                {(inventories.data?.next || inventories.data?.previous) && (
                  <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                    {inventories.data?.previous && (
                      <PaginationItem>
                        <PaginationLink
                          previous
                          onClick={() => handleSearch(inventories.data?.previous.split("?")[1])}
                        />
                      </PaginationItem>
                    )}
                    {inventories.data?.next && (
                      <PaginationItem>
                        <PaginationLink next onClick={() => handleSearch(inventories.data?.next.split("?")[1])} />
                      </PaginationItem>
                    )}
                  </Pagination>
                )}
              </div>

              {checkboxCost && (
                <div className="table-responsive">
                  <Table hover responsive>
                    <thead>
                      <tr className="table-secondary">
                        <th style={styleTotalTable}></th>
                        {stores.data.map((store) => (
                          <th key={store.id} style={styleCenterTable}>
                            {store.name}
                            <Table className="mt-1 mb-0">
                              <thead>
                                {checkboxCost ? (
                                  <>
                                    <th style={styleQuantityTable}>Cantidad</th>
                                    <th style={styleLeftTable}>Dinero</th>
                                  </>
                                ) : (
                                  <th style={styleCenterTable}>Cantidad</th>
                                )}
                              </thead>
                            </Table>
                          </th>
                        ))}
                        <th style={styleCenterTotalTable}>
                          Total
                          <Table className="mt-1 mb-0">
                            <thead>
                              {checkboxCost ? (
                                <>
                                  <th style={styleQuantityTable}>Cantidad</th>
                                  <th style={styleLeftTable}>Dinero</th>
                                </>
                              ) : (
                                <th style={styleCenterTable}>Cantidad</th>
                              )}
                            </thead>
                          </Table>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <InventoryItemTotal
                        inventories={inventories?.data?.results}
                        stores={stores.data}
                      />
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

export default InventoriesList
