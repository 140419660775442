import { syncCustomersOffline, syncPricesOffline, syncProductsOffline } from "services/offlineFiles"
import { getBusinessInfo } from "services/business-info"

let ipcRenderer = null
if (window.electron && window.electron.ipcRenderer) {
  ipcRenderer = window.electron.ipcRenderer
}

export const readFile = async (filePath) => {
  try {
    const content = await ipcRenderer.invoke("readFile", filePath)
    // Puedes usar el contenido devuelto por ipcRenderer.invoke como lo necesites
    return content
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

export const writeFile = async (filePath, contentToWrite) => {
  try {
    await ipcRenderer.invoke("writeFile", filePath, contentToWrite)
    // Puedes usar el contenido devuelto por ipcRenderer.invoke como lo necesites
  } catch (error) {
    console.error("Error:", error)
    throw error
  }
}

function processReceivedData(receivedData, items) {
  if (!items) {
    return receivedData
  }
  receivedData.forEach((actionItem) => {
    const { id, action, data } = actionItem
    const item = items.find((item) => item.id === id)

    switch (action) {
      case "updated":
        if (item) {
          Object.assign(item, data)
        }
        break

      case "insert":
        if (!item) {
          items.push({ id, ...data })
        }
        break

      case "delete":
        // eslint-disable-next-line
        const itemIndex = items.findIndex((item) => item.id === id)
        if (itemIndex !== -1) {
          items.splice(itemIndex, 1)
        }
        break

      /* case "delete":
        items = items.filter((item) => item.id !== id)
        console.log(`Cliente con ID ${id} eliminado`)
        break */

      default:
        break
    }
  })
  return items
}

export const syncCustomers = async () => {
  let customers = await readFile("./customers.json")
  let localData = ""
  let receivedData = ""
  if (customers !== null) {
    customers = JSON.parse(customers)
    localData = customers.map((item) => ({
      id: item.id,
      modified_at: item.modified_at,
    }))
    receivedData = await syncCustomersOffline({ elements: localData })
  } else {
    receivedData = await syncCustomersOffline({ elements: [] })
  }
  customers = processReceivedData(receivedData, customers)

  // Escribir en el archivo customers.json los cambios que se haya hecho en customers
  writeFile("./customers.json", JSON.stringify(customers, null, 2))
}

export const syncPrices = async () => {
  let prices = await readFile("./prices.json")
  let localData = ""
  let receivedData = ""
  if (prices !== null) {
    prices = JSON.parse(prices)
    localData = prices.map((item) => ({
      id: item.id,
      modified_at: item.modified_at,
    }))
    receivedData = await syncPricesOffline({ elements: localData })
  } else {
    receivedData = await syncPricesOffline({ elements: [] })
  }
  prices = processReceivedData(receivedData, prices)

  // Escribir en el archivo prices.json los cambios que se haya hecho en prices
  writeFile("./prices.json", JSON.stringify(prices, null, 2))
}

export const syncProducts = async () => {
  let products = await readFile("./products.json")
  let localData = ""
  let receivedData = ""
  if (products !== null) {
    products = JSON.parse(products)
    localData = products.map((item) => ({
      id: item.id,
      modified_at: item.modified_at,
    }))
    receivedData = await syncProductsOffline({ elements: localData })
  } else {
    receivedData = await syncProductsOffline({ elements: [] })
  }
  products = processReceivedData(receivedData, products)

  // Escribir en el archivo products.json los cambios que se haya hecho en products
  writeFile("./products.json", JSON.stringify(products, null, 2))
}

export const syncUser = (user) => {
  writeFile("./user.json", JSON.stringify(user, null, 2))
}

export const syncBussinessInfo = async () => {
  const bussinesInfo = await getBusinessInfo()
  const images = bussinesInfo.results[0]?.image?.medium_thumbnail
  writeFile("./business-info.json", JSON.stringify(bussinesInfo, null, 2))
  try {
    const downloadedImagePaths = await ipcRenderer.invoke("download-image", images)
    console.log("Images downloaded and saved:", downloadedImagePaths)
  } catch (error) {
    console.error("Error downloading images:", error.message) // Agrega .message para mostrar el mensaje del error
  }
}

export const createTicketOffline = async (ticket) => {
  let tickets = await readFile("./ticketsOffline.json")
  if (tickets === null || tickets === "") {
    tickets = [ticket]
  } else {
    tickets = JSON.parse(tickets)
    tickets.push(ticket)
  }
  writeFile("./ticketsOffline.json", JSON.stringify(tickets, null, 2))
}
