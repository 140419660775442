import { useRef } from "react"
import { Alert, Button, Container } from "reactstrap"
import { useQuery } from "@tanstack/react-query"
import { getTicket } from "services/tickets"
import { getBusinessInfo } from "services/business-info"
import { Loading } from "components"
import storage from "services/storage"
import ReactToPrint from "react-to-print"
import { authUserKey } from "config/API"
import { RenderTicketContent } from "components/sales"

const TicketRetrive = (id) => {
  const { data, error, isLoading, isError } = useQuery(["invoice", id], () => getTicket(id.id))
  const businessInfo = useQuery(["businessInfo"], () => getBusinessInfo())
  const permissions = storage.get(authUserKey).user_permissions || []
  const componentRef = useRef()

  const renderError = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={isError}>
          {error.errors.map((error) => error.message)}
        </Alert>
      </div>
    )
  }

  return !permissions.some((e) => e.codename === "view_ticket") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="page-content" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
      <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <div className="bg-white">
          {isError ? (
            renderError()
          ) : isLoading ? (
            <div className="text-center">
              <Loading isLoading={isLoading} size={2} />
            </div>
          ) : (
            <>
              <div style={{ marginTop: "-70px" }} ref={componentRef}>
                <RenderTicketContent data={data} businessInfo={businessInfo} />
              </div>
              <div className="d-flex justify-content-end">
                <ReactToPrint
                  trigger={() => (
                    <Button color="primary" style={{ marginRight: "1rem" }}>
                      Imprimir
                    </Button>
                  )}
                  content={() => componentRef.current}
                />
                {/*  <Link to={"/sales"}>
                  <Button color="secondary" style={{ marginLeft: "10px" }}>
                    Regresar
                  </Button>
                </Link> */}
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  )
}

export default TicketRetrive
