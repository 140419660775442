import { useState, useEffect } from "react"
import propTypes from "prop-types"
import { Formik } from "formik"
import { Alert, Button, Col, Form, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap"

import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"
import schema from "./schema"

const PeriodicTaskForm = ({ submit, data, toggle, isLoading, isError, error }) => {
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])

  const initialValues = {
    limit: currencyFormatter({ value: data.limit }) ?? "",
  }

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  function currencyFormatter({ value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return formatter.format(value)
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={submit}>
      {({ values, handleChange, handleSubmit, handleBlur, touched, errors, setFieldValue }) => (
        <Form className="form-horizontal" onSubmit={handleSubmit}>
          <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
            {isError && error?.errors?.map((error) => error?.message)}
          </Alert>
          <Row className="mb-3">
            <Col>
              <Label className="form-label">
                Límite <RequiredIcon />
              </Label>
              <InputGroup>
              <InputGroupText>$</InputGroupText>
                <Input
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="limit"
                  onBlur={(event) => {
                    const inputValue = event.target.value.replace(/,/g, "")
                    const formattedValue = currencyFormatter({ value: inputValue })
                    setFieldValue("limit", formattedValue)
                    handleBlur(event)
                  }}
                  onChange={handleChange}
                  value={values.limit}
                  invalid={
                    !!(touched.limit && errors.limit) ||
                    (isError && validationErrors.findIndex((e) => e.field === "limit") >= 0)
                  }
                />
              </InputGroup>
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="limit"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
          </Row>

          <hr />

          <Row>
            <Col className="d-flex justify-content-end">
              <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                <i className="fa fa-times" /> Cerrar
              </Button>
              <Button color="success" type="submit" disabled={isLoading}>
                <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

PeriodicTaskForm.defaultProps = {
  data: null,
}

PeriodicTaskForm.propTypes = {
  isCreate: propTypes.bool,
  submit: propTypes.func,
  toggle: propTypes.func,
  data: propTypes.any,
  isLoading: propTypes.bool,
  isError: propTypes.bool,
  error: propTypes.any,
}

export default PeriodicTaskForm
