import React from "react"
import propTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { Row, Col, CardBody, Card, Container } from "reactstrap"

import ForgotForm from "./ForgotForm"
import { loginImg, logo } from "assets/images"

const ForgotPassword = ({ history }) => {
  document.title = "Recuperar mi contraseña | Punto de venta"

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-dark">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-white">Recuperar mi contraseña</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={loginImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="Punto de venta" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <ForgotForm history={history} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ForgotPassword)

ForgotPassword.propTypes = {
  history: propTypes.object,
}
