import propTypes from "prop-types"
import { useEffect } from "react"
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"

import { Loading } from "components"
import { editInventory, getInventory } from "services/inventories"
import InventoryForm from "./InventoryForm"
import { API } from "config/API"

const EditModalForm = ({ 
  isOpen, 
  toggle, 
  inventoryId, 
  setShowAlertSuccess, 
  setMsgSuccess, 
  inventories, 
  refetchInventory, 
  storeId,
  inventoriesRegistered
}) => {
  const { 
    data, 
    error, 
    isLoading, 
    isError, 
    refetch 
  } = useQuery(["inventories", inventoryId], () => getInventory(inventoryId))

  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["inventories", inventoryId], (data) => editInventory(inventoryId, data))

  const submit = async (data) => {
    const dataTemp = { ...data }
    dataTemp.product = dataTemp.product ? dataTemp.product.id : null
    await mutateAsync({ ...dataTemp })
  }

  const handleSearch = async () => {
    refetchInventory(inventories.length = 0)
    const response = await API.get(
      `/stores-inventories?ordering=quantity&product__name=&store__id=${storeId}`
    )
    refetchInventory(inventories.concat(response.results))
  }

  useEffect(() => {
    if (isSuccessEdit) {
      setMsgSuccess("Inventario editado satisfactoriamente")
      handleSearch()
      setShowAlertSuccess(true)
      toggle()
    }
  }, [isSuccessEdit])

  useEffect(() => {
    refetch()
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Editar inventario
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className="text-center my-3">
            <Loading isLoading={isLoading} size={2} />
          </div>
        ) : isError ? (
          <div className="text-center my-3">
            <Alert color="danger">{error?.errors?.map((error) => error?.message)}</Alert>
          </div>
        ) : (
          <InventoryForm
            data={data}
            submit={submit}
            toggle={toggle}
            isLoading={isLoadingEdit || isLoading}
            isError={isErrorEdit}
            error={errorEdit}
            isEdit={true}
            inventoriesRegistered={inventoriesRegistered}
          />
        )}
      </ModalBody>
    </Modal>
  )
}

EditModalForm.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  inventoryId: propTypes.string,
  setShowAlertSuccess: propTypes.func.isRequired,
  setMsgSuccess: propTypes.func.isRequired,
  inventories: propTypes.any,
  refetchInventory: propTypes.func,
  storeId: propTypes.number,
  inventoriesRegistered: propTypes.array,
}

export default EditModalForm
