import { API } from "config/API"

export const getPrices = async (filters) => {
  const response = await API.get("/products-prices", { params: filters })
  return response
}

export const getPrice = async (id) => {
  const response = await API.get(`/products-prices/${id}`)
  return response
}

export const createPrices = async (payload) => {
  const response = await API.post("/products-prices", payload)
  return response
}

export const editPrices = async (id, payload) => {
  const response = await API.patch(`/products-prices/${id}`, payload)
  return response
}

export const deletePrice = async (id) => {
  const response = await API.delete(`/products-prices/${id}`)
  return response
}

export const searchPrices = async (query) => {
  const response = await API.get(`/products-prices?search=${query}`)
  return response
}
