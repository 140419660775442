import propTypes from "prop-types"
import { useEffect } from "react"
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"

import { Loading } from "components"
import { editStore, getStore } from "services/stores"
import StoresForm from "./StoresForm"

const EditModalForm = ({ isOpen, toggle, storeId, setShowAlertSuccess, setMsgSuccess }) => {
  const { data, error, isLoading, isError, refetch } = useQuery(["stores", storeId], () => getStore(storeId))

  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["stores", storeId], (data) => editStore(storeId, data))

  const submit = async (data) => {
    await mutateAsync(data)
  }

  useEffect(() => {
    if (isSuccessEdit) {
      setMsgSuccess("Inventario editado satisfactoriamente")
      setShowAlertSuccess(true)
      toggle()
    }
  }, [isSuccessEdit])

  useEffect(() => {
    refetch()
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Editar Inventario
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className="text-center my-3">
            <Loading isLoading={isLoading} size={2} />
          </div>
        ) : isError ? (
          <div className="text-center my-3">
            <Alert color="danger">{error?.errors?.map((error) => error?.message)}</Alert>
          </div>
        ) : (
          <StoresForm
            data={data}
            submit={submit}
            toggle={toggle}
            isLoading={isLoadingEdit || isLoading}
            isError={isErrorEdit}
            error={errorEdit}
          />
        )}
      </ModalBody>
    </Modal>
  )
}

EditModalForm.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  storeId: propTypes.number,
  setShowAlertSuccess: propTypes.func.isRequired,
  setMsgSuccess: propTypes.func.isRequired,
}

export default EditModalForm
