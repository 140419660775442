import React, { useState } from "react"
import propTypes from "prop-types"
import { Col, Row, Input, Button, Tooltip } from "reactstrap"

const ListHeader = (props) => {
  const {
    canAdd,
    downloadTemplate,
    handleOnChangeSearch,
    handleSearch,
    searchTxt,
    toggleModalCreate,
    toggleModalImport,
    isComingSoonButton,
  } = props

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen(!tooltipOpen)

  const renderButtonCreate = () => {
    if (!toggleModalCreate) {
      return null
    }
    return (
      <Button color="success" className="d-flex align-items-center me-2" onClick={toggleModalCreate}>
        <i className="me-2 fa fa-plus" />
        <span className="text-truncate">Agregar</span>
      </Button>
    )
  }

  const renderButtonCreateComingSoon = () => {
    if (!toggleModalCreate) {
      return null
    }
    return (
      <>
        <Button color="secondary" className="d-flex align-items-center me-2" id={"tooltip"}>
          <i className="me-2 fa fa-plus" />
          <span className="text-truncate">Agregar</span>
        </Button>
        <Tooltip
          hideArrow={true}
          innerClassName="mb-1"
          style={{ backgroundColor: "#74788d" }}
          placement={"top"}
          isOpen={tooltipOpen}
          target={"tooltip"}
          toggle={toggle}
        >
          <span className="text-truncate">Próximamente</span>
        </Tooltip>
      </>
    )
  }

  const renderButtonImport = () => {
    if (!toggleModalImport) {
      return null
    }
    return (
      <Button color="primary" className="d-flex align-items-center me-2" onClick={toggleModalImport}>
        <i className="me-2 me-lg-2 me-md-0 fa fa-file-import" />
        <span className="d-md-none d-lg-block text-truncate">Importar</span>
      </Button>
    )
  }

  const renderButtonDownloadTemplate = () => {
    if (!downloadTemplate) {
      return null
    }
    return (
      <Button className="d-flex align-items-center" onClick={downloadTemplate}>
        <i className="me-2 me-lg-2 me-md-0 fa fa-download" />
        <span className="d-md-none d-lg-block text-truncate">Descargar plantilla</span>
      </Button>
    )
  }

  return (
    <Row>
      <Col md="6">
        <Row>
          <Col xs="9">
            <Input placeholder="Buscar" value={searchTxt} onChange={handleOnChangeSearch} />
          </Col>
          <Col xs="3" className="">
            <Button color="info" className="d-flex align-items-center" onClick={() => handleSearch()}>
              <i className="me-2 fa fa-search" />
              <span className="text-truncate">Buscar</span>
            </Button>
          </Col>
        </Row>
      </Col>
      <Col md="6" className="d-inline-flex justify-content-sm-start justify-content-md-end mt-md-0 mt-2">
        {canAdd && (
          <>
            {isComingSoonButton ? renderButtonCreateComingSoon() : renderButtonCreate()}
            {renderButtonImport()}
            {renderButtonDownloadTemplate()}
          </>
        )}
      </Col>
    </Row>
  )
}

ListHeader.propTypes = {
  onCloseClick: propTypes.func,
  onDeleteClick: propTypes.func,
  show: propTypes.any,
  canAdd: propTypes.bool,
  downloadTemplate: propTypes.func,
  handleOnChangeSearch: propTypes.func,
  handleSearch: propTypes.func,
  searchTxt: propTypes.string,
  toggleModalCreate: propTypes.func,
  toggleModalImport: propTypes.func,
  isComingSoonButton: propTypes.bool,
}

export default ListHeader
