import { API } from "config/API"

export const getAllBrands = async (filters) => {
  const response = await API.get("/brands/all?ordering=name", { params: filters })
  return response
}

export const getBrands = async (filters) => {
  const response = await API.get("/brands?ordering=name", { params: filters })
  return response
}

export const getBrand = async (id) => {
  const response = await API.get(`/brands/${id}`)
  return response
}

export const createBrand = async (payload) => {
  const response = await API.post("/brands", payload)
  return response
}

export const editBrand = async (id, payload) => {
  const response = await API.patch(`/brands/${id}`, payload)
  return response
}

export const deleteBrand = async (id) => {
  const response = await API.delete(`/brands/${id}`)
  return response
}
