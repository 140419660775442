import { useState, useEffect } from "react"
import propTypes from "prop-types"
import { Alert, Button, Col, Form, Input, Label, Row } from "reactstrap"
import { Link } from "react-router-dom"
import { Formik } from "formik"

import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"
import schema from "./schema"
import "assets/scss/custom/pages/providers.scss"
import { API } from "config/API"

const BusinessInfoForm = (props) => {
  const { data, disabledForm, error, isError, isLoading, isRetrivePage, submit, toggle } = props
  const [shownErrorAlert, setShownErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const [image, setImage] = useState("")
  const business = data?.results[0]
  const apiUrl = ["https://api.pos.emkode.org", "https://api-dulceria.emkode.com", "https://api-plasticos.emkode.com"]

  const initialValues = {
    name: business?.name ?? "",
    street: business?.street ?? "",
    neighborhood: business?.neighborhood ?? "",
    phone_number: business?.phone_number ?? "",
    zip_code: business?.zip_code ?? "",
    fiscal_address: business?.fiscal_address ?? "",
    fiscal_zipcode: business?.fiscal_zipcode ?? "",
    business_name: business?.business_name ?? "",
    rfc: business?.rfc ?? "",
    image: undefined,
  }

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShownErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShownErrorAlert(false)
    }
  }, [isError])

  useEffect(()=>{
    if (apiUrl.includes(API.defaults.baseURL)) {
      const imageArray = business?.image?.medium_thumbnail?.split("media")
      const imageString = imageArray[0] + ".media" + imageArray[1]
      setImage(imageString)
    } else {
      setImage(business?.image?.medium_thumbnail)
    }
  }, [business])

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={submit}>
      {({ values, handleChange, handleSubmit, handleBlur, setFieldValue, touched, errors }) => (
        <Form className="form-horizontal" onSubmit={handleSubmit} encType="multipart/form-data">
          <Alert color="danger" isOpen={shownErrorAlert} toggle={() => setShownErrorAlert(false)}>
            {isError && error?.errors?.map((error) => error?.message)}
          </Alert>
          <Row className="mb-3">
            <Col>
              <Label className="form-label">
                Nombre del negocio <RequiredIcon />
              </Label>
              <Input
                autoComplete="off"
                className="form-control"
                name="name"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                invalid={
                  !!(touched.name && errors.name) ||
                  (isError && validationErrors.findIndex((e) => e.field === "name") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="name"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
          </Row>
          <Label className="form-label" style={{ display: "block", textAlign: "center" }}>
            {" "}
            -Dirección-{" "}
          </Label>
          <Row className="mb-3">
            <Col>
              <Label className="form-label">Calle y número</Label>
              <Input
                autoComplete="off"
                className="form-control"
                name="street"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.street}
                invalid={
                  !!(touched.street && errors.street) ||
                  (isError && validationErrors.findIndex((e) => e.field === "street") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="street"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
            <Col>
              <Label className="form-label">
                Código postal <RequiredIcon />
              </Label>
              <Input
                autoComplete="off"
                className="form-control"
                type="number"
                maxLength={10}
                name="zip_code"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.zip_code}
                invalid={
                  !!(touched.zip_code && errors.zip_code) ||
                  (isError && validationErrors.findIndex((e) => e.field === "zip_code") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="zip_code"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Label className="form-label">Colonia</Label>
              <Input
                autoComplete="off"
                className="form-control"
                name="neighborhood"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.neighborhood}
                invalid={
                  !!(touched.neighborhood && errors.neighborhood) ||
                  (isError && validationErrors.findIndex((e) => e.field === "neighborhood") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="neighborhood"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
            <Col>
              <Label className="form-label">Número telefónico</Label>
              <Input
                autoComplete="off"
                className="form-control"
                name="phone_number"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone_number}
                invalid={
                  !!(touched.phone_number && errors.phone_number) ||
                  (isError && validationErrors.findIndex((e) => e.field === "phone_number") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="phone_number"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
          </Row>
          <Label className="form-label" style={{ display: "block", textAlign: "center" }}>
            {" "}
            -Datos fiscales-{" "}
          </Label>
          <Row className="mb-3">
            <Col>
              <Label className="form-label">
                Razón social <RequiredIcon />
              </Label>
              <Input
                autoComplete="off"
                className="form-control"
                name="business_name"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.business_name}
                invalid={
                  !!(touched.business_name && errors.business_name) ||
                  (isError && validationErrors.findIndex((e) => e.field === "business_name") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="business_name"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
            <Col>
              <Label className="form-label">
                RFC <RequiredIcon />
              </Label>
              <Input
                autoComplete="off"
                className="form-control"
                maxLength={13}
                name="rfc"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.rfc?.toUpperCase()}
                invalid={
                  !!(touched.rfc && errors.rfc) ||
                  (isError && validationErrors.findIndex((e) => e.field === "rfc") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="rfc"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Label className="form-label">Dirección fiscal</Label>
              <Input
                autoComplete="off"
                className="form-control"
                name="fiscal_address"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fiscal_address}
                invalid={
                  !!(touched.fiscal_address && errors.fiscal_address) ||
                  (isError && validationErrors.findIndex((e) => e.field === "fiscal_address") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="fiscal_address"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
            <Col>
              <Label className="form-label">
                Código postal fiscal <RequiredIcon />
              </Label>
              <Input
                autoComplete="off"
                className="form-control"
                type="number"
                maxLength={10}
                name="fiscal_zipcode"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fiscal_zipcode}
                invalid={
                  !!(touched.fiscal_zipcode && errors.fiscal_zipcode) ||
                  (isError && validationErrors.findIndex((e) => e.field === "fiscal_zipcode") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="fiscal_zipcode"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
          </Row>
          <Label className="form-label" style={{ display: "block", textAlign: "center" }}>
            {" "}
            -Imagen-{" "}
          </Label>
          <Row className="mb-3">
            <Col style={{ display: "flex", alignContent: "center" }}>
              <Label className="form-label">Imagen actual</Label>
              <Label className="form-label">
                <br />
                <img
                  src={`${image}?w=248&fit=crop&auto=format`}
                  srcSet={`${image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt="Logo de la empresa"
                  loading="lazy"
                />
              </Label>
            </Col>
            <Col>
              <Label className="form-label">Actualizar imagen de la empresa</Label>
              <Input
                autoComplete="off"
                className="form-control"
                name="image"
                type="file"
                accept=".jpg, .jpeg, .png"
                disabled={disabledForm}
                onBlur={handleBlur}
                onChange={(event) => {
                  setFieldValue("image", event.currentTarget.files[0])
                }}
                invalid={
                  !!(touched.image && errors.image) ||
                  (isError && validationErrors.findIndex((e) => e.field === "image") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="image"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
          </Row>

          <hr />

          <Row>
            <Col className="d-flex justify-content-end">
              {isRetrivePage ? (
                <Link to={{ pathname: "/business-info" }}>
                  <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                    <i className="fa fa-times" /> Salir
                  </Button>
                </Link>
              ) : (
                <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                  <i className="fa fa-times" /> Cerrar
                </Button>
              )}
              {!disabledForm && (
                <Button color="success" type="submit" disabled={isLoading}>
                  <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

BusinessInfoForm.defaultProps = {
  data: null,
  disabledForm: false,
  isRetrivePage: false,
}

BusinessInfoForm.propTypes = {
  data: propTypes.any,
  disabledForm: propTypes.bool,
  error: propTypes.any,
  isCreate: propTypes.bool,
  isError: propTypes.bool,
  isLoading: propTypes.bool,
  isRetrivePage: propTypes.bool,
  submit: propTypes.func,
  toggle: propTypes.func,
}

export default BusinessInfoForm
