import { useState, useEffect } from "react"
import propTypes from "prop-types"
import { Formik } from "formik"
import { Button, Form, Input, Label, Alert, Row, Col } from "reactstrap"

import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"
import schema from "./schema"

const AgentForm = ({ submit, data, toggle, isLoading, isError, error }) => {
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          email: data?.email ?? "",
          name: data?.name ?? "",
          phone: data?.phone ?? "",
        }}
        validationSchema={schema}
        onSubmit={submit}
      >
        {({ values, setFieldValue, handleChange, handleSubmit, handleBlur, touched, errors, setTouched }) => (
          <Form className="form-horizontal" onSubmit={handleSubmit}>
            <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
              {isError && error?.errors?.map((error) => error?.message)}
            </Alert>

            <Row className="mb-3">
              <Col>
                <Label className="form-label">
                  Nombre <RequiredIcon />
                </Label>
                <Input
                  autoComplete="do-not-autofill"
                  className="form-control"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="name"
                  value={values.name}
                  invalid={
                    !!(touched.name && errors.name) ||
                    (isError && validationErrors.findIndex((e) => e.field === "name") >= 0)
                  }
                />
                <ErrorsText
                  errorsBack={error}
                  errorsForm={errors}
                  formName="name"
                  isErrorBack={isError}
                  touched={touched}
                />
              </Col>
              <Col>
                <Label className="form-label">
                  Teléfono <RequiredIcon />
                </Label>
                <Input
                  autoComplete="do-not-autofill"
                  className="form-control"
                  maxLength={10}
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="phone"
                  value={values.phone}
                  invalid={
                    !!(touched.phone && errors.phone) ||
                    (isError && validationErrors.findIndex((e) => e.field === "phone") >= 0)
                  }
                />
                <ErrorsText
                  errorsBack={error}
                  errorsForm={errors}
                  formName="phone"
                  isErrorBack={isError}
                  touched={touched}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Label className="form-label">Email</Label>
                <Input
                  autoComplete="do-not-autofill"
                  className="form-control"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  invalid={
                    !!(touched.email && errors.email) ||
                    (isError && validationErrors.findIndex((e) => e.field === "email") >= 0)
                  }
                />
                <ErrorsText
                  errorsBack={error}
                  errorsForm={errors}
                  formName="email"
                  isErrorBack={isError}
                  touched={touched}
                />
              </Col>
            </Row>

            <Row>
              <Col className="d-flex justify-content-end">
                <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                  <i className="fa fa-times" /> Cancelar
                </Button>
                <Button color="success" type="submit" disabled={isLoading}>
                  <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

AgentForm.defaultProps = {
  data: null,
}

AgentForm.propTypes = {
  data: propTypes.object,
  disabledForm: propTypes.bool,
  error: propTypes.any,
  isCreate: propTypes.bool,
  isError: propTypes.bool,
  isLoading: propTypes.bool,
  submit: propTypes.func,
  toggle: propTypes.func,
}

export default AgentForm
