import React, { useEffect, useState } from "react"
import propTypes from "prop-types"
import { Alert, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"

import { Loading } from "components"
import { getProduct, editProduct } from "services/products"
import { getAllPrices } from "services/prices"
import ProductsForm from "./ProductsForm"
import { ProductsPresentation } from "pages/Products"

const EditModalForm = ({ isOpen, toggle, productId, setShowAlertSuccess, setMsgSuccess }) => {
  const prices = useQuery(["prices"], () => getAllPrices())
  const { data, error, isLoading, isError, refetch } = useQuery(["product", productId], () => getProduct(productId))
  const [state, setState] = useState({ activeTab: "product" })

  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["product", productId], (data) => editProduct(productId, data))

  const submit = async (data) => {
    const dataTemp = { ...data }
    dataTemp.prices = dataTemp.prices
      ? dataTemp.prices?.map((price) => ({
          id: price.id,
          price: price.price.id,
          amount: parseFloat(price.amount.replace(/,/g, "")),
          quantity: price.quantity,
          utility: price.utility,
        }))
      : null
    dataTemp.providers = dataTemp.providers ? dataTemp.providers?.map((provider) => provider.id) : null
    dataTemp.brand = dataTemp?.brand?.value || null
    dataTemp.cost = parseFloat(dataTemp.cost.replace(/,/g, "")) || null
    dataTemp.unit_measurement = dataTemp?.unit_measurement?.value || null
    dataTemp.taxes = dataTemp.taxes ? dataTemp.taxes?.map((tax) => tax.id) : null
    await mutateAsync(dataTemp)
  }

  useEffect(() => {
    if (isSuccessEdit) {
      setMsgSuccess("Producto modificado satisfactoriamente")
      setShowAlertSuccess(true)
      refetch()
      toggle()
    }
  }, [isSuccessEdit])

  const toggleActiveTab = (tab) => {
    if (state.activeTab !== tab) {
      setState({
        activeTab: tab,
      })
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="xl" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Editar Producto
      </ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={`${state.activeTab === "product" && "active"}`}
              onClick={() => toggleActiveTab("product")}
            >
              Producto
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${state.activeTab === "productPresentation" && "active"}`}
              onClick={() => toggleActiveTab("productPresentation")}
            >
              Presentaciones de Producto
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={state.activeTab}>
          <TabPane tabId="product">
            {isLoading ? (
              <div className="text-center">
                <Loading isLoading={isLoading} size={2} />
              </div>
            ) : isError ? (
              <div className="text-center">
                <Alert color="danger" isOpen={isError}>
                  {error?.errors?.map((error) => error?.message)}
                </Alert>
              </div>
            ) : (
              <ProductsForm
                isRetrivePage={false}
                submit={submit}
                toggle={toggle}
                isLoading={isLoadingEdit}
                isError={isErrorEdit}
                error={errorEdit}
                data={data}
                refetchData={refetch}
                prices={prices}
              />
            )}
          </TabPane>
          <TabPane tabId="productPresentation">
            <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
            <ProductsPresentation idFromModal={data?.id} />
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  )
}

EditModalForm.defaultProps = {
  productId: null,
}

EditModalForm.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  productId: propTypes.string,
  refetch: propTypes.func,
  setShowAlertSuccess: propTypes.func,
  setMsgSuccess: propTypes.func,
}

export default EditModalForm
