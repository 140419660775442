import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Alert, Button, Container, Table } from "reactstrap"
import { useMutation, useQuery } from "@tanstack/react-query"
import Swal from "sweetalert2"

import storage from "services/storage"
import { authUserKey } from "config/API"
import { getPrices, deletePrice } from "services/prices"
import { Loading } from "components"
import { CreateModalForm, EditModalForm } from "components/prices"

const PriceList = () => {
  document.title = "Lista de precios | Punto de venta"
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }

  const Location = useLocation()

  const [filters, setFilters] = useState("")
  const [modalCreate, setmodalCreate] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)
  const [priceId, setPriceId] = useState(null)
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  const prices = useQuery(["prices", filters, filters.toString()], () => getPrices(filters))
  const customerDeleted = useMutation((id) => deletePrice(id))

  const permissions = storage.get(authUserKey).user_permissions || []

  const toggleModalCreate = () => {
    setmodalCreate(!modalCreate)
  }

  const toggleModalEdit = () => {
    if (modalEdit) {
      setPriceId("")
    }
    setmodalEdit(!modalEdit)
  }

  const openEditModal = (priceId) => {
    setPriceId(priceId)
    toggleModalEdit()
  }

  const isLoading = prices.isLoading || customerDeleted.isLoading

  useEffect(() => {
    setFilters(Location.search)
  }, [])

  useEffect(() => {
    if (showAlertSuccess) {
      Toast.fire({
        icon: "success",
        title: msgSuccess,
        didOpen: () => {
          prices.refetch()
          setShowAlertSuccess(false)
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "green",
      })
    }
  }, [showAlertSuccess])

  return !permissions.some((e) => e.codename === "view_price") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <React.Fragment>
      <div className="page-content">
        {permissions.some((e) => e.codename === "add_price") && (
          <CreateModalForm
            isOpen={modalCreate}
            toggle={toggleModalCreate}
            setShowAlertSuccess={setShowAlertSuccess}
            setMsgSuccess={setMsgSuccess}
          />
        )}
        {permissions.some((e) => e.codename === "change_price") && priceId && (
          <EditModalForm
            isOpen={modalEdit}
            toggle={toggleModalEdit}
            priceId={priceId}
            setShowAlertSuccess={setShowAlertSuccess}
            setMsgSuccess={setMsgSuccess}
          />
        )}
        <Container fluid>
          <Alert color="danger" isOpen={prices.isError}>
            {prices?.error?.errors?.map((error) => error?.message)}
          </Alert>

          <h2>Lista de Precios</h2>
          <div className="bg-white p-3">
            {!prices?.error && isLoading ? (
              <div className="my-5 text-center">
                <Loading isLoading={isLoading} size={2} />
              </div>
            ) : prices?.error && !isLoading ? (
              <div className="my-5 text-center">
                <h1>Error intenta mas tarde</h1>
              </div>
            ) : (
              <div className="table-responsive" style={{ marginTop: "30px" }}>
                <Table>
                  <thead>
                    <tr>
                      <th style={styleCenterTable}>ID</th>
                      <th style={styleCenterTable}>Nombre</th>
                      <th style={styleCenterTable}>Descripción</th>
                      <th style={styleCenterTable}>Estatus</th>
                      <th style={styleCenterTable} className="text-left">
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {prices?.data?.results?.map((price) => {
                      return (
                        <tr key={price?.id}>
                          <td style={styleCenterTable} className="w-25">
                            {price?.name}
                          </td>
                          <td style={styleCenterTable} className="w-25">
                            {price?.identifier}
                          </td>
                          <td style={styleCenterTable} className="w-40">
                            {price?.description
                              ? price?.description.length > 70
                                ? price?.description.slice(0, 70) + "..."
                                : price?.description
                              : ""}
                          </td>
                          <td style={styleCenterTable} className="w-25">
                            {price?.is_active === true ? <b>Activo</b> : <p>Inactivo</p>}
                          </td>
                          <td style={styleCenterTable} className="text-left">
                            {permissions.some((e) => e.codename === "change_price") && (
                              <Button color="primary" className="m-1" onClick={() => openEditModal(price?.id)}>
                                <i className="fa fa-edit" />
                              </Button>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PriceList
