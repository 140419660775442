import { API } from "config/API"

export const getAllPrices = async () => {
  const response = await API.get("/prices/all")
  return response
}

export const getPrices = async (filters) => {
  const response = await API.get("/prices?", { params: filters })
  return response
}

export const getPrice = async (id) => {
  const response = await API.get(`/prices/${id}`)
  return response
}

export const createPrice = async (payload) => {
  const response = await API.post("/prices", payload)
  return response
}

export const editPrice = async (id, payload) => {
  const response = await API.patch(`/prices/${id}`, payload)
  return response
}

export const deletePrice = async (id) => {
  const response = await API.delete(`/prices/${id}`)
  return response
}
