import React from "react"
import propTypes from "prop-types"
import { Table } from "reactstrap"

const InventoryItemTotal = ({ inventories, stores }) => {
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }
  const styleNameTable = { textAlign: "center", verticalAlign: "middle", width: "41rem" }
  const styleBorderedColumns = {
    borderLeft: "1px solid #b1b4b8",
    borderRight: "1px solid #b1b4b8",
  }

  const totals = {}

  inventories?.forEach((item) => {
    for (const category in item) {
      if (Object.prototype.hasOwnProperty.call(item, category) && typeof item[category] === "object") {
        if (!totals[category]) {
          totals[category] = {
            quantity: 0,
            inventoryCost: 0,
          }
        }
        totals[category].quantity += item[category].quantity
        totals[category].inventoryCost += item[category].inventoryCost
      }
    }
  })

  const totalValues = Object.values(totals)

  function currencyFormatterMoney({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }

  function currencyFormatterQuantity({ value }) {
    const formatter = new Intl.NumberFormat("en-US", {})
    return formatter.format(value)
  }

  return (
    <tr key={"totalFinal"} className="table-light">
      <td style={styleNameTable} scope="row">
        Total en dinero
      </td>
      {stores.map((store, index) => {
        return (
          <td key={store.id} style={styleBorderedColumns}>
            <Table className="mt-1 mb-0">
              <tbody>
                <td style={{ width: "3rem", textAlign: "center", paddingLeft: "1rem" }}>
                  {currencyFormatterQuantity({ value: totalValues[index]?.quantity })}
                </td>
                <td style={{ width: "3rem", textAlign: "center" }}>
                  {currencyFormatterMoney({ currency: "USD", value: totalValues[index]?.inventoryCost })}
                </td>
              </tbody>
            </Table>
          </td>
        )
      })}
      <td style={styleCenterTable}>
        <Table className="mt-1 mb-0">
          <tbody>
            <td style={{ width: "3rem", textAlign: "center", paddingLeft: "1rem" }}>
              {currencyFormatterQuantity({ value: totalValues[totalValues.length - 1]?.quantity })}
            </td>
            <td style={{ width: "3rem", textAlign: "center" }}>
              {currencyFormatterMoney({ currency: "USD", value: totalValues[totalValues.length - 1]?.inventoryCost })}
            </td>
          </tbody>
        </Table>
      </td>
    </tr>
  )
}

InventoryItemTotal.propTypes = {
  inventories: propTypes.array,
  stores: propTypes.array,
}

export default InventoryItemTotal
