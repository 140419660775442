import React, { useState } from "react"
import PropTypes from "prop-types"
import Swal from "sweetalert2"
import { Button, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import Select from "react-select"

const PaymentMethodModal = ({
  isOpen,
  toggleModal,
  handlePaymentMethod,
  handleSubmit,
  setPayMethod,
  availableCredit,
}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const ToastPaymentMethod = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  const paymentMethods = [
    { value: "01 - Efectivo.", label: "Efectivo" },
    { value: "02 - Cheque nominativo.", label: "Cheque nominativo" },
    { value: "03 - Transferencia electrónica de fondos.", label: "Transferencia electrónica de fondos" },
    { value: "04 - Tarjeta de crédito.", label: "Tarjeta de crédito" },
    { value: "99 - Por definir.", label: "Por definir" },
  ]

  const handleSubmitPayment = () => {
    if (selectedPaymentMethod === null) {
      ToastPaymentMethod.fire({
        icon: "error",
        title: "Debe seleccionar un método de pago",
      })
      return
    }
    if (selectedPaymentMethod.value === "01 - Efectivo.") {
      handlePaymentMethod()
    } else {
      setPayMethod(selectedPaymentMethod.value)
      handleSubmit()
    }
    toggleModal()
  }
  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Seleccionar Método de Pago</ModalHeader>
      <ModalBody>
        <Label htmlFor="paymentMethodSelect">Método de Pago:</Label>
        <Select
          id="paymentMethodSelect"
          value={selectedPaymentMethod}
          onChange={(selectedOption) => setSelectedPaymentMethod(selectedOption)}
          options={paymentMethods}
        />
        <br/>
        El credito disponible es de: {availableCredit}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmitPayment}>
          Continuar
        </Button>
        <Button color="secondary" onClick={toggleModal}>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

PaymentMethodModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handlePaymentMethod: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setPayMethod: PropTypes.func.isRequired,
  availableCredit: PropTypes.string,
}

export default PaymentMethodModal
