import { useState, useEffect } from "react"
import propTypes from "prop-types"
import { Formik } from "formik"
import { Button, Input, Form, FormGroup, FormText, Label, Alert, Row, Col } from "reactstrap"

import schemaImport from "./schemaImport"
import { RequiredIcon, ErrorsText, Loading } from "components"

const ImportForm = ({ submit, isError, isLoading, toggle, error, downloadTemplate }) => {
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        file: undefined,
      }}
      validationSchema={schemaImport}
      onSubmit={submit}
    >
      {({ setFieldValue, handleSubmit, handleBlur, touched, errors }) => (
        <Form className="form-horizontal" onSubmit={handleSubmit}>
          <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
            {isError && error?.errors?.map((error) => error?.message)}
          </Alert>
          <FormGroup>
            <Row className="mb-3">
              <Col>
                <Label className="form-label">
                  Archivo a importar <RequiredIcon />
                </Label>
                <Input
                  className="form-control"
                  id="file"
                  name="file"
                  type="file"
                  accept="text/csv,
                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                    application/vnd.ms-excel"
                  label="Archivo a importar"
                  onChange={(e) => {
                    setFieldValue("file", e.target.files[0])
                  }}
                  onBlur={handleBlur}
                  invalid={
                    !!(touched.file && errors.file) ||
                    (isError && validationErrors.findIndex((e) => e.field === "file") >= 0)
                  }
                />
                <FormText color="muted">Subir archivo con el formato .csv, .xls o .xlsx</FormText>
                <ErrorsText
                  formName="file"
                  touched={touched}
                  errorsForm={errors}
                  isErrorBack={isError}
                  errorsBack={error}
                />
              </Col>
            </Row>
          </FormGroup>

          <hr />

          <Row>
            <Col className="d-flex justify-content-start">
              {
                <Button onClick={downloadTemplate} className="d-flex align-items-center">
                  <i className="me-2 me-lg-2 me-md-0 fa fa-download" />
                  <span className="d-md-none d-lg-block text-truncate">Descargar plantilla</span>
                </Button>
              }
            </Col>
            <Col className="d-flex justify-content-end">
              <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                <i className="fa fa-times" /> Cerrar
              </Button>
              <Button color="success" type="submit" disabled={isLoading}>
                <Loading isLoading={isLoading} /> <i className="fa fa-check" /> Aceptar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

ImportForm.propTypes = {
  submit: propTypes.func.isRequired,
  isError: propTypes.bool.isRequired,
  error: propTypes.object,
  isLoading: propTypes.bool.isRequired,
  toggle: propTypes.func.isRequired,
  downloadTemplate: propTypes.func.isRequired,
}

export default ImportForm
