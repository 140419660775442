import React, { useState } from "react"
import propTypes from "prop-types"
import { Tooltip } from "reactstrap"

const ToolTipPrices = ({ index, price, name, description }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen)
  }

  return (
    <>
      <td>
        {name ? (
          <span id={"tooltipRetrieve" + index}>{name}</span>
        ) : (
          <span id={"tooltipRetrieve" + index}>{price?.price?.name}</span>
        )}
      </td>
      <Tooltip
        hideArrow={true}
        innerClassName="mb-1"
        style={{ backgroundColor: "#74788d" }}
        placement="top"
        isOpen={tooltipOpen}
        target={"tooltipRetrieve" + index}
        toggle={toggleTooltip}
      >
        {description}
      </Tooltip>
    </>
  )
}

ToolTipPrices.propTypes = {
  index: propTypes.number,
  price: propTypes.object,
  name: propTypes.string,
  description: propTypes.string,
}

export default ToolTipPrices
