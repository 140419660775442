import { useState, useEffect } from "react"
import propTypes from "prop-types"
import { Formik } from "formik"
import { Button, Form, Input, Label, Alert, Row, Col } from "reactstrap"
import Select from "react-select"
import schema from "./schema"
import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"


const FileForm = ({ submit, data, toggle, isLoading, isError, error }) => {
    const [showErrorAlert, setShowErrorAlert] = useState(false)
    const [validationErrors, setValidationErrors] = useState([])
    const typeOptionsFiles = [
        { value: "Voucher", label: "Voucher" },
        { value: "NC", label: "NC" }
    ]
    
    useEffect(() => {
        if (isError && error?.error_type !== "ValidationError") {
            setShowErrorAlert(true)
        } else if (isError && error?.error_type === "ValidationError") {
            setValidationErrors(error.errors)
        } else if (!isError) {
            setValidationErrors([])
            setShowErrorAlert(false)
        }
    }, [isError])

    const getStringInvoice = (filename) => {
        if (typeof (filename) === "string") {
            const name = filename.substring(filename.indexOf("invoicefiles/") + 13)
            return name
        } else {
            return null
        }
    }

    return (<>
        <Formik
            enableReinitialize
            initialValues={{
                file: data?.file ?? undefined,
                type: data?.type ? { value: data?.type, label: data?.type } : undefined,
            }}
            validationSchema={schema}
            onSubmit={submit}
        >
            {({ values, setFieldValue, handleChange, handleSubmit, handleBlur, touched, errors, setTouched }) => (
                <Form className="form-horizontal" onSubmit={handleSubmit}>
                    <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
                        {isError && error?.errors?.map((error) => error?.message)}
                    </Alert>
                    {values.file !== undefined && (
                        <Alert
                            style={{
                                fontSize: "1em",
                            }}
                            color="warning">
                            Archivo actual: {getStringInvoice(values.file)}
                        </Alert>
                    )}
                    <Row className="mb-3">
                        <Col>
                            <Label className="form-label">
                                Archivo <RequiredIcon />
                            </Label>
                            <Input
                                name="file"
                                type="file"
                                accept="application/pdf, text/xml"
                                onChange={(event) => {
                                    setFieldValue("file", event.currentTarget.files[0]);
                                }}
                            />
                            <ErrorsText
                                errorsBack={error}
                                errorsForm={errors}
                                formName="file"
                                isErrorBack={isError}
                                touched={touched}
                            />
                        </Col>
                        <Col>
                            <Label className="form-label">
                                Tipo <RequiredIcon />
                            </Label>
                            <Select
                                id="type"
                                name="type"
                                className={
                                    (touched.type && errors.type) ||
                                        (isError && validationErrors.findIndex((e) => e.field === values.type) >= 0)
                                        ? "is-invalid"
                                        : undefined
                                }
                                isClearable
                                value={values.type}
                                onChange={(val) => {
                                    setFieldValue("type", val);
                                }}
                                onBlur={() => {
                                    touched.type = true;
                                    setTouched(touched);
                                }}
                                isLoading={typeOptionsFiles.isLoading}
                                options={typeOptionsFiles}
                                noOptionsMessage={() => "0 opciones"}
                                loadingMessage={() => "Cargando..."}
                                placeholder="Tipo de archivo"
                            />
                            <ErrorsText
                                errorsBack={error}
                                errorsForm={errors}
                                formName="Tipo"
                                isErrorBack={isError}
                                touched={touched}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-end">
                            <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                                <i className="fa fa-times" /> Cancelar
                            </Button>
                            <Button color="success" type="submit" disabled={isLoading}>
                                <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    </>
    )
}


FileForm.defaultProps = {
    data: null,
}


FileForm.propTypes = {
    data: propTypes.object,
    disabledForm: propTypes.bool,
    error: propTypes.any,
    isCreate: propTypes.bool,
    isError: propTypes.bool,
    isLoading: propTypes.bool,
    submit: propTypes.func,
    toggle: propTypes.func,
}


export default FileForm
