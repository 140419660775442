import React from "react"
import propTypes from "prop-types"
import { Link } from "react-router-dom"

const LogItemList = ({ item }) => {
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }

  const getFormattedTime = (time) => {
    const newTime = new Date("2023-01-01T" + time + "Z")

    const hours = newTime.getUTCHours()
    const minutes = newTime.getUTCMinutes()
    const seconds = newTime.getUTCSeconds()

    const timeFormatted = hours + " : " + minutes + " : " + seconds
    return timeFormatted
  }

  return (
    <tr key={item.id}>
      <td style={styleCenterTable}>
        <Link to={{ pathname: `/logs-importation/${item.id}` }}>{item.id}</Link>
      </td>
      <td style={styleCenterTable}>
        {new Date(item.log_date).toLocaleDateString("es-MX", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </td>
      <td style={styleCenterTable}>{getFormattedTime(item.log_time)}</td>
      <td style={styleCenterTable}>{item.type_model}</td>
      <td style={styleCenterTable} className="text-center">
        {item?.user?.first_name} {item?.user?.last_name}
      </td>
    </tr>
  )
}

LogItemList.propTypes = {
  item: propTypes.object,
}

export default LogItemList
