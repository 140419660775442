import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useTable, useGlobalFilter, useSortBy, useFilters, usePagination } from "react-table"
import { Table, Row, Col, Button, Input, Card, CardBody } from "reactstrap"

const orderedProductsTable = ({ data, isLoading, error }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Producto",
        accessor: "product.product.name",
      },
      {
        Header: "Descripción",
        accessor: "product.description",
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 5, // Tamaño de página predeterminado
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const onChangeInInput = (event) => {
    const inputValue = event.target.value
    const page = inputValue ? Number(inputValue) - 1 : 0
    if (page >= 0 && page < pageCount) {
      gotoPage(page)
    }
  }

  return (
    <Fragment>
      {isLoading ? (
        <div>Cargando...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : (
        <div>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Productos Ordenados</h4>
              <div className="table-responsive react-table">
                <Table bordered {...getTableProps()} className="mb-0">
                  <thead className="table-light table-nowrap">
                    {headerGroups.map((headerGroup) => (
                      <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th key={column.id}>
                            <div className="mb-2" {...column.getSortByToggleProps()}>
                              {column.render("Header")}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {page.map((row, rowIndex) => {
                      prepareRow(row)

                      return (
                        <tr key={`row-${rowIndex}`} {...row.getRowProps()}>
                          {row.cells.map((cell, cellIndex) => {
                            const cellStyle = {
                              fontWeight: "normal",
                            }

                            if (row.original.quantity <= 0) {
                              cellStyle.backgroundColor = "#ff3b00"
                              cellStyle.color = "white"
                            } else if (row.original.quantity > 0) {
                              cellStyle.backgroundColor = "#f3f369"
                            }

                            return (
                              <td key={`cell-${rowIndex}-${cellIndex}`} {...cell.getCellProps()} style={cellStyle}>
                                {cell.render("Cell")}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
              <Row className="justify-content-md-center justify-content-center align-items-center">
                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button color="primary" onClick={previousPage} disabled={!canPreviousPage}>
                      {"<"}
                    </Button>
                  </div>
                </Col>
                <Col className="col-md-auto d-none d-md-block">
                  Página{" "}
                  <strong>
                    {pageIndex + 1} de {pageOptions.length}
                  </strong>
                </Col>
                <Col className="col-md-auto">
                  <Input
                    type="number"
                    min={1}
                    style={{ width: 70 }}
                    max={pageOptions.length}
                    defaultValue={pageIndex + 1}
                    onChange={onChangeInInput}
                  />
                </Col>
                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
                      {">"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      )}
    </Fragment>
  )
}

orderedProductsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  row: PropTypes.object,
}

export default orderedProductsTable
