import React from "react"
import propTypes from "prop-types"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import PeriodicTask from "./periodic-task"

const ModalPeriodicTask = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Corte de caja manual
      </ModalHeader>
      <ModalBody>
        <PeriodicTask/>
      </ModalBody>
    </Modal>
  )
}

ModalPeriodicTask.propTypes = {
  isOpen: propTypes.bool,
  toggle: propTypes.func,
}

export default ModalPeriodicTask
