import React, { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Alert, Col, Container, Pagination, PaginationItem, PaginationLink, Table } from "reactstrap"
import { useMutation, useQuery } from "@tanstack/react-query"
import Swal from "sweetalert2"
import { debounce } from "lodash"
import Select from "react-select"

import storage from "services/storage"
import { authUserKey } from "config/API"
import { deleteInvoice, getInvoices } from "services/invoices"
import { Loading } from "components"
import { InvoiceItemList, CreateModalForm, EditModalForm } from "components/invoices"
import ListHeader from "ui-componets/list-header"
import Shortcuts from "components/Shortcuts"

const InvoicesList = () => {
  document.title = "Lista de Compras | Punto de venta"

  const Location = useLocation()

  const [filters, setFilters] = useState("")
  const [searchTxt, setSearchTxt] = useState(new URLSearchParams(Location.search).get("search") ?? "")
  const [modalCreate, setmodalCreate] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)
  const [invoiceId, setInvoiceId] = useState(null)
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")

  const invoices = useQuery(["invoices", filters, filters.toString()], () => getInvoices(filters))
  const [stateSelect, setStateSelect] = useState(1)
  const invoiceDeleted = useMutation((id) => deleteInvoice(id))
  const isLoading = invoices.isLoading || invoiceDeleted.isLoading
  const permissions = storage.get(authUserKey).user_permissions || []
  const canEdit = permissions.some((e) => e.codename === "change_invoice")
  const canDelete = permissions.some((e) => e.codename === "delete_invoice")
  const canAdd = permissions.some((e) => e.codename === "add_invoice")
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }

  const optionsSelect = [
    { value: 1, label: "Mostrar todo" },
    { value: 2, label: "Mostrar solo facturas" },
    { value: 3, label: "Mostrar solo remisiones" },
  ]

  const filterInvoices = (option) => {
    setStateSelect(option.value)
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  useEffect(() => {
    if (showAlertSuccess) {
      Toast.fire({
        icon: "success",
        title: `${msgSuccess}`,
      })
      invoices.refetch()
      setShowAlertSuccess(false)
    }
  }, [showAlertSuccess])

  useEffect(() => {
    setFilters(Location.search)
  }, [])

  const toggleModalCreate = () => {
    setmodalCreate(!modalCreate)
  }

  const toggleModalEdit = () => {
    if (modalEdit) {
      setInvoiceId("")
    }
    setmodalEdit(!modalEdit)
  }

  const openEditModal = (invoiceId) => {
    setInvoiceId(invoiceId)
    toggleModalEdit()
  }

  const openDeleteModal = (invoice) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar la factura: <b>${invoice.number_invoice}</b>?`,
      text: "Este cambio no se podrá revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        invoiceDeleted.mutateAsync(invoice?.id).then(() => {
          invoices.refetch()
          Toast.fire({
            icon: "success",
            title: `La factura <b>${invoice.number_invoice}</b> ha sido eliminado correctamente`,
          })
        })
      }
    })
  }

  const handleSearch = (pageParams) => {
    const params = new URLSearchParams(filters)
    if (pageParams) {
      const newParams = new URLSearchParams(pageParams)
      window.history.replaceState({}, null, `${Location.pathname}?${newParams}`)
      setFilters(newParams)
    } else if (searchTxt) {
      params.set("search", searchTxt)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    } else {
      if (params.get("search")) {
        params.delete("offset")
        params.delete("per_page")
        params.delete("search")
        window.history.replaceState({}, null, `${Location.pathname}?${params}`)
        setFilters(params)
      }
    }
  }

  const debouncedSearch = useCallback(
    debounce((text) => {
      const params = new URLSearchParams()
      params.set("search", text)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    }, 300),
    []
  )

  function handleChangeSearch(event) {
    setSearchTxt(event.target.value)
    debouncedSearch(event.target.value)
  }

  return !permissions.some((e) => e.codename === "view_invoice") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <React.Fragment>
      <div className="page-content">
        {canAdd && (
          <CreateModalForm
            isOpen={modalCreate}
            toggle={toggleModalCreate}
            setShowAlertSuccess={setShowAlertSuccess}
            setMsgSuccess={setMsgSuccess}
          />
        )}
        {canEdit && invoiceId && (
          <EditModalForm
            isOpen={modalEdit}
            toggle={toggleModalEdit}
            invoiceId={invoiceId}
            setShowAlertSuccess={setShowAlertSuccess}
            setMsgSuccess={setMsgSuccess}
          />
        )}
        <Container fluid>
          <Alert color="danger" isOpen={invoices.isError}>
            {invoices.error?.errors?.map((error) => error?.message)}
          </Alert>

          <h2>Facturas y remisiones de mercancías recibidas</h2>
          <div className="bg-white p-3">
            <ListHeader
              canAdd={canAdd}
              handleOnChangeSearch={handleChangeSearch}
              handleSearch={handleSearch}
              searchTxt={searchTxt}
              toggleModalCreate={toggleModalCreate}
            />
            {!invoices.error && isLoading ? (
              <div className="my-5 text-center">
                <Loading isLoading={isLoading} size={2} />
              </div>
            ) : invoices.error && !invoices.isLoading ? (
              <div className="my-5 text-center">
                <h1>Error intenta mas tarde</h1>
              </div>
            ) : (
              <>
                <Col className="justify-content-center mt-3" style={{ display: "flex", alignItems: "flex-end" }}>
                  <div style={{ width: "20rem" }}>
                    <Select
                      name="filters"
                      className="basic-single"
                      isClearable
                      onChange={(val) => {
                        filterInvoices(val)
                      }}
                      defaultValue={optionsSelect[0]}
                      options={optionsSelect}
                      noOptionsMessage={() => "Sin filtros"}
                      loadingMessage={() => "Cargando..."}
                      placeholder="Filtro"
                    />
                  </div>
                </Col>
                <div className="table-responsive" style={{ marginTop: "20px" }}>
                  <Table>
                    <thead>
                      <tr>
                        <th style={styleCenterTable}>Numero de factura</th>
                        <th style={styleCenterTable}>Fecha de llegada</th>
                        <th style={styleCenterTable}>Fecha de pago</th>
                        <th style={styleCenterTable}>Proveedor</th>
                        <th style={styleCenterTable}>PDF</th>
                        <th style={styleCenterTable}>XML</th>
                        <th style={styleCenterTable}>Total</th>
                        <th style={styleCenterTable}>Descuentos</th>
                        <th style={styleCenterTable}>Voucher</th>
                        <th style={styleCenterTable}>NC</th>
                        <th style={styleCenterTable}>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoices.data?.results.length > 0 ? (
                        stateSelect === 1 ? (
                          invoices.data?.results.map((item) => {
                            return (
                              <InvoiceItemList
                                key={item.id}
                                item={item}
                                canDelete={canDelete}
                                canEdit={canEdit}
                                openDeleteModal={openDeleteModal}
                                openEditModal={openEditModal}
                              />
                            )
                          })
                        ) : stateSelect === 2 ? (
                          invoices.data?.results
                            .filter((invoice) => invoice.type === "Factura")
                            .map((item) => {
                              return (
                                <InvoiceItemList
                                  key={item.id}
                                  item={item}
                                  canDelete={canDelete}
                                  canEdit={canEdit}
                                  openDeleteModal={openDeleteModal}
                                  openEditModal={openEditModal}
                                />
                              )
                            })
                        ) : (
                          invoices.data?.results
                          .filter((invoice) => invoice.type === "Remisión")
                          .map((item) => {
                            return (
                              <InvoiceItemList
                                key={item.id}
                                item={item}
                                canDelete={canDelete}
                                canEdit={canEdit}
                                openDeleteModal={openDeleteModal}
                                openEditModal={openEditModal}
                              />
                            )
                          })
                        )
                      ) : (
                        <tr>
                          <td colSpan={11} className="text-center">
                            No hay datos
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                  <div className="text-center">
                    {(invoices.data?.next || invoices.data?.previous) && (
                      <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                        {invoices.data?.previous && (
                          <PaginationItem>
                            <PaginationLink
                              previous
                              onClick={() => handleSearch(invoices.data?.previous.split("?")[1])}
                            />
                          </PaginationItem>
                        )}
                        {invoices.data?.next && (
                          <PaginationItem>
                            <PaginationLink next onClick={() => handleSearch(invoices.data?.next.split("?")[1])} />
                          </PaginationItem>
                        )}
                      </Pagination>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </Container>
      </div>
      <Shortcuts toggleModalCreate={toggleModalCreate} />
    </React.Fragment>
  )
}

export default InvoicesList
