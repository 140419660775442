import * as Yup from "yup"

import { messages } from "utils/messages"

const schema = Yup.object({
    file: Yup.mixed().required(messages.required),
    type: Yup.object().required(messages.required),
})

export default schema
