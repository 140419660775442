import { API } from "config/API"

export const login = async (payload) => {
  const response = await API.post("/auth/login", payload)
  return response
}

export const logout = async () => {
  const response = await API.post("/auth/logout")
  return response
}

export const forgotPassword = async (payload) => {
  const response = await API.post("/auth/password_reset", payload)
  return response
}

export const resetPassword = async (payload) => {
  const response = await API.post("/auth/password_reset_confirm", payload)
  return response
}

export const me = async () => {
  const response = await API.get("/me")
  return response
}

export const updateMe = async (payload) => {
  const response = await API.patch("/me", payload)
  return response
}

export const changePassword = async (payload) => {
  const response = await API.post("/auth/password_change", payload)
  return response
}

export const userRegister = async (payload) => {
  const response = await API.post("/auth/register", payload)
  return response
}
