import React from "react"
import propTypes from "prop-types"
import { Button } from "reactstrap"
import numeral from "numeral"

const CustomersItemList = ({ item, canDelete, canEdit, openEditModal, openDeleteModal }) => {
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }
  const renderEditButton = () => {
    if (!canEdit) {
      return null
    }

    return (
      <Button color="primary" className="m-1" onClick={() => openEditModal(item.id)}>
        <i className="fa fa-edit" />
      </Button>
    )
  }

  const renderDeleteButton = () => {
    if (!canDelete) {
      return null
    }

    return (
      <Button color="danger" onClick={() => openDeleteModal(item)}>
        <i className="fa fa-trash" />
      </Button>
    )
  }

  return (
    <tr key={item.id}>
      <td style={styleCenterTable}>{item.name}</td>
      <td style={styleCenterTable}>{item.email}</td>
      <td style={styleCenterTable}>{item.phone}</td>
      <td style={styleCenterTable}>{item.postal_code || "--"}</td>
      <td style={styleCenterTable}>{item.rfc || "--"}</td>
      <td style={styleCenterTable}>{item.credit_limit ? numeral(item.credit_limit).format("$0,0.00") : "--"}</td>
      <td style={styleCenterTable}>{item.credit_days || "--"}</td>
      <td style={styleCenterTable}>{item.price?.name || "--"}</td>
      <td style={styleCenterTable} className="text-center">
        {renderEditButton()}
        {renderDeleteButton()}
      </td>
    </tr>
  )
}

CustomersItemList.propTypes = {
  item: propTypes.object,
  canDelete: propTypes.bool,
  canEdit: propTypes.bool,
  openEditModal: propTypes.func,
  openDeleteModal: propTypes.func,
}

export default CustomersItemList
