import React, { useState } from "react"
import propTypes from "prop-types"
import Swal from "sweetalert2"
import { Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

const DiscountModal = ({
  isOpen,
  toggleModal,
  subtotal,
  setIsPaymentMethodModalOpen,
  discount,
  setDiscount,
  setFieldValue,
}) => {
  const [discountAmount, setDiscountAmount] = useState(0)

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  const applyDiscountHandler = () => {
    if (discountAmount < 0) {
      Toast.fire({
        icon: "error",
        title: "Descuento inválido",
      })
      return
    }
    toggleModal()
    setDiscount(discount + discountAmount)
    setFieldValue("total", subtotal - discount - discountAmount)
    setIsPaymentMethodModalOpen(true)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Aplicar Descuento</ModalHeader>
      <ModalBody>
        <Label htmlFor="discountAmount">Monto del Descuento:</Label>
        <Input
          type="number"
          id="discountAmount"
          value={discountAmount}
          onChange={(e) => setDiscountAmount(parseFloat(e.target.value))}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={applyDiscountHandler}>
          Aplicar Descuento
        </Button>
        <Button color="secondary" onClick={toggleModal}>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

DiscountModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  toggleModal: propTypes.func.isRequired,
  subtotal: propTypes.number.isRequired,
  setIsPaymentMethodModalOpen: propTypes.func.isRequired,
  discount: propTypes.number.isRequired,
  setDiscount: propTypes.func.isRequired,
  setFieldValue: propTypes.func.isRequired,
}

export default DiscountModal
