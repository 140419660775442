import React, { useState, useEffect, useCallback } from "react"
import { useLocation } from "react-router-dom"
import { Container, Table, Alert, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { useMutation, useQuery } from "@tanstack/react-query"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { debounce } from "lodash"

import storage from "services/storage"
import { authUserKey } from "config/API"
import { getProviders, deleteProvider } from "services/providers"
import { Loading } from "components"
import { CreateModalForm, EditModalForm, ImportModalForm, ProvidersItemList } from "components/providers"
import ListHeader from "ui-componets/list-header"
import Shortcuts from "components/Shortcuts"

const ProvidersList = () => {
  document.title = "Lista de proveedores | Punto de venta"

  const Location = useLocation()

  const [filters, setFilters] = useState("")
  const [searchTxt, setSearchTxt] = useState(new URLSearchParams(Location.search).get("search") ?? "")
  const [modalCreate, setmodalCreate] = useState(false)
  const [modalImport, setmodalImport] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)
  const [providerId, setproviderId] = useState(null)
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")

  const ModalProvider = withReactContent(Swal)

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  const providers = useQuery(["providers", filters, filters.toString()], () => getProviders(filters))
  const providerDeleted = useMutation((id) => deleteProvider(id))
  const isLoading = providers.isLoading || providerDeleted.isLoading
  const permissions = storage.get(authUserKey).user_permissions || []
  const canEdit = permissions.some((e) => e.codename === "change_provider")
  const canDelete = permissions.some((e) => e.codename === "delete_provider")
  const canAdd = permissions.some((e) => e.codename === "add_provider")
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }

  useEffect(() => {
    if (showAlertSuccess) {
      ModalProvider.fire({
        icon: "success",
        title: msgSuccess,
        didOpen: () => {
          providers.refetch()
          setShowAlertSuccess(false)
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "green",
      })
    }
  }, [showAlertSuccess])

  useEffect(() => {
    setFilters(Location.search)
  }, [])

  const toggleModalCreate = () => {
    setmodalCreate(!modalCreate)
  }

  const toggleModalImport = () => {
    setmodalImport(!modalImport)
  }

  const toggleModalEdit = () => {
    if (modalEdit) {
      setproviderId("")
    }
    setmodalEdit(!modalEdit)
  }

  const openEditModal = (providerId) => {
    setproviderId(providerId)
    toggleModalEdit()
  }

  const openDeleteModal = (row) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar el proveedor: <b>${row.name}</b>?`,
      text: "Este cambio no se podrá revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        providerDeleted.mutateAsync(row?.id).then(() => {
          providers.refetch()
          Toast.fire("!Eliminado!", `El proveedor <b>${row.name}</b> ha sido eliminado correctamente`, "success")
        })
      }
    })
  }

  const handleSearch = (pageParams) => {
    const params = new URLSearchParams(filters)
    if (pageParams) {
      const newParams = new URLSearchParams(pageParams)
      window.history.replaceState({}, null, `${Location.pathname}?${newParams}`)
      setFilters(newParams)
    } else if (searchTxt) {
      params.set("search", searchTxt)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    } else {
      if (params.get("search")) {
        params.delete("offset")
        params.delete("per_page")
        params.delete("search")
        window.history.replaceState({}, null, `${Location.pathname}?${params}`)
        setFilters(params)
      }
    }
  }

  const debouncedSearch = useCallback(
    debounce((text) => {
      const params = new URLSearchParams()
      params.set("search", text)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    }, 300),
    []
  )

  function handleChangeSearch(event) {
    setSearchTxt(event.target.value)
    debouncedSearch(event.target.value)
  }

  return !permissions.some((e) => e.codename === "view_provider") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <React.Fragment>
      <div className="page-content">
        {canAdd && (
          <CreateModalForm
            isOpen={modalCreate}
            toggle={toggleModalCreate}
            setShowAlertSuccess={setShowAlertSuccess}
            setMsgSuccess={setMsgSuccess}
          />
        )}
        {canAdd && (
          <ImportModalForm
            isOpen={modalImport}
            toggle={toggleModalImport}
            refetch={providers.refetch}
            setShowAlertSuccess={setShowAlertSuccess}
            setMsgSuccess={setMsgSuccess}
          />
        )}
        {canEdit && providerId && (
          <EditModalForm
            isOpen={modalEdit}
            toggle={toggleModalEdit}
            providerId={providerId}
            setShowAlertSuccess={setShowAlertSuccess}
            setMsgSuccess={setMsgSuccess}
          />
        )}
        <Container fluid>
          <Alert color="danger" isOpen={providers.isError}>
            {providers?.error?.errors?.map((error) => error?.message)}
          </Alert>

          <h2>Lista de Proveedores</h2>
          <div className="bg-white p-3">
            <ListHeader
              canAdd={canAdd}
              handleOnChangeSearch={handleChangeSearch}
              handleSearch={handleSearch}
              searchTxt={searchTxt}
              toggleModalCreate={toggleModalCreate}
              toggleModalImport={toggleModalImport}
            />
            {!providers.error && isLoading ? (
              <div className="my-5 text-center">
                <Loading isLoading={isLoading} size={2} />
              </div>
            ) : providers.error && !providers.isLoading ? (
              <div className="my-5 text-center">
                <h1>Error intenta mas tarde</h1>
              </div>
            ) : (
              <div className="table-responsive" style={{ marginTop: "30px" }}>
                <Table>
                  <thead>
                    <tr>
                      <th style={styleCenterTable}>Nombre</th>
                      <th style={styleCenterTable}>Correo electrónico</th>
                      <th style={styleCenterTable}>Teléfono</th>
                      <th style={styleCenterTable}>RFC</th>
                      <th style={styleCenterTable}>Observaciones</th>
                      <th style={styleCenterTable}>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {providers?.data?.results.length > 0 ? (
                      providers?.data?.results.map((item) => {
                        return (
                          <ProvidersItemList
                            key={item.id}
                            item={item}
                            canDelete={canDelete}
                            canEdit={canEdit}
                            openDeleteModal={openDeleteModal}
                            openEditModal={openEditModal}
                          />
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No hay datos
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                <div className="text-center">
                  {(providers.data?.next || providers.data?.previous) && (
                    <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                      {providers.data?.previous && (
                        <PaginationItem>
                          <PaginationLink
                            previous
                            onClick={() => handleSearch(providers.data?.previous.split("?")[1])}
                          />
                        </PaginationItem>
                      )}
                      {providers.data?.next && (
                        <PaginationItem>
                          <PaginationLink next onClick={() => handleSearch(providers.data?.next.split("?")[1])} />
                        </PaginationItem>
                      )}
                    </Pagination>
                  )}
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
      <Shortcuts
        toggleModalCreate={toggleModalCreate}
        toggleModalImport={toggleModalImport}
      />
    </React.Fragment>
  )
}

export default ProvidersList
