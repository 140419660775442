import React, { useEffect, useState } from "react"
import propTypes from "prop-types"
import {
  Alert,
  Button,
  Label,
  Input,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupText,
} from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import { Formik } from "formik"

import { ErrorsText, Loading, RequiredIcon } from "components"
import { getProductPresentation, editProductPresentation } from "services/product-presentations"
import { getProduct } from "services/products"
import schema from "./schema"

const EditModalForm = ({ isOpen, toggle, refetch, productId, setShowAlertSuccess, setMsgSuccess }) => {
  const {
    data,
    isLoading,
    refetch: refetchProductPresentation,
  } = useQuery(["product", productId], () => getProductPresentation(productId))
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const productBase = useQuery(["productBase", data?.product?.id], () => getProduct(data?.product?.id))

  const initialValues = {
    id: data?.id ?? "",
    product: data?.product?.id ?? "",
    clave: data?.key ?? "",
    description: data?.description ?? "",
    presentation_unit: data?.presentation_unit ?? "",
  }

  const {
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["product-presentations", productId], (data) => editProductPresentation(productId, data))

  const handleSubmit = async (values) => {
    const { id, product, clave, description, presentation_unit: presentationUnit } = values
    const dataTemp = {
      id,
      product,
      key: clave,
      description: `${productBase?.data?.name} - ${description}`,
      presentation_unit: presentationUnit,
    }
    try {
      await mutateAsync(dataTemp)
    } catch (error) {}
  }

  const getNamePresentation = (namePresentation, nameProductBase) => {
    let result
    if (namePresentation?.includes(nameProductBase)) {
      result = namePresentation?.substring(nameProductBase?.length + 3, namePresentation?.length)
    }
    return result
  }

  useEffect(() => {
    if (isSuccessEdit) {
      setMsgSuccess("Presentación modificada satisfactoriamente")
      setShowAlertSuccess(true)
      refetch()
      refetchProductPresentation()
      toggle()
    }
  }, [isSuccessEdit])

  useEffect(() => {
    if (isErrorEdit && errorEdit?.error_type !== "ValidationError") {
      setShowErrorAlert(true)
    } else if (isErrorEdit && errorEdit?.error_type === "ValidationError") {
      setValidationErrors(errorEdit.errors)
    } else if (!isErrorEdit) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isErrorEdit])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Editar Producto
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className="text-center">
            <Loading isLoading={isLoading} size={2} />
          </div>
        ) : isErrorEdit ? (
          <div className="text-center">
            <Alert color="danger" isOpen={isErrorEdit}>
              {errorEdit?.errors?.map((error) => error?.message)}
            </Alert>
          </div>
        ) : (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
            {({ values, handleSubmit, handleChange, handleBlur, touched, errors }) => (
              <Form onSubmit={handleSubmit}>
                <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
                  {isErrorEdit && errorEdit?.errors?.map((error) => error?.message)}
                </Alert>
                <Label>
                  Clave <RequiredIcon />
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  name="clave"
                  value={values.clave}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={
                    !!(touched.clave && errors.clave) ||
                    (isErrorEdit && validationErrors.findIndex((e) => e.field === "clave") >= 0)
                  }
                />
                <ErrorsText
                  errorsBack={errorEdit}
                  errorsForm={errors}
                  formName="clave"
                  isErrorBack={isErrorEdit}
                  touched={touched}
                />

                <Label className="mt-2">
                  Descripción <RequiredIcon />
                </Label>
                <InputGroup>
                  <InputGroupText>{productBase?.data?.name} - </InputGroupText>
                  <Input
                    type="text"
                    className="form-control"
                    name="description"
                    value={getNamePresentation(values?.description, productBase?.data?.name)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={
                      !!(touched.description && errors.description) ||
                      (isErrorEdit && validationErrors.findIndex((e) => e.field === "description") >= 0)
                    }
                  />
                  <ErrorsText
                    errorsBack={errorEdit}
                    errorsForm={errors}
                    formName="description"
                    isErrorBack={isErrorEdit}
                    touched={touched}
                  />
                </InputGroup>

                <Label className="mt-2">
                  Cantidad por presentación <RequiredIcon />
                </Label>
                <Input
                  type="number"
                  className="form-control"
                  name="presentation_unit"
                  value={values.presentation_unit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={
                    !!(touched.presentation_unit && errors.presentation_unit) ||
                    (isErrorEdit && validationErrors.findIndex((e) => e.field === "presentation_unit") >= 0)
                  }
                />
                <ErrorsText
                  errorsBack={errorEdit}
                  errorsForm={errors}
                  formName="presentation_unit"
                  isErrorBack={isErrorEdit}
                  touched={touched}
                />

                <div className="d-flex justify-content-end">
                  <Button type="submit" color="success" style={{ marginTop: "10px" }}>
                    Editar
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </ModalBody>
    </Modal>
  )
}

EditModalForm.defaultProps = {
  productId: null,
}

EditModalForm.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  productId: propTypes.string,
  refetch: propTypes.func,
  setShowAlertSuccess: propTypes.func,
  setMsgSuccess: propTypes.func,
}

export default EditModalForm
