import propTypes from "prop-types"
import { useEffect } from "react"
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import { getAgent, editAgent } from "services/agents"
import { Loading } from "components"
import AgentForm from "./agent-form"

const AgentEditModalForm = ({ isOpen, toggle, agentId, setShowAlertSuccess, setMsgSuccess }) => {
  const {
    data,
    error,
    isLoading,
    isError
  } = useQuery(["agent", agentId], () => getAgent(agentId))

  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["agent", agentId], (data) => editAgent(agentId, data))

  const submit = async (data) => {
    await mutateAsync({
      ...data,
    })
  }

  useEffect(() => {
    if (isSuccessEdit) {
      setMsgSuccess("Agente modificado satisfactoriamente")
      setShowAlertSuccess(true)
      toggle()
    }
  }, [isSuccessEdit])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Editar Agente
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className="text-center">
            <Loading isLoading={isLoading} size={2} />
          </div>
        ) : isError ? (
          <div className="text-center">
            <Alert color="danger" isOpen={isError}>
              {error?.errors?.map((error) => error?.message)}
            </Alert>
          </div>
        ) : (
          <AgentForm
            data={data}
            error={errorEdit}
            isCreateModal={false}
            isError={isErrorEdit}
            isLoading={isLoadingEdit}
            submit={submit}
            toggle={toggle}
          />
        )}
      </ModalBody>
    </Modal>
  )
}

AgentEditModalForm.defaultProps = {
  agentId: null,
}

AgentEditModalForm.propTypes = {
  agentId: propTypes.string,
  isOpen: propTypes.any,
  setMsgSuccess: propTypes.func,
  setShowAlertSuccess: propTypes.func,
  toggle: propTypes.func,
}

export default AgentEditModalForm
