import { useState, useEffect } from "react"
import propTypes from "prop-types"
import { Formik } from "formik"
import { useQuery } from "@tanstack/react-query"
import { Button, Form, Input, Label, Alert, Row, Col, InputGroup, InputGroupText } from "reactstrap"
import Select from "react-select"

import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"
import { getAllPrices } from "services/prices"
import { getAllTaxRegimens, getCFDIUses } from "services/taxRegimens"
import schema from "./schema"

const CustomerForm = ({ isCreate, submit, data, toggle, isLoading, isError, error }) => {
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])

  function currencyFormatter({ value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return formatter.format(value)
  }

  function reverseFormatNumber(val) {
    const group = new Intl.NumberFormat("en-US").format(1111).replace(/1/g, "")
    const decimal = new Intl.NumberFormat("en-US").format(1.1).replace(/1/g, "")
    let reversedVal = val.replace(new RegExp("\\" + group, "g"), "")
    reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".")
    return Number.isNaN(reversedVal) ? 0 : reversedVal
  }

  const {
    data: prices,
    error: errorPrices,
    isError: isErrorPrices,
    isLoading: isLoadingPrices,
  } = useQuery(["prices"], getAllPrices)
  const taxRegimens = useQuery(["tax-regimens"], () => getAllTaxRegimens())
  const cfdiUses = useQuery(["cfdi-uses"], () => getCFDIUses())
  const defaultPrice = isLoadingPrices ? null : { value: "", label: "Ninguno" }

  const isLoadingDiv = () => {
    return (
      <div className="text-center">
        <Loading isLoading={isLoadingPrices} size={2} />
      </div>
    )
  }

  const isErrorDiv = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={isErrorPrices}>
          {errorPrices?.errors?.map((error) => error?.message)}
        </Alert>
      </div>
    )
  }

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  const activePrices = (prices) => {
    let data = prices?.filter((price) => price.is_active === true)
    data = data.map((element) => ({
      value: element.id,
      label: `${element.name} - ${element.identifier ? element.identifier : ""}`,
    }))
    data.push({ value: "", label: "Ninguno" })
    return data
  }

  return (
    <>
      {isLoadingPrices ? (
        isLoadingDiv()
      ) : isErrorPrices ? (
        isErrorDiv()
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            name: data?.name ?? "",
            email: data?.email ?? "",
            phone: data?.phone ?? "",
            rfc: data?.rfc ?? "",
            business_name: data?.business_name ?? "",
            tax_regimen: data?.tax_regimen
              ? { value: data?.tax_regimen?.id, label: data?.tax_regimen?.name }
              : undefined,
            postal_code: data?.postal_code ?? "",
            credit_days: data?.credit_days ?? 0,
            credit_limit: data?.credit_limit ?? 0,
            price: data?.price
              ? {
                  value: data?.price?.id,
                  label: `${data?.price?.name} - ${data?.price?.identifier ? data?.price?.identifier : ""}`,
                }
              : defaultPrice,
            available_credit: data?.available_credit ?? 0,
            cfdi_use: data?.cfdi_use ? { value: data?.cfdi_use?.id, label: data?.cfdi_use?.description } : undefined,
          }}
          validationSchema={schema}
          onSubmit={submit}
        >
          {({ values, setFieldValue, handleChange, handleSubmit, handleBlur, touched, errors, setTouched }) => (
            <Form className="form-horizontal" onSubmit={handleSubmit}>
              <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
                {isError && error?.errors?.map((error) => error?.message)}
              </Alert>

              <Row className="mb-3">
                <Col>
                  <Label className="form-label">
                    Nombre <RequiredIcon />
                  </Label>
                  <Input
                    autoComplete="off"
                    className="form-control"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="name"
                    value={values.name}
                    invalid={
                      !!(touched.name && errors.name) ||
                      (isError && validationErrors.findIndex((e) => e.field === "name") >= 0)
                    }
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="name"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
                <Col>
                  <Label className="form-label">
                    Teléfono <RequiredIcon />
                  </Label>
                  <Input
                    autoComplete="off"
                    className="form-control"
                    maxLength={10}
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="phone"
                    value={values.phone}
                    invalid={
                      !!(touched.phone && errors.phone) ||
                      (isError && validationErrors.findIndex((e) => e.field === "phone") >= 0)
                    }
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="phone"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Label className="form-label">Email</Label>
                  <Input
                    autoComplete="off"
                    className="form-control"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    invalid={
                      !!(touched.email && errors.email) ||
                      (isError && validationErrors.findIndex((e) => e.field === "email") >= 0)
                    }
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="email"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
                <Col>
                  <Label className="form-label">Uso de CFDI</Label>
                  <Select
                    id="cfdi_use"
                    name="cfdi_use"
                    className={
                      (touched.cfdi_use && errors.cfdi_use) ||
                      (isError && validationErrors.findIndex((e) => e.field === "cfdi_use") >= 0)
                        ? "is-invalid"
                        : undefined
                    }
                    isClearable
                    value={values.cfdi_use}
                    onChange={(val) => {
                      setFieldValue("cfdi_use", val)
                    }}
                    onBlur={() => {
                      touched.cfdi_use = true
                      setTouched(touched)
                    }}
                    isLoading={cfdiUses.isLoading}
                    options={cfdiUses?.data?.results?.map((row) => ({
                      ...row,
                      label: `(${row.code}) ${row.description}`,
                      value: row.id,
                    }))}
                    noOptionsMessage={() => "0 usos de cfdi"}
                    loadingMessage={() => "Cargando..."}
                    placeholder="Uso de CFDI"
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="cfdi_use"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>

              <hr />

              <Row className="mb-3">
                <Col>
                  <Label className="form-label">Régimen Fiscal</Label>
                  <Select
                    id="tax_regimen"
                    name="tax_regimen"
                    className={
                      (touched.tax_regimen && errors.tax_regimen) ||
                      (isError && validationErrors.findIndex((e) => e.field === "tax_regimen") >= 0)
                        ? "is-invalid"
                        : undefined
                    }
                    isClearable
                    value={values.tax_regimen}
                    onChange={(val) => {
                      setFieldValue("tax_regimen", val)
                    }}
                    onBlur={() => {
                      touched.tax_regimen = true
                      setTouched(touched)
                    }}
                    isLoading={taxRegimens.isLoading}
                    options={taxRegimens?.data?.map((row) => ({
                      ...row,
                      label: `(${row.code}) ${row.name}`,
                      value: row.id,
                    }))}
                    noOptionsMessage={() => "0 razones sociales"}
                    loadingMessage={() => "Cargando..."}
                    placeholder="Régimen Fiscal"
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="tax_regimen"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
                <Col>
                  <Label className="form-label">Razón Social</Label>
                  <Input
                    autoComplete="off"
                    className="form-control"
                    name="business_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="business_name"
                    value={values.business_name.toUpperCase()}
                    invalid={
                      !!(touched.business_name && errors.business_name) ||
                      (isError && validationErrors.findIndex((e) => e.field === "business_name") >= 0)
                    }
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="business_name"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Label className="form-label">RFC</Label>
                  <Input
                    autoComplete="off"
                    className="form-control"
                    maxLength={13}
                    name="rfc"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.rfc.toUpperCase()}
                    invalid={
                      !!(touched.rfc && errors.rfc) ||
                      (isError && validationErrors.findIndex((e) => e.field === "rfc") >= 0)
                    }
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="rfc"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
                <Col>
                  <Label className="form-label">Código postal</Label>
                  <Input
                    autoComplete="off"
                    className="form-control"
                    maxLength={5}
                    name="postal_code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.postal_code}
                    invalid={
                      !!(touched.postal_code && errors.postal_code) ||
                      (isError && validationErrors.findIndex((e) => e.field === "postal_code") >= 0)
                    }
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="postal_code"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>

              <hr />
              <Row className="mb-3">
                <Col>
                  <Label for="price">
                    Tipo de precio <RequiredIcon />
                  </Label>
                  <Select
                    className={
                      (touched.price && errors.price) ||
                      (isError && validationErrors.findIndex((e) => e.field === "price") >= 0)
                        ? "is-invalid"
                        : undefined
                    }
                    name="price"
                    value={values.price}
                    onBlur={() => {
                      touched.price = true
                      setTouched(touched)
                    }}
                    onChange={(val) => setFieldValue("price", val)}
                    isLoading={isLoadingPrices}
                    options={activePrices(prices)}
                    noOptionsMessage={() => "0 precios"}
                    loadingMessage={() => "Cargando..."}
                    placeholder="Precio"
                  />
                  <ErrorsText
                    formName="price"
                    touched={touched}
                    errorsForm={errors}
                    isErrorBack={isError}
                    errorsBack={error}
                  />
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Label className="form-label">Días de crédito</Label>
                  <Input
                    autoComplete="off"
                    className="form-control"
                    name="credit_days"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.credit_days}
                    invalid={
                      !!(touched.credit_days && errors.credit_days) ||
                      (isError && validationErrors.findIndex((e) => e.field === "credit_days") >= 0)
                    }
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="credit_days"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
                <Col>
                  <Label className="form-label">Límite de crédito</Label>
                  <InputGroup>
                    <InputGroupText>$</InputGroupText>
                    <Input
                      autoComplete="off"
                      id="credit_limit"
                      name="credit_limit"
                      className={
                        (touched.credit_limit && errors.credit_limit) ||
                        (isError && validationErrors.findIndex((e) => e.field === "credit_limit") >= 0)
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={(e) => {
                        const value = e.target.value
                        if (!isNaN(value)) {
                          setFieldValue("credit_limit", value)
                        } else {
                          const unformatValue = reverseFormatNumber(value)
                          setFieldValue("credit_limit", unformatValue)
                        }
                      }}
                      onBlur={(e) => {
                        let value = e.target.value
                        if (isNaN(value)) {
                          value = reverseFormatNumber(value)
                        }
                        const formattedValue = currencyFormatter({ value })

                        // Actualizar el valor formateado en el estado del formulario
                        setFieldValue("credit_limit", formattedValue)
                        handleBlur(e)
                      }}
                      value={
                        touched.credit_limit ? values.credit_limit : currencyFormatter({ value: values.credit_limit })
                      }
                      required={true}
                    />
                  </InputGroup>
                  <ErrorsText
                    formName="credit_limit"
                    touched={touched}
                    errorsForm={errors}
                    isErrorBack={isError}
                    errorsBack={error}
                  />
                </Col>
              </Row>
              {!isCreate && (
                <Row>
                  <span>
                    <Label className="form-label">Crédito disponible</Label>
                    <InputGroup>
                      <InputGroupText>$</InputGroupText>
                      <Input
                        autoComplete="off"
                        value={currencyFormatter({ value: values.available_credit })}
                        className="form-control"
                        name="available_credit"
                        disabled={true}
                      />
                    </InputGroup>
                  </span>
                </Row>
              )}

              <hr />

              <Row>
                <Col className="d-flex justify-content-end">
                  <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                    <i className="fa fa-times" /> Cerrar
                  </Button>
                  <Button color="success" type="submit" disabled={isLoading}>
                    <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

CustomerForm.defaultProps = {
  data: null,
}

CustomerForm.propTypes = {
  isCreate: propTypes.bool,
  submit: propTypes.func,
  toggle: propTypes.func,
  data: propTypes.any,
  isLoading: propTypes.bool,
  isError: propTypes.bool,
  error: propTypes.any,
}

export default CustomerForm
