import propTypes from "prop-types"
import { useEffect } from "react"
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap"
import { useMutation } from "@tanstack/react-query"
import { updateLimitForCashCut } from "services/periodic-tasks"
import { Loading } from "components"
import LimitCashControlForm from "./cash-control-form"


const EditModalForm = ({
  isOpen,
  toggle,
  setShowAlertSuccess,
  setMsgSuccess,
  data,
  error,
  isLoading,
  isError,
  refetch,
  limitId,
}) => {
  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["limit-cash-cut", limitId], (data) => updateLimitForCashCut(limitId, data))

  const submit = async (data) => {
    const dataTemp = {...data}
    dataTemp.limit = parseFloat(dataTemp.limit.replace(/,/g, ""))
    await mutateAsync({...dataTemp})
  }

  useEffect(() => {
    if (isSuccessEdit) {
      setMsgSuccess("Límite de control de efectivo modificado satisfactoriamente")
      setShowAlertSuccess(true)
      refetch()
      toggle()
    }
  }, [isSuccessEdit])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Editar límite de control de efectivo
      </ModalHeader>
      <ModalBody>
        {isLoading && (
          <div className="text-center">
            <Loading isLoading={isLoading} size={2} />
          </div>
        )}
        {isError && (
          <div className="text-center">
            <Alert color="danger" isOpen={isError}>
              {error?.errors?.map((error) => error?.message)}
            </Alert>
          </div>
        )}
        {(!isLoading && !isError) && (
          <LimitCashControlForm
            submit={submit}
            toggle={toggle}
            isLoading={isLoadingEdit}
            isError={isErrorEdit}
            error={errorEdit}
            data={data}
          />
        )}
      </ModalBody>
    </Modal>
  )
}


EditModalForm.defaultProps = {
  taskId: null,
}


EditModalForm.propTypes = {
  isOpen: propTypes.bool,
  toggle: propTypes.func,
  setShowAlertSuccess: propTypes.func,
  setMsgSuccess: propTypes.func,
  data: propTypes.object,
  error: propTypes.any,
  isLoading: propTypes.bool,
  isError: propTypes.bool,
  limitId: propTypes.any,
  refetch: propTypes.func,
}

export default EditModalForm
