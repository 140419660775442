import React from "react"
import { Redirect } from "react-router-dom"

import { Login, Logout, ForgotPassword, ResetPassword } from "pages/Authentication"

// import { Dashboard } from "pages/Dashboard" TODO: Verify page content or remove me
import { CustomersList } from "pages/Customers"
import { ProvidersList, Provider } from "pages/Providers"
import { AgentsList } from "pages/Agents"
import { ProductsList, ProductRetrive, PriceList } from "pages/Products"
import { UsersList } from "pages/Users"
import { Profile } from "pages/Profile"
import { Page404 } from "pages/Page404"
import { BranchesList, Branch } from "pages/Branches"
import { Store } from "pages/Stores"
import { InvoicesList, Invoice } from "pages/Invoices"
import { TicketByUser, TicketForm, TicketList, InvoiceList, CashCutList } from "pages/Sales"
import { BusinessInfo, BusinessInfoRetrieve } from "pages/Business-Info"
import { PeriodicTask } from "pages/PeriodicTasks"
import { BrandsList } from "pages/Brands"
import { Dashboard } from "pages/Dashboard"
import { CashControl } from "pages/CashControl"
import { InventoriesSections } from "pages/InventoriesSections"
import { LogsImportationList, LogsImportationRetrieve } from "pages/Logs-Importation"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/brands", component: BrandsList },
  { path: "/branch-offices", component: BranchesList },
  { path: "/branch-offices/:id", component: Branch },
  { path: "/business-info", component: BusinessInfo },
  { path: "/business-info/:id", component: BusinessInfoRetrieve },
  { path: "/stores/:id", component: Store },
  { path: "/inventories-sections", component: InventoriesSections },
  { path: "/invoices", component: InvoicesList },
  { path: "/invoices/:id", component: Invoice },
  { path: "/customers", component: CustomersList },
  { path: "/agents", component: AgentsList },
  { path: "/providers", component: ProvidersList },
  { path: "/providers/:id", component: Provider },
  { path: "/products", component: ProductsList },
  { path: "/products/:id", component: ProductRetrive },
  { path: "/prices", component: PriceList },
  { path: "/users", component: UsersList },
  { path: "/sales-user", component: TicketByUser },
  { path: "/sale", component: ()=> <TicketForm isCreate={true}/> },
  { path: "/sale/:id", component: ()=> <TicketForm isCreate={false}/> },
  { path: "/sale/:id/:payment", component: ()=> <TicketForm isCreate={false}/> },
  { path: "/sales", component: TicketList },
  { path: "/sales-invoices", component: InvoiceList },
  { path: "/cash-cuts", component: CashCutList },
  { path: "/periodic-task", component: PeriodicTask },
  { path: "/cash-control", component: CashControl },
  { path: "/logs-importation", component: LogsImportationList },
  { path: "/logs-importation/:id", component: LogsImportationRetrieve },
  { path: "/profile", component: Profile },
  { path: "/logout", component: Logout },
  { path: "/404", component: Page404 },
  { path: "/", exact: true, component: () => <Redirect to="/customers" /> },
  { path: "*", component: Page404 },
]

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/reset-password/:token", component: ResetPassword },
]

export { publicRoutes, authProtectedRoutes }
