import * as Yup from "yup"

import { messages } from "utils/messages"

const schema = Yup.object({
  first_name: Yup.string().max(120, messages.max_chars(120)).required(messages.required),
  last_name: Yup.string().max(120, messages.max_chars(120)).required(messages.required),
  email: Yup.string().email(messages.email).required(messages.required),
})

export default schema
