import * as Yup from "yup"

import { messages, NUNBER_REGEX, RFC_EXAMPLE, RFC_REGEX } from "utils/messages"

const schema = Yup.object({
  name: Yup.string().max(120, messages.max_chars(120)).required(messages.required),
  email: Yup.string().email(messages.email),
  phone: Yup.string()
    .matches(NUNBER_REGEX, messages.number)
    .min(10, messages.exactly(10))
    .max(10, messages.exactly(10))
    .required(messages.required),
  rfc: Yup.string()
    .matches(RFC_REGEX, messages.invalidFormat(RFC_EXAMPLE))
    .min(12, messages.exactly(12))
    .max(13, messages.exactly(13)),
  address: Yup.string().max(255, messages.max_chars(255)),
  creditDays: Yup.number().positive(messages.positive).min(0, messages.positive).integer().typeError(messages.number),
  agents: Yup.array().of(
    Yup.object({
      name: Yup.string().max(120, messages.max_chars(120)).required(messages.required),
      email: Yup.string().email(messages.email),
      phone: Yup.string()
        .matches(NUNBER_REGEX, messages.number)
        .min(10, messages.exactly(10))
        .max(10, messages.exactly(10))
        .required(messages.required),
    })
  ),
  brands: Yup.array().nullable(),
})

export default schema
