import * as Yup from "yup"

import { messages } from "utils/messages"

const schema = Yup.object({
  product: Yup.object().required(messages.required),
  quantity: Yup.number().positive(messages.positive).required(messages.required),
  reorder_point: Yup.number().positive(messages.positive),
})

export default schema
