import React, { createContext, useEffect, useState } from "react"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"

import { authProtectedRoutes, publicRoutes } from "./routes"
import Authmiddleware from "./routes/route"
import { ShortcutProvider } from "@shopify/react-shortcuts"

import { Layout, NonAuthLayout } from "layouts"
import { Page404 } from "pages/Page404"
import { me } from "services/auth"
import { syncBussinessInfo, syncCustomers, syncPrices, syncProducts, syncUser } from "SyncDB"

import "./assets/scss/theme.scss"
import "react-checkbox-tree/lib/react-checkbox-tree.css"
// Crea un UserContext
export const UserContext = createContext()
export const OfflineContext = createContext()

const App = () => {
  const [isOffline, setIsOffline] = useState(false)
  const { data } = useQuery(["me"], () => me(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalSquared: false,
  })
  const user = data
  let ipcRenderer = null
  if (window.electron && window.electron.ipcRenderer) {
    ipcRenderer = window.electron.ipcRenderer
  }

  useEffect(() => {
    if (ipcRenderer && user && !isOffline) {
      syncCustomers()
      syncPrices()
      syncProducts()
      syncUser(user)
      syncBussinessInfo()
    }
  }, [user, ipcRenderer, isOffline])

  return (
    <React.Fragment>
      <OfflineContext.Provider value={{ isOffline, setIsOffline }}>
        <UserContext.Provider value={user}>
          <ShortcutProvider>
            <Router>
              <Switch>
                {publicRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                    exact
                  />
                ))}

                {authProtectedRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    exact
                  />
                ))}
                <Authmiddleware path="*" layout={NonAuthLayout} component={Page404} isAuthProtected={false} exact />
              </Switch>
            </Router>
          </ShortcutProvider>
        </UserContext.Provider>
      </OfflineContext.Provider>
    </React.Fragment>
  )
}

App.propTypes = {}

export default App
