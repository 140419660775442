import propTypes from "prop-types"
import { useEffect } from "react"
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"

import { getPrice, editPrice } from "services/prices"
import { Loading } from "components"
import PriceForm from "./PriceForm"

const EditModalForm = ({ isOpen, toggle, priceId, setShowAlertSuccess, setMsgSuccess }) => {
  const { data, error, isLoading, isError, refetch } = useQuery(["price", priceId], () => getPrice(priceId))

  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["priceUpdate", priceId], (data) => editPrice(priceId, data))

  const submit = async (dataSubmit) => {
    await mutateAsync(dataSubmit)
  }

  useEffect(() => {
    if (isSuccessEdit) {
      setMsgSuccess("Precio modificado satisfactoriamente")
      setShowAlertSuccess(true)
      toggle()
      refetch()
    }
  }, [isSuccessEdit])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Editar precio
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className="text-center">
            <Loading isLoading={isLoading} size={2} />
          </div>
        ) : isError ? (
          <div className="text-center">
            <Alert color="danger" isOpen={isError}>
              {error?.errors?.map((error) => error?.message)}
            </Alert>
          </div>
        ) : (
          <PriceForm
            isCreateModal={false}
            submit={submit}
            toggle={toggle}
            isLoadingSubmit={isLoadingEdit}
            isError={isErrorEdit}
            error={errorEdit}
            data={data}
          />
        )}
      </ModalBody>
    </Modal>
  )
}

EditModalForm.defaultProps = {
  priceId: null,
}

EditModalForm.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  priceId: propTypes.string,
  setShowAlertSuccess: propTypes.func,
  setMsgSuccess: propTypes.func,
}

export default EditModalForm
