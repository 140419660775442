import { useEffect } from "react"
import propTypes from "prop-types"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useMutation } from "@tanstack/react-query"
import { createInvoice } from "services/invoices"
import InvoiceForm from "./invoice-form"

const CreateModalForm = ({ isOpen, toggle, setShowAlertSuccess, setMsgSuccess }) => {
  const { isLoading, isError, error, isSuccess, mutateAsync } = useMutation(createInvoice)

  useEffect(() => {
    if (isSuccess) {
      setMsgSuccess("Factura creada satisfactoriamente")
      setShowAlertSuccess(true)
      toggle()
    }
  }, [isSuccess])

  const submit = async (data) => {
    const dataTemp = { ...data }
    dataTemp.type = dataTemp?.type?.value || null
    dataTemp.status = dataTemp?.status?.value || null
    dataTemp.business_name = dataTemp?.business_name?.value || null
    dataTemp.payment_condition = dataTemp?.payment_condition?.value || null
    dataTemp.payment_method = dataTemp?.payment_method?.value || null
    dataTemp.provider = dataTemp?.provider?.value || null
    dataTemp.invoice_pdf = dataTemp?.invoice_pdf || null
    dataTemp.invoice_xml = dataTemp?.invoice_xml || null
    dataTemp.files = dataTemp?.files || []
    dataTemp.discount = parseFloat(dataTemp?.discount.replace(/,/g, "")) || 0
    dataTemp.total = parseFloat(dataTemp?.total.replace(/,/g, "")) || 0
    const response = await mutateAsync({ ...dataTemp })
    return response
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Agregar Factura
      </ModalHeader>
      <ModalBody>
        <InvoiceForm
          isCreate={true}
          submit={submit}
          toggle={toggle}
          isLoading={isLoading}
          isError={isError}
          error={error}
        />
      </ModalBody>
    </Modal>
  )
}

CreateModalForm.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  setShowAlertSuccess: propTypes.func,
  setMsgSuccess: propTypes.func,
}

export default CreateModalForm
