import React from "react"
import { Container } from "reactstrap"

const Page404 = () => {
  document.title = "404 | Punto de venta"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>Page404</h4>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Page404
