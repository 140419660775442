import * as Yup from "yup"

import { messages } from "utils/messages"

const schema = Yup.object({
  amount: Yup.number().positive(messages.positive).required(messages.required),
  price: Yup.string().required(messages.required),
  utility: Yup.number().required(messages.required),
  quantity: Yup.number().positive(messages.positive).required(messages.required),
})

export default schema
