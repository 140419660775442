import React, { useState, useEffect, useCallback } from "react"
import { Alert, Container, Pagination, PaginationItem, PaginationLink, Table } from "reactstrap"
import { useQuery } from "@tanstack/react-query"
import { debounce } from "lodash"

import storage from "services/storage"
import { PendingSalesItemList } from "components/sales"
import { authUserKey } from "config/API"
import { Loading } from "components"
import ListHeader from "ui-componets/list-header"
import { getPendingTickets } from "services/tickets"
import { PropTypes } from "prop-types"

const PendingSales = (props) => {
  const { activeTab } = props
  const [filters, setFilters] = useState("")
  const [searchTxt, setSearchTxt] = useState(new URLSearchParams(Location.search).get("search") ?? "")
  const permissions = storage.get(authUserKey).user_permissions || []
  const pendingTickets = useQuery(["pendingTickets", filters, filters.toString()], () => getPendingTickets(filters), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retryOnMount: false,
    refetchInterval: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchIntervalInBackground: false,
    refetchIntervalSquared: false,
    retryDelay: (attemptIndex) => 0,
  })
  const isLoadingPending = pendingTickets.isLoading
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }

  useEffect(() => {
    if (pendingTickets.isSuccess) {
      pendingTickets.refetch()
    }
  }, [activeTab, pendingTickets])

  const handleSearch = (pageParams) => {
    const params = new URLSearchParams(filters)
    if (pageParams) {
      const newParams = new URLSearchParams(pageParams)
      window.history.replaceState({}, null, `${Location.pathname}?${newParams}`)
      setFilters(newParams)
    } else if (searchTxt) {
      params.set("search", searchTxt)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    } else {
      if (params.get("search")) {
        params.delete("offset")
        params.delete("per_page")
        params.delete("search")
        window.history.replaceState({}, null, `${Location.pathname}?${params}`)
        setFilters(params)
      }
    }
  }

  const debouncedSearch = useCallback(
    debounce((text) => {
      const params = new URLSearchParams()
      params.set("search", text)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    }, 300),
    []
  )

  function handleChangeSearch(event) {
    setSearchTxt(event.target.value)
    debouncedSearch(event.target.value)
  }
  return !permissions.some((e) => e.codename === "view_ticket") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <Container fluid>
      <Alert color="danger" isOpen={pendingTickets.isError}>
        {pendingTickets.error?.errors?.map((error) => error?.message)}
      </Alert>

      <h2>Lista de Ventas Pendientes</h2>
      <div className="bg-white p-3">
        <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
        <ListHeader handleOnChangeSearch={handleChangeSearch} handleSearch={handleSearch} searchTxt={searchTxt} />
        {!pendingTickets.error && isLoadingPending ? (
          <div className="my-5 text-center">
            <Loading isLoading={isLoadingPending} size={2} />
          </div>
        ) : pendingTickets.error && !pendingTickets.isLoading ? (
          <div className="my-5 text-center">
            <h1>Error intenta mas tarde</h1>
          </div>
        ) : (
          <div className="table-responsive mt-3">
            <Table>
              <thead>
                <tr>
                  <th style={styleCenterTable}>Folio</th>
                  <th style={styleCenterTable}>Fecha de creación</th>
                  <th style={styleCenterTable}>Cajero</th>
                  <th style={styleCenterTable}>Cliente</th>
                  <th style={styleCenterTable}>Total</th>
                  <th style={styleCenterTable}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {pendingTickets.data?.results.length ? (
                  pendingTickets.data?.results.map((item) => {
                    return <PendingSalesItemList key={item.id} item={item} />
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No hay datos
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div className="text-center">
              {(pendingTickets.data?.next || pendingTickets.data?.previous) && (
                <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                  {pendingTickets.data?.previous && (
                    <PaginationItem>
                      <PaginationLink
                        previous
                        onClick={() => handleSearch(pendingTickets.data?.previous.split("?")[1])}
                      />
                    </PaginationItem>
                  )}
                  {pendingTickets.data?.next && (
                    <PaginationItem>
                      <PaginationLink next onClick={() => handleSearch(pendingTickets.data?.next.split("?")[1])} />
                    </PaginationItem>
                  )}
                </Pagination>
              )}
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

PendingSales.propTypes = {
  activeTab: PropTypes.object,
}

export default PendingSales
