import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Container, Alert, Row, Col } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import Swal from "sweetalert2"

import { getBusinessInfo, editBusinessInfo } from "services/business-info"
import { Loading } from "components"
import storage from "services/storage"
import { authUserKey } from "config/API"
import { BusinessInfoForm } from "components/business-info"


const BusinessInfoRetrieve = () => {
  document.title = "Editar información | Punto de venta"

  const [successAlert, setSuccessAlert] = useState(false)
  const { id } = useParams()
  const { data, error, isLoading, isError, refetch } = useQuery(["business-info", id], () => getBusinessInfo(id))
  const permissions = storage.get(authUserKey).user_permissions || []

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    }
  })

  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["business-info", id], (data) => editBusinessInfo(id, data))

  useEffect(() => {
    if (isSuccessEdit) {
      refetch()
      setSuccessAlert(true)
    }
  }, [isSuccessEdit])

  useEffect(() => {
    if (successAlert) {
      Toast.fire({
        icon: "success",
        title: "Información modificada satisfactoriamente"
      })
      refetch()
      setSuccessAlert(false)
    }
  }, [successAlert])

  const submit = async (data) => {
    await mutateAsync(data)
  }

  const isErrorDiv = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={isError}>
          { error?.errors?.map((error) => error?.message) }
        </Alert>
      </div>
    )
  }

  const formdiv = () => {
    return isLoading ? (
      <div className="text-center">
        <Loading isLoading={isLoading} size={2} />
      </div>
    ) : (
      <>
        <Row>
          <Col>
            <BusinessInfoForm
              data={data}
              error={errorEdit}
              isError={isErrorEdit}
              isLoading={isLoadingEdit}
              isRetrivePage={true}
              submit={submit}
              disabledForm={!permissions.some((e) => e.codename === "change_businessinfo")} />
          </Col>
        </Row>
      </>
    )
  }

  return !permissions.some((e) => e.codename === "view_businessinfo") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="page-content">
      <Container fluid>
        <h1>Editar informacion de la empresa</h1>
        <div className="bg-white p-3">
          {isError ? (
              isErrorDiv()
            ) : formdiv()
          }
        </div>
      </Container>
    </div>
  )
}


export default BusinessInfoRetrieve
