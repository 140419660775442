import React, { useContext } from "react"
import { useParams, Link } from "react-router-dom"
import { Alert, Col, Container, Row, Table } from "reactstrap"
import { useQuery } from "@tanstack/react-query"

import { Loading } from "components"
import { getLog } from "services/logs-importation"
import { UserContext } from "App"
import { ItemListRow } from "components/logs-importation"

const LogsImportationRetrieve = () => {
  document.title = "Log de importación | Punto de venta"

  const { id } = useParams()
  const { data, error, isLoading, isError } = useQuery(["logs-imporation", id], () => getLog(id))
  const userData = useContext(UserContext)
  const isAdmin = userData?.user_type

  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }

  const isErrorDiv = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={isError}>
          {error?.errors?.map((error) => error?.message)}
        </Alert>
      </div>
    )
  }

  return !isAdmin ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="page-content">
      <Container fluid>
        <Row style={{ marginBottom: "20px" }}>
          <Col className="text-start">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link to="/logs-importation" className="btn btn-primary" style={{ marginRight: "10px" }}>
                <i className="fa fa-arrow-left" /> Volver
              </Link>
              <h1 style={{ marginBottom: "0" }}>Log de importación: {data?.id}</h1>
            </div>
          </Col>
        </Row>
        <div className="bg-white p-3">
          {isError ? (
            isErrorDiv()
          ) : isLoading ? (
            <div className="text-center">
              <Loading isLoading={isLoading} size={2} />
            </div>
          ) : (
            <>
              <h1 style={{ textAlign: "center" }}>{data?.type_model}</h1>
              <h3>Registros creados</h3>
              <Table hover responsive>
                <thead>
                  <tr className="table-secondary">
                    <th style={styleCenterTable}>Renglon</th>
                    <th style={styleCenterTable}>Mensaje</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.log_file?.created?.length > 0 ? (
                    data?.log_file?.created?.map((item) => {
                      return <ItemListRow key={item.id} item={item} />
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No hay datos
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <h3>Registros actualizados</h3>
              <Table hover responsive>
                <thead>
                  <tr className="table-secondary">
                    <th style={styleCenterTable}>Renglon</th>
                    <th style={styleCenterTable}>Mensaje</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.log_file?.updated?.length > 0 ? (
                    data?.log_file?.updated?.map((item) => {
                      return <ItemListRow key={item.id} item={item} />
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No hay datos
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <h3>Registros con errores</h3>
              <Table hover responsive>
                <thead>
                  <tr className="table-secondary">
                    <th style={styleCenterTable}>Renglon</th>
                    <th style={styleCenterTable}>Mensaje</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.log_file?.errors?.length > 0 ? (
                    data?.log_file?.errors?.map((item) => {
                      return <ItemListRow key={item.id} item={item} />
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No hay datos
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              {data?.type_model === "Productos" && (
                <>
                  <h1 className="mt-3" style={{ textAlign: "center" }}>Presentaciones</h1>
                  <h3>Registros creados</h3>
                  <Table hover responsive>
                    <thead>
                      <tr className="table-secondary">
                        <th style={styleCenterTable}>Renglon</th>
                        <th style={styleCenterTable}>Mensaje</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.log_file?.created_presentations?.length > 0 ? (
                        data?.log_file?.created_presentations?.map((item) => {
                          return <ItemListRow key={item.id} item={item} />
                        })
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No hay datos
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                  <h3>Registros actualizados</h3>
                  <Table hover responsive>
                    <thead>
                      <tr className="table-secondary">
                        <th style={styleCenterTable}>Renglon</th>
                        <th style={styleCenterTable}>Mensaje</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.log_file?.updated_presentations?.length > 0 ? (
                        data?.log_file?.updated_presentations?.map((item) => {
                          return <ItemListRow key={item.id} item={item} />
                        })
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No hay datos
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                  <h3>Registros con errores</h3>
                  <Table hover responsive>
                    <thead>
                      <tr className="table-secondary">
                        <th style={styleCenterTable}>Renglon</th>
                        <th style={styleCenterTable}>Mensaje</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.log_file?.errors_presentations?.length > 0 ? (
                        data?.log_file?.errors_presentations?.map((item) => {
                          return <ItemListRow key={item.id} item={item} />
                        })
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No hay datos
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </>
              )}
            </>
          )}
        </div>
      </Container>
    </div>
  )
}

export default LogsImportationRetrieve
