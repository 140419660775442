const save = (key, payload) => {
  const data = JSON.stringify(payload)
  localStorage.setItem(key, data)
}

const get = (key) => {
  const data = localStorage.getItem(key)
  return JSON.parse(data)
}

const clear = () => localStorage.clear()

const remove = (key) => {
  localStorage.removeItem(key)
}

export default {
  clear,
  save,
  get,
  remove,
}
