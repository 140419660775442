import React, { useEffect } from "react"
import propTypes from "prop-types"

import { withRouter } from "react-router-dom"

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"

import { leftSideBarThemeTypes, leftSidebarTypes } from "constants/layout"

const Layout = ({ children }) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      {false && (
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
            </div>
          </div>
        </div>
      )}

      <div id="layout-wrapper">
        <Header toggleMenuCallback={false} />
        <Sidebar theme={leftSideBarThemeTypes.COLORED} type={leftSidebarTypes.DEFAULT} isMobile={isMobile} />
        <div className="main-content">{children}</div>
        <Footer />
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: propTypes.any,
}

export default withRouter(Layout)
