export const NUNBER_REGEX = /^[0-9]+$/

export const RFC_REGEX = /^[ña-z]{3,4}[0-9]{6}[0-9a-z]{3}$/i
export const RFC_EXAMPLE = "ABCD123456XX0"

export const FILE_SIZE = 10000000
export const SUPPORTED_FORMATS = [
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
]

export const XML_PDF_FORMATS = [
  "application/pdf",
  "text/xml",
]

export const messages = {
  number: "Debe ser numérico.",
  email: "Ingresa un email válido.",
  required: "Este campo es obligatorio.",
  positive: "Debe ser mayor o igual a 0.",
  greater_than_zero: "Debe ser mayor 0.",
  integer: "Debe ser numero entero.",
  valid_password: "Tu contraseña debe tener al menos letra mayuscula, una letra minuscula, un digito y 8 caracteres.",
  invalidFormat: (format) => `No cumple con el formato ${format}.`,
  max_chars: (chars) => `Máximo ${chars} caracteres.`,
  exactly: (digits) => `Debe ser igual a ${digits} caracteres.`,
  unsupportedTypeFile: "Tipo de archivo no soportado.",
  fileSize: "El archivo es demasiado grande. Max 10 MB",
  password_confirm: "Las contraseñas no coinciden.",
}
