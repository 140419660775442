import { API } from "config/API"

export const getAllProductsPresentations = async (filters) => {
  const response = await API.get("/products-presentations/all", { params: filters })
  return response
}

export const getProductsPresentations = async (filters) => {
  const response = await API.get("/products-presentations", { params: filters })
  return response
}

export const getProductPresentation = async (id) => {
  const response = await API.get(`/products-presentations/${id}`)
  return response
}

export const createProductPresentation = async (payload) => {
  const response = await API.post("/products-presentations", payload)
  return response
}

export const editProductPresentation = async (id, payload) => {
  const response = await API.patch(`/products-presentations/${id}`, payload)
  return response
}

export const deleteProductPresentation = async (id) => {
  const response = await API.delete(`/products-presentations/${id}`)
  return response
}

export const getAllProductsPresentationsInventories = async (filters) => {
  const response = await API.get("/products-presentations/inventory_per_product", { params: filters })
  return response
}

export const getTotalOfInventories = async (filters) => {
  const response = await API.get("/products-presentations/totals_of_inventories", { params: filters })
  return response
}
