import propTypes from "prop-types"
import { useEffect } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useMutation } from "@tanstack/react-query"
import { createAgent } from "services/agents"
import AgentForm from "./agent-form"


const AgentCreateModalForm = ({ isOpen, toggle, setShowAlertSuccess, setMsgSuccess }) => {
  const { isLoading, isError, error, isSuccess, mutateAsync } = useMutation(createAgent)

  useEffect(() => {
    if (isSuccess) {
      setMsgSuccess("Agent creado satisfactoriamente")
      setShowAlertSuccess(true)
      toggle()
    }
  }, [isSuccess])

  const submit = async (data) => {
    await mutateAsync({
      ...data,
      provider: data.provider?.value,
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Agregar agente
      </ModalHeader>
      <ModalBody>
        <AgentForm
          isCreate={true}
          submit={submit}
          toggle={toggle}
          isLoading={isLoading}
          isError={isError}
          error={error}
        />
      </ModalBody>
    </Modal>
  )
}


AgentCreateModalForm.propTypes = {
  isOpen: propTypes.any,
  setMsgSuccess: propTypes.func,
  setShowAlertSuccess: propTypes.func,
  toggle: propTypes.func,
}


export default AgentCreateModalForm
