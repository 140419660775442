import * as Yup from "yup"

import { messages } from "utils/messages"

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const schema = Yup.object({
  first_name: Yup.string().max(120, messages.max_chars(120)).required(messages.required),
  last_name: Yup.string().max(120, messages.max_chars(120)).required(messages.required),
  email: Yup.string().email(messages.email).required(messages.required),
  password: Yup.string().when("type", (type, schema) => {
    return type === "string" ? schema.matches(passwordRules, messages.valid_password).required("Required") : schema;
  }),
  user_type: Yup.object().nullable(),
  user_permissions: Yup.array().required(messages.required),
})

export default schema
