import propTypes from "prop-types"
import { useEffect } from "react"
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import { Loading } from "components"
import { getProvider, editProvider } from "services/providers"
import ProviderForm from "./ProviderForm"

const EditModalForm = ({ isOpen, toggle, providerId, setShowAlertSuccess, setMsgSuccess }) => {
  const {
    data,
    error,
    isError,
    isLoading,
    refetch,
  } = useQuery(["provider", providerId], () => getProvider(providerId))

  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["provider", providerId], (data) => editProvider(providerId, data))

  useEffect(() => {
    if (isSuccessEdit) {
      setMsgSuccess("Proveedor modificado satisfactoriamente")
      setShowAlertSuccess(true)
      toggle()
    }
  }, [isSuccessEdit])

  const submit = async (data) => {
    const dataTemp = { ...data }
    dataTemp.brands = dataTemp.brands ? dataTemp.brands?.map((brand) => brand.id) : null
    await mutateAsync(dataTemp)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Editar Proveedor
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className="text-center">
            <Loading isLoading={isLoading} size={2} />
          </div>
        ) : isError ? (
          <div className="text-center">
            <Alert color="danger" isOpen={isError}>
              {error?.errors?.map((error) => error?.message)}
            </Alert>
          </div>
        ) : (
          <ProviderForm
            data={data}
            error={errorEdit}
            isCreateModal={false}
            isError={isErrorEdit}
            isLoading={isLoadingEdit}
            refetchData={refetch}
            submit={submit}
            toggle={toggle}
          />
        )}
      </ModalBody>
    </Modal>
  )
}

EditModalForm.defaultProps = {
  providerId: null,
}

EditModalForm.propTypes = {
  isOpen: propTypes.any,
  providerId: propTypes.string,
  refetch: propTypes.func,
  setMsgSuccess: propTypes.func,
  setShowAlertSuccess: propTypes.func,
  toggle: propTypes.func,
}

export default EditModalForm
