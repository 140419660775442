import React from "react"
import propTypes from "prop-types"
import { Table } from "reactstrap"

import { useQuery } from "@tanstack/react-query"
import { getTotalOfInventories } from "services/product-presentations"
import Loading from "components/Loading"

const TotalAverageInventories = ({ stores }) => {
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }
  const styleBorderedColumns = {
    borderLeft: "1px solid #b1b4b8",
    borderRight: "1px solid #b1b4b8",
  }
  const totalsInventories = useQuery(["totals-inventories"], () => getTotalOfInventories())

  function currencyFormatterMoney({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }

  function currencyFormatterQuantity({ value }) {
    const formatter = new Intl.NumberFormat("en-US", {})
    return formatter.format(value)
  }

  const isLoading = totalsInventories.isLoading

  return (
    <tr key={"totalFinal"} className="table-light">
      {!totalsInventories.error && isLoading ? (
        <div className="my-5 text-center">
          <Loading isLoading={isLoading} size={2} />
        </div>
      ) : totalsInventories.error && !isLoading ? (
        <div className="my-5 text-center">
          <h1>Error intenta mas tarde</h1>
        </div>
      ) : (
        <>
          <td style={styleCenterTable} scope="row">
            Totales de los inventarios
          </td>
          {stores.map((store, index) => {
            return (
              <td key={store.id} style={styleBorderedColumns}>
                <Table className="mt-1 mb-0">
                  <tbody>
                    <td style={{ width: "5rem", textAlign: "center" }}>
                      {currencyFormatterQuantity({ value: totalsInventories?.data[index]?.quantity_total })}
                    </td>
                    <td style={{ width: "5rem", textAlign: "center" }}>
                      {currencyFormatterMoney({
                        currency: "USD",
                        value: totalsInventories?.data[index]?.inventory_cost_total,
                      })}
                    </td>
                  </tbody>
                </Table>
              </td>
            )
          })}
          <td style={styleCenterTable}>
            <Table className="mt-1 mb-0">
              <tbody>
                <td style={{ width: "5rem", textAlign: "center" }}>
                  {currencyFormatterQuantity({
                    value: totalsInventories?.data[totalsInventories?.data.length - 1]?.quantity_total,
                  })}
                </td>
                <td style={{ width: "5rem", textAlign: "center" }}>
                  {currencyFormatterMoney({
                    currency: "USD",
                    value: totalsInventories?.data[totalsInventories?.data.length - 1]?.inventory_cost_total,
                  })}
                </td>
              </tbody>
            </Table>
          </td>
        </>
      )}
    </tr>
  )
}

TotalAverageInventories.propTypes = {
  stores: propTypes.array,
}

export default TotalAverageInventories
