import { API } from "config/API"

export const getAllStores = async (filters) => {
  const response = await API.get("/stores/all?ordering=name", { params: filters })
  return response
}

export const getStores = async (filters) => {
  const response = await API.get("/stores", { params: filters })
  return response
}

export const getStore = async (id) => {
  const response = await API.get(`/stores/${id}`)
  return response
}

export const createStore = async (payload) => {
  const response = await API.post("/stores", payload)
  return response
}

export const editStore = async (id, payload) => {
  const response = await API.patch(`/stores/${id}`, payload)
  return response
}

export const deleteStore = async (id) => {
  const response = await API.delete(`/stores/${id}`)
  return response
}
