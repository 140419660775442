import React from "react"
import propTypes from "prop-types"

const CashCutItemList = ({ item }) => {
  const date = new Date(item.date)
  const formattedDate = date.toLocaleString()
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }

  function currencyFormatter({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }

  return (
    <tr key={item.id}>
      <td style={styleCenterTable}>{formattedDate}</td>
      <td style={styleCenterTable}>{currencyFormatter({ currency: "USD", value: item.total_tickets })}</td>
      <td style={styleCenterTable}>{currencyFormatter({ currency: "USD", value: item.total_ticket_counter })}</td>
      <td style={styleCenterTable}>{currencyFormatter({ currency: "USD", value: item.total_invoices })}</td>
      <td style={styleCenterTable}>{currencyFormatter({ currency: "USD", value: item.card_pay })}</td>
      <td style={styleCenterTable}>{currencyFormatter({ currency: "USD", value: item.total_cash })}</td>
      <td style={styleCenterTable}>{item?.user?.first_name}</td>
    </tr>
  )
}

CashCutItemList.propTypes = {
  item: propTypes.object,
}

export default CashCutItemList
