import React from "react"
import propTypes from "prop-types"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"

const ProvidersItemList = ({ item, canDelete, canEdit, openEditModal, openDeleteModal }) => {
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }
  const { id, name, email, phone, rfc, observations } = item
  // refactor candidate
  const renderEditButton = () => {
    if (!canEdit) {
      return null
    }

    return (
      <Button color="primary" className="m-1" onClick={() => openEditModal(id)}>
        <i className="fa fa-edit" />
      </Button>
    )
  }

  const renderDeleteButton = () => {
    if (!canDelete) {
      return null
    }

    return (
      <Button color="danger" onClick={() => openDeleteModal(item)}>
        <i className="fa fa-trash" />
      </Button>
    )
  }

  return (
    <tr key={id}>
      <td style={styleCenterTable}>
        <Link to={{ pathname: `/providers/${item.id}` }}>{name}</Link>
      </td>
      <td style={styleCenterTable}>{email}</td>
      <td style={styleCenterTable}>{phone}</td>
      <td style={styleCenterTable}>{rfc || "--"}</td>
      <td style={styleCenterTable}>{observations || "--"}</td>
      <td style={styleCenterTable} className="text-center">
        {renderEditButton()}
        {renderDeleteButton()}
      </td>
    </tr>
  )
}

ProvidersItemList.propTypes = {
  item: propTypes.object,
  canDelete: propTypes.bool,
  canEdit: propTypes.bool,
  openEditModal: propTypes.func,
  openDeleteModal: propTypes.func,
}

export default ProvidersItemList
