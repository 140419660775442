import React, { useContext, useEffect, useState } from "react"
import propTypes from "prop-types"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"
import ErrorsText from "components/ErrorsText"
import { FieldArray, Formik, Field } from "formik"
import Select from "react-select"
import Swal from "sweetalert2"

import { Loading, RequiredIcon } from "components"
import { getAllStores } from "services/stores"
import { getInvetoryStore } from "services/inventories"
import { transferProducts } from "services/movementsStores"
import schema from "./schema"
import { UserContext } from "App"

const MovementForm = ({ toggle }) => {
  const [selectedOriginStore, setSelectedOriginStore] = useState(1)
  const [selectedDestinationStore, setSelectedDestinationStore] = useState(null)
  const [products, setProducts] = useState([])
  const stores = useQuery(["stores"], () => getAllStores())
  const { error, isError, isLoading, isSuccess, mutateAsync } = useMutation(transferProducts)

  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, "0")
  const day = String(today.getDate()).padStart(2, "0")
  const formattedDate = `${year}-${month}-${day}`
  const user = useContext(UserContext)

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  const submit = async (data) => {
    console.log("user", user.id);
    const dataTemp = { ...data }
    dataTemp.user = user.id
    dataTemp.movement_details.forEach((item, index) => {
      dataTemp.movement_details[index].product = item.product.value
    })
    console.log("user", user.id);

    try {
      const result = await mutateAsync(dataTemp)
      if (isSuccess || result) {
        Toast.fire("!Exitoso!", "El movimiento se ha sido realizado correctamente", "success")
        setSelectedOriginStore(1)
        setSelectedDestinationStore(null)
        toggle()
      }
    } catch (error) {
      Toast.fire("!Error!", error?.errors[0]?.message, "error")
    }
  }

  useEffect(() => {
    if (selectedOriginStore?.value) {
      getInvetoryStore(selectedOriginStore?.value).then((response) => {
        setProducts(response)
      })
    }
  }, [selectedOriginStore])

  return (
    <>
      {!stores.error && isLoading ? (
        <div className="my-5 text-center">
          <Loading isLoading={isLoading} size={2} />
        </div>
      ) : stores.error && !isLoading ? (
        <div className="my-5 text-center">
          <h1>Error intenta mas tarde</h1>
        </div>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            date: formattedDate,
            source_store: selectedOriginStore ? selectedOriginStore?.value : 0,
            destiny_store: selectedDestinationStore ? selectedDestinationStore?.value : 0,
            reason: "",
            description: "",
            user: "",
            movement_details: [{ quantity: "", product: {} }],
          }}
          validationSchema={schema}
          onSubmit={submit}
        >
          {({ values, handleChange, handleSubmit, handleBlur, touched, errors, setFieldValue, setTouched }) => (
            <Form className="form-horizontal" onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Label>
                    Origen: <RequiredIcon />
                  </Label>
                  <Select
                    name="source_store"
                    value={selectedOriginStore}
                    onBlur={handleBlur}
                    onChange={(selectedOption) => setSelectedOriginStore(selectedOption)}
                    options={stores?.data?.map((store) => ({ value: store.id, label: store.name }))}
                    placeholder="Seleccione un inventario de origen"
                    noOptionsMessage={() => "0 productos"}
                    loadingMessage={() => "Cargando..."}
                    isLoading={stores.isLoading}
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="source_store"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
                <Col md={6}>
                  <Label>
                    Destino: <RequiredIcon />
                  </Label>
                  <Select
                    name="destiny_store"
                    value={selectedDestinationStore}
                    onBlur={handleBlur}
                    onChange={(selectedOption) => setSelectedDestinationStore(selectedOption)}
                    options={stores?.data?.map((store) => ({ value: store.id, label: store.name }))}
                    placeholder="Seleccione un inventario de destino"
                    noOptionsMessage={() => "0 productos"}
                    loadingMessage={() => "Cargando..."}
                    isLoading={stores.isLoading}
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="destiny_store"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Col md={6}>
                  <Label>Fecha: </Label>
                  <Input disabled type="date" value={formattedDate} />
                </Col>
                <Col md={6}>
                  <Label>Usuario: </Label>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {user?.first_name} {user?.last_name}
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px", marginBottom: "10px" }}>
                <Label for="movement_details">Lista de productos</Label>
                <Col lg={4} className="">
                  <Label className="form-label">
                    Cantidad <RequiredIcon />
                  </Label>
                </Col>
                <Col lg={6} className="">
                  <Label className="form-label">
                    Producto <RequiredIcon />
                  </Label>
                </Col>
                <Col lg={1} className="text-center d-lg-flex">
                  <Label className="form-label">Eliminar</Label>
                </Col>
                <FieldArray
                  name="movement_details"
                  value={values?.movement_details}
                  render={(arrayHelpers) => (
                    <div>
                      {values?.movement_details.map((_, index) => (
                        <div className="mt-2" key={index}>
                          <Field className="d-none" name={`movement_details.${index}.id`} />
                          <Row>
                            <Col lg={4} className="mb-3">
                              <Input
                                name={`movement_details.${index}.quantity`}
                                className="form-control"
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.movement_details[index]?.quantity}
                              />
                              {touched?.movement_details &&
                                touched?.movement_details[index]?.quantity &&
                                errors?.movement_details &&
                                errors?.movement_details[index]?.quantity && (
                                  <FormFeedback type="invalid">
                                    {errors?.movement_details[index]?.quantity}
                                  </FormFeedback>
                                )}
                            </Col>
                            <Col lg={6}>
                              <Select
                                id={`movement_details.${index}.product`}
                                value={values?.movement_details[index]?.product}
                                onBlur={handleBlur}
                                options={products?.results?.map((product) => ({
                                  value: product.product.id,
                                  label: product.product.description,
                                }))}
                                onChange={(option) => {
                                  setFieldValue(`movement_details.[${index}].product`, option)
                                }}
                                placeholder="Seleccione un producto"
                                noOptionsMessage={() => "0 productos"}
                                loadingMessage={() => "Cargando..."}
                              />
                              {touched?.movement_details &&
                                touched?.movement_details[index]?.product &&
                                errors?.movement_details &&
                                errors?.movement_details[index]?.product && (
                                  <FormFeedback type="invalid">{errors?.movement_details[index]?.product}</FormFeedback>
                                )}
                            </Col>
                            {index !== 0 && (
                              <Col lg={1} className="text-center d-lg-flex">
                                <div>
                                  <button
                                    className="btn btn-danger"
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                </div>
                              </Col>
                            )}
                          </Row>
                        </div>
                      ))}
                      <div>
                        <Label
                          style={{ color: "#0dcaf0", cursor: "pointer" }}
                          onClick={() => arrayHelpers.push({ quantity: "", product: {} })}
                        >
                          Agregar fila
                        </Label>
                      </div>
                    </div>
                  )}
                />
              </Row>
              <Row>
                <Col>
                  <Label>Motivo:</Label>
                  <Input
                    name="reason"
                    className="form-control"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.reason}
                    maxLength="50"
                    required
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="reason"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col>
                  <Label>Descripcion:</Label>
                  <Input
                    name="description"
                    className="form-control"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    maxLength="100"
                  />
                  <ErrorsText
                    errorsBack={error}
                    errorsForm={errors}
                    formName="description"
                    isErrorBack={isError}
                    touched={touched}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                    color={"success"}
                    type="submit"
                    disabled={isLoading}
                  >
                    Transferir
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

MovementForm.propTypes = {
  toggle: propTypes.func,
}

export default MovementForm
