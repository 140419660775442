import * as Yup from "yup"

import { messages } from "utils/messages"

const schema = Yup.object({
  current_password: Yup.string().required(messages.required),
  new_password: Yup.string()
    .required(messages.required)
    .matches(
      /^(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]))^.{8,}$/,
      "Por favor, ingrese una contraseña de al menos 8 caracteres con 1 letra minúscula, 1 letra mayúscula y 1 número."
    ),
  password_confirm: Yup.string()
    .oneOf([Yup.ref("new_password"), null], messages.password_confirm)
    .required(messages.required),
})

export default schema
