import { useEffect } from "react"
import propTypes from "prop-types"
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import { getUser, editUser } from "services/users"
import { Loading } from "components"
import UserForm from "./UserForm"

const EditModalForm = ({ isOpen, toggle, userId, setShowAlertSuccess, setMsgSuccess }) => {
  const { data: dataUser, error, isLoading, isError, refetch } = useQuery(["user", userId], () => getUser(userId))

  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["user", userId], (data) => editUser(userId, data))

  const submit = async (dataUser) => {
    const dataTemp = { ...dataUser }
    dataTemp.user_type = dataTemp?.user_type?.value || null
    dataTemp.user_permissions = dataTemp?.user_permissions?.map((permission) =>
      typeof permission === "object" ? permission.id : permission
    )
    await mutateAsync(dataTemp)
  }

  useEffect(() => {
    if (isSuccessEdit) {
      setMsgSuccess("Usuario modificado satisfactoriamente")
      setShowAlertSuccess(true)
      toggle()
      refetch()
    }
  }, [isSuccessEdit])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Editar Usuario
      </ModalHeader>
      <ModalBody>
        {isLoading && (
          <div className="text-center">
            <Loading isLoading={isLoading} size={2} />
          </div>
        )}
        {isError && (
          <div className="text-center">
            <Alert color="danger" isOpen={isError}>
              {error?.errors?.map((error) => error?.message)}
            </Alert>
          </div>
        )}
        {(!isLoading || !isError) && (
          <UserForm
            data={dataUser}
            error={errorEdit}
            isCreateModal={false}
            isError={isErrorEdit}
            isLoadingSubmit={isLoadingEdit}
            submit={submit}
            toggle={toggle}
          />
        )}
      </ModalBody>
    </Modal>
  )
}

EditModalForm.defaultProps = {
  userId: null,
}

EditModalForm.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  userId: propTypes.string,
  setShowAlertSuccess: propTypes.func,
  setMsgSuccess: propTypes.func,
}

export default EditModalForm
