import propTypes from "prop-types"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

import { useQuery } from "@tanstack/react-query"
import { getMovementBetweenStores } from "services/movementsStores"
import MovementRetrieve from "./retrieve-movement"

const RetrieveModalMovement = ({ isOpen, toggle, movementId }) => {
  const { data, error, isLoading } = useQuery(["movement", movementId], () => getMovementBetweenStores(movementId))

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="xl" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Detalles de movimiento entre inventarios
      </ModalHeader>
      <ModalBody>
        <MovementRetrieve data={data} error={error} isLoading={isLoading} />
      </ModalBody>
    </Modal>
  )
}

RetrieveModalMovement.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  movementId: propTypes.number,
}

export default RetrieveModalMovement
