import React, { useState, useEffect, useRef } from "react"
import propTypes from "prop-types"
import { Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Row } from "reactstrap"

const SearchProductsModal = ({ isOpen, toggleModal, products, barcode, setBarcode }) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [filteredProducts, setFilteredProducts] = useState(products)
  const [activeRowIndex, setActiveRowIndex] = useState(0)
  const searchInput = useRef(null)
  const tableContainerRef = useRef(null)

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (isOpen) {
        if (event.key === "ArrowRight") {
          searchInput.current.focus()
          setActiveRowIndex(0)
        }
        if (event.key === "ArrowUp" && activeRowIndex > 0) {
          setActiveRowIndex(activeRowIndex - 1)
        } else if (event.key === "ArrowDown" && activeRowIndex < filteredProducts?.length - 1) {
          setActiveRowIndex(activeRowIndex + 1)
        } else if (event.code === "Space") {
          const selectedProduct = filteredProducts[activeRowIndex]
          if (selectedProduct) {
            handleProductSelect(selectedProduct.id)
          }
        }
        if (tableContainerRef.current && activeRowIndex > 2) {
          tableContainerRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          })
        }
      }
    }

    window.addEventListener("keydown", handleKeyPress)

    return () => {
      window.removeEventListener("keydown", handleKeyPress)
    }
  }, [activeRowIndex, filteredProducts])

  useEffect(() => {
    const filtered = products?.filter(
      (product) =>
        !searchQuery ||
        product.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.key?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    if (barcode !== "") {
      if (searchQuery !== "" && searchQuery !== null) {
        setFilteredProducts(filtered)
      } else {
        const filteredBarcode = products?.filter(
          (product) =>
            product.description?.toLowerCase().includes(barcode?.toLowerCase()) ||
            product.key?.toLowerCase().includes(barcode?.toLowerCase())
        )
        setFilteredProducts(filteredBarcode)
      }
    } else {
      if (searchQuery !== "" && searchQuery !== null) {
        setFilteredProducts(filtered)
      } else {
        const filteredBarcode = products?.filter(
          (product) =>
            product.description?.toLowerCase().includes(barcode?.toLowerCase()) ||
            product.key?.toLowerCase().includes(barcode?.toLowerCase())
        )
        setFilteredProducts(filteredBarcode)
      }
    }
  }, [searchQuery, products, isOpen])

  function currencyFormatter({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }

  const handleProductSelect = (productId) => {
    const selectedProduct = products?.find((product) => product.id === productId)
    if (selectedProduct) {
      setFilteredProducts(products)
      setSearchQuery(null)
      setBarcode(selectedProduct.key)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    setSearchQuery("")
    setActiveRowIndex(0)
    toggleModal() // Cierra el modal
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleCloseModal}
      style={{ height: "500px" }}
      size="xl"
      scrollable={true}
      // scrollable={{ scrollbarSize: 0, maxHeight: "500px" }}
      contentClassName="overflow-auto"
      modalClassName="overflow-auto"
      centered
    >
      <ModalHeader toggle={handleCloseModal}>Seleccionar Producto</ModalHeader>
      <ModalBody>
        <Row style={{ marginBottom: "1rem", marginLeft: "8px", marginRight: "8px" }}>
          <Label htmlFor="searchQuery">Buscar:</Label>
          <Input
            innerRef={searchInput}
            type="text"
            id="searchQuery"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Row>
        <Table innerRef={tableContainerRef}>
          <thead>
            <tr>
              <th>Codigo</th>
              <th>Descripcion</th>
              <th>Precio</th>
              <th style={{ textAlign: "center" }}>Seleccionar</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts?.map((product, index) => (
              <tr
                key={product.id}
                ref={index === activeRowIndex ? tableContainerRef : null}
                style={index === activeRowIndex ? { backgroundColor: "#5DADE2", color: "red" } : {}}
              >
                <td style={index === activeRowIndex ? { backgroundColor: "#5DADE2" } : {}}>{product.key}</td>
                <td style={index === activeRowIndex ? { backgroundColor: "#5DADE2" } : {}}>{product.description}</td>
                <td style={index === activeRowIndex ? { backgroundColor: "#5DADE2" } : {}}>
                  {currencyFormatter({ currency: "USD", value: product.product.price.amount })}
                </td>
                <td
                  style={
                    index === activeRowIndex
                      ? { backgroundColor: "#5DADE2", textAlign: "center" }
                      : { textAlign: "center" }
                  }
                >
                  <Button color="primary" onClick={() => handleProductSelect(product.id)}>
                    Seleccionar
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

SearchProductsModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  toggleModal: propTypes.func.isRequired,
  products: propTypes.any,
  barcode: propTypes.string,
  setBarcode: propTypes.func,
}

export default SearchProductsModal
