import React, { useState, useEffect } from "react";
import propTypes from "prop-types"
import Swal from "sweetalert2";
import {
    Button,
    Label,
    Input,
    Modal,
    Row,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";

const ModalChange = ({ total, isOpen, toggleModal, handleSubmit }) => {
    const [amountIncoming, setAmountIncoming] = useState(0);
    const [restMoney, setRestMoney] = useState(0);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer)
            toast.addEventListener("mouseleave", Swal.resumeTimer)
        }
    })

    useEffect(() => {
        const calculatedChange = amountIncoming - total;
        setRestMoney(calculatedChange >= 0 ? calculatedChange : 0);
    }, [amountIncoming, total]);

    const finalizarVenta = () => {
        if (restMoney < 0) {
            Toast.fire({
                icon: "error",
                title: "Saldo Insuficiente"
            })
            return;
        }
        setAmountIncoming(0);
        setRestMoney(0);
        toggleModal();
        handleSubmit();
    }

    return (
        <Modal isOpen={isOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Realizar Venta</ModalHeader>
            <ModalBody>
                <Row>
                    <h3>Total: {total} pesos</h3>
                </Row>
                <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
                <Label htmlFor="amountIncoming">Monto recibido:</Label>
                <Input
                    type="number"
                    id="amountIncoming"
                    value={amountIncoming}
                    onChange={(e) => setAmountIncoming(parseFloat(e.target.value))}
                />
                <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
                <h3>Cambio: {(restMoney).toFixed(2)} pesos</h3>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={finalizarVenta}>
                    Finalizar Venta
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

ModalChange.propTypes = {
    total: propTypes.string.isRequired,
    isOpen: propTypes.bool.isRequired,
    toggleModal: propTypes.func.isRequired,
    handleSubmit: propTypes.func.isRequired,
}

export default ModalChange;
