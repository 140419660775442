import React, { useEffect, useState } from "react"
import { Alert, Button, Container, Col, Row } from "reactstrap"
import { useQuery } from "@tanstack/react-query"

import storage from "services/storage"
import { authUserKey } from "config/API"
import { getCashCutCounterManual, getAllLimitForCashCut } from "services/periodic-tasks"
import Swal from "sweetalert2"
import "assets/scss/custom/components/periodic-task.scss"
import { EditModalForm } from "components/cash-control"

const CashControl = () => {
  document.title = "Control de Efectivo | Punto de venta"
  const { error, isError } = useQuery(["cash-cuts"], () => getCashCutCounterManual())
  const limitCashControl = useQuery(["limit-cash-cuts"], () => getAllLimitForCashCut())
  const permissions = storage.get(authUserKey).user_permissions || []
  const canAdd = permissions.some((e) => e.codename === "add_cashcut")

  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")
  const [modalCreate, setmodalCreate] = useState(false)
  const toggleModalCreate = () => {
    setmodalCreate(!modalCreate)
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  useEffect(() => {
    if (showAlertSuccess) {
      Toast.fire("¡Actualizado!", msgSuccess, "success")
      setShowAlertSuccess(false)
    }
  }, [showAlertSuccess])

  function currencyFormatter({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }

  return !permissions.some((e) => e.codename === "view_cashcut") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="page-content">
      {canAdd && (
        <EditModalForm
          isOpen={modalCreate}
          toggle={toggleModalCreate}
          setShowAlertSuccess={setShowAlertSuccess}
          setMsgSuccess={setMsgSuccess}
          data={limitCashControl?.isLoading ? {} : limitCashControl?.data[1]}
          error={limitCashControl.error}
          isLoading={limitCashControl.isLoading}
          isError={limitCashControl.isError}
          refetch={limitCashControl.refetch}
          limitId={limitCashControl?.isLoading ? "" : limitCashControl?.data[1].id}
        />
      )}
      <Container fluid>
        <Alert color="danger" isOpen={isError}>
          {error?.errors?.map((error) => error?.message)}
        </Alert>

        <h2 style={{ textAlign: "center" }}>Control de efectivo</h2>
        <div className="bg-white p-3">
          <div className="image">
            <Row>
              <Col
                xs="auto"
                className="fa fa-money-bill display-grid col-icon"
                style={{ paddingTop: "1rem", marginInline: "5rem" }}
              >
                <i />
              </Col>
              <Col className="justify-content-center display-grid">
                <h3>
                  Establezca límites de efectivo en sus cajas registradoras para un control preciso. Evite excesos y
                  asegure el efectivo. Ajuste el límite según sea necesario. El sistema mostrará advertencias cuando se
                  alcance el límite y, después de varias advertencias, no permitirá ventas adicionales.
                </h3>
              </Col>
            </Row>
            <br />
            <Row>
              {canAdd && (
                <Col className="justify-content-end" style={{ display: "flex", alignItems: "flex-end" }}>
                  <Col>
                    <Alert
                      color="info"
                      className="d-flex align-items-center me-2"
                      style={{ height: "2.3rem", marginBottom: "0" }}
                    >
                      El límite de control de efectivo actualmente es de:<p>&nbsp;</p>
                      {limitCashControl?.data
                        ? currencyFormatter({ currency: "USD", value: limitCashControl?.data[1]?.limit })
                        : ""}
                    </Alert>
                  </Col>
                  <Button color="warning" className="d-flex align-items-center me-2" onClick={toggleModalCreate}>
                    <i className="me-2 fa fa-edit" />
                    <span className="text-truncate">Editar límite</span>
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default CashControl
