import propTypes from "prop-types"
import React, { useEffect, useRef, useContext, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { Link, withRouter } from "react-router-dom"

import storage from "services/storage"
import { authUserKey } from "config/API"
import GlobalShortcuts from "./GlobalShortcuts"
import { UserContext } from "App"

const SidebarContent = ({ location }) => {
  const ref = useRef()
  const userData = useContext(UserContext)
  const [pathnameActive, setPathnameActive] = useState()
  useEffect(() => {
    const pathName = location.pathname
    const parts = pathName.split("/")
    const trimmedPath = parts.slice(0, -1).join("/")
    setPathnameActive(trimmedPath)
    const initMenu = () => {
      // eslint-disable-next-line
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") // a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  const permissions = storage.get(authUserKey).user_permissions || []
  const isBrand = permissions.some((e) => e.codename === "view_brand")
  const isBranchOffice = permissions.some((e) => e.codename === "view_branchoffice")
  const isCustomer = permissions.some((e) => e.codename === "view_customer")
  const isProvider = permissions.some((e) => e.codename === "view_provider")
  const isProduct = permissions.some((e) => e.codename === "view_product")
  const isInvoice = permissions.some((e) => e.codename === "view_invoice")
  const isSale = permissions.some((e) => e.codename === "view_ticket")
  const isCashCut = permissions.some((e) => e.codename === "add_cashcut")
  const isTicket = permissions.some((e) => e.codename === "view_invoice")
  const isTicketInvoice = permissions.some((e) => e.codename === "view_ticket_invoices")
  const isAdmin = userData?.user_type

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {isAdmin === "A" && (
              <>
                <li className={pathnameActive === "/dashboard" ? "mm-active" : null}>
                  <Link to="/dashboard">
                    <i className="fa fa-chart-line" />
                    <span>Dashboard</span>
                  </Link>
                </li>
              </>
            )}

            {(isSale || isTicket || isTicketInvoice || isCashCut) && (
              <>
                <li className="menu-title">Ventas</li>
                <li className={pathnameActive === "/sale" ? "mm-active" : null}>
                  <Link to="/sale">
                    <i className="fa fa-cash-register"></i>
                    <span>Punto de Venta</span>
                  </Link>
                </li>
                {isTicket && (
                  <li className={pathnameActive === "/sale" ? "mm-active" : null}>
                    <Link to="/sales" className="dropdown-item">
                      <i className="bx bx-dollar" />
                      <span>Tickets</span>
                    </Link>
                  </li>
                )}
                {isTicketInvoice && (
                  <li className={pathnameActive === "/sale" ? "mm-active" : null}>
                    <Link to="/sales-invoices" className="dropdown-item">
                      <i className="bx bx-file" />
                      <span>Ventas facturadas</span>
                    </Link>
                  </li>
                )}
                {isCashCut && (
                  <li className={pathnameActive === "/sale" ? "mm-active" : null}>
                    <Link to="/cash-cuts" className="dropdown-item">
                      <i className="bx bx-wallet" />
                      <span>Cortes de caja</span>
                    </Link>
                  </li>
                )}
              </>
            )}

            {(isProduct || isBrand || isProvider || isCustomer) && (
              <>
                <li className="menu-title">Gestión de catálogos</li>
                {isProduct && (
                  <li className={pathnameActive === "/products" ? "mm-active" : null}>
                    <Link to="/products">
                      <i className="fa fa-boxes" />
                      <span>Productos</span>
                    </Link>
                  </li>
                )}
                {isBrand && (
                  <li className={pathnameActive === "/brands" ? "mm-active" : null}>
                    <Link to="/brands">
                      <i className="fa fa-dolly" />
                      <span>Marcas</span>
                    </Link>
                  </li>
                )}
                {isProvider && (
                  <li className={pathnameActive === "/providers" ? "mm-active" : null}>
                    <Link to="/providers">
                      <i className="fa fa-truck" />
                      <span>Proveedores</span>
                    </Link>
                  </li>
                )}
                {isCustomer && (
                  <li className={pathnameActive === "/customers" ? "mm-active" : null}>
                    <Link to="/customers">
                      <i className="fa fa-users" />
                      <span>Clientes</span>
                    </Link>
                  </li>
                )}
              </>
            )}

            {(isInvoice || isBranchOffice) && (
              <>
                <li className="menu-title">Mercancia</li>
                <li className={pathnameActive === "/invoices" ? "mm-active" : null}>
                  <Link to="/invoices">
                    <i className="fa fa-file-invoice-dollar" />
                    <span>Compras</span>
                  </Link>
                </li>
                <li>
                  <Link to="/inventories-sections" className="dropdown-item">
                    <i className="bx bx-box font-size-16 align-middle me-1" />
                    <span>Inventarios</span>
                  </Link>
                </li>
              </>
            )}

            {/* <li> TODO: Confirm delete
              <Link to="/agents">
                <i className="fa fa-user-tie" />
                <span>Agentes</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
      <GlobalShortcuts />
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: propTypes.object,
}

export default withRouter(SidebarContent)
