import React, { useEffect, useState } from "react"
import propTypes from "prop-types"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"
import "assets/scss/custom/components/invoices.scss"
import { API } from "config/API"

const InvoiceItemList = ({ item, canDelete, canEdit, openEditModal, openDeleteModal }) => {
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle", padding: "0" }
  const apiUrl = ["https://api.pos.emkode.org", "https://api-dulceria.emkode.com", "https://api-plasticos.emkode.com"]

  const [pdfFile, setPdfFile] = useState("")
  const [xmlFile, setXmlFile] = useState("")

  useEffect(()=>{
    if (apiUrl.includes(API.defaults.baseURL)) {
      const pdfArray = item?.invoice_pdf?.split("media")
      const xmlArray = item?.invoice_xml?.split("media")
      if (pdfArray && pdfArray[0].slice(-1) !== ".") {
        const pdfString = pdfArray[0] + ".media" + pdfArray[1]
        setPdfFile(pdfString)
      }
      if (xmlArray && xmlArray[0].slice(-1) !== ".") {
        const xmlString = xmlArray[0] + ".media" + xmlArray[1]
        setXmlFile(xmlString)
      }
    } else {
      setPdfFile(item?.invoice_pdf)
      setXmlFile(item?.invoice_xml)
    }
  }, [])

  const renderEditButton = () => {
    if (!canEdit) {
      return null
    }

    return (
      <Button color="primary" className="m-1" size="sm" onClick={() => openEditModal(item.id)}>
        <i className="fa fa-edit" />
      </Button>
    )
  }

  function currencyFormatter({ currency, value }) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency,
    })
    return formatter.format(value)
  }

  const renderDeleteButton = () => {
    if (!canDelete) {
      return null
    }

    return (
      <Button color="danger" size="sm" onClick={() => openDeleteModal(item)}>
        <i className="fa fa-trash" />
      </Button>
    )
  }

  const vouchers = () => {
    const data = item.files.filter((file) => {
      return file.type === "Voucher"
    })
    data.map((file)=>{
      if (apiUrl.includes(API.defaults.baseURL)) {
        const stringSplited = file?.file?.split("media")
        file.file = stringSplited[0] + ".media" + stringSplited[1]
      }
      return file
    })
    return data
  }

  const creditNotes = () => {
    const data = item.files.filter((file) => {
      return file.type === "NC"
    })
    data.map((file)=>{
      if (apiUrl.includes(API.defaults.baseURL)) {
        const stringSplited = file?.file?.split("media")
        file.file = stringSplited[0] + ".media" + stringSplited[1]
      }
      return file
    })
    return data
  }

  return (
    <tr
      key={item.id}
      className={item.status === "Pagada" ? "center-content table-success" : "center-content table-danger"}
    >
      <td style={styleCenterTable}>
        <Link to={{ pathname: `/invoices/${item.id}` }}>{item.number_invoice}</Link>
      </td>
      <td style={styleCenterTable}>
        {new Date(item.arrival_date).toLocaleDateString("es-MX", { year: "numeric", month: "short", day: "numeric" })}
      </td>
      <td style={styleCenterTable}>
        {new Date(item.pay_date).toLocaleDateString("es-MX", { year: "numeric", month: "short", day: "numeric" })}
      </td>
      <td style={styleCenterTable}>{item.provider.name}</td>
      <td style={styleCenterTable}>
        {item?.invoice_pdf ? (
          <Link to={{ pathname: `${pdfFile}` }} target="_blank">
            <i className="fa fa-file-pdf invoice-icon" />
          </Link>
        ) : (
          <p>---</p>
        )}
      </td>
      <td style={styleCenterTable}>
        {item?.invoice_xml ? (
          <Link to={{ pathname: `${xmlFile}` }} target="_blank">
            <i className="fa fa-file-excel invoice-icon" />
          </Link>
        ) : (
          <p>---</p>
        )}
      </td>
      <td style={styleCenterTable}>{currencyFormatter({ currency: "USD", value: item.total })}</td>
      <td style={styleCenterTable}>{currencyFormatter({ currency: "USD", value: item.discount })}</td>
      <td style={styleCenterTable}>
        {vouchers().map((voucher) => (
          <Link key={voucher.id} to={{ pathname: `${voucher.file}` }} target="_blank">
            {`${voucher.file}`.slice(`${voucher.file}`.length - 3, `${voucher.file}`.length) === "pdf" ? (
              <i className="fa fa-file-pdf invoice-icon" />
            ) : (
              <i className="fa fa-file-excel invoice-icon" />
            )}
          </Link>
        ))}
      </td>
      <td style={styleCenterTable}>
        {creditNotes().map((nc) => (
          <Link key={nc.id} to={{ pathname: `${nc.file}` }} target="_blank">
            {`${nc.file}`.slice(`${nc.file}`.length - 3, `${nc.file}`.length) === "pdf" ? (
              <i className="fa fa-file-pdf invoice-icon" />
            ) : (
              <i className="fa fa-file-excel invoice-icon" />
            )}
          </Link>
        ))}
      </td>
      <td style={styleCenterTable}>
        {renderEditButton()}
        {renderDeleteButton()}
      </td>
    </tr>
  )
}

InvoiceItemList.propTypes = {
  item: propTypes.object,
  canDelete: propTypes.bool,
  canEdit: propTypes.bool,
  openEditModal: propTypes.func,
  openDeleteModal: propTypes.func,
}

export default InvoiceItemList
