import { useState, useEffect } from "react"
import propTypes from "prop-types"
import { Formik } from "formik"
import { useQuery } from "@tanstack/react-query"
import { Button, Form, Input, Label, Alert, Row, Col } from "reactstrap"
import Select from "react-select"

import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"
import { getAllProviders } from "services/providers"
import schema from "./schema"


const AgentForm = ({ isCreate, submit, data, toggle, isLoading, isError, error, disabledForm}) => {
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])

  const {
    data: providers,
    error: errorProvider,
    isError: isErrorProviders,
    isLoading: isLoadingProvider,
  } = useQuery(["providers"], getAllProviders)

  const isLoadingDiv = () => {
    return (
      <div className="text-center">
        <Loading isLoading={isLoadingProvider} size={2} />
      </div>
    )
  }

  const isErrorDiv = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={isErrorProviders}>
          {errorProvider?.errors?.map((error) => error?.message)}
        </Alert>
      </div>
    )
  }

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  return (
    <>
      {isLoadingProvider ? (
        isLoadingDiv()
      ) : isErrorProviders ? (
        isErrorDiv()
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            name: data?.name ?? "",
            email: data?.email ?? "",
            phone: data?.phone ?? "",
            provider: data?.provider ? { value: data?.provider?.id, label: data?.provider?.name } : undefined,
          }}
          validationSchema={schema}
          onSubmit={submit}
        >
          {({ values, setFieldValue, handleChange, handleSubmit, handleBlur, touched, errors, setTouched }) => (
            <Form className="form-horizontal" onSubmit={handleSubmit}>
              <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
                {isError && error?.errors?.map((error) => error?.message)}
              </Alert>

              <Row className="mb-3">
                <Col>
                  <Label className="form-label">
                    Nombre <RequiredIcon />
                  </Label>
                  <Input
                    name="name"
                    className="form-control"
                    type="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    invalid={
                      !!(touched.name && errors.name) ||
                      (isError && validationErrors.findIndex((e) => e.field === "name") >= 0)
                    }
                  />
                  <ErrorsText
                    formName="name"
                    touched={touched}
                    errorsForm={errors}
                    isErrorBack={isError}
                    errorsBack={error}
                  />
                </Col>
                <Col>
                  <Label className="form-label">
                    Teléfono <RequiredIcon />
                  </Label>
                  <Input
                    name="phone"
                    className="form-control"
                    type="phone"
                    maxLength={10}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    invalid={
                      !!(touched.phone && errors.phone) ||
                      (isError && validationErrors.findIndex((e) => e.field === "phone") >= 0)
                    }
                  />
                  <ErrorsText
                    formName="phone"
                    touched={touched}
                    errorsForm={errors}
                    isErrorBack={isError}
                    errorsBack={error}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    className="form-control"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    invalid={
                      !!(touched.email && errors.email) ||
                      (isError && validationErrors.findIndex((e) => e.field === "email") >= 0)
                    }
                  />
                  <ErrorsText
                    formName="email"
                    touched={touched}
                    errorsForm={errors}
                    isErrorBack={isError}
                    errorsBack={error}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Label className="form-label">Proveedor <RequiredIcon /></Label>
                  <Select
                    name="provider"
                    id="provider"
                    className={
                      (touched.provider && errors.provider) ||
                      (isError && validationErrors.findIndex((e) => e.field === "provider") >= 0)
                        ? "is-invalid"
                        : undefined
                    }
                    value={values.provider}
                    isDisabled={disabledForm}
                    onChange={(val) => {
                      setFieldValue("provider", val)
                    }}
                    onBlur={() => {
                      touched.price = true
                      setTouched(touched)
                    }}
                    isLoading={isLoadingProvider}
                    options={providers?.map((provider) => ({ value: provider.id, label: provider.name }))}
                    noOptionsMessage={() => "Sin Proveedores"}
                    loadingMessage={() => "Cargando..."}
                    placeholder="Proveedores"
                  />
                  <ErrorsText
                    formName="provider"
                    touched={touched}
                    errorsForm={errors}
                    isErrorBack={isError}
                    errorsBack={error}
                  />
                </Col>
              </Row>

              <Row>
                <Col className="d-flex justify-content-end">
                  <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                    <i className="fa fa-times" /> Cancelar
                  </Button>
                  <Button color="success" type="submit" disabled={isLoading}>
                    <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}


AgentForm.defaultProps = {
  data: null,
}


AgentForm.propTypes = {
  isCreate: propTypes.bool,
  submit: propTypes.func,
  toggle: propTypes.func,
  data: propTypes.object,
  isLoading: propTypes.bool,
  isError: propTypes.bool,
  error: propTypes.any,
  disabledForm: propTypes.bool,
}


export default AgentForm
