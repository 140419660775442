import propTypes from "prop-types"
import { Shortcut } from "@shopify/react-shortcuts"


const Shortcuts = (props) => {
  const {
    toggleModalCreate, 
    toggleModalImport, 
    downloadTemplate
  } = props

  return (
    <>
      <Shortcut ordered={["a"]} onMatch={() => toggleModalCreate()} />
      <Shortcut ordered={["i"]} onMatch={() => toggleModalImport()} />
      <Shortcut ordered={["p"]} onMatch={() => downloadTemplate()} />
    </>
  )
}


Shortcuts.defaultProps = {
  toggleModalCreate: () => {},
  toggleModalImport: () => {},
  downloadTemplate: () => {},
}


Shortcuts.propTypes = {
  toggleModalCreate: propTypes.func,
  toggleModalImport: propTypes.func,
  downloadTemplate: propTypes.func,
}


export default Shortcuts
