import { API } from "config/API"

export const transferProducts = async (payload) => {
  const response = await API.post("/movements-between-stores", payload)
  return response
}

export const getMovements = async (page)=> {
  const response = await API.get(`/movements-between-stores?page=${page}`)
  return response
}

export const getMovementsBetweenStores = async (filters)=> {
  const response = await API.get("/movements-between-stores", { params: filters })
  return response
}

export const getMovementBetweenStores = async (id)=> {
  const response = await API.get(`/movements-between-stores/${id}`)
  return response
}
