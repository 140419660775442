import { useEffect } from "react"
import propTypes from "prop-types"
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import { getInvoice, editInvoice } from "services/invoices"
import { Loading } from "components"
import InvoiceForm from "./invoice-form"

const EditModalForm = ({ isOpen, toggle, invoiceId, setShowAlertSuccess, setMsgSuccess }) => {
  const { data, error, isLoading, isError, refetch } = useQuery(["invoice", invoiceId], () => getInvoice(invoiceId))

  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["invoice", invoiceId], (data) => editInvoice(invoiceId, data))

  const submit = async (data) => {
    const dataTemp = { ...data }
    dataTemp.type = dataTemp?.type?.value || null
    dataTemp.status = dataTemp?.status?.value || null
    dataTemp.business_name = dataTemp?.business_name?.value || null
    dataTemp.payment_condition = dataTemp?.payment_condition?.value || null
    dataTemp.payment_method = dataTemp?.payment_method?.value || null
    dataTemp.provider = dataTemp?.provider?.value || null
    dataTemp.files = dataTemp?.files || []
    dataTemp.discount = parseFloat(dataTemp?.discount.replace(/,/g, "")) || 0
    dataTemp.total = parseFloat(dataTemp?.total.replace(/,/g, "")) || 0
    await mutateAsync(dataTemp)
  }

  useEffect(() => {
    if (isSuccessEdit) {
      setMsgSuccess("Factura modificada satisfactoriamente")
      setShowAlertSuccess(true)
      refetch()
      toggle()
    }
  }, [isSuccessEdit])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Editar Factura
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className="text-center">
            <Loading isLoading={isLoading} size={2} />
          </div>
        ) : isError ? (
          <div className="text-center">
            <Alert color="danger" isOpen={isError}>
              {error?.errors?.map((error) => error?.message)}
            </Alert>
          </div>
        ) : (
          <InvoiceForm
            isCreateModal={false}
            submit={submit}
            toggle={toggle}
            isLoading={isLoadingEdit}
            isError={isErrorEdit}
            error={errorEdit}
            data={data}
            refetchData={refetch}
          />
        )}
      </ModalBody>
    </Modal>
  )
}

EditModalForm.defaultProps = {
  customerId: null,
}

EditModalForm.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  invoiceId: propTypes.string,
  setShowAlertSuccess: propTypes.func,
  setMsgSuccess: propTypes.func,
}

export default EditModalForm
