import React, { useCallback, useState } from "react"
import { Alert, Container, Pagination, PaginationLink, PaginationItem, Table } from "reactstrap"
import { useQuery } from "@tanstack/react-query"
import { debounce } from "lodash"

import storage from "services/storage"
import { authUserKey } from "config/API"
import { Loading } from "components"
import { useLocation } from "react-router-dom"
import { getMovementsBetweenStores } from "services/movementsStores"
import MovementItemList from "./movements-item-list"
import ListHeader from "ui-componets/list-header"
import CreateModalMovement from "./ModalMovements/CreateModalMovement"

const MovementsBetweenStoresList = () => {
  document.title = "Lista de movimientos | Punto de venta"

  const Location = useLocation()
  const [filters, setFilters] = useState("")
  const [searchTxt, setSearchTxt] = useState(new URLSearchParams(Location.search).get("search") ?? "")
  const movements = useQuery(["movements", filters, filters.toString()], () => getMovementsBetweenStores(filters))
  const [modalCreate, setmodalCreate] = useState(false)

  const permissions = storage.get(authUserKey).user_permissions || []
  const canAdd = permissions.some((e) => e.codename === "add_store")
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }
  const isLoading = movements.isLoading

  const toggleModalCreate = () => {
    setmodalCreate(!modalCreate)
    movements.refetch()
  }

  const handleSearch = (pageParams) => {
    const params = new URLSearchParams(filters)
    if (pageParams) {
      const newParams = new URLSearchParams(pageParams)
      window.history.replaceState({}, null, `${Location.pathname}/movements?${newParams}`)
      setFilters(newParams)
    } else if (searchTxt) {
      params.set("search", searchTxt)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}/movements?${params}`)
      setFilters(params)
    } else {
      if (params.get("search")) {
        params.delete("offset")
        params.delete("per_page")
        params.delete("search")
        window.history.replaceState({}, null, `${Location.pathname}/movements?${params}`)
        setFilters(params)
      }
    }
  }

  const debouncedSearch = useCallback(
    debounce((text) => {
      const params = new URLSearchParams()
      params.set("search", text)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}/movements?${params}`)
      setFilters(params)
    }, 300),
    []
  )

  function handleChangeSearch(event) {
    setSearchTxt(event.target.value)
    debouncedSearch(event.target.value)
  }

  return !permissions.some((e) => e.codename === "view_store") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="mt-3">
      <Container fluid>
        {canAdd && (
          <CreateModalMovement
            isOpen={modalCreate}
            toggle={toggleModalCreate}
          />
        )}
        <Alert color="danger" isOpen={movements.isError}>
          {movements?.error?.errors?.map((error) => error?.message)}
        </Alert>
        <h2 className="mb-2">Movimientos entre inventarios</h2>
        <div className="mb-3">
          <ListHeader
            canAdd={canAdd}
            handleOnChangeSearch={handleChangeSearch}
            handleSearch={handleSearch}
            searchTxt={searchTxt}
            toggleModalCreate={toggleModalCreate}
          />
        </div>
        <div className="bg-white p-3 pt-0">
          {!movements.error && isLoading ? (
            <div className="my-5 text-center">
              <Loading isLoading={isLoading} size={2} />
            </div>
          ) : movements.error && !isLoading ? (
            <div className="my-5 text-center">
              <h1>Error intenta mas tarde</h1>
            </div>
          ) : (
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr>
                    <th style={styleCenterTable}>Numero</th>
                    <th style={styleCenterTable}>Motivo</th>
                    <th style={styleCenterTable}>Descripción</th>
                    <th style={styleCenterTable}>Almacén Origen</th>
                    <th style={styleCenterTable}>Almacén Destino</th>
                    <th style={styleCenterTable}>Fecha</th>
                    <th style={styleCenterTable}>Usuario</th>
                  </tr>
                </thead>
                <tbody>
                  {movements?.data?.results?.length > 0 ? (
                    movements?.data?.results?.map((item) => {
                      return <MovementItemList key={item.id} item={item} />
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No hay datos
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <div className="text-center">
                {(movements.data?.next || movements.data?.previous) && (
                  <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                    {movements.data?.previous && (
                      <PaginationItem>
                        <PaginationLink previous onClick={() => handleSearch(movements.data?.previous.split("?")[1])} />
                      </PaginationItem>
                    )}
                    {movements.data?.next && (
                      <PaginationItem>
                        <PaginationLink next onClick={() => handleSearch(movements.data?.next.split("?")[1])} />
                      </PaginationItem>
                    )}
                  </Pagination>
                )}
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

export default MovementsBetweenStoresList
