import * as Yup from "yup"

const messages = {
  email: "Ingresa un email válido.",
  required: "Este campo es obligatorio.",
}

const schema = Yup.object({
  email: Yup.string().email(messages.email).required(messages.required),
  password: Yup.string().required(messages.required),
})

export default schema
