import React, { useState, useCallback } from "react"
import {
  Alert,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import storage from "services/storage"
import { authUserKey } from "config/API"
import { debounce } from "lodash"
import { Loading } from "components"

import { useQuery } from "@tanstack/react-query"
import ListHeader from "ui-componets/list-header"
import { getDailyCashCuts, getManualCashCuts, getMonthlyCashCuts } from "services/cash-cuts"
import CashCutItemList from "components/sales/cashCut-item-list"

const CashCutList = () => {
  document.title = "Cortes de Caja | Punto de venta"
  const [filters, setFilters] = useState("")
  const [searchTxt, setSearchTxt] = useState(new URLSearchParams(Location.search).get("search") ?? "")
  const permissions = storage.get(authUserKey).user_permissions || []
  const cashCuts = useQuery(["cash-cuts-daily", filters, filters.toString()], () => getDailyCashCuts(filters))
  const manualCashCuts = useQuery(["cash-cuts-manual"], () => getManualCashCuts(filters))
  const monthlyCashCuts = useQuery(["cash-cuts-monthly"], () => getMonthlyCashCuts(filters))
  const styleCenterTable = { textAlign: "center", verticalAlign: "middle" }
  const [tabState, settabState] = useState({ activeTab: "manual" })

  const handleSearch = (pageParams) => {
    const params = new URLSearchParams(filters)
    if (pageParams) {
      const newParams = new URLSearchParams(pageParams)
      window.history.replaceState({}, null, `${Location.pathname}?${newParams}`)
      setFilters(newParams)
    } else if (searchTxt) {
      params.set("search", searchTxt)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    } else {
      if (params.get("search")) {
        params.delete("offset")
        params.delete("per_page")
        params.delete("search")
        window.history.replaceState({}, null, `${Location.pathname}?${params}`)
        setFilters(params)
      }
    }
  }

  const debouncedSearch = useCallback(
    debounce((text) => {
      const params = new URLSearchParams()
      params.set("search", text)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    }, 300),
    []
  )

  function handleChangeSearch(event) {
    setSearchTxt(event.target.value)
    debouncedSearch(event.target.value)
  }

  const toggleActiveTab = (tab) => {
    if (tabState.activeTab !== tab) {
      settabState({
        activeTab: tab,
      })
    }
  }

  return !permissions.some((e) => e.codename === "view_cashcut") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="page-content">
      <Container fluid>
        <Alert color="danger" isOpen={cashCuts.isError}>
          {cashCuts.error?.errors?.map((error) => error?.message)}
        </Alert>

        <h2>Lista de Cortes de Caja</h2>
        <div className="bg-white p-3">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={` ${tabState.activeTab === "manual" && "active"}`}
                onClick={() => toggleActiveTab("manual")}
              >
                Manual
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={` ${tabState.activeTab === "diario" && "active"}`}
                onClick={() => toggleActiveTab("diario")}
              >
                Diario
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={` ${tabState.activeTab === "mensual" && "active"}`}
                onClick={() => toggleActiveTab("mensual")}
              >
                Mensual
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={tabState.activeTab}>
            <TabPane tabId="manual">
              <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
              <ListHeader handleOnChangeSearch={handleChangeSearch} handleSearch={handleSearch} searchTxt={searchTxt} />
              {!manualCashCuts.error && manualCashCuts.isLoading ? (
                <div className="my-5 text-center">
                  <Loading isLoading={manualCashCuts.isLoading} size={2} />
                </div>
              ) : manualCashCuts.error && !manualCashCuts.isLoading ? (
                <div className="my-5 text-center">
                  <h1>Error intenta mas tarde</h1>
                </div>
              ) : (
                <div style={{ marginTop: "30px" }} className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th style={styleCenterTable}>Fecha</th>
                        <th style={styleCenterTable}>Total General</th>
                        <th style={styleCenterTable}>Total Tickets</th>
                        <th style={styleCenterTable}>Total Facturas</th>
                        <th style={styleCenterTable}>Pago Tarjeta</th>
                        <th style={styleCenterTable}>Pago Efectivo</th>
                        <th style={styleCenterTable}>Usuario</th>
                      </tr>
                    </thead>
                    <tbody>
                      {manualCashCuts.data?.results.filter((item) => item.uuid_invoice !== null).length > 0 ? (
                        manualCashCuts.data?.results
                          .filter((item) => item.uuid_invoice !== null)
                          .map((item) => {
                            return <CashCutItemList key={item.id} item={item} />
                          })
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No hay datos
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <div className="text-center">
                    {(manualCashCuts.data?.next || manualCashCuts.data?.previous) && (
                      <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                        {manualCashCuts.data?.previous && (
                          <PaginationItem>
                            <PaginationLink
                              previous
                              onClick={() => handleSearch(manualCashCuts.data?.previous.split("?")[1])}
                            />
                          </PaginationItem>
                        )}
                        {manualCashCuts.data?.next && (
                          <PaginationItem>
                            <PaginationLink
                              next
                              onClick={() => handleSearch(manualCashCuts.data?.next.split("?")[1])}
                            />
                          </PaginationItem>
                        )}
                      </Pagination>
                    )}
                  </div>
                </div>
              )}
            </TabPane>
            <TabPane tabId="diario">
              <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
              <ListHeader handleOnChangeSearch={handleChangeSearch} handleSearch={handleSearch} searchTxt={searchTxt} />
              {!cashCuts.error && cashCuts.isLoading ? (
                <div className="my-5 text-center">
                  <Loading isLoading={cashCuts.isLoading} size={2} />
                </div>
              ) : cashCuts.error && !cashCuts.isLoading ? (
                <div className="my-5 text-center">
                  <h1>Error intenta mas tarde</h1>
                </div>
              ) : (
                <div style={{ marginTop: "30px" }} className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th style={styleCenterTable}>Fecha</th>
                        <th style={styleCenterTable}>Total General</th>
                        <th style={styleCenterTable}>Total Tickets</th>
                        <th style={styleCenterTable}>Total Facturas</th>
                        <th style={styleCenterTable}>Pago Tarjeta</th>
                        <th style={styleCenterTable}>Pago Efectivo</th>
                        <th style={styleCenterTable}>Usuario</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cashCuts.data?.results.filter((item) => item.uuid_invoice !== null).length > 0 ? (
                        cashCuts.data?.results
                          .filter((item) => item.uuid_invoice !== null)
                          .map((item) => {
                            return <CashCutItemList key={item.id} item={item} />
                          })
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No hay datos
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <div className="text-center">
                    {(cashCuts.data?.next || cashCuts.data?.previous) && (
                      <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                        {cashCuts.data?.previous && (
                          <PaginationItem>
                            <PaginationLink
                              previous
                              onClick={() => handleSearch(cashCuts.data?.previous.split("?")[1])}
                            />
                          </PaginationItem>
                        )}
                        {cashCuts.data?.next && (
                          <PaginationItem>
                            <PaginationLink next onClick={() => handleSearch(cashCuts.data?.next.split("?")[1])} />
                          </PaginationItem>
                        )}
                      </Pagination>
                    )}
                  </div>
                </div>
              )}
            </TabPane>
            <TabPane tabId="mensual">
              <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
              <ListHeader handleOnChangeSearch={handleChangeSearch} handleSearch={handleSearch} searchTxt={searchTxt} />
              {!monthlyCashCuts.error && monthlyCashCuts.isLoading ? (
                <div className="my-5 text-center">
                  <Loading isLoading={monthlyCashCuts.isLoading} size={2} />
                </div>
              ) : monthlyCashCuts.error && !monthlyCashCuts.isLoading ? (
                <div className="my-5 text-center">
                  <h1>Error intenta mas tarde</h1>
                </div>
              ) : (
                <div style={{ marginTop: "30px" }} className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th style={styleCenterTable}>Fecha</th>
                        <th style={styleCenterTable}>Total General</th>
                        <th style={styleCenterTable}>Total Tickets</th>
                        <th style={styleCenterTable}>Total Facturas</th>
                        <th style={styleCenterTable}>Pago Tarjeta</th>
                        <th style={styleCenterTable}>Pago Efectivo</th>
                        <th style={styleCenterTable}>Usuario</th>
                      </tr>
                    </thead>
                    <tbody>
                      {monthlyCashCuts.data?.results.filter((item) => item.uuid_invoice !== null).length > 0 ? (
                        monthlyCashCuts.data?.results
                          .filter((item) => item.uuid_invoice !== null)
                          .map((item) => {
                            return <CashCutItemList key={item.id} item={item} />
                          })
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No hay datos
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <div className="text-center">
                    {(monthlyCashCuts.data?.next || monthlyCashCuts.data?.previous) && (
                      <Pagination aria-label="Page navigation" className="d-flex justify-content-center">
                        {monthlyCashCuts.data?.previous && (
                          <PaginationItem>
                            <PaginationLink
                              previous
                              onClick={() => handleSearch(monthlyCashCuts.data?.previous.split("?")[1])}
                            />
                          </PaginationItem>
                        )}
                        {monthlyCashCuts.data?.next && (
                          <PaginationItem>
                            <PaginationLink
                              next
                              onClick={() => handleSearch(monthlyCashCuts.data?.next.split("?")[1])}
                            />
                          </PaginationItem>
                        )}
                      </Pagination>
                    )}
                  </div>
                </div>
              )}
            </TabPane>
          </TabContent>
        </div>
      </Container>
    </div>
  )
}

export default CashCutList
