import React, { useState, useCallback } from "react"
import { useLocation } from "react-router-dom"
import { Formik, Form } from "formik"
import {
  Alert,
  Container,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import { useQuery } from "@tanstack/react-query"
import Select from "react-select"
import { debounce } from "lodash"
import Swal from "sweetalert2"

import TicketItemList from "components/sales/ticket-item-list"
import storage from "services/storage"
import { authUserKey } from "config/API"
import { Loading } from "components"
import ListHeader from "ui-componets/list-header"
import { getPaidTickets, createInvoice } from "services/tickets"
import TicketByUser from "./ticket-by-user"
import TicketRetrive from "./ticketRetrive"

const TicketList = () => {
  document.title = "Lista de Ventas | Punto de venta"

  const Location = useLocation()
  const [filters, setFilters] = useState("")
  const [searchTxt, setSearchTxt] = useState(new URLSearchParams(Location.search).get("search") ?? "")

  const permissions = storage.get(authUserKey).user_permissions || []
  const tickets = useQuery(["tickets", filters, filters.toString()], () => getPaidTickets(filters))
  const isLoading = tickets.isLoading
  const [modalInvoiceOpen, setModalInvoiceOpen] = useState(false)
  const [modalTicketOpen, setModalTicketOpen] = useState(false)
  const [ticketId, setTicketId] = useState(null)
  const [state, setState] = useState({ activeTab: "sales" })
  const initialValues = {
    payForm: null,
    voucherType: null,
    paymentMethod: null,
  }
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })
  const StyleTableHeaders = {
    position: "sticky",
    background: "white",
    top: "0px",
    zIndex: "3",
    textAlign: "center",
    verticalAlign: "middle",
  }

  const handleSubmit = async (values) => {
    const payload = {
      pay_form: values.payForm.value,
      voucher_type: values.voucherType.value,
      pay_method: values.paymentMethod.value,
    }
    try {
      await createInvoice(ticketId, payload)
      Toast.fire({
        icon: "success",
        title: "Venta realizada correctamente",
      })
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error,
      })
    }
  }

  const openInvoiceModal = (id) => {
    setTicketId(id)
    setModalInvoiceOpen(true)
  }

  const openTicketModal = (id) => {
    setTicketId(id)
    setModalTicketOpen(true)
  }

  const closeInvoiceModal = () => {
    setModalInvoiceOpen(false)
  }

  const closeTicketModal = () => {
    setModalTicketOpen(false)
  }
  const handleSearch = (pageParams) => {
    const params = new URLSearchParams(filters)
    if (pageParams) {
      const newParams = new URLSearchParams(pageParams)
      window.history.replaceState({}, null, `${Location.pathname}?${newParams}`)
      setFilters(newParams)
    } else if (searchTxt) {
      params.set("search", searchTxt)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    } else {
      if (params.get("search")) {
        params.delete("offset")
        params.delete("per_page")
        params.delete("search")
        window.history.replaceState({}, null, `${Location.pathname}?${params}`)
        setFilters(params)
      }
    }
  }

  const debouncedSearch = useCallback(
    debounce((text) => {
      const params = new URLSearchParams()
      params.set("search", text)
      params.delete("offset")
      params.delete("per_page")
      window.history.replaceState({}, null, `${Location.pathname}?${params}`)
      setFilters(params)
    }, 300),
    []
  )

  function handleChangeSearch(event) {
    setSearchTxt(event.target.value)
    debouncedSearch(event.target.value)
  }

  const toggleActiveTab = (tab) => {
    if (state.activeTab !== tab) {
      setState({
        activeTab: tab,
      })
    }
  }

  return !permissions.some((e) => e.codename === "view_ticket") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="page-content">
      <Container fluid>
        <Alert color="danger" isOpen={tickets.isError}>
          {tickets.error?.errors?.map((error) => error?.message)}
        </Alert>

        <h2>Lista de Ventas</h2>
        <div className="bg-white p-3">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={`${state.activeTab === "sales" && "active"}`}
                onClick={() => {
                  toggleActiveTab("sales")
                }}
              >
                Ventas
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${state.activeTab === "cashierSales" && "active"}`}
                onClick={() => {
                  toggleActiveTab("cashierSales")
                }}
              >
                Ventas por cajero
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={state.activeTab}>
            <TabPane tabId="sales">
              <div style={{ marginTop: "20px", marginBottom: "20px" }}></div>
              <ListHeader handleOnChangeSearch={handleChangeSearch} handleSearch={handleSearch} searchTxt={searchTxt} />
              {!tickets.error && isLoading ? (
                <div className="my-5 text-center">
                  <Loading isLoading={isLoading} size={2} />
                </div>
              ) : tickets.error && !tickets.isLoading ? (
                <div className="my-5 text-center">
                  <h1>Error intenta mas tarde</h1>
                </div>
              ) : (
                <div
                  style={{ height: "calc(100vh - 360px)", overflow: "auto", marginTop: "30px", marginBottom: "20px" }}
                  className="table-responsive"
                >
                  <Table>
                    <thead>
                      <tr>
                        <th style={StyleTableHeaders}>Folio</th>
                        <th style={StyleTableHeaders}>Fecha de creación</th>
                        <th style={StyleTableHeaders}>Cajero</th>
                        <th style={StyleTableHeaders}>Cliente</th>
                        <th style={StyleTableHeaders}>Total</th>
                        <th style={StyleTableHeaders}>Facturado</th>
                        <th style={StyleTableHeaders}>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tickets.data?.results.length > 0 ? (
                        tickets.data?.results.map((item) => {
                          return (
                            <TicketItemList
                              key={item.id}
                              item={item}
                              openInvoiceModal={openInvoiceModal}
                              isOnlySeller={false}
                              openTicketModal={openTicketModal}
                            />
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No hay datos
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {modalInvoiceOpen && (
                    <Modal
                      centered={true}
                      size="lg"
                      backdrop="static"
                      isOpen={modalInvoiceOpen}
                      toggle={closeInvoiceModal}
                    >
                      <ModalHeader toggle={closeInvoiceModal}>Facturar Ticket</ModalHeader>
                      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                        {({ values, setFieldValue }) => (
                          <Form>
                            <ModalBody>
                              <Label>Forma de pago</Label>
                              <Select
                                id="payForm"
                                name="payForm"
                                isClearable
                                value={values.payForm}
                                onChange={(val) => {
                                  setFieldValue("payForm", val)
                                }}
                                options={[
                                  {
                                    value: "01",
                                    label: "Efectivo",
                                  },
                                  {
                                    value: "02",
                                    label: "Cheque nominativo",
                                  },
                                  {
                                    value: "03",
                                    label: "Transferencia electrónica de fondos",
                                  },
                                  {
                                    value: "04",
                                    label: "Tarjeta de crédito",
                                  },
                                  {
                                    value: "99",
                                    label: "Por definir",
                                  },
                                ]}
                                placeholder="Forma de pago"
                              />
                              <Label className="mt-2">Tipo de comprobante</Label>
                              <Select
                                id="voucherType"
                                name="voucherType"
                                isClearable
                                value={values.voucherType}
                                onChange={(val) => {
                                  setFieldValue("voucherType", val)
                                }}
                                options={[
                                  { value: "I", label: "Ingreso" },
                                  { value: "E", label: "Egreso" },
                                  { value: "T", label: "Traslado" },
                                  { value: "N", label: "Nómina" },
                                  { value: "P", label: "Pago" },
                                ]}
                                placeholder="Tipo de comprobante"
                              />
                              <Label className="mt-2">Método de pago</Label>
                              <Select
                                id="paymentMethod"
                                name="paymentMethod"
                                isClearable
                                value={values.paymentMethod}
                                onChange={(val) => {
                                  setFieldValue("paymentMethod", val)
                                }}
                                options={[
                                  {
                                    value: "PUE",
                                    label: "Pago en una sola exhibición",
                                  },
                                  {
                                    value: "PPD",
                                    label: "Pago en parcialidades o diferido",
                                  },
                                ]}
                                placeholder="Método de pago"
                              />
                              <div className="d-flex justify-content-end">
                                <Button type="submit" color="success" style={{ marginTop: "10px" }}>
                                  Facturar
                                </Button>
                              </div>
                            </ModalBody>
                          </Form>
                        )}
                      </Formik>
                    </Modal>
                  )}
                  {modalTicketOpen && (
                    <Modal
                      centered={true}
                      size="lg"
                      backdrop={true}
                      isOpen={modalTicketOpen}
                      toggle={closeTicketModal}
                    >
                      <ModalBody style={{padding: "0px"}}>
                        <TicketRetrive id={ticketId} />
                      </ModalBody>
                    </Modal>
                  )}
                  <div className="text-center">
                    {(tickets.data?.next || tickets.data?.previous) && (
                      <Pagination size="lg" aria-label="Page navigation" className="d-flex justify-content-center">
                        {tickets.data?.previous && (
                          <PaginationItem>
                            <PaginationLink
                              previous
                              onClick={() => handleSearch(tickets.data?.previous.split("?")[1])}
                            />
                          </PaginationItem>
                        )}
                        {tickets.data?.next && (
                          <PaginationItem>
                            <PaginationLink next onClick={() => handleSearch(tickets.data?.next.split("?")[1])} />
                          </PaginationItem>
                        )}
                      </Pagination>
                    )}
                  </div>
                </div>
              )}
            </TabPane>
            <TabPane tabId="cashierSales">
              <TicketByUser />
            </TabPane>
          </TabContent>
        </div>
      </Container>
    </div>
  )
}

export default TicketList
