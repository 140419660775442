import propTypes from "prop-types"
import { useEffect } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useMutation, useQuery } from "@tanstack/react-query"

import { createProduct } from "services/products"
import { getAllPrices } from "services/prices"
import ProductsForm from "./ProductsForm"

const CreateModalForm = ({ isOpen, toggle, setShowAlertSuccess, setMsgSuccess }) => {
  const prices = useQuery(["prices"], () => getAllPrices())
  const { isLoading, isError, error, isSuccess, mutateAsync } = useMutation(createProduct)

  const submit = async (data) => {
    const dataTemp = { ...data }
    dataTemp.providers = dataTemp.providers ? dataTemp.providers?.map((provider) => provider.id) : null
    dataTemp.brand = dataTemp?.brand?.value || null
    dataTemp.cost = parseFloat(dataTemp.cost.replace(/,/g, "")) || null
    dataTemp.unit_measurement = dataTemp?.unit_measurement?.value || null
    dataTemp.prices = dataTemp.prices || []
    dataTemp.prices.forEach((item, index) => {
      dataTemp.prices[index].amount = parseFloat(item.amount)
      if (index === 0){
        dataTemp.prices[index].quantity = 1
      }
    })
    dataTemp.taxes = dataTemp.taxes ? dataTemp.taxes?.map((tax) => tax.id) : null
    await mutateAsync(dataTemp)
  }

  useEffect(() => {
    if (isSuccess) {
      setMsgSuccess("Producto creado satisfactoriamente")
      setShowAlertSuccess(true)
      toggle()
    }
  }, [isSuccess])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="xl" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Agregar Producto
      </ModalHeader>
      <ModalBody>
        <ProductsForm
          isRetrivePage={false}
          submit={submit}
          toggle={toggle}
          isLoading={isLoading}
          isError={isError}
          error={error}
          prices={prices}
        />
      </ModalBody>
    </Modal>
  )
}

CreateModalForm.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  setShowAlertSuccess: propTypes.func,
  setMsgSuccess: propTypes.func,
}

export default CreateModalForm
