import { useState, useEffect } from "react"
import { useParams, useHistory, Link } from "react-router-dom"
import { Container, Alert, Row, Col, Button } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import Swal from "sweetalert2"

import { getStore, editStore, deleteStore } from "services/stores"
import { Loading } from "components"
import { StoreForm } from "components/stores"
import storage from "services/storage"
import { authUserKey } from "config/API"

const Store = () => {
  document.title = "Almacén | Punto de venta"

  const [successAlert, setSuccessAlert] = useState(false)

  const { id } = useParams()
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })
  const history = useHistory()

  const { data, error, isLoading, isError, refetch } = useQuery(["stores", id], () => getStore(id))
  const deletedStore = useMutation((id) => deleteStore(id))

  const permissions = storage.get(authUserKey).user_permissions || []
  const canDelete = permissions.some((e) => e.codename === "delete_store")

  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["stores", id], (data) => editStore(id, data))

  useEffect(() => {
    if (isSuccessEdit) {
      refetch()
      setSuccessAlert(true)
    }
  }, [isSuccessEdit])

  useEffect(() => {
    if (successAlert) {
      Toast.fire({
        icon: "success",
        title: "Almacén modificado satisfactoriamente",
        didOpen: () => {
          refetch()
          setSuccessAlert(false)
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "green",
      })
    }
  }, [successAlert])

  const openDeleteModal = (store) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar el almacén: <b>${store.name}</b>?`,
      text: "Este cambio no se podrá revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        deletedStore.mutateAsync(store?.id).then(() => {
          Toast.fire("!Eliminado!", `El almacén <b>${store.name}</b> ha sido eliminado correctamente`, "success").then(
            () => {
              history.push(`/branch-offices/${store.branch_office.id}`)
            }
          )
        })
      }
    })
  }

  const submit = async (data) => {
    await mutateAsync(data)
  }

  const renderError = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={isError}>
          {error?.errors?.map((error) => error?.message)}
        </Alert>
      </div>
    )
  }

  return !permissions.some((e) => e.codename === "view_store") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="page-content">
      <Container fluid>
        <Row style={{ marginBottom: "20px" }}>
          <Col className="text-start">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link
                to={{ pathname: `/branch-offices/${data?.branch_office.id}` }}
                className="btn btn-primary"
                style={{ marginRight: "10px" }}
              >
                <i className="fa fa-arrow-left" /> Volver
              </Link>
              <h1 style={{ marginBottom: "0" }}>Inventario: {data?.name}</h1>
            </div>
          </Col>
        </Row>
        <div className="bg-white p-3">
          {isError ? (
            renderError()
          ) : isLoading ? (
            <div className="text-center">
              <Loading isLoading={isLoading} size={2} />
            </div>
          ) : (
            <>
              <Row>
                <Col className="text-end">
                  {canDelete && (
                    <Button color="danger" onClick={() => openDeleteModal(data)} disabled={isLoading}>
                      <i className="fa fa-trash" /> Eliminar
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <StoreForm
                    data={data}
                    error={errorEdit}
                    isError={isErrorEdit}
                    isLoading={isLoadingEdit}
                    isRetrivePage={true}
                    refetchData={refetch}
                    submit={submit}
                    disabledForm={!permissions.some((e) => e.codename === "change_store")}
                  />
                </Col>
              </Row>
            </>
          )}
        </div>
      </Container>
    </div>
  )
}

export default Store
