import propTypes from "prop-types"
import { Formik } from "formik"
import { useMutation } from "@tanstack/react-query"
import { useState, useEffect } from "react"
import { Alert, Button, Col, Form, Input, Label, Row, Table } from "reactstrap"
import Swal from "sweetalert2"

import { Loading, RequiredIcon } from "components"
import ErrorsText from "components/ErrorsText"
import { deleteStore } from "services/stores"
import { CreateModalStores, EditModalForm } from "components/branches/ModalStores"

import schema from "./schema"
import storage from "services/storage"
import { authUserKey } from "config/API"

const BranchRetrieveForm = (props) => {
  const { data, disabledForm, error, isError, isLoading, isRetrivePage, refetchData, submit, toggle } = props
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const [filters, setfilters] = useState("")
  const [inputSelect, setInputSelect] = useState("")
  const [modalStoresCreate, setmodalStoresCreate] = useState(false)
  const [modalStoresEdit, setmodalStoresEdit] = useState(false)
  const [storeId, setstoreId] = useState(false)
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState("")

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })
  const storeDeleted = useMutation((id) => deleteStore(id))

  const permissions = storage.get(authUserKey).user_permissions || []
  const canEdit = permissions.some((e) => e.codename === "change_store")
  const canDelete = permissions.some((e) => e.codename === "delete_store")
  const canAdd = permissions.some((e) => e.codename === "add_store")

  const openDeleteModal = (store) => {
    Swal.fire({
      title: `¿Está seguro que desea eliminar el inventario: <b>${store.name}</b>?`,
      text: "Este cambio no se podrá revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        storeDeleted
          .mutateAsync(store?.id)
          .then(() => {
            refetchData()
            Toast.fire("!Eliminado!", `El inventario <b>${store.name}</b> ha sido eliminado correctamente`, "success")
          })
          .catch((error) => {
            console.error(error)
            Toast.fire("!Erorr!", error.errors[0].message, "error")
          })
      }
    })
  }

  const toggleModalStoresCreate = () => {
    setmodalStoresCreate(!modalStoresCreate)
  }

  const toggleModalStoresEdit = () => {
    setmodalStoresEdit(!modalStoresEdit)
  }

  useEffect(() => {
    if (showAlertSuccess) {
      Toast.fire({
        icon: "success",
        title: msgSuccess,
        didOpen: () => {
          refetchData()
          setShowAlertSuccess(false)
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "green",
      })
    }
  }, [showAlertSuccess])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const params = new URLSearchParams("")
      params.set("search", inputSelect)
      setfilters(filters)
      setfilters(params)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [inputSelect])

  useEffect(() => {
    if (isError && error?.error_type !== "ValidationError") {
      setInputSelect()
      setShowErrorAlert(true)
    } else if (isError && error?.error_type === "ValidationError") {
      setValidationErrors(error.errors)
    } else if (!isError) {
      setValidationErrors([])
      setShowErrorAlert(false)
    }
  }, [isError])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: data?.name ?? "",
        location: data?.location ?? "",
      }}
      validationSchema={schema}
      onSubmit={submit}
    >
      {({ values, handleChange, handleSubmit, handleBlur, touched, errors }) => (
        <Form className="form-horizontal" onSubmit={handleSubmit}>
          <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
            {isError && error.errors?.map((error) => error?.message)}
          </Alert>
          {data?.id && (
            <>
              {!disabledForm && (
                <CreateModalStores
                  isOpen={modalStoresCreate}
                  toggle={toggleModalStoresCreate}
                  branchId={data?.id}
                  setShowAlertSuccess={setShowAlertSuccess}
                  setMsgSuccess={setMsgSuccess}
                />
              )}
              {!disabledForm && storeId && (
                <EditModalForm
                  isOpen={modalStoresEdit}
                  toggle={toggleModalStoresEdit}
                  storeId={storeId}
                  setShowAlertSuccess={setShowAlertSuccess}
                  setMsgSuccess={setMsgSuccess}
                />
              )}
            </>
          )}
          <Row className="mb-3">
            <Col>
              <Label className="form-label">
                Nombre de la sucursal <RequiredIcon />
              </Label>
              <Input
                autoComplete="do-not-autofill"
                className="form-control"
                disabled={disabledForm}
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.name}
                invalid={
                  !!(touched.name && errors.name) ||
                  (isError && validationErrors.findIndex((e) => e.field === "name") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="name"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
            <Col>
              <Label className="form-label">Domicilio</Label>
              <Input
                autoComplete="do-not-autofill"
                className="form-control"
                disabled={disabledForm}
                name="location"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.location}
                invalid={
                  !!(touched.location && errors.location) ||
                  (isError && validationErrors.findIndex((e) => e.field === "location") >= 0)
                }
              />
              <ErrorsText
                errorsBack={error}
                errorsForm={errors}
                formName="location"
                isErrorBack={isError}
                touched={touched}
              />
            </Col>
          </Row>

          <hr />

          <Row className="mb-3">
            <Col>
              <Row>
                <Col>
                  <h5>Lista de inventarios</h5>
                </Col>
                <Col className="text-end">
                  {canAdd && !disabledForm && data?.id && (
                    <Button
                      color="info"
                      onClick={() => {
                        toggleModalStoresCreate()
                      }}
                    >
                      <i className="fa fa-plus" /> Agregar
                    </Button>
                  )}
                </Col>
              </Row>

              <Table striped>
                <thead>
                  <tr>
                    <th>Codigo</th>
                    <th>Nombre</th>
                    <th>Administrador</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.stores?.map((store) => (
                    <tr key={store.id}>
                      <td>{store?.code}</td>
                      <td>{store?.name}</td>
                      <td>{store?.manager}</td>
                      <td>
                        {canEdit && !disabledForm && (
                          <Button
                            className="mx-1"
                            color="info"
                            size="sm"
                            onClick={() => {
                              setstoreId(store?.id)
                              toggleModalStoresEdit()
                            }}
                          >
                            <i className="fa fa-edit" />
                          </Button>
                        )}
                        {data?.stores.length > 1
                          ? canDelete &&
                            !disabledForm && (
                              <Button
                                className="mx-1"
                                color="danger"
                                size="sm"
                                onClick={() => {
                                  openDeleteModal(store)
                                }}
                              >
                                <i className="fa fa-trash" />
                              </Button>
                            )
                          : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>

          <hr />

          <div className="mt-3 text-end">
            {!isRetrivePage && (
              <Button className="mx-3" color="secondary" onClick={toggle} disabled={isLoading}>
                <i className="fa fa-times" /> Cerrar
              </Button>
            )}
            {!disabledForm && (
              <Button color="success" type="submit" disabled={isLoading}>
                <Loading isLoading={isLoading} /> <i className="fa fa-save" /> Guardar
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

BranchRetrieveForm.defaultProps = {
  data: null,
  isRetrivePage: false,
  toggle: () => {},
  disabledForm: false,
}

BranchRetrieveForm.propTypes = {
  data: propTypes.any,
  disabledForm: propTypes.bool,
  error: propTypes.any,
  isError: propTypes.bool,
  isLoading: propTypes.bool,
  isRetrivePage: propTypes.bool,
  refetchData: propTypes.func,
  submit: propTypes.func,
  toggle: propTypes.func,
}

export default BranchRetrieveForm
