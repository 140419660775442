import { Formik } from "formik"
import { useResetPassword } from "hooks/auth"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Button, Form, FormFeedback, Input, Label, Alert } from "reactstrap"
import { Loading } from "components"

import schema from "./schema"

const LoginForm = (props) => {
  const { token } = useParams()
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const resetPassword = useResetPassword()

  useEffect(() => {
    const wrongArguments = resetPassword.error?.error_type === "WrongArguments"
    if (resetPassword.isError && wrongArguments) setShowErrorAlert(true)
  }, [resetPassword.isError])

  useEffect(() => {
    if (resetPassword.isSuccess) setShowSuccessAlert(true)
  }, [resetPassword.isSuccess])

  const handleOnSubmit = (values) => {
    values.token = token
    resetPassword.mutate(values)
  }

  const isLoading = resetPassword.isLoading

  return (
    <Formik
      enableReinitialize
      initialValues={{
        new_password: "",
        password_confirm: "",
      }}
      validationSchema={schema}
      onSubmit={handleOnSubmit}
    >
      {({ values, handleChange, handleSubmit, handleBlur, touched, errors }) => (
        <Form className="form-horizontal" onSubmit={handleSubmit}>
          <Alert color="danger" isOpen={showErrorAlert} toggle={() => setShowErrorAlert(false)}>
            {resetPassword.error?.errors?.map((error) => error?.message)}
          </Alert>
          <Alert color="success" isOpen={showSuccessAlert} toggle={() => showSuccessAlert(false)}>
            La contraseña se cambio correctamente. En un momento estaras en la pantalla de inicio de sesión.
          </Alert>

          <div className="mb-3">
            <Label className="form-label">Contraseña</Label>
            <Input
              name="new_password"
              value={values.new_password}
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={!!(touched.new_password && errors.new_password)}
            />
            {touched.new_password && errors.new_password && (
              <FormFeedback type="invalid">{errors.new_password}</FormFeedback>
            )}
          </div>

          <div className="mb-3">
            <Label className="form-label">Confirma Contraseña</Label>
            <Input
              name="password_confirm"
              value={values.password_confirm}
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={!!(touched.password_confirm && errors.password_confirm)}
            />
            {touched.password_confirm && errors.password_confirm && (
              <FormFeedback type="invalid">{errors.password_confirm}</FormFeedback>
            )}
          </div>

          <div className="mt-3 d-grid">
            <Button color="dark" type="submit" disabled={isLoading}>
              <Loading isLoading={isLoading} /> Aceptar
            </Button>
          </div>

          {/* <div className="mt-4 text-center">
            <Link to="/login" className="text-muted">
              <i className="mdi mdi-lock me-1" />
              Inicia sesión!
            </Link>
          </div> */}
        </Form>
      )}
    </Formik>
  )
}

export default LoginForm
