import { API } from "config/API"

export const getAllLogs = async (filters) => {
  const response = await API.get("/logs-importation/all", { params: filters })
  return response
}

export const getLogs = async (filters) => {
  const response = await API.get("/logs-importation", { params: filters })
  return response
}

export const getLog = async (id) => {
  const response = await API.get(`/logs-importation/${id}`)
  return response
}

export const createLog = async (payload) => {
  const response = await API.post("/logs-importation", payload)
  return response
}

export const editLog = async (id, payload) => {
  const response = await API.patch(`/logs-importation/${id}`, payload)
  return response
}

export const deleteLog = async (id) => {
  const response = await API.delete(`/logs-importation/${id}`)
  return response
}
