import * as Yup from "yup"

import { messages } from "utils/messages"

const schema = Yup.object({
  name: Yup.string().required(messages.required),
  description: Yup.string().max(255, messages.max_chars(255)),
  is_active: Yup.boolean(),
  identifier: Yup.string().max(70, messages.max_chars(70)).required(messages.required),
  discount_volume: Yup.boolean(),
})

export default schema
