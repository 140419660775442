import * as Yup from "yup"

import { messages } from "utils/messages"

const schema = Yup.object({
  code: Yup.string().max(30, messages.max_chars(30)).required(messages.required),
  name: Yup.string().max(80, messages.max_chars(80)).required(messages.required),
  manager: Yup.string().max(255, messages.max_chars(255)),
})

export default schema
