import { useParams, useHistory, Link } from "react-router-dom"
import { Container, Alert, Row, Col, Button } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { deleteInvoice, getInvoice } from "services/invoices"
import { Loading } from "components"
import storage from "services/storage"
import { authUserKey } from "config/API"
import ProductTable from "components/invoices/invoice"

const InvoiceRetrive = () => {
  document.title = "Factura | Punto de venta"
  const { id } = useParams()
  const modalDelete = withReactContent(Swal)
  const history = useHistory()
  const { data, error, isLoading, isError, refetch } = useQuery(["invoice", id], () => getInvoice(id))
  const invoiceDeleted = useMutation((id) => deleteInvoice(id))
  const permissions = storage.get(authUserKey).user_permissions || []

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer)
      toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
  })

  const renderDeleteModal = (invoice) => {
    modalDelete
      .fire({
        title: `¿Está seguro que desea eliminar la factura: <b>${invoice?.number_invoice}</b>?`,
        text: "Este cambio no se podrá revertir",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      })
      .then((result) => {
        if (result.isConfirmed) {
          invoiceDeleted.mutateAsync(invoice?.id).then(() => {
            Toast.fire({
              icon: "success",
              title: `La factura <b>${invoice.number_invoice}</b> ha sido eliminado correctamente`,
            })
            history.push("/invoices")
          })
        }
      })
  }

  const renderError = () => {
    return (
      <div className="text-center">
        <Alert color="danger" isOpen={isError}>
          {error?.errors?.map((error) => error?.message)}
        </Alert>
      </div>
    )
  }

  return !permissions.some((e) => e.codename === "view_invoice") ? (
    <div className="page-content">
      <div className="text-center">
        <h1>No autorizado para ver esta pagina</h1>
      </div>
    </div>
  ) : (
    <div className="page-content">
      <Container fluid>
        <Row style={{ marginBottom: "20px" }}>
          <Col className="text-start">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link to="/invoices" className="btn btn-primary" style={{ marginRight: "10px" }}>
                <i className="fa fa-arrow-left" /> Volver
              </Link>
              <h1 style={{ marginBottom: "0" }}>Factura: {data?.number_invoice}</h1>
            </div>
          </Col>
        </Row>
        <div className="bg-white p-3">
          {isError ? (
            renderError()
          ) : isLoading ? (
            <div className="text-center">
              <Loading isLoading={isLoading} size={2} />
            </div>
          ) : (
            <>
              <Row>
                <Col className="text-end">
                  {permissions.some((e) => e.codename === "delete_invoice") && (
                    <Button color="danger" onClick={() => renderDeleteModal(data)} disabled={isLoading}>
                      <i className="fa fa-trash" /> Eliminar
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5>Proveedor: {data?.provider?.name}</h5>
                  <p>RFC: {data?.provider?.rfc}</p>
                  <p>Fecha de pago: {data?.pay_date}</p>
                  <p>Fecha de ingreso: {data?.arrival_date}</p>
                </Col>
                <Col>
                  <p>Metodo de pago: {data?.payment_method}</p>
                  <p>Condición de pago: {data?.payment_condition}</p>
                  <p>Estado: {data?.status}</p>
                </Col>
              </Row>
              <br></br>
              <ProductTable products={data?.products} refetch={refetch} />
              <p className="text-end" style={{ fontSize: "1.2em" }}>
                Subtotal: {parseFloat(data?.total).toFixed(2)}
              </p>
              <p className="text-end" style={{ fontSize: "1.2em" }}>
                IVA: {parseFloat(data?.total).toFixed(2)}
              </p>
              <p className="text-end" style={{ fontSize: "1.2em" }}>
                Total: {parseFloat(data?.total).toFixed(2)}
              </p>
            </>
          )}
        </div>
      </Container>
    </div>
  )
}

export default InvoiceRetrive
