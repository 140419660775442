import propTypes from "prop-types"
import { useEffect } from "react"
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap"
import { useQuery, useMutation } from "@tanstack/react-query"
import { getCustomer, editCustomer } from "services/customers"
import { Loading } from "components"
import CustomerForm from "./CustomerForm"

const EditModalForm = ({ isOpen, toggle, customerId, setShowAlertSuccess, setMsgSuccess }) => {
  const { data, error, isLoading, isError, refetch } = useQuery(["customer", customerId], () => getCustomer(customerId))

  const {
    isLoading: isLoadingEdit,
    isError: isErrorEdit,
    error: errorEdit,
    isSuccess: isSuccessEdit,
    mutateAsync,
  } = useMutation(["customer", customerId], (data) => editCustomer(customerId, data))

  const submit = async (data) => {
    await mutateAsync({
      ...data,
      price: data.price?.value,
      credit_days: data?.credit_days || null,
      credit_limit: parseFloat(data?.credit_limit.replace(/,/g, "")) || null,
      tax_regimen: data?.tax_regimen?.value || null,
      cfdi_use: data?.cfdi_use?.value || null,
    })
  }

  useEffect(() => {
    if (isSuccessEdit) {
      setMsgSuccess("Cliente modificado satisfactoriamente")
      setShowAlertSuccess(true)
      refetch()
      toggle()
    }
  }, [isSuccessEdit])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg" backdrop="static">
      <ModalHeader toggle={toggle} charcode="X">
        Editar Cliente
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <div className="text-center">
            <Loading isLoading={isLoading} size={2} />
          </div>
        ) : isError ? (
          <div className="text-center">
            <Alert color="danger" isOpen={isError}>
              {error?.errors?.map((error) => error?.message)}
            </Alert>
          </div>
        ) : (
          <CustomerForm
            isCreateModal={false}
            submit={submit}
            toggle={toggle}
            isLoading={isLoadingEdit}
            isError={isErrorEdit}
            error={errorEdit}
            data={data}
          />
        )}
      </ModalBody>
    </Modal>
  )
}

EditModalForm.defaultProps = {
  customerId: null,
}

EditModalForm.propTypes = {
  isOpen: propTypes.any,
  toggle: propTypes.func,
  customerId: propTypes.string,
  setShowAlertSuccess: propTypes.func,
  setMsgSuccess: propTypes.func,
}

export default EditModalForm
